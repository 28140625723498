import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Quote } from '@talos/kyoko';
import {
  AllocationValueTypeEnum,
  BaseField,
  ConnectionStatusEnum,
  EMPTY_ARRAY,
  Field,
  MultiSelectorField,
  NumericField,
  OrderMarketStatusEnum,
  SelectorField,
  SideEnum,
  Unit,
  isOrder,
  isSpot,
  toBigWithDefault,
  type Allocation,
  type CareOrder,
  type ConnectionType,
  type FixingIndex,
  type Market,
  type MarketAccount,
  type Order,
  type Security,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import { streamingDataSlice } from 'providers/AppStateProvider/streamingDataSlice';
import type { AppState, AppStateListenerStart } from 'providers/AppStateProvider/types';
import { getQuantityIncrement, setGlobalSymbol } from '../Common';
import { mapAllocationsToField } from '../NewOrder/OrderSlice';
import type { PrimeOMSParams } from '../NewOrder/types';
import { initialRefDataState } from '../OMSReferenceDataSlice';
import { openView } from '../OMSSlice';
import { OMSView } from '../OMSView';
import { numberIsPositive } from '../commonFieldRules';
import type { OMSReferenceDataState } from '../types';
import { getOrderAndRFQMarketAccounts } from '../utils';
import { allocationTotalValidation, careOrderQuantityValidation, quantityValidation } from './FieldRules';
import type { RFQDependencies, RFQFormState, RFQState } from './types';

export const selectCurrentCareOrder = (state: AppState) => state.rfq.currentCareOrder;

export const getInitialState = (): RFQState => ({
  referenceData: initialRefDataState,
  dependencies: {
    tradableSubAccounts: EMPTY_ARRAY,
  },
  form: {
    symbolField: new SelectorField({ idProperty: 'Symbol' }),
    fixingIndexField: new SelectorField({ idProperty: 'Name', isVisible: true, isRequired: false }),
    sideField: new Field({ name: 'Side', isRequired: false }),
    quantityField: new NumericField({ name: 'Quantity' }),
    rfqCurrencyField: new Field(),
    rfqMarketAccountsField: new MultiSelectorField(),
    groupField: new Field({ name: 'Group', isRequired: false }),
    allocationValueTypeField: new Field({ value: AllocationValueTypeEnum.Percentage }),
    allocations: EMPTY_ARRAY,
    parentIDField: new Field({ name: 'Parent ID', isRequired: false }),
    assignToParentField: new Field({ name: 'Assign to Parent', isRequired: false }),
  },
  isLoading: false,
  currentQuote: undefined,
  currentCareOrder: undefined,
});

const initializeFieldsFromOrder = (state: WritableDraft<RFQState>, order: Order) => {
  handleSymbolChange(state, order.Symbol);

  state.form.sideField = state.form.sideField.updateValue(order.Side === SideEnum.Buy ? SideEnum.Buy : SideEnum.Sell);
  state.form.quantityField = state.form.quantityField.updateValue(
    order.OrderQty && toBigWithDefault(order.OrderQty, 0).toFixed()
  );
  state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(order.Currency);
  state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField.updateValue(
    order.Markets.filter(m => m.MarketStatus !== OrderMarketStatusEnum.Disabled).map(m => m.MarketAccount!)
  );

  if (order.SubAccount) {
    const allocations: Allocation[] = [
      {
        subAccount: order.SubAccount,
        value: '1',
      },
    ];
    state.form.allocations = mapAllocationsToField(allocations, AllocationValueTypeEnum.Percentage);
  }
  if (order.Allocation) {
    const allocations: Allocation[] = order.Allocation.Allocations.map(alloc => ({
      subAccount: alloc.SubAccount,
      value: alloc.Value || '1',
    }));
    state.form.allocationValueTypeField = state.form.allocationValueTypeField.updateValue(order.Allocation.ValueType);
    state.form.allocations = mapAllocationsToField(allocations, order.Allocation.ValueType);
  }
  state.form.groupField = state.form.groupField.updateValue(order.Group);

  state.form.parentIDField = state.form.parentIDField.updateValue(order.ParentOrderID);
  state.form.assignToParentField = state.form.assignToParentField.updateValue(order.ParentOrderID != null);
  if (order.ParentOrderID != null) {
    state.form.symbolField = state.form.symbolField.setDisabled(true);
    state.form.sideField = state.form.sideField.setDisabled(true);
  }

  validate(state);
};

const initializeFieldsFromQuote = (state: WritableDraft<RFQState>, quote: Quote) => {
  handleSymbolChange(state, quote.Symbol);

  state.form.sideField = state.form.sideField.updateValue(quote.Side);
  state.form.quantityField = state.form.quantityField.updateValue(
    quote.OrderQty && toBigWithDefault(quote.OrderQty, 0).toFixed()
  );

  state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(quote.Currency);

  state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField.updateValue(
    quote.Markets.map(m => m.MarketAccount!)
  );

  if (quote.SubAccount) {
    const allocations: Allocation[] = [
      {
        subAccount: quote.SubAccount,
        value: '1',
      },
    ];
    state.form.allocations = mapAllocationsToField(allocations, AllocationValueTypeEnum.Percentage);
  }
  if (quote.Allocation) {
    const allocations: Allocation[] = quote.Allocation.Allocations.map(alloc => ({
      subAccount: alloc.SubAccount,
      value: alloc.Value || '1',
    }));
    state.form.allocationValueTypeField = state.form.allocationValueTypeField.updateValue(quote.Allocation.ValueType);
    state.form.allocations = mapAllocationsToField(allocations, quote.Allocation.ValueType);
  }
  state.form.groupField = state.form.groupField.updateValue(quote.Group);

  state.form.parentIDField = state.form.parentIDField.updateValue(quote.ParentRFQID);
  state.form.assignToParentField = state.form.assignToParentField.updateValue(quote.ParentRFQID != null);
  if (quote.ParentRFQID != null) {
    state.form.symbolField = state.form.symbolField.setDisabled(true);
    state.form.sideField = state.form.sideField.setDisabled(true);
  }

  validate(state);
};

export const getAvailableRFQMarketAccounts = (
  marketAccounts: { marketAccountsList: MarketAccount[] },
  markets: Market[],
  availableMarketAccountNames: string[]
) => {
  return availableMarketAccountNames.filter(marketAccountName => {
    const marketAccount = marketAccounts.marketAccountsList.find(account => account.Name === marketAccountName);
    if (!marketAccount) {
      return false;
    }
    const market = markets.find(market => market.Name === marketAccount.Market);
    const isAvailable = market?.Orders?.Status !== ConnectionStatusEnum.Unavailable;
    return market != null && isAvailable;
  });
};

const validateQuantity = (state: WritableDraft<RFQState>) => {
  state.form.quantityField = state.form.quantityField.validate(
    [numberIsPositive, quantityValidation, careOrderQuantityValidation],
    state
  );
};

export const getDefaultRFQMarketAccounts = (
  isMarketOnline: (m: Market | string, connectionType: ConnectionType) => boolean,
  security: Security | undefined,
  currency: string | undefined,
  marketAccounts: {
    marketAccountsByMarket: Map<string, MarketAccount[]>;
    marketAccountsByName: Map<string, MarketAccount>;
  },
  markets: { marketsByName: Map<string, Market> },
  availableMarketAccountNames: string[],
  allowSyntheticCounterCurrency: boolean
) => {
  const { rfqMarketAccounts } = getOrderAndRFQMarketAccounts(
    security?.Markets || EMPTY_ARRAY,
    isMarketOnline,
    marketAccounts.marketAccountsByMarket
  );

  let defaultRFQMarketAccounts = [...rfqMarketAccounts];

  // If we're trying to use counter currency, but don't have synthetic counter currency enabled,
  // we need to only include markets that support it natively.
  const isUsingCounterCurrency = currency === security?.QuoteCurrency;

  if (isUsingCounterCurrency && !allowSyntheticCounterCurrency) {
    defaultRFQMarketAccounts = defaultRFQMarketAccounts.filter(marketAccountName => {
      const marketAccount = marketAccounts.marketAccountsByName.get(marketAccountName);
      if (marketAccount == null) {
        return false;
      }
      const market = markets.marketsByName.get(marketAccount.Market);
      return market?.Flags?.SupportsNativeCounterCurrency === true;
    });
  }

  const availableMarketAccountNamesSet = new Set(availableMarketAccountNames);
  defaultRFQMarketAccounts = defaultRFQMarketAccounts.filter(marketAccountName =>
    availableMarketAccountNamesSet.has(marketAccountName)
  );

  return defaultRFQMarketAccounts;
};

const disableInputFields = (state: WritableDraft<RFQState>, isDisabled: boolean) => {
  state.form.sideField = state.form.sideField.setDisabled(isDisabled);
  state.form.symbolField = state.form.symbolField.setDisabled(isDisabled);
  state.form.quantityField = state.form.quantityField.setDisabled(isDisabled);
  state.form.groupField = state.form.groupField.setDisabled(isDisabled);
  state.form.allocations = state.form.allocations.map(pair => ({
    subAccountField: pair.subAccountField.setDisabled(isDisabled),
    valueField: pair.valueField.setDisabled(isDisabled),
  }));
  state.form.allocationValueTypeField = state.form.allocationValueTypeField.setDisabled(isDisabled);
  state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField.setDisabled(isDisabled);
  state.form.fixingIndexField = state.form.fixingIndexField.setDisabled(isDisabled);
  state.form.assignToParentField = state.form.assignToParentField.setDisabled(isDisabled);
};

const validate = (state: WritableDraft<RFQState>) => {
  validateQuantity(state);
  validateAllocations(state);
};

const handleSymbolChange = (state: WritableDraft<RFQState>, symbol?: string) => {
  const security = state.form.symbolField.availableItems.find(s => s.Symbol === symbol);
  if (!security) {
    return;
  }

  if (state.form.symbolField.value === security) {
    return;
  }

  state.form.symbolField = state.form.symbolField.updateValue(security);

  const { BaseCurrency, QuoteCurrency } = security || {};
  if (state.form.rfqCurrencyField.value !== BaseCurrency && state.form.rfqCurrencyField.value !== QuoteCurrency) {
    state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(BaseCurrency, true);
  }
  state.form.quantityField = state.form.quantityField
    .updateValue(security.NormalSize, true)
    .updateScale(getQuantityIncrement(security, state.form.rfqCurrencyField.value));

  state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField.updateValue(EMPTY_ARRAY, true);

  const fixingIndices = state.referenceData.fixingIndices.fixingIndicesBySymbol.get(security.Symbol) ?? [];
  if (state.referenceData.settings.enableTakerMarketplaceETFRFQFlow) {
    state.form.fixingIndexField = state.form.fixingIndexField
      .updateAvailableItems(fixingIndices)
      .updateValue(fixingIndices.length === 1 ? fixingIndices[0] : undefined)
      .setIsVisible(fixingIndices.length > 0);
  } else {
    state.form.fixingIndexField = state.form.fixingIndexField
      .updateAvailableItems([])
      .updateValue(undefined)
      .setIsVisible(false);
  }
};

const populateDropdownsFromRefData = (state: WritableDraft<RFQState>) => {
  const { securities } = state.referenceData;

  state.form.symbolField = state.form.symbolField.updateAvailableItems(
    securities.securitiesList.filter(s => isSpot(s))
  );

  // Probably wouldn't happen in Principal but just in case as it has been observed in sales order
  if (securities.securitiesList.length === 1) {
    handleSymbolChange(state, securities.securitiesList[0]?.Symbol);
  }
};

export const rfqSlice = createSlice({
  name: 'RFQ',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;

      // handle the case where we launched the form so quickly before the app received it's first ref data tick
      if (state.form.symbolField.availableItems.length === 0) {
        populateDropdownsFromRefData(state);
      }
    },
    setDependencies: (state, action: PayloadAction<RFQDependencies>) => {
      state.dependencies = action.payload;

      if (!state.currentQuote) {
        if (state.form.allocations.length && action.payload.tradableSubAccounts?.length != null) {
          // Filter out sub-accounts that are no longer tradeable
          state.form.allocations = state.form.allocations.filter(a =>
            a.subAccountField.value
              ? action.payload.tradableSubAccounts!.find(sb => sb.Name === a.subAccountField.value)
              : true
          );
        }

        validateAllocations(state);
      }
    },
    resubmitRFQ: (state, action: PayloadAction<Quote | Order>) => {
      populateDropdownsFromRefData(state);

      if (isOrder(action.payload)) {
        initializeFieldsFromOrder(state, action.payload);
      } else {
        initializeFieldsFromQuote(state, action.payload);
      }

      state.currentQuote = undefined;
      state.isLoading = false;
    },
    showRFQ: (state, action: PayloadAction<Quote>) => {
      populateDropdownsFromRefData(state);
      initializeFieldsFromQuote(state, action.payload);

      disableInputFields(state, true);

      state.currentQuote = action.payload;
      state.isLoading = false;
    },
    primeNewRFQForm: (state, action: PayloadAction<PrimeOMSParams>) => {
      populateDropdownsFromRefData(state);
      disableInputFields(state, false);

      const symbol = action.payload.symbol;
      handleSymbolChange(state, symbol);

      state.form.sideField = state.form.sideField.updateValue(action.payload.side);

      if (action.payload.marketAccounts) {
        state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField.updateValue(
          action.payload.marketAccounts
        );
      }

      // Differentiate between priming with empty initial quantity vs using security's NormalSize
      if (action.payload.orderQty != null) {
        state.form.quantityField = state.form.quantityField
          // removes trailing zeroes after the decimal place if we toFixed() a Big object instead of just setting the rawString
          .updateValue(action.payload.orderQty && toBigWithDefault(action.payload.orderQty, 0).toFixed());
      }

      if (action.payload.currency) {
        state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(action.payload.currency);
      }

      state.form.groupField = state.form.groupField.updateValue(action.payload.group);

      if (action.payload.subAccount) {
        state.form.allocations = mapAllocationsToField([{ subAccount: action.payload.subAccount, value: '1' }]);
      } else {
        const shouldDefaultSubAccount = !state.form.allocations.length && state.referenceData.defaultSubAccount;
        if (shouldDefaultSubAccount) {
          state.form.allocations = mapAllocationsToField([
            { subAccount: state.referenceData.defaultSubAccount!, value: '1' },
          ]);
        }
      }
      validate(state);

      const parentID = action.payload.parentID;
      state.form.parentIDField = state.form.parentIDField.updateValue(parentID);
      state.form.assignToParentField = state.form.assignToParentField.updateValue(parentID != null);
      if (parentID != null) {
        state.form.symbolField = state.form.symbolField.setDisabled(true);
      }

      state.currentCareOrder = undefined;
      state.currentQuote = undefined;
      state.isLoading = false;
    },
    setSide: (state, action: PayloadAction<SideEnum | undefined>) => {
      state.form.sideField = state.form.sideField.updateValue(action.payload);
      validate(state);
    },
    setSymbol: (state, action: PayloadAction<string | undefined>) => {
      handleSymbolChange(state, action.payload);
    },
    setQuantity: (state, action: PayloadAction<string>) => {
      state.form.quantityField = state.form.quantityField.updateValue(action.payload);
      validateQuantity(state);
    },
    toggleAssignToParent: state => {
      const nextAssignToParentField = !state.form.assignToParentField.value;
      state.form.assignToParentField = state.form.assignToParentField.updateValue(nextAssignToParentField);

      const currentCareOrder = state.currentCareOrder;
      if (nextAssignToParentField) {
        state.form.symbolField = state.form.symbolField.updateValueFromID(currentCareOrder?.Symbol).setDisabled(true);
        // TODO fhqvst In the future it will also be possible to select two-way here.
        state.form.sideField = state.form.sideField.updateValue(currentCareOrder?.Side);
        state.form.groupField = state.form.groupField.updateValue(currentCareOrder?.Group).setDisabled(true);
      } else {
        state.form.symbolField = state.form.symbolField.setDisabled(false);
        state.form.groupField = state.form.groupField.updateValue('').setDisabled(false);
      }

      validateQuantity(state);
    },
    setRFQCurrency: (state, action: PayloadAction<string>) => {
      state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(action.payload);
      state.form.quantityField = state.form.quantityField
        .updateValue('', true)
        .updateScale(getQuantityIncrement(state.form.symbolField.value, action.payload));
      validateQuantity(state);
    },
    setCurrentQuote: (state, { payload: quote }: PayloadAction<Quote>) => {
      // If we've reached a terminal state, just reset all the things
      if (quote.isTerminal) {
        disableInputFields(state, false);
        state.isLoading = false;
        state.currentQuote = undefined;
        return;
      }

      state.isLoading = quote.isPending;

      // Disable input fields unless quote undefined or in terminal state
      disableInputFields(state, true);
      state.currentQuote = quote;
    },
    setCurrentCareOrder: (state, { payload }: PayloadAction<CareOrder | undefined>) => {
      state.currentCareOrder = payload;
      if (state.currentCareOrder?.isComplete) {
        state.form.assignToParentField = state.form.assignToParentField.updateValue(false).setDisabled(true);
      }
      validateQuantity(state);
    },
    setAllocations: (state, action: PayloadAction<Allocation[]>) => {
      state.form.allocations = mapAllocationsToField(action.payload, state.form.allocationValueTypeField.value, true);
      validateAllocations(state);
    },
    setAllocationValueType: (state, action: PayloadAction<AllocationValueTypeEnum>) => {
      const mappedToNewUnit = state.form.allocations.map(pair => ({
        subAccountField: pair.subAccountField,
        valueField: pair.valueField
          .updateUnit(action.payload === AllocationValueTypeEnum.Percentage ? Unit.Percent : Unit.Decimal)
          .updateValue(
            state.form.allocationValueTypeField.value === AllocationValueTypeEnum.Percentage
              ? pair.valueField.displayValue
              : pair.valueField.value
          ),
      }));
      state.form.allocations = mappedToNewUnit;
      state.form.allocationValueTypeField = state.form.allocationValueTypeField.updateValue(action.payload);
      validateAllocations(state);
    },
    setRFQMarkets: (state, action: PayloadAction<string[]>) => {
      state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField.updateValue(action.payload);
    },
    setAvailableRFQMarketAccounts: (
      state,
      action: PayloadAction<{
        availableMarketAccountNames: string[];
        isMarketOnline: (m: Market | string, connectionType: ConnectionType) => boolean;
      }>
    ) => {
      const availableMarketAccountNames = getAvailableRFQMarketAccounts(
        state.referenceData.marketAccounts,
        state.referenceData.markets.marketsList,
        action.payload.availableMarketAccountNames
      );

      const defaultSelectedRFQMarketAccounts = getDefaultRFQMarketAccounts(
        action.payload.isMarketOnline,
        state.form.symbolField.value,
        state.form.rfqCurrencyField.value,
        state.referenceData.marketAccounts,
        state.referenceData.markets,
        availableMarketAccountNames,
        state.referenceData.settings.allowSyntheticCcy
      );

      let value = defaultSelectedRFQMarketAccounts;
      if (state.form.rfqMarketAccountsField.isTouched) {
        // If field is not touched then just replace with system defaults, if touched then keep user selection as long as user selection still exists
        const existing = state.form.rfqMarketAccountsField.value;
        const applicable = existing.filter(e => availableMarketAccountNames.includes(e));

        // if user had manually unselected everything, we want to keep the empty selection
        // we only re-default if their existing non-empty selection is not valid anymore
        if (!existing.length) {
          value = [];
        } else {
          value = applicable.length ? applicable : defaultSelectedRFQMarketAccounts;
        }
      }

      state.form.rfqMarketAccountsField = state.form.rfqMarketAccountsField
        .updateAvailableItems(availableMarketAccountNames)
        .updateValue(value, true);
    },
    setGroup: (state, action: PayloadAction<string | undefined>) => {
      state.form.groupField = state.form.groupField.updateValue(action.payload);
    },
    setFixingIndex: (state, action: PayloadAction<FixingIndex | undefined>) => {
      state.form.fixingIndexField = state.form.fixingIndexField.updateValue(action.payload);
      if (state.form.symbolField.value) {
        state.form.rfqCurrencyField = state.form.rfqCurrencyField.updateValue(
          state.form.symbolField.value.BaseCurrency
        );
      }
    },
    setQuoteRequested: state => {
      disableInputFields(state, true);
      state.isLoading = true;
    },
    setQuoteAccepted: state => {
      state.isLoading = true;
    },
    resetState: state => {
      const prevSymbol = state.form.symbolField;
      const prevFixingIndex = state.form.fixingIndexField;
      const prevRFQCurrency = state.form.rfqCurrencyField;
      const newState = getInitialState();
      newState.form.symbolField = prevSymbol.setTouched(false);
      newState.form.fixingIndexField = prevFixingIndex.setTouched(false);
      newState.form.rfqCurrencyField = prevRFQCurrency.setTouched(false);
      newState.form.quantityField = state.form.quantityField.setTouched(false);
      newState.referenceData = state.referenceData;
      newState.currentQuote = undefined;
      return newState;
    },
    touchAll: state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });

      state.form.allocations = state.form.allocations.map(pair => ({
        subAccountField: pair.subAccountField.setTouched(true),
        valueField: pair.valueField.setTouched(true),
      }));
    },
  },
  // https://redux-toolkit.js.org/api/createSlice#extrareducers
  // extraReducers allows own state update in response to action generated from other slices
  // in other words, we can listen to actions from another slice (reference data) to update this slice
  // the use case for now is when the top level OMS settings is updated, we can compare the new values (payload)
  // against the current values to determine what has changed and react appropriately
  extraReducers: builder => {
    builder.addCase(setGlobalSymbol, (state, action) => {
      handleSymbolChange(state, action.payload);
    });
    builder.addCase(openView, (state, action) => {
      if (action.payload !== OMSView.RFQForm) {
        return;
      }

      populateDropdownsFromRefData(state);

      disableInputFields(state, false);

      if (!state.form.allocations.length) {
        state.form.allocations = mapAllocationsToField([
          { subAccount: state.referenceData.defaultSubAccount || '', value: '1' },
        ]);
      }
    });
  },
});

export const {
  setReferenceData,
  setDependencies,
  setSide,
  setSymbol,
  setQuantity,
  setAllocations,
  setAllocationValueType,
  setGroup,
  setFixingIndex,
  setRFQCurrency,
  resubmitRFQ,
  primeNewRFQForm,
  showRFQ,

  setCurrentQuote,
  toggleAssignToParent,
  setQuoteRequested,
  setQuoteAccepted,
  resetState,
  setRFQMarkets,
  setAvailableRFQMarketAccounts,
} = rfqSlice.actions;

export const selectCanSendRfq = createSelector(
  selectCurrentCareOrder,
  (state: AppState) => state.rfq.isLoading,
  (state: AppState) => state.rfq.currentQuote,
  (state: AppState) => state.rfq.form,
  (state: AppState) => state.rfq.dependencies.tradableSubAccounts.length,
  (careOrder, isLoading, currentQuote, form, numTradableSubAccounts) => {
    // Check that care order has loaded
    const parentID = form.parentIDField.value;
    if (parentID != null && careOrder == null) {
      return false;
    }

    // Validate fields
    const hasValidAllocations = allocationsAreValid(form.allocations, numTradableSubAccounts);
    const hasValidPrincipalFields = principalFieldsAreValid(form);

    return currentQuote == null && !isLoading && hasValidAllocations && hasValidPrincipalFields;
  }
);

const validateAllocations = (state: WritableDraft<RFQState>) => {
  // If there are no sub accounts configured in the system, then allocations is not applicable
  // However it is a required field if it is configured
  const isSubAccountRequired = state.dependencies.tradableSubAccounts.length > 0;

  state.form.allocations = state.form.allocations.map(pair => ({
    subAccountField: pair.subAccountField.setIsRequired(isSubAccountRequired).validate(),
    valueField: pair.valueField.validate(isSubAccountRequired ? [allocationTotalValidation] : [], state.form),
  }));
};

/**
 * Check if all principal fields are valid
 * @param form - order form state
 * @param excludedFields - fields to exclude from validation
 */
function principalFieldsAreValid(form: RFQFormState, excludedFields: string[] = []) {
  const principalFields = Object.keys(form)
    .filter(k => form[k] instanceof BaseField)
    .filter(k => !excludedFields.includes(form[k].name))
    .map(k => form[k]);
  const principalHasError = principalFields.some(f => f.hasError);
  return !principalHasError;
}

function allocationsAreValid(
  allocations: { subAccountField: Field<string>; valueField: NumericField }[],
  numTradableSubAccounts: number
) {
  const isSubAccountRequired = numTradableSubAccounts > 0;
  const allocationsHasError = isSubAccountRequired
    ? allocations.length === 0 ||
      allocations.flatMap(alloc => [alloc.subAccountField, alloc.valueField]).some(field => field.hasError)
    : false;
  return !allocationsHasError;
}

export function setupListeners(startListening: AppStateListenerStart) {
  /**
   * Subscribe (via RTK query) to the care order with the given OrderID,
   * so we can update the form if the care order changes.
   */
  const unsubscribeCareOrderForModify = startListening({
    matcher: action => primeNewRFQForm.match(action) || showRFQ.match(action),
    effect: async (_, listener) => {
      listener.cancelActiveListeners();

      const state = listener.getState();

      const orderID = state.rfq.form.parentIDField.value;
      const queryArg = { OrderID: orderID };
      if (orderID == null) {
        return;
      }

      const selectCareOrder = streamingDataSlice.endpoints.getCareOrders.select(queryArg);
      // Check if there's already a cached value for this OrderID
      const careOrder = selectCareOrder(listener.getState()).data?.get(orderID);
      if (careOrder?.WorkingQty != null) {
        listener.dispatch(rfqSlice.actions.setCurrentCareOrder(careOrder));
      }

      // Listen for changes to the care order
      const unsubscribeCareOrderForModifyListener = startListening({
        predicate: (_, next, prev) => selectCareOrder(next) !== selectCareOrder(prev),
        effect: (_, listener) => {
          const careOrder = selectCareOrder(listener.getState()).data?.get(orderID);
          if (careOrder == null) {
            return;
          }
          listener.dispatch(rfqSlice.actions.setCurrentCareOrder(careOrder));
        },
      });
      const careOrders = listener.dispatch(streamingDataSlice.endpoints.getCareOrders.initiate(queryArg));

      // If this effect gets cancelled (e.g. the user closes the form), we should unsubscribe from the care order query
      listener.signal.addEventListener('abort', () => {
        careOrders.unsubscribe();
        unsubscribeCareOrderForModifyListener();
      });

      const prev = listener.getState();
      await listener.condition(() => {
        const next = listener.getState();
        const didCloseForm = next.OMS.openedView !== OMSView.RFQForm;
        const didChangeParentID = next.rfq.form.parentIDField.value !== prev.rfq.form.parentIDField.value;
        return didCloseForm || didChangeParentID;
      });
    },
  });

  return () => {
    unsubscribeCareOrderForModify();
  };
}
