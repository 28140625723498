import {
  EMPTY_ARRAY,
  FormControlSizes,
  IconName,
  getAgGridColId,
  useDefaultColumns,
  type Column,
  type ColumnDef,
} from '@talos/kyoko';

import type { IRowNode } from 'ag-grid-community';
import { useMemo } from 'react';
import { usePositionAutoHedgingRulesDrawer } from '../../../components/AutoHedgingRulesDrawer/AutoHedgingRulesDrawer';
import { useFeatureFlag, useRollupTreeRef } from '../../../hooks';
import type { IHedgePositionStatusRow } from '../../../providers/HedgePositionStatusProvider';

export function useHedgePositionStatusColumns({
  size = 'full',
  defaultColumns,
}: {
  size: 'mini' | 'full';
  defaultColumns?: Column<IHedgePositionStatusRow>[];
}) {
  const { enableOrderLinkInHedgeRule } = useFeatureFlag();
  const rollupTreeRef = useRollupTreeRef();

  const orderColumns = useMemo(
    () =>
      (enableOrderLinkInHedgeRule
        ? // If order link is enabled, show the columns rendering the order details
          [
            {
              hide: size === 'mini',
              field: 'HedgeOrderID',
              type: 'id',
              headerGroupToggle: true,
              // If toggling this group, order columns gets closed/opened
              columnsInGroup: ['Order.AvgPx', 'Order.CumQty', 'Order.Side', 'Order.Symbol', 'Order.Strategy'],
              columnGroup: true,
            },
            {
              title: 'Hedge Order AvgPx',
              hide: size === 'mini',
              columnGroup: true,
              field: 'Order.AvgPx',
              type: 'price',
              params: {
                assetField: 'Order.Symbol',
              },
            },
            {
              title: 'Hedge Order CumQty',
              hide: size === 'mini',
              columnGroup: true,
              field: 'Order.CumQty',
              type: 'size',
              params: {
                currencyField: 'Order.Currency',
              },
            },
            {
              title: 'Hedge Order Side',
              hide: size === 'mini',
              columnGroup: true,
              field: 'Order.Side',
              type: 'side',
            },
            {
              title: 'Hedge Order Symbol',
              hide: size === 'mini',
              columnGroup: true,
              field: 'Order.Symbol',
              type: 'security',
            },
            {
              title: 'Hedge Order Strategy',
              hide: size === 'mini',
              columnGroup: true,
              field: 'Order.Strategy',
              type: 'text',
            },
          ]
        : // If order link is disabled, show only the order id column
          [
            {
              hide: size === 'mini',
              field: 'HedgeOrderID',
              type: 'id',
            },
          ]) satisfies ColumnDef<IHedgePositionStatusRow>[],
    [enableOrderLinkInHedgeRule, size]
  );

  const { openPositionAutoHedgingRulesDrawer } = usePositionAutoHedgingRulesDrawer();
  const columnsDefs = useMemo(
    () =>
      new Map<string, Column>(
        (
          [
            {
              field: 'HedgeControlStatus',
              type: 'autohedgeStatus',
              title: 'Status',
              sort: '-',
              params: {
                textField: 'Text' satisfies keyof IHedgePositionStatusRow,
              },
            },
            {
              field: 'AccountID',
              title: 'Monitored Rollup',
              type: 'subAccountName',
              params: {
                rollupTree: rollupTreeRef,
              },
              width: size === 'mini' ? 218 : undefined,
            },
            {
              id: 'current-asset-position',
              field: 'CurrentAssetPosition',
              title: size === 'mini' ? 'Current Asset Pos.' : 'Current Asset Position',
              width: size === 'mini' ? 127 : undefined,
              type: 'number',
              params: {
                currencyField: 'Asset',
              },
            },
            {
              hide: size === 'mini',
              id: 'current-position',
              field: 'CurrentPosition',
              type: 'number',
              params: {
                currencyField: 'TargetAsset',
              },
            },
            {
              id: 'target-position',
              field: 'TargetPosition',
              title: size === 'mini' ? 'Target Pos.' : 'Target Position',
              type: 'number',
              params: {
                currencyField: 'TargetAsset',
              },
              width: size === 'mini' ? 127 : undefined,
            },
            {
              title: 'Target Threshold',
              id: 'CurrentPosition',
              type: 'positionThresholdMeter',
              sortable: false,
            },
            {
              id: 'hedgeControls',
              type: 'button',
              frozen: true,
              pinned: 'right',
              width: size === 'full' ? 115 : 45,
              suppressColumnsToolPanel: true,
              params: {
                onClick: ({ node }: { node: IRowNode<IHedgePositionStatusRow> }) => {
                  if (!node.data) {
                    return;
                  }
                  openPositionAutoHedgingRulesDrawer({
                    hedgeRuleID: node.data.HedgeRuleID,
                  });
                },
                startIcon: IconName.Preferences,
                children: size === 'full' ? 'Edit Controls' : undefined,
                size: FormControlSizes.Small,
              },
            },
            ...orderColumns,
            { hide: size === 'mini', field: 'HedgeDecision', type: 'text' },
            { hide: size === 'mini', field: 'HedgeAction', type: 'text' },
            {
              hide: size === 'mini',
              field: 'ShortPositionLimit',
              type: 'number',
              params: { currencyField: 'TargetAsset' },
            },
            {
              hide: size === 'mini',
              field: 'LongPositionLimit',
              type: 'number',
              params: { currencyField: 'TargetAsset' },
            },

            { hide: true, field: 'HedgeSymbol', type: 'security' },
            { hide: true, field: 'HedgeRuleID', type: 'id', params: { maxLength: 6 } },
            { hide: true, field: 'Asset', title: 'Monitored Asset', type: 'currency' },
            { hide: true, field: 'TargetAsset', type: 'currency' },
            { hide: true, field: 'Timestamp', type: 'text' },
            { hide: true, field: 'LastLongAssetPositionDelta', type: 'text' },
            { hide: true, field: 'LastLongPositionDelta', type: 'text' },
            { hide: true, field: 'LongDeltaAttribution', type: 'text' },
            { hide: true, field: 'LastShortAssetPositionDelta', type: 'text' },
            { hide: true, field: 'LastShortPositionDelta', type: 'text' },
            { hide: true, field: 'ShortDeltaAttribution', type: 'text' },
            { hide: true, field: 'HedgeDecisionData', type: 'text' },
          ] satisfies ColumnDef<IHedgePositionStatusRow>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    [rollupTreeRef, size, orderColumns, openPositionAutoHedgingRulesDrawer]
  );

  return useDefaultColumns(defaultColumns ?? EMPTY_ARRAY, columnsDefs);
}
