import type { Store } from '@reduxjs/toolkit';
import {
  FetchError,
  NotificationVariants,
  SideEnum,
  SyntheticProductTypeEnum,
  getErrorMessage,
  logger,
  tryParseJSON,
  type MultilegModel,
  type ToastProps,
} from '@talos/kyoko';
import { appStateActionsStream } from 'providers/AppStateProvider';
import type { AppState } from 'providers/AppStateProvider/types';
import type { MultilegTabData } from 'providers/MarketTabs.types';
import { filter, type Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { createMultileg, deleteMultileg, updateMultileg } from 'utils/multileg';
import { setReferenceData } from '../OMS/NewOrder/OrderSlice';
import type { OptionStrategy } from '../OMS/NewOrder/models';
import type { PrimeOMSParams } from '../OMS/NewOrder/types';
import {
  primeMultilegState,
  selectIsValid,
  setCompactOrderBook,
  setIsEditing,
  setIsLoading,
  setResolvedSymbol,
} from './MultilegComboSlice';
import { MultilegComboType, isMultilegOptionComboType } from './enums';
import { mapLegsContainerToMultilegModel, mapOptionStrategyToMultilegModel } from './mappers';
import type { LegsContainer } from './models';
import type { MultilegComboPanelState, MultilegComboState } from './types';

const actionTypesToTriggerPersistTabState: string[] = [
  primeMultilegState.type,
  setResolvedSymbol.type,
  setCompactOrderBook.type,
];

export class MultilegComboService {
  private symbolToPrimeOnceAvailable = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private orgApiEndpoint: string,
    private primeOrderForm: (params: PrimeOMSParams) => void,
    private updateMultilegTabData: (tabData: MultilegTabData, label: string) => void,
    private addToast: (props: ToastProps) => void,
    private panelID: string
  ) {
    this.observeReferenceDataUpdates();
    this.persistTabStateOnChange();
  }

  public async upsertMultilegSecurityAndPrimeOMS(primeOMS: boolean): Promise<boolean> {
    if (!selectIsValid(this.store.getState(), this.panelID)) {
      return Promise.reject();
    }

    // We include the prime logic here because in case a previously persisted symbol is now deleted/removed
    // for whatever reason, we would fail to prime so we can fall back to trying to re-create it

    // TODO move to order form / market data card etc
    if (!this.panelState.isEditing && this.panelState.resolvedSymbol) {
      const exists = this.store
        .getState()
        .order.referenceData.securities.securitiesBySymbol.has(this.panelState.resolvedSymbol);
      if (exists) {
        const primeOmsParams = {
          symbol: this.panelState.resolvedSymbol,
          marketAccounts: [],
          side: SideEnum.Buy,
        };
        this.primeOrderForm(primeOmsParams);
      }
      this.store.dispatch(setIsLoading({ isLoading: false, panelID: this.panelID }));
      return Promise.resolve(true);
    }

    let multilegModel: MultilegModel | undefined;
    if (isMultilegOptionComboType(this.panelState.instrumentField.value!.value)) {
      multilegModel = mapOptionStrategyToMultilegModel(
        this.optionStrategy,
        this.panelState.resolvedSymbol,
        this.panelState.editableProperties
      );
    } else {
      const syntheticProductType =
        this.panelState.instrumentField.value?.value === MultilegComboType.SyntheticCross
          ? SyntheticProductTypeEnum.Cross
          : SyntheticProductTypeEnum.Delta1Spread;
      multilegModel = mapLegsContainerToMultilegModel(
        this.legsContainer,
        syntheticProductType,
        this.panelState.resolvedSymbol,
        this.panelState.editableProperties
      );
    }
    if (!multilegModel) {
      return Promise.reject();
    }
    try {
      const response = this.panelState.isEditing
        ? await updateMultileg(multilegModel, this.orgApiEndpoint)
        : await createMultileg(multilegModel, this.orgApiEndpoint);

      const symbol = 'data' in response ? response.data?.[0].Symbol : undefined;

      if ('data' in response && symbol) {
        const verb = this.panelState.isEditing ? 'updated' : 'created';
        const displaySymbol = this.panelState.editableProperties?.displaySymbol;
        this.addToast({
          text: `Symbol ${displaySymbol ?? symbol} ${verb}.`,
          variant: NotificationVariants.Positive,
        });

        this.store.dispatch(setResolvedSymbol({ resolvedSymbol: response.data[0].Symbol, panelID: this.panelID }));
        this.store.dispatch(setIsEditing({ isEditing: undefined, panelID: this.panelID }));

        // check if symbol already exists, if it does we can immediately prime order form, else we need to wait for securities ws to update
        const exists = this.store
          .getState()
          .order.referenceData.securities.securitiesBySymbol.has(response.data[0].Symbol);
        if (exists && primeOMS) {
          const primeOmsParams = {
            symbol: response.data[0].Symbol,
            marketAccounts: [],
            side: SideEnum.Buy,
          };
          this.primeOrderForm(primeOmsParams);
        } else if (primeOMS) {
          this.symbolToPrimeOnceAvailable = response.data[0].Symbol;
        }
        return Promise.resolve(true);
      } else {
        this.symbolToPrimeOnceAvailable = '';
        this.addToast({
          text:
            'message' in response && response.message
              ? response.message
              : `Unexpected error occurred when creating new Multileg symbol`,
          variant: NotificationVariants.Negative,
        });
        return Promise.resolve(false);
      }
    } catch (e) {
      if (e instanceof FetchError) {
        // the backend is actually sending us a string here, for example: "Failed to create instrument: cannot use the same leg with the same markets."
        // so we display it directly with e.body
        const errorMessage = getErrorMessage(e.errorJson) ?? tryParseJSON(e.body, false)?.error_msg ?? e.body;
        this.addToast({
          text: errorMessage,
          variant: NotificationVariants.Negative,
        });
        logger.error(new Error(`Could not save multi leg symbol: ${errorMessage}`), {
          extra: { symbol: JSON.stringify(multilegModel) },
        });
      } else {
        this.addToast({
          text: `Unexpected error occurred when creating new Multileg symbol`,
          variant: NotificationVariants.Negative,
        });
        logger.error(new Error('Could not save multi leg symbol'), {
          extra: { symbol: JSON.stringify(multilegModel) },
        });
      }
    } finally {
      this.store.dispatch(setIsLoading({ isLoading: false, panelID: this.panelID }));
    }
    return Promise.resolve(false);
  }

  public async deleteSymbol() {
    const symbol = this.panelState.resolvedSymbol;
    const displaySymbol = this.panelState.editableProperties?.displaySymbol;

    if (this.orgApiEndpoint === undefined || !symbol) {
      return;
    }

    try {
      await deleteMultileg(symbol, this.orgApiEndpoint);
      this.addToast({
        text: `Symbol ${displaySymbol ?? symbol} deleted.`,
        variant: NotificationVariants.Positive,
      });
      this.store.dispatch(setResolvedSymbol({ resolvedSymbol: '', panelID: this.panelID }));
    } catch (e) {
      this.addToast({
        text: (e as Error).message,
        variant: NotificationVariants.Negative,
      });
    } finally {
      this.store.dispatch(setIsLoading({ isLoading: false, panelID: this.panelID }));
    }
  }

  private observeReferenceDataUpdates() {
    const subscription = appStateActionsStream
      .pipe(
        filter(action => action.type === setReferenceData.type),
        debounceTime(100)
      )
      .subscribe(() => {
        if (
          this.symbolToPrimeOnceAvailable &&
          this.store.getState().order.referenceData.securities.securitiesBySymbol.has(this.symbolToPrimeOnceAvailable)
        ) {
          const primeOmsParams = {
            symbol: this.symbolToPrimeOnceAvailable,
            marketAccounts: [],
            side: SideEnum.Buy,
          };
          this.primeOrderForm(primeOmsParams);
          this.symbolToPrimeOnceAvailable = '';
        }
      });

    this.subscriptions.push(subscription);
  }

  private persistTabStateOnChange() {
    const subscription = appStateActionsStream
      .pipe(
        filter(action => actionTypesToTriggerPersistTabState.includes(action.type) && !!this.panelState?.resolvedSymbol)
      )
      .subscribe(() => {
        const instrumentType = this.panelState.instrumentField.value!.value;
        const resolvedSymbol = this.panelState.resolvedSymbol!;

        const tabData: MultilegTabData = {
          instrumentType,
          initiatingLegs: this.optionStrategy?.initiatingLegs || [],
          resolvedSymbol,
          compactOrderBook: this.panelState.compactOrderBook,
        };

        const security = this.store.getState().referenceData.securities.securitiesBySymbol.get(resolvedSymbol);
        const label = security?.DisplaySymbol || resolvedSymbol;

        this.updateMultilegTabData(tabData, label);
      });

    this.subscriptions.push(subscription);
  }

  private get state(): MultilegComboState {
    return this.store.getState().multilegCombo;
  }

  private get panelState(): MultilegComboPanelState {
    if (!this.state.panels[this.panelID]) {
      throw new Error(`Panel with ID ${this.panelID} not found`);
    }
    return this.state.panels[this.panelID];
  }

  private get optionStrategy(): OptionStrategy | undefined {
    return this.panelState.optionStrategy;
  }

  private get legsContainer(): LegsContainer | undefined {
    return this.panelState.customLegs;
  }

  public destroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
