import {
  abbreviateId,
  type CustomerOrder,
  Flex,
  isEntityRFQ,
  isMultileg,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceContext,
  type Order,
  type Security,
  UnifiedLiquidityEnum,
  useMixpanel,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { useUser } from '../../../../hooks';
import { useTradingSettings } from '../../../../providers/TradingSettingsContext';
import {
  BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS,
  BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS,
  BLOTTER_ID_DDH_ORDERS_ORDER_DETAILS,
  BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS,
  BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS,
  BLOTTER_ID_TRADES_ORDER_DETAILS,
} from '../../../../tokens/blotters';
import { prepareAllocationOrderForUI } from '../../../../utils/order';
import { CustomerTradesBlotterTable } from '../../../Blotters/CustomerTrades';
import { ExecutionReportsBlotterTable } from '../../../Blotters/ExecutionReports';
import { MarketExecutionReportsBlotterTable } from '../../../Blotters/MarketExecutionReports';
import { MarketOrdersBlotterTable } from '../../../Blotters/MarketOrders';
import { FilteredOrders } from '../../../Blotters/Orders/FilteredOrders';
import { TradeAllocationsBlotterTable } from '../../../Blotters/TradeAllocations';
import { FilteredTrades } from '../../../Blotters/Trades/FilteredTrades';
import { AnalyticsTab } from './AnalyticsTab';
import { ORDER_DETAILS_DEFAULT_TRADE_COLUMNS } from './defaults';
import { DetailsTab } from './DetailsTab';
import { MemoizedTabs } from './MemoizedTabs';
import { OrderDetailsHeader } from './OrderDetailsHeader';
import { Wrapper } from './styles';
import type { OrderDetailsPath, OrderDetailsRoute, OrderDetailsRouteProps } from './types';
import { useTradeAllocationsColumns } from './useTradeAllocationsColumns';

function getBlotterID(prefix: string, order: { OrderID: string }) {
  return `${prefix}/${order.OrderID}`;
}

interface OrderDetailsInnerContainerProps {
  order: Order;
  customerOrder?: CustomerOrder;
  security: Security | undefined;
  width: number;
  selectedTab: OrderDetailsPath | undefined;
  onSelectTab: (path: OrderDetailsPath | undefined) => void;
  onClose: () => void;
  useLink: boolean;
}

export function OrderDetailsInnerContainer({
  order,
  customerOrder,
  security,
  width,
  onSelectTab,
  selectedTab,
  onClose,
  useLink,
}: OrderDetailsInnerContainerProps) {
  const location = useLocation();
  const filtersContainerRef = useRef<HTMLDivElement>(null);
  const user = useUser();

  const { useTradeAllocations, enableAdvancedOrderDetails } = useTradingSettings();

  const tradeAllocationsColumns = useTradeAllocationsColumns(order?.unifiedLiquidity === UnifiedLiquidityEnum.Enabled);
  const defaultFilter = useMemo(() => (order.SubmitTime ? { StartDate: order.SubmitTime } : {}), [order.SubmitTime]);
  const ddhOrdersDefaultFilter = useMemo(
    () => ({
      ...defaultFilter,
    }),
    [defaultFilter]
  );

  const ddhPermanentFilters = useMemo(
    () => ({
      ParentOrderID: order.OrderID,
    }),
    [order.OrderID]
  );

  const { subAccountAllocations, allocationValueType } = useMemo(
    () => prepareAllocationOrderForUI({ Allocation: order.Allocation }),
    [order.Allocation]
  );
  const isRFQ = isEntityRFQ(order);
  const isCustomerOrder = customerOrder != null;
  const orderDetailsRoutes: OrderDetailsRoute<OrderDetailsRouteProps>[] = useMemo(() => {
    const routes: OrderDetailsRoute<OrderDetailsRouteProps>[] = [
      {
        path: 'details',
        label: 'Details',
        render: props => <DetailsTab {...props} width={width} />,
      },
    ];

    if (!isRFQ) {
      routes.push({
        path: 'analytics',
        label: 'Analytics',
        render: props => <AnalyticsTab {...props} width={width} isOrderMultileg={isMultileg(security)} />,
      });
    }

    const createOrderIdTag = (id: string) => {
      return `Order-${abbreviateId(id)}`;
    };

    return compact([
      ...routes,
      enableAdvancedOrderDetails
        ? ({
            path: 'market-orders' as const,
            label: 'Market Orders',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsMarketOrdersTab}>
                <MarketOrdersBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_MARKET_ORDERS_ORDER_DETAILS}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  parentOrderID={props.order.OrderID}
                  defaultFilter={defaultFilter}
                  persistFilter={false}
                  source="trading"
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          } satisfies OrderDetailsRoute)
        : null,
      {
        path: 'trades' as const,
        label: isCustomerOrder ? 'Hedge Trades' : 'Trades',
        render: props => (
          <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsTradesTab}>
            <FilteredTrades
              orderID={props.order.OrderID}
              blotterPersistID={BLOTTER_ID_TRADES_ORDER_DETAILS}
              defaultFilter={defaultFilter}
              persistFilter={false}
              tabLabel={createOrderIdTag(props.order.OrderID)}
              showOpenOrderDetails={false}
              defaultColumns={ORDER_DETAILS_DEFAULT_TRADE_COLUMNS}
              {...props}
            />
          </MixpanelSourceContext.Provider>
        ),
      },
      isCustomerOrder
        ? {
            path: 'customer-trades' as const,
            label: 'Customer Trades',
            render: props => (
              <CustomerTradesBlotterTable
                orderID={props.customerOrder!.OrderID}
                blotterID={getBlotterID(BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS, props.customerOrder!)}
                blotterPersistID={BLOTTER_ID_CUSTOMER_TRADES_ORDER_DETAILS}
                defaultFilter={defaultFilter}
                persistFilter={false}
                tabLabel={createOrderIdTag(props.order.OrderID)}
                {...props}
              />
            ),
          }
        : null,
      enableAdvancedOrderDetails
        ? {
            path: 'execution-reports' as const,
            label: 'Execution Reports',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsExecutionReportsTab}>
                <ExecutionReportsBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_EXEC_REPORTS_ORDER_DETAILS}
                  defaultFilter={defaultFilter}
                  persistFilter={false}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  orderID={props.order.OrderID}
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
      enableAdvancedOrderDetails
        ? {
            path: 'market-execution-reports' as const,
            label: 'Market Execution Reports',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsMarketExecutionReportsTab}>
                <MarketExecutionReportsBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_MARKET_EXEC_REPORTS_ORDER_DETAILS}
                  defaultFilter={defaultFilter}
                  persistFilter={false}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  parentOrderID={props.order.OrderID}
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
      useTradeAllocations && !!subAccountAllocations?.length && subAccountAllocations?.length > 1
        ? {
            path: 'allocations' as const,
            label: 'Allocations',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.OrderDetailsAllocationsTab}>
                <TradeAllocationsBlotterTable
                  blotterID={getBlotterID(BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS, props.order)}
                  blotterPersistID={BLOTTER_ID_ALLOCATIONS_ORDER_DETAILS}
                  defaultFilter={defaultFilter}
                  persistFilter={false}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  defaultColumns={tradeAllocationsColumns}
                  orderID={props.order.OrderID}
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
      order.isDDHParentOrder
        ? {
            path: 'ddh-orders' as const,
            label: 'DDH Orders',
            render: props => (
              <MixpanelSourceContext.Provider value={MixpanelEventSource.DDHOrders}>
                <FilteredOrders
                  blotterID={getBlotterID(BLOTTER_ID_DDH_ORDERS_ORDER_DETAILS, props.order)}
                  defaultFilter={ddhOrdersDefaultFilter}
                  persistFilter={false}
                  permanentFilters={ddhPermanentFilters}
                  tabLabel={createOrderIdTag(props.order.OrderID)}
                  suppressCustomerColumns
                  alwaysShowDDHOrders
                  {...props}
                />
              </MixpanelSourceContext.Provider>
            ),
          }
        : null,
    ] satisfies (OrderDetailsRoute<OrderDetailsRouteProps> | null)[]);
  }, [
    isRFQ,
    security,
    tradeAllocationsColumns,
    width,
    subAccountAllocations,
    useTradeAllocations,
    isCustomerOrder,
    defaultFilter,
    enableAdvancedOrderDetails,
    ddhOrdersDefaultFilter,
    ddhPermanentFilters,
    order.isDDHParentOrder,
  ]);

  const match = useRouteMatch();
  const selectedTabIndex = orderDetailsRoutes.findIndex(route =>
    selectedTab ? route.path === selectedTab : location.pathname === `${match?.url}/${route.path}`
  );
  const selectedRoute =
    orderDetailsRoutes.find(route =>
      selectedTab ? route.path === selectedTab : location.pathname === `${match?.url}/${route.path}`
    ) ?? orderDetailsRoutes[0];

  const mixpanel = useMixpanel();
  const handleSelectTab = useCallback(
    (index: number) => {
      const route = orderDetailsRoutes.at(index);
      if (route) {
        onSelectTab(route.path);
        mixpanel.track(MixpanelEvent.ChangeOrderDetailsTab, { [MixpanelEventProperty.TabLabel]: route.label });
      }
    },
    [mixpanel, orderDetailsRoutes, onSelectTab]
  );

  return (
    <Wrapper w="100%" data-testid="order-details-container">
      <OrderDetailsHeader
        order={order}
        security={security}
        width={width}
        customerOrder={customerOrder}
        subAccountAllocations={subAccountAllocations}
        allocationValueType={allocationValueType}
        user={user}
        onClose={onClose}
      />
      <MemoizedTabs
        useLink={useLink}
        selectedTabIndex={selectedTabIndex}
        matchUrl={match?.url}
        onSelectTab={handleSelectTab}
        routes={orderDetailsRoutes}
      />
      <Flex flex="1" flexDirection="column" overflow="hidden" mt="spacingTiny">
        {selectedRoute?.render({ filtersContainerRef, order, customerOrder })}
      </Flex>
      {selectedTabIndex === -1 && selectedTab === undefined && <Redirect exact={true} to={`${match?.url}/details`} />}
    </Wrapper>
  );
}
