import type { LayoutConstraints, TypedGroupviewPanelState } from '../../../types/LayoutConfig';
import { LEAF } from '../../Layout/tokens';

export const ONLY_WHEN_VISIBLE = 'onlyWhenVisible';

export const DEFAULT_MIN_SIZE = 100;
export const DEFAULT_WATCHLIST_WIDTH = 420;
export const MIN_WATCHLIST_WIDTH = 380;
export const MAX_WATCHLIST_WIDTH = MIN_WATCHLIST_WIDTH + 120;
export const DEFAULT_OMS_WIDTH = 370;
export const MIN_OMS_WIDTH = DEFAULT_OMS_WIDTH;
export const MAX_OMS_WIDTH = 540;

// TODO Only include the relevant panels based on user permissions
export const BLOTTER_PANELS: Record<string, TypedGroupviewPanelState> = {
  orders: { id: 'orders', contentComponent: 'ordersBlotter', tabComponent: 'readOnly', title: 'Orders' },
  trades: { id: 'trades', contentComponent: 'tradesBlotter', tabComponent: 'readOnly', title: 'Trades' },
  rfqs: { id: 'rfqs', contentComponent: 'rfqsBlotter', tabComponent: 'readOnly', title: 'RFQs' },
  positions: {
    id: 'positions',
    contentComponent: 'positionsBlotter',
    tabComponent: 'readOnly',
    title: 'Positions',
  },
  reconMismatches: {
    id: 'reconMismatches',
    contentComponent: 'reconMismatches',
    tabComponent: 'readOnly',
    title: 'Reconciliation Mismatches',
  },
  balances: { id: 'balances', contentComponent: 'balancesBlotter', tabComponent: 'readOnly', title: 'Balances' },
  credit: { id: 'credit', contentComponent: 'creditBlotter', tabComponent: 'readOnly', title: 'Credit' },
  transfers: {
    id: 'transfers',
    contentComponent: 'transfersBlotter',
    tabComponent: 'readOnly',
    title: 'Transfers',
  },
  allocations: {
    id: 'allocations',
    contentComponent: 'allocations',
    tabComponent: 'readOnly',
    title: 'Allocations',
  },
  hedgePositionStatus: {
    id: 'hedgePositionStatus',
    contentComponent: 'hedgePositionStatus',
    tabComponent: 'readOnly',
    title: 'Position Autohedging',
  },
};

export const BLOTTERS_GROUP = {
  type: LEAF,
  data: {
    views: [
      'orders',
      'trades',
      'rfqs',
      'positions',
      'reconMismatches',
      'balances',
      'credit',
      'transfers',
      'allocations',
      'hedgePositionStatus',
    ],
    activeView: 'orders',
    id: 'blotters',
  },
  minimumWidth: 0,
  size: 200,
};

export const WATCHLIST_CONTSTRAINTS: LayoutConstraints = {
  groupId: 'watchlists',
  panelIds: ['watchlist'],
  defaultWidth: DEFAULT_WATCHLIST_WIDTH,
  minWidth: MIN_WATCHLIST_WIDTH,
};
