import styled from 'styled-components';

import { Loader } from 'components/Loader';

export const Header = styled.h1`
  font-size: ${({ theme }) => theme.fontSizeBig}rem;
`;

export const ConnectionFailureMessage = styled.p`
  margin-bottom: 40px;
`;

export const ModalLoader = styled(Loader)`
  margin: 20px auto;
`;

export const Support = styled.div`
  margin: 0 auto;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
`;

export const SupportDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacingSmall}px;

  a,
  span {
    display: inline-block;

    + span {
      margin-left: ${({ theme }) => theme.spacingLarge}px;
    }
  }

  a:focus {
    outline: none;
  }
`;
