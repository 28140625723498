import {
  Dialog,
  Flex,
  FormGroup,
  type MarketAccount,
  NotificationVariants,
  Text,
  type UseDisclosureReturn,
  useGlobalToasts,
  useMarketAccountsContext,
} from '@talos/kyoko';
import { compact, uniq } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Autocomplete } from '../../../components/Form';
import { useMarketAccountRequests } from '../../../hooks/useMarketAccountRequests';

type BulkModifyMarketAccountGroupModalProps = { marketAccounts: MarketAccount[] } & UseDisclosureReturn;

export const BulkModifyMarketAccountGroupModal = ({
  marketAccounts,
  ...disclosure
}: BulkModifyMarketAccountGroupModalProps) => {
  const { add: addToast } = useGlobalToasts();
  const { updateMarketAccount } = useMarketAccountRequests();
  const { marketAccountsList } = useMarketAccountsContext();

  const [group, setGroup] = useState('');
  const groupOptions = useMemo(() => {
    return compact(uniq(marketAccountsList.map(ma => ma.Group))).map(group => ({ id: group, label: group }));
  }, [marketAccountsList]);

  const handleConfirm = useCallback(() => {
    const requests = marketAccounts.map(ma =>
      updateMarketAccount(ma.Name, ma.Counterparty, { Group: group, DisplayName: ma.DisplayName })
    );
    Promise.allSettled(requests).then(promises => {
      const countFulfilled = promises.filter(p => p.status === 'fulfilled').length;
      const countRejected = promises.filter(p => p.status === 'rejected').length;

      if (countFulfilled > 0) {
        addToast({
          variant: NotificationVariants.Positive,
          text: `Successfully updated ${countFulfilled} accounts.`,
        });
      }

      if (countRejected > 0) {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to update ${countRejected} accounts.`,
        });
      }
    });
  }, [marketAccounts, updateMarketAccount, addToast, group]);

  return (
    <Dialog
      {...disclosure}
      width="350px"
      title="Modify Group"
      showClose
      confirmLabel="Update"
      stretchButtons
      contentOverflow="visible"
      onConfirm={handleConfirm}
    >
      <Flex flexDirection="column" gap="spacingDefault" justifyContent="flex-start">
        <Text textAlign="left">You are setting the Group property for {marketAccounts.length} Market Accounts.</Text>
        <FormGroup label="Group" w="100%">
          <Autocomplete
            isCentered
            getItemValue={item => item.label}
            inputProps={{
              type: 'text',
              'data-testid': 'bulk-market-account-group-input',
            }}
            menuStyle={{
              /* There's some weird overflow things going on with this dropdown.
            Have to set a height smaller than the modal's height so all the items can be shown. */
              maxHeight: '100px',
            }}
            items={groupOptions}
            shouldItemRender={(item, value) => item.label.indexOf(value) !== -1}
            value={group}
            sortItems={(a, b) => a.label.localeCompare(b.label)}
            onChange={(e, val) => {
              setGroup(val);
            }}
            onSelect={val => {
              setGroup(val);
            }}
          />
        </FormGroup>
      </Flex>
    </Dialog>
  );
};
