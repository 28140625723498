import {
  BUYING_POWER,
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  MixpanelEvent,
  columnToColumnState,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterGroupingToggling,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterGroupingDefinition,
  type BlotterTableFilter,
  type BuyingPower,
  type Column,
  type ColumnState,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';

import type { GetContextMenuItemsParams, GetMainMenuItemsParams } from 'ag-grid-community';
import {
  colIDToFilterBuilderKey,
  toServerFilter,
  useBuyingPowerBlotterFilter,
  type BuyingPowerBlotterFilter,
} from './useBuyingPowerBlotterFilter';
import { useBuyingPowerColumns } from './useBuyingPowerColumns';

type BuyingPowerGroupings = 'Market Account' | 'Currency';
type GroupableBuyingPowerColumns = keyof BuyingPower;
const GROUPINGS: BlotterGroupingDefinition<BuyingPowerGroupings, GroupableBuyingPowerColumns>[] = [
  {
    key: 'Market Account',
    label: 'By Market Account',
    rowGroupColumns: ['MarketAccount'],
    buttonDataTestId: 'by-market-account-button',
  },
  {
    key: 'Currency',
    label: 'By Currency',
    rowGroupColumns: ['Currency'],
    buttonDataTestId: 'by-currency-button',
  },
];

export interface BuyingPowerBlotterTableProps {
  blotterID: string;
  tabLabel?: string;
  defaultColumns?: Column[];
  defaultFilter?: BuyingPowerBlotterFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
}

export function BuyingPowerBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
}: BuyingPowerBlotterTableProps) {
  const mixpanel = useMixpanel();
  const columns = useBuyingPowerColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<BuyingPower>(blotterID, {
    columns: columns,
    filter: defaultFilter,
  });

  const filterResults = useBuyingPowerBlotterFilter({
    persistedBlotterTable,
  });

  const { filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const { handleClickJson, jsonModal } = useJsonModal();
  const defaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params.node?.data;
    const items = compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties: filterBuilderProps.properties,
        openClause: filterBuilderAccordion.openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      ...(data
        ? [
            {
              name: `Show JSON`,
              action: () => handleClickJson(data),
            },
            'separator',
          ]
        : []),
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const handleColumnsChanged = useDynamicCallback((columns: Column<any>[]) => {
    updateGroupingOnColumnsChanged?.(columns);
    persistedBlotterTable.onColumnsChanged(columns);
  });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        editable: false,
        hide: false,
        sortable: true,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      tag: blotterID,
      name: BUYING_POWER,
    },
    rowID: 'rowID' satisfies keyof BuyingPower,
    filter: toServerFilter(filterResults.filter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: handleColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: persistedBlotterTable.columns,
    getExtraMainMenuItems,
    gridOptions: {
      showOpenedGroup: true,
      autoGroupColumnDef,
      groupRemoveLowestSingleChildren: true,
      getContextMenuItems,
    },
  });

  const { setRowGroupColumns, expandAllGroups, collapseAllGroups } = blotterTable;

  const { updateGroupingOnColumnsChanged, groupingToggleButtons } = useBlotterGroupingToggling({
    blotterName: 'buying power',
    initialColumns: persistedBlotterTable.columns,
    setRowGroupColumns,
    groupings: GROUPINGS,
    allowNoGrouping: true,
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Buying Power',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        accordionBodyPrefix={groupingToggleButtons}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
      {jsonModal}
    </>
  );
}
