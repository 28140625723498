import { forwardRef, type ReactNode } from 'react';
import type { BoxProps } from '../../Core';
import { useAccordionContext } from '../AccordionContext';
import { AccordionTableContent } from './styles';

export const AccordionRowBody = forwardRef<HTMLTableSectionElement, BoxProps & { children: ReactNode }>(
  ({ children, ...props }, ref) => {
    const { ghost } = useAccordionContext();
    return (
      <AccordionTableContent ref={ref} ghost={ghost} data-testid="accordion-row-body">
        {children}
      </AccordionTableContent>
    );
  }
);
