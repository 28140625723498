import { createAsyncThunk, createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  BaseField,
  CARE_CANCEL_REQUEST,
  Field,
  NumericField,
  SelectorField,
  SideEnum,
  formattedDateForSubscription,
  isSpot,
  type CareOrder,
  type FieldData,
} from '@talos/kyoko';
import type { WritableDraft } from 'immer';
import { streamingDataSlice } from 'providers/AppStateProvider/streamingDataSlice';
import type { AppExtraArgument, AppState, AppStateListenerStart } from 'providers/AppStateProvider/types';
import { v4 as uuid } from 'uuid';
import { getQuantityIncrement, setGlobalSymbol } from '../Common';
import { initialRefDataState } from '../OMSReferenceDataSlice';
import { closeView } from '../OMSSlice';
import { OMSView } from '../OMSView';
import { numberIsPositive } from '../commonFieldRules';
import type { OMSReferenceDataState } from '../types';
import { quantityValidation } from './FieldRules';
import type { CareOrderFormState, CareOrderInitPayload, CareOrderState } from './types';

export const selectCareOrderBeingModified = (state: AppState) => state.careOrder.careOrderBeingModified;

const validate = (state: WritableDraft<CareOrderState>) => {
  validateQuantity(state);
};

const validateQuantity = (state: WritableDraft<CareOrderState>) => {
  state.form.quantityField = state.form.quantityField.validate([numberIsPositive, quantityValidation], state);
};

function fieldsAreValid(form: CareOrderFormState, excludedFields: (keyof CareOrderFormState)[] = []) {
  const fields: BaseField<FieldData>[] = Object.keys(form)
    .filter(k => !excludedFields.includes(k as keyof CareOrderFormState))
    .filter(k => form[k] instanceof BaseField)
    .map(k => form[k]);
  const fieldsHasError = fields.some(f => f.hasError);
  return !fieldsHasError;
}

export const getInitialState = (): CareOrderState => ({
  referenceData: initialRefDataState,
  form: {
    symbolField: new SelectorField({ idProperty: 'Symbol', name: 'Symbol' }),
    sideField: new Field({ value: SideEnum.Buy }),
    quantityField: new NumericField({ name: 'Quantity', scale: undefined }), // quantity validation fully managed by FieldRules.quantityValidation
    careOrderCurrencyField: new SelectorField(),
    commentsField: new Field({ name: 'Comments', isRequired: false, value: '' }),
    initiatingFromField: new Field({ name: 'InitiatingFrom', isRequired: false, value: '' }),
    groupField: new Field({ name: 'Group', isRequired: false, value: '' }),
    counterpartyField: new SelectorField({ name: 'Counterparty', isRequired: false, idProperty: 'Name' }),
  },
  isLoading: false,
  initialized: false,
  careOrderBeingModified: undefined,
});

const disableInputFields = (state: WritableDraft<CareOrderState>, isDisabled: boolean) => {
  state.form.symbolField = state.form.symbolField.setDisabled(isDisabled);
  state.form.sideField = state.form.sideField.setDisabled(isDisabled);
  state.form.quantityField = state.form.quantityField.setDisabled(isDisabled);
  state.form.careOrderCurrencyField = state.form.careOrderCurrencyField.setDisabled(isDisabled);
  state.form.commentsField = state.form.commentsField.setDisabled(isDisabled);
  state.form.groupField = state.form.groupField.setDisabled(isDisabled);
};

export const careOrderSlice = createSlice({
  name: 'careOrder',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;
      populateDropdownsFromRefData(state);

      // Probably wouldn't happen in Principal but just in case as it has been observed in sales order
      const { securities } = state.referenceData;
      if (securities.securitiesList.length === 1) {
        handleSymbolChange(state, securities.securitiesList[0]?.Symbol);
      }
    },
    onMount: (state, action: PayloadAction<CareOrderInitPayload>) => {
      state.initialPayload = action.payload;
    },
    setSide: (state, action: PayloadAction<SideEnum | undefined>) => {
      state.form.sideField = state.form.sideField.updateValue(action.payload);
      validate(state);
    },
    setSymbol: (state, action: PayloadAction<string | undefined>) => {
      handleSymbolChange(state, action.payload);
    },
    setQuantity: (state, action: PayloadAction<string>) => {
      state.form.quantityField = state.form.quantityField.updateValue(action.payload);
      validateQuantity(state);
    },
    setGroup: (state, action: PayloadAction<string>) => {
      state.form.groupField = state.form.groupField.updateValue(action.payload);
    },
    setComments: (state, action: PayloadAction<string>) => {
      state.form.commentsField = state.form.commentsField.updateValue(action.payload);
    },
    setInitiatingFrom: (state, action: PayloadAction<string>) => {
      state.form.initiatingFromField = state.form.initiatingFromField.updateValue(action.payload);
    },
    setCounterparty: (state, action: PayloadAction<string | undefined>) => {
      state.form.counterpartyField = state.form.counterpartyField.updateValueFromID(action.payload);
    },
    setCareOrderCurrency: (state, action: PayloadAction<string>) => {
      state.form.careOrderCurrencyField = state.form.careOrderCurrencyField.updateValue(action.payload);
      state.form.quantityField = state.form.quantityField
        .updateValue('', true)
        .updateScale(getQuantityIncrement(state.form.symbolField.value, action.payload));
      validateQuantity(state);
    },
    newCareOrder: state => {
      const newState = getInitialState();
      newState.referenceData = state.referenceData;
      newState.form.sideField = state.form.sideField.setTouched(false).setDisabled(false);
      newState.form.symbolField = state.form.symbolField.setTouched(false).setDisabled(false);
      newState.form.careOrderCurrencyField = state.form.careOrderCurrencyField.setTouched(false);
      newState.form.counterpartyField = state.form.counterpartyField.updateValue(undefined).setTouched(false);
      return newState;
    },
    modify: (state, action: PayloadAction<CareOrder>) => {
      const { Symbol, Side, OrderQty, Currency, Comments, Group, Counterparty, initiatingFrom } = action.payload;
      state.form.symbolField = state.form.symbolField.updateValueFromID(Symbol).setDisabled(true);
      state.form.sideField = state.form.sideField.updateValue(Side).setDisabled(true);
      state.form.quantityField = state.form.quantityField.updateValue(OrderQty);
      state.form.careOrderCurrencyField = state.form.careOrderCurrencyField.updateValue(Currency);
      state.form.counterpartyField = state.form.counterpartyField.updateValueFromID(Counterparty);
      state.form.initiatingFromField = state.form.initiatingFromField.updateValue(initiatingFrom ?? '');
      state.form.commentsField = state.form.commentsField.updateValue(Comments ?? '');
      state.form.groupField = state.form.groupField.updateValue(Group ?? '');
      state.careOrderBeingModified = action.payload;
      validateQuantity(state);
    },
    touchAll: state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });
    },
    setCareOrderForModify: (state, action: PayloadAction<CareOrder>) => {
      state.careOrderBeingModified = action.payload;
      validateQuantity(state);
    },
    resetState: state => {
      const newState = getInitialState();
      newState.referenceData = state.referenceData;
      newState.form.sideField = state.form.sideField.setTouched(false);
      newState.form.symbolField = state.form.symbolField.setTouched(false);
      newState.form.careOrderCurrencyField = state.form.careOrderCurrencyField.setTouched(false);
      newState.form.counterpartyField = state.form.counterpartyField.updateValue(undefined).setTouched(false);
      return newState;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      disableInputFields(state, payload);
      state.isLoading = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(setGlobalSymbol, (state, action) => {
      handleSymbolChange(state, action.payload);
    });
  },
});

const handleSymbolChange = (state: WritableDraft<CareOrderState>, symbol?: string) => {
  const security = state.form.symbolField.availableItems.find(s => s.Symbol === symbol);
  if (!security) {
    return;
  }

  state.form.symbolField = state.form.symbolField.updateValue(security);

  const { BaseCurrency, QuoteCurrency } = security || {};
  if (
    state.form.careOrderCurrencyField.value !== BaseCurrency &&
    state.form.careOrderCurrencyField.value !== QuoteCurrency
  ) {
    state.form.careOrderCurrencyField = state.form.careOrderCurrencyField.updateValue(BaseCurrency, true);
  }
  state.form.quantityField = state.form.quantityField
    .updateValue(security.NormalSize, true)
    .updateScale(getQuantityIncrement(security, state.form.careOrderCurrencyField.value));
};

const populateDropdownsFromRefData = (state: WritableDraft<CareOrderState>) => {
  const { securities, counterparties, fixingIndices } = state.referenceData;

  state.form.counterpartyField = state.form.counterpartyField.updateAvailableItems(counterparties);
  state.form.symbolField = state.form.symbolField.updateAvailableItems(
    securities.securitiesList.filter(s => isSpot(s) && fixingIndices.fixingIndicesBySymbol.has(s.Symbol))
  );
};

export const {
  setReferenceData,
  onMount,
  setSymbol,
  setQuantity,
  setSide,
  setCareOrderCurrency,
  setGroup,
  setCounterparty,
  setComments,
  setInitiatingFrom,
  setIsLoading,
  resetState,
  modify,
  touchAll,
  newCareOrder,
} = careOrderSlice.actions;

// DERIVED state below
export const selectCanRequestOrder = createSelector(
  (state: AppState) => state.careOrder.isLoading,
  (state: AppState) => state.careOrder.form,
  (state: AppState) => state.careOrder.careOrderBeingModified,
  (isLoading, form, careOrderBeingModified) => {
    if (careOrderBeingModified != null && careOrderBeingModified.isComplete) {
      return false;
    }
    const hasValidFields = fieldsAreValid(form);
    return hasValidFields && !isLoading;
  }
);

export function setupListeners(startListening: AppStateListenerStart) {
  /**
   * Subscribe (via RTK query) to the care order with the given OrderID,
   * so we can update the form if the care order changes.
   */
  const unsubscribeCareOrderForModify = startListening({
    actionCreator: modify,
    effect: async (action, listener) => {
      listener.cancelActiveListeners();
      const orderID = action.payload.OrderID;
      const queryArg = { OrderID: orderID };

      const selectCareOrder = streamingDataSlice.endpoints.getCareOrders.select(queryArg);
      // Check if there's already a cached value for this OrderID
      const careOrder = selectCareOrder(listener.getState()).data?.get(orderID);
      if (careOrder != null) {
        listener.dispatch(careOrderSlice.actions.setCareOrderForModify(careOrder));
      }

      // Listen for changes to the care order
      const unsubscribeCareOrderForModifyListener = startListening({
        predicate: (_, next, prev) => selectCareOrder(next) !== selectCareOrder(prev),
        effect: (_, listener) => {
          const careOrder = selectCareOrder(listener.getState()).data?.get(orderID);
          if (careOrder == null) {
            return;
          }
          listener.dispatch(careOrderSlice.actions.setCareOrderForModify(careOrder));
        },
      });
      const careOrders = listener.dispatch(streamingDataSlice.endpoints.getCareOrders.initiate(queryArg));

      // If this effect gets cancelled (e.g. the user closes the form), we should unsubscribe from the care order query
      listener.signal.addEventListener('abort', () => {
        careOrders.unsubscribe();
        unsubscribeCareOrderForModifyListener();
      });

      await listener.condition(() => listener.getState().OMS.openedView !== OMSView.CareOrderForm);
    },
  });

  return () => {
    unsubscribeCareOrderForModify();
  };
}

export const cancelCareOrder = createAsyncThunk<void, string, { extra: AppExtraArgument }>(
  'careOrder/cancelCareOrder',
  (orderID, thunk) => {
    thunk.extra.wsClient.registerPublication({
      type: CARE_CANCEL_REQUEST,
      data: [
        {
          OrderID: orderID,
          ClOrdID: uuid(),
          TransactTime: formattedDateForSubscription(new Date()),
        },
      ],
    });
    const state = thunk.getState() as AppState;
    if (state.careOrder.careOrderBeingModified?.OrderID === orderID && state.OMS.openedView === OMSView.CareOrderForm) {
      thunk.dispatch(closeView());
    }
  }
);
