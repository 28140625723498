import { toBigWithDefault } from '../utils';
import {
  type MarketAccountTypeEnum,
  type MarketTypeEnum,
  SubAccountReconCheckpointEvalStatusEnum,
  SubAccountReconCheckpointStatusEnum,
} from './types';

export interface SubAccountReconCheckpoint {
  ID: string;
  /**
   * The AccountsKey represents the  combined set of IDs of SubAccounts and MarketAccounts that are relevant to this SubAccountReconCheckpoint
   * It looks a bit weird, something like (1212,3434):(7878,4343) where its (mktacc1.id,mktacc2.id):(subacc1.id,subacc2.id)
   */
  AccountsKey: string;

  StartTime: string;
  EndTime: string;
  LastUpdateTime: string;
  Asset: string;
  Status: SubAccountReconCheckpointStatusEnum;
  EvalStatus: SubAccountReconCheckpointEvalStatusEnum;

  Unmatched: {
    Count: number;
    Amount: string;
  };

  SubAccountDetails: {
    Amount: string;
    SubAccounts: {
      SubAccount: string;
      Amount: string;
      Status: SubAccountReconCheckpointStatusEnum;
      Unmatched: {
        Amount: string;
        Count: number;
      };
    }[];
  };

  MarketAccountDetails?: {
    Amount: string;
    MarketAccounts: MarketAccountDetail[];
  };
}

export function subAccountReconCheckpointHasBreak(checkpoint: SubAccountReconCheckpoint): boolean {
  if (checkpoint.Status !== SubAccountReconCheckpointStatusEnum.Reconciled) {
    return true;
  }

  if (checkpoint.EvalStatus !== SubAccountReconCheckpointEvalStatusEnum.Done) {
    return true;
  }

  if (checkpoint.Unmatched.Count !== 0) {
    return true;
  }

  if (!toBigWithDefault(checkpoint.Unmatched.Amount, 0).eq(0)) {
    return true;
  }

  return false;
}

export interface MarketAccountDetail {
  MarketAccount: string;
  MarketType: MarketTypeEnum;
  MarketAccountType: MarketAccountTypeEnum;
  Status: SubAccountReconCheckpointStatusEnum;

  Amount: string;
  Unmatched: {
    Count: number;
    Amount: string;
  };
}
