import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  Button,
  ButtonVariants,
  FormGroup,
  Input,
  MEDIA,
  MultiSelect,
  NotificationVariants,
  Panel,
  PanelContent,
  PanelHeader,
  ROLE,
  TextArea,
  compactString,
  logger,
  useGlobalToasts,
  useUserContext,
} from '@talos/kyoko';

import { FormError, FormHeader } from 'components/Form';
import { ALL_ROLE_ITEMS, APIKEY_ROLE_ITEMS } from 'tokens/roles';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { SETTINGS_ROW_MAX_WIDTH } from '../../../styles/dimensions';
import { Actions } from './styles';

const PLACEHOLDER = 'HIDDEN_SECRETS_ONLY';

interface KeyAndSecret {
  Key: string;
  Secret: string;
}

// for quick lookup by role option value. use all possible roles for this
const roleOptionsMap = new Map(ALL_ROLE_ITEMS.map(roleItem => [roleItem.value, roleItem]));
const isSelectionLocked = (role: ROLE) => role === ROLE.READ_ONLY;
const getRoleLabel = (role: ROLE) => roleOptionsMap.get(role)?.label || '';
const getRoleDescription = (role: ROLE) => roleOptionsMap.get(role)?.value || `' '`;

const getInitialAPIKeyRoles = apiKeyRoles => {
  // READ_ONLY should always be present, but only once.
  const rolesSet = new Set([ROLE.READ_ONLY].concat(apiKeyRoles ?? []));
  return [...rolesSet];
};

export function ApiKey() {
  const theme = useTheme();
  const userService = useUserContext();
  const { user } = userService;
  const [apiKey, setApiKey] = useState<KeyAndSecret | null>(null);
  const [whitelistedIPs, setWhitelistedIPs] = useState<string | undefined>();
  const { add } = useGlobalToasts();

  const [keyRoles, setKeyRoles] = useState(getInitialAPIKeyRoles(user.ApiKey?.Roles));

  // The possible key roles is equal to the set intersection of a user's current roles
  // and the static apikey role items list
  const possibleKeyRoles = useMemo(() => {
    const userRolesSet = new Set(user.Roles ?? []).add(ROLE.READ_ONLY); // read only always present, either explicitly or implicitly.
    return (
      APIKEY_ROLE_ITEMS
        // [sc-43673] Remove None from API keys capabilities menu
        // Existing Users with the role will still see the option to remove from their capabilities.
        .filter(roleItem => roleItem.value !== ROLE.NONE)
        // Only allow the user to set api keys which the user also has on itself
        .filter(roleItem => userRolesSet.has(roleItem.value))
        .map(roleItem => roleItem.value)
    );
  }, [user]);

  const createApiKey = useCallback(
    whitelistedIPs => {
      userService
        .createApiKey(whitelistedIPs)
        .then(apiKey => {
          setApiKey(apiKey);
          setKeyRoles(getInitialAPIKeyRoles(undefined));
          setWhitelistedIPs(apiKey.WhitelistedIPs || '');
          add({
            text: `API key created.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(e => {
          logger.error(e as Error);
          add({
            text: `Could not create API key${e?.message ? `: ${e.message}` : ''}.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [userService, add]
  );

  const removeApiKey = useCallback(() => {
    if (window.confirm('Are you sure you want to delete your current API key?')) {
      userService
        .deleteApiKey()
        .then(() => {
          setApiKey(null);
          add({
            text: `API key removed.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(e => {
          logger.error(e as Error);
          add({
            text: `Could not delete API key${e?.message ? `: ${e.message}` : ''}.`,
            variant: NotificationVariants.Negative,
          });
        });
    }
  }, [userService, add]);

  const updateWhitelistedIPs = useCallback(
    whitelistedIPs => {
      userService
        .updateApiKeyWhitelistedIPs(whitelistedIPs)
        .then(() => {
          setWhitelistedIPs(whitelistedIPs);
          add({
            text: `Whitelisted IPs updated.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(e => {
          logger.error(e as Error);
          add({
            text: `Could not update whitelisted IPs${e?.message ? `: ${e.message}` : ''}.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [userService, add]
  );

  const updateRoles = useCallback(
    newRoles => {
      if (newRoles.length === 0) {
        // Push the ReadOnly role if there are no roles selected.
        newRoles.push(ROLE.READ_ONLY);
      }

      userService
        .updateApiKeyRoles(newRoles)
        .then(() => setKeyRoles(newRoles))
        .then(() => {
          add({
            text: `API key capabilities updated.`,
            variant: NotificationVariants.Positive,
          });
        })
        .catch(e => {
          logger.error(e as Error);
          add({
            text: `Could not update API key capabilities${e?.message ? `: ${e.message}` : ''}.`,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [userService, add]
  );

  useEffect(() => {
    if (user != null) {
      setWhitelistedIPs(user.ApiKey?.WhitelistedIPs || '');
    }
  }, [user]);

  if (user == null) {
    return null;
  }

  let key, secret;
  if (apiKey) {
    key = apiKey.Key;
    secret = apiKey.Secret;
  } else if (user.ApiKey) {
    key = user.ApiKey.Key;
  }

  return key ? (
    <ApiKeyForm
      key={key}
      user={user}
      apikey={key}
      secret={secret}
      whitelistedIPs={whitelistedIPs}
      roles={keyRoles}
      possibleRoles={possibleKeyRoles}
      createApiKey={createApiKey}
      removeApiKey={removeApiKey}
      updateWhitelistedIPs={updateWhitelistedIPs}
      updateRoles={updateRoles}
      domain={user.OrgWsEndpoint}
    />
  ) : (
    <div style={{ padding: `${theme.spacingSmall}px ${theme.spacingMedium}px` }}>
      <p>You currently do not have an API Key.</p>
      <Button variant={ButtonVariants.Positive} onClick={() => createApiKey(null)}>
        Create API Key
      </Button>
    </div>
  );
}

const ApiKeyForm = ({
  user,
  createApiKey,
  removeApiKey,
  updateWhitelistedIPs,
  updateRoles,
  apikey,
  secret,
  whitelistedIPs,
  possibleRoles,
  roles,
  domain,
}) => {
  const initialValues = {
    key: apikey,
    secret: secret || PLACEHOLDER,
    whitelistedIPs,
    roles,
    domain,
  };
  const { width } = useWindowSize();
  const inline = width > MEDIA.default;

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<{ [key: string]: string | boolean }>({ whitelistedIPs: false });

  useEffect(() => {
    setValues(prev => ({ ...prev, whitelistedIPs }));
  }, [whitelistedIPs]);

  const isValidIP = ip => {
    const match = ip.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
    return match != null && [match[1], match[2], match[3], match[4]].every(n => parseInt(n) <= 255);
  };

  const validateWhitelistedIPs = (value: string) => {
    if (value == null) {
      return true;
    }
    return value.split(/,|\n/).compact(true).map(compactString).every(isValidIP);
  };

  const setFieldValue = (field, newValue) => {
    setValues(prev => ({ ...prev, [field]: newValue }));
    if (field === 'whitelistedIPs' && !validateWhitelistedIPs(newValue)) {
      setErrors(prev => ({ ...prev, [field]: 'Invalid IP address(es).' }));
    } else {
      setErrors(prev => ({ ...prev, [field]: false }));
    }
  };

  return (
    <Panel>
      <PanelHeader>
        <h2>API Key</h2>
      </PanelHeader>
      <PanelContent>
        <FormHeader>Credentials</FormHeader>
        <Box maxWidth={`${SETTINGS_ROW_MAX_WIDTH}px`}>
          <FormGroup labelWidth="120px" controlId="key" label="Key" inline={inline}>
            <Input name="key" readOnly={true} value={apikey} />
          </FormGroup>
          <FormGroup mb="spacingSmall" labelWidth="120px" controlId="secret" label="Secret" inline={inline}>
            <Input
              name="secret"
              inputType={secret ? 'text' : 'password'}
              readOnly={true}
              value={secret || PLACEHOLDER}
            />
          </FormGroup>
          <Box ml={inline ? '120px' : '0'} mt="0" mb="spacingDefault">
            {secret ? (
              'Make sure you store the secret somewhere safe. It will not be displayed again.'
            ) : (
              <>
                For security reasons we do not expose your API key or secrets. If you have lost your secret you will
                need to create a new key.
              </>
            )}
          </Box>
          <FormGroup labelWidth="120px" controlId="domain" label="Domain" inline={inline}>
            <Input
              name="domain"
              readOnly={true}
              value={values.domain}
              onChange={e => setFieldValue('domain', e.target.value)}
            />
          </FormGroup>
          <FormGroup labelWidth="120px" inline={inline}>
            <Actions>
              <Button variant={ButtonVariants.Negative} onClick={removeApiKey}>
                Remove API Key
              </Button>
              <Button variant={ButtonVariants.Positive} onClick={() => createApiKey(values.whitelistedIPs)}>
                Recreate API Key
              </Button>
            </Actions>
          </FormGroup>
        </Box>

        <FormHeader>Capabilities</FormHeader>
        <Box maxWidth={`${SETTINGS_ROW_MAX_WIDTH}px`}>
          <Box ml={inline ? '120px' : '0'} mt="spacingDefault" mb="spacingDefault">
            Select which roles you would like to assign to this API key. View is the default role for all API keys. For
            reference, your user&apos;s roles are:
            {' ' +
              [ROLE.READ_ONLY]
                .concat(user.Roles ?? [])
                .map(role => getRoleLabel(role))
                .join(', ') +
              '.'}
          </Box>
          <FormGroup labelWidth="120px" inline={inline} label=" ">
            <MultiSelect
              style={{ width: '100%' }}
              selections={values.roles}
              options={possibleRoles}
              getLabel={getRoleLabel}
              getDescription={getRoleDescription}
              isSelectionLocked={isSelectionLocked}
              onChange={newRoles => setFieldValue('roles', newRoles)}
            />
            {errors['roles'] && <FormError style={{ marginLeft: 0 }}>{errors['roles']}</FormError>}
          </FormGroup>
          <FormGroup labelWidth="120px" inline={inline}>
            <Actions>
              <span />
              <Button
                variant={ButtonVariants.Positive}
                onClick={() => updateRoles(values.roles)}
                disabled={errors['roles'] as boolean}
              >
                Save Capabilities
              </Button>
            </Actions>
          </FormGroup>
        </Box>

        <FormHeader>IP-address whitelist</FormHeader>
        <Box maxWidth={`${SETTINGS_ROW_MAX_WIDTH}px`}>
          <FormGroup labelWidth="120px" inline={inline}>
            <p>
              For extra security you can supply a list of whitelisted IPs. When specified, only requests from an IP in
              the list will be accepted.
            </p>
          </FormGroup>
          <FormGroup
            inline={inline}
            labelWidth="120px"
            controlId="whitelistedIPs"
            label="Whitelisted IPs"
            error={errors['whitelistedIPs'] as string}
            help="Multiple addresses can be comma-separated or written on new lines."
          >
            <TextArea
              id="whitelistedIPs"
              name="whitelistedIPs"
              rows={5}
              value={values.whitelistedIPs}
              touched={initialValues.whitelistedIPs !== values.whitelistedIPs}
              invalid={!!errors['whitelistedIPs']}
              onChange={e => setFieldValue('whitelistedIPs', e.target.value)}
            />
          </FormGroup>
          <Box ml={inline ? '20%' : '0'} />
          <FormGroup labelWidth="120px" inline={inline}>
            <Actions>
              <span />
              <Button
                variant={ButtonVariants.Positive}
                onClick={() => updateWhitelistedIPs(values.whitelistedIPs)}
                disabled={
                  (errors['whitelistedIPs'] as boolean) || initialValues.whitelistedIPs === values.whitelistedIPs
                }
              >
                Save Whitelisted IPs
              </Button>
            </Actions>
          </FormGroup>
        </Box>
      </PanelContent>
    </Panel>
  );
};
