import { type Allocation, runValidation, toBigWithDefault, useDynamicCallback } from '@talos/kyoko';
import Big from 'big.js';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { array, date, object, string } from 'yup';
import type { SchemaLike } from 'yup/lib/types';
import type { ResolveBreakForm } from './types';

export const useResolutionValidation = (form: ResolveBreakForm, breakAmount: string | undefined) => {
  const breakAmountBig = toBigWithDefault(breakAmount, 0);
  const [errors, setErrors] = useState<Partial<Record<keyof ResolveBreakForm, string>>>({});
  const [touched, setTouched] = useState<Partial<Record<keyof ResolveBreakForm, boolean>>>({});

  const setAllTouched = useDynamicCallback(() =>
    setTouched({
      resolutionType: true,
      subAccountAllocations: true,
      asset: true,
      comments: true,
      timestamp: true,
    } satisfies Record<keyof ResolveBreakForm, boolean>)
  ); // static test to ensure we arent missing any

  useEffect(() => {
    const schema: Partial<Record<keyof ResolveBreakForm, SchemaLike>> = {
      resolutionType: string().required('Resolution Type is required'),
      asset: string().required('Asset is required'),
      timestamp: date().required('Transact Time is required').typeError('Transact Time is not valid'),
    };

    if (form.resolutionType !== 'ignore') {
      let subAccountAllocationsSchema = array<Allocation>(
        object({
          subAccount: string()['required']('Please select a Sub Account'),
          value: string().default(''),
        }).typeError('Please select a Sub Account')
      );

      subAccountAllocationsSchema = subAccountAllocationsSchema.min(1, 'Please select a Sub Account');

      subAccountAllocationsSchema = subAccountAllocationsSchema.test(
        'allocations',
        `Total must equal ${breakAmountBig.times(-1).toFixed()}`,
        (arr: Allocation[] | undefined) => {
          if (!arr) {
            return false;
          }

          const value = arr.reduce(
            (workingValue, allocation) => workingValue.plus(toBigWithDefault(allocation.value, 0)),
            Big(0)
          );

          // The break amount is sub account amount - market account amount. In order to resolve the break, the user must
          // apply the inverse of this break (diff) to the sub account amount, hence the -1.
          // Eg: SubAccAmt: 1.0, MktAccAmt: 1.5. Diff: 1.0-1.5=-0.5. Resolution: +0.5 to the SubAccAmt.
          return value.eq(breakAmountBig.times(-1));
        }
      );

      schema.subAccountAllocations = subAccountAllocationsSchema;
    }

    setErrors(prev => {
      const next = runValidation(object().shape(schema), form);
      return isEqual(prev, next) ? prev : next;
    });
  }, [form, breakAmountBig]);

  return { errors, touched, setTouched, setAllTouched };
};
