import {
  ACTION,
  ApiPermissionActionEnum,
  BlotterTable,
  Box,
  Button,
  Flex,
  IconName,
  LocalFilterInput,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Portal,
  Text,
  logger,
  useBlotterTable,
  useDisclosure,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useGlobalToasts,
  useMixpanel,
  useObservable,
  type Column,
  type DrawerProps,
  type SubAccount,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, IRowNode } from 'ag-grid-community';
import { useFeatureFlag, useGetMarketAccountsDisplayNameBySubAccount, useRoleAuth } from 'hooks';
import { compact, uniq } from 'lodash';
import { useSubAccountPermissionFilters, useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { useTheme } from 'styled-components';
import { CreateSubAccountModal } from '../../../components/CreateSubAccountModal';
import { FlexContainer, FlexContent, FlexHeader } from './styles';
import { SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID } from './types';

interface SubAccountsProps {
  drawer: DrawerProps;
  selectedSubAccountName: string | null;
  setSelectedSubAccountName: React.Dispatch<React.SetStateAction<string | null>>;
}

export function SubAccounts({ drawer, selectedSubAccountName, setSelectedSubAccountName }: SubAccountsProps) {
  const { add: addToast } = useGlobalToasts();
  const theme = useTheme();
  const [quickFilterText, setQuickFilterText] = useState('');
  const { deleteSubAccount } = useSubAccounts();
  const mixpanel = useMixpanel();
  const dialog = useDisclosure();

  const { enableAccountSegregation } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();

  const addSubAccountEnabled = enableAccountSegregation ? isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS) : true;
  const [modelSubAccount, setModelSubAccount] = useState<SubAccount | undefined>(undefined);

  const handleOnEditRow = useDynamicCallback((subAccount: SubAccount) => {
    drawer.open();
    setSelectedSubAccountName(subAccount.Name);
  });

  const handleOnDeleteRow = useDynamicCallback((subAccount: SubAccount) => {
    deleteSubAccount(subAccount.SubaccountID)
      .then(() => {
        addToast({
          text: `Sub account deleted.`,
          variant: NotificationVariants.Positive,
        });
        // if the deleted subaccount was opened in the drawer, close the drawer
        if (selectedSubAccountName === subAccount.Name) {
          drawer.close();
        }
      })
      .catch(e => {
        logger.error(e);
        addToast({
          text: `Could not delete sub account${e?.message ? `: ${e.message}` : ''}.`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const handleCloneRow = useDynamicCallback((subAccount: SubAccount) => {
    setModelSubAccount(subAccount);
    dialog.open();
  });

  const handleSuccessfulCreation = useDynamicCallback((newSubAccount: SubAccount) => {
    setSelectedSubAccountName(newSubAccount.Name);
    setModelSubAccount(undefined);
    drawer.open();
    dialog.close();
  });

  return (
    <>
      <Portal portalId={SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID}>
        {addSubAccountEnabled && (
          <Button
            startIcon={IconName.Plus}
            onClick={() => dialog.open()}
            disabled={!addSubAccountEnabled}
            data-testid="create-sub-account-button"
          >
            Create Sub Account
          </Button>
        )}
      </Portal>
      <FlexContainer h="100%" w="100%">
        <FlexHeader flexDirection="row" w="100%" justifyContent="space-between">
          <Flex flexDirection="row" justifyContent="flex-start" my={theme.spacingMedium}>
            <Text>Sub accounts allow traders to assign trades to a specific accounts.</Text>
          </Flex>
          <Flex flexDirection="row" justifyContent="flex-end" my={theme.spacingMedium}>
            <LocalFilterInput
              value={quickFilterText}
              onChange={e => {
                mixpanel.track(MixpanelEvent.FilterQuickSearch, {
                  [MixpanelEventProperty.Source]: 'SubAccounts',
                });
                setQuickFilterText(e);
              }}
              width="200px"
            />
          </Flex>
        </FlexHeader>
        <FlexContent>
          <SubAccountsBlotter
            onCloneRow={handleCloneRow}
            onEditRow={handleOnEditRow}
            onDeleteRow={handleOnDeleteRow}
            quickFilterText={quickFilterText}
          />
        </FlexContent>
      </FlexContainer>
      <CreateSubAccountModal
        /** Remount the model on every change of the model SA. The need for this is documented on the CreateSubAccountModal component itself. */
        key={modelSubAccount?.Name ?? 'key'}
        onSuccessfulCreation={handleSuccessfulCreation}
        initialModelSubAccount={modelSubAccount}
        {...dialog}
      />
    </>
  );
}

type SubAccountRow = {
  SubAccountName: string;
  SubAccountDisplayName: string;
  SubAccountRollups: string;
  SubAccount: SubAccount;
  TradeUsers: string[];
};

function SubAccountsBlotter({
  onCloneRow,
  onEditRow,
  onDeleteRow,
  quickFilterText,
}: {
  onCloneRow: (subAccount: SubAccount) => void;
  onEditRow: (subAccount: SubAccount) => void;
  onDeleteRow: (subAccount: SubAccount) => void;
  quickFilterText: string;
}) {
  // In this list we render all subaccounts
  const { allSubAccountBooks, subAccountsByID } = useSubAccounts();
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();

  const { filterIDsBySubAccountName, subAccountPermissionFiltersByFilterID } = useSubAccountPermissionFilters();
  const { enableAccountSegregation } = useFeatureFlag();
  const { isAuthorized } = useRoleAuth();

  const canCloneSubAccounts = isAuthorized(ACTION.CLONE_SUB_ACCOUNTS);
  const canEditSubAccounts = enableAccountSegregation ? isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS) : true;
  const handleEditRow = useDynamicCallback((data: SubAccount) => onEditRow(data));
  const handleDeleteRow = useDynamicCallback((data: SubAccount) => onDeleteRow(data));

  const rows: SubAccountRow[] = useMemo(() => {
    if (!filterIDsBySubAccountName) {
      return [];
    }

    return (allSubAccountBooks || []).map(subAccount => {
      const apiFilterPermissions = Array.from(filterIDsBySubAccountName?.get(subAccount.Name) || []).concat(
        Array.from(filterIDsBySubAccountName?.get('*') || [])
      );
      const TradeUsers: string[] = [];
      const ViewUsers: string[] = [];

      for (const apiFilterPermissionID of apiFilterPermissions) {
        const apiFilterPermission = subAccountPermissionFiltersByFilterID?.get(apiFilterPermissionID);
        if (apiFilterPermission?.Subject.User) {
          switch (apiFilterPermission.Action) {
            case ApiPermissionActionEnum.Write:
              TradeUsers.push(apiFilterPermission.Subject.User);
              break;
            case ApiPermissionActionEnum.Read:
              ViewUsers.push(apiFilterPermission.Subject.User);
          }
        }
      }
      const rollups = rollupMembershipsByChildParent.get(subAccount.SubaccountID);
      const subAccountRollups =
        rollups && subAccountsByID
          ? Array.from(rollups.values())
              .map(rollup => {
                return subAccountsByID.get(rollup.ParentID)?.DisplayName || '';
              })
              .join(', ')
          : '';

      return {
        SubAccountName: subAccount.Name,
        SubAccountDisplayName: subAccount.DisplayName,
        SubAccountRollups: subAccountRollups,
        SubAccount: subAccount,
        TradeUsers: uniq(TradeUsers.slice(0, 25)),
        ViewUsers: uniq(ViewUsers.slice(0, 25)),
      };
    });
  }, [
    filterIDsBySubAccountName,
    subAccountPermissionFiltersByFilterID,
    allSubAccountBooks,
    rollupMembershipsByChildParent,
    subAccountsByID,
  ]);

  const getMarketAccountDisplayNames = useGetMarketAccountsDisplayNameBySubAccount();

  const observableRows = useObservable(
    () => of(rows).pipe(map(data => ({ data, initial: true, type: 'SubAccountApiPermissions' }))),
    [rows]
  );

  const columns = useMemo(
    () =>
      compact([
        {
          field: 'SubAccountDisplayName',
          title: 'Display Name',
          type: 'text',
          sort: '+',
          sortable: true,
          description: 'The Sub Account Display Name is not unique and can be changed.',
        },
        {
          field: 'SubAccountName',
          title: 'Name',
          type: 'text',
          sortable: true,
          description: 'The Sub Account Name is unique per Sub Account and cannot be changed.',
        },
        {
          id: 'tradeUsers',
          title: 'Trade Users',
          type: 'custom',
          params: {
            valueGetter: ({ data }) => data.TradeUsers.join(', '),
          },
        },
        {
          id: 'viewUsers',
          title: 'View Users',
          type: 'custom',
          params: {
            valueGetter: ({ data }) => data.ViewUsers.join(', '),
          },
        },
        {
          title: 'Market Accounts',
          id: 'marketAccounts',
          suppressHeaderMenuButton: true,
          type: 'custom',
          params: {
            valueGetter: ({ data }) => getMarketAccountDisplayNames(data.SubAccountName).join(', '),
          },
        },
        {
          title: 'Sub Account Rollups',
          id: 'marketAccounts',
          suppressHeaderMenuButton: true,
          field: 'SubAccountRollups',
          type: 'text',
        },
        {
          id: 'edit',
          type: 'iconButton',
          pinned: 'right',
          frozen: true,
          width: 45,
          suppressColumnsToolPanel: true,
          params: {
            onClick: ({ node }: { node: IRowNode<SubAccountRow> }) =>
              node.data?.SubAccount && handleEditRow(node.data.SubAccount),
            icon: IconName.Pencil,
          },
        },
        canEditSubAccounts
          ? {
              id: 'delete',
              type: 'iconButton',
              pinned: 'right',
              width: 45,
              frozen: true,
              suppressColumnsToolPanel: true,
              params: {
                onClick: ({ node }: { node: IRowNode<SubAccountRow> }) =>
                  node.data?.SubAccount && handleDeleteRow(node.data.SubAccount),
                icon: IconName.Trash,
              },
            }
          : null,
      ]) satisfies Column[],
    [getMarketAccountDisplayNames, handleDeleteRow, handleEditRow, canEditSubAccounts]
  );

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<SubAccountRow>) => {
      const subAccountRightClicked = params.node?.data?.SubAccount;
      return compact([
        canCloneSubAccounts &&
          subAccountRightClicked != null && {
            name: 'Clone',
            action: () => onCloneRow(subAccountRightClicked),
            icon: `<i class="ag-icon ${IconName.Duplicate}"/>`,
          },
        'separator',
        ...getDefaultContextMenuItems(params),
      ]);
    },
    [canCloneSubAccounts, getDefaultContextMenuItems, onCloneRow]
  );

  const blotterTable = useBlotterTable({
    rowID: 'SubAccountName',
    columns,
    dataObservable: observableRows,
    quickSearchParams: {
      filterText: quickFilterText,
    },
    gridOptions: {
      onRowDoubleClicked: ({ data }) => data != null && handleEditRow(data.SubAccount),
      getContextMenuItems,
    },
  });

  return (
    <Box w="100%" h="100%">
      <BlotterTable {...blotterTable} />
    </Box>
  );
}
