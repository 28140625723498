import {
  Dialog,
  HeartBeatStatusEnum,
  WebSocketStatus,
  useDisclosure,
  useHeartbeats,
  useObservableValue,
  useSocketStatus,
} from '@talos/kyoko';

import { ConnectionFailureMessage, ModalLoader, Support, SupportDetails } from './styles';

export function ConnectionMessage() {
  const heartbeats = useHeartbeats();
  const socketStatus = useSocketStatus();

  const heartbeatStatus = useObservableValue(() => heartbeats.status, [heartbeats.status]);

  const dialog = useDisclosure();
  const faultySocketStatuses = [WebSocketStatus.CLOSED, WebSocketStatus.RECONNECTING];

  if (!faultySocketStatuses.includes(socketStatus) && heartbeatStatus !== HeartBeatStatusEnum.OFFLINE) {
    return null;
  }

  return (
    <Dialog
      {...dialog}
      closeOnEscape={false}
      closeOnClickOutside={false}
      showCancel={false}
      showConfirm={false}
      width={340}
      usePortal={false}
      isOpen={true}
    >
      {socketStatus === WebSocketStatus.CLOSED && (
        <ConnectionFailureMessage>
          Please contact Talos Support and we will resolve the problem as quickly as possible.
        </ConnectionFailureMessage>
      )}
      {(socketStatus === WebSocketStatus.RECONNECTING || heartbeatStatus === HeartBeatStatusEnum.OFFLINE) && (
        <>
          <p>Attempting to reconnect...</p>
          <ModalLoader />
        </>
      )}
      <Support>
        <h3>Talos Support</h3>
        <SupportDetails>
          <a href="mailto:support@talos.com">support@talos.com</a> <span>{import.meta.env.VITE_SUPPORT_PHONE}</span>
        </SupportDetails>
      </Support>
    </Dialog>
  );
}
