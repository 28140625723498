import { MixpanelEvent, MixpanelEventSource } from '@talos/kyoko';
import type { LayoutOptionView } from '../portfolioDashboard.types';
import type { PortfolioViewType } from '../portfolioDashboardLayouts';
import { getOperationsLayout } from './dockviewWorkspaceConfigs/operations/operations.defaultLayout';
import { getPortfolioManagementLayout } from './dockviewWorkspaceConfigs/portfolioManagement/portfolioManagement.defaultLayout';

export function getPortfolioLayoutOptions(): LayoutOptionView<PortfolioViewType>[] {
  const result: LayoutOptionView<PortfolioViewType>[] = [
    {
      label: 'Portfolio Management',
      route: '/portfolio-management',
      value: 'portfolio-management',
      tabs: [
        {
          label: 'Overview',
          route: '/overview',
          layoutType: 'fixed',
          content: 'PM-Overview',
          activeFilters: ['SubAccounts'],
          activeControls: {
            showRollupHierarchy: true,
            menuOptions: ['includeCash', 'showZeroBalances'],
          },
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementOverview,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementOverview,
        },
        {
          label: 'Performance',
          route: '/performance',
          layoutType: 'fixed',
          content: 'PM-Performance',
          activeFilters: ['SubAccounts'],
          activeControls: {
            showRollupHierarchy: true,
            menuOptions: ['includeCash', 'showZeroBalances'],
          },
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementPerformance,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementPerformance,
        },
        {
          label: 'Transactions',
          route: '/transactions',
          layoutType: 'flexible',
          getDefaultLayout: () => getPortfolioManagementLayout(),
          availablePanels: 'pm-transactions',
          activeFilters: ['SubAccounts', 'DateRange'],
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementTransactions,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementTransactions,
        },
        {
          label: 'Reports',
          route: '/reports',
          layoutType: 'fixed',
          content: 'PM-Reports',
          activeFilters: ['SubAccounts', 'DateRange'],
          activeControls: {
            menuOptions: ['showZeroBalances'],
          },
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioManagementReports,
          mixpanelEventSource: MixpanelEventSource.PortfolioManagementReports,
        },
      ],
    },
    {
      label: 'Risk Management',
      route: '/risk-management',
      value: 'risk',
      tabs: [
        {
          label: 'Risk Exposures',
          route: '/exposures',
          layoutType: 'fixed',
          content: 'Risk-Exposures',
          activeFilters: ['SubAccounts'],
          activeControls: {
            showRollupHierarchy: true,
            menuOptions: ['includeCash', 'showZeroBalances'],
          },
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioRiskExposures,
          mixpanelEventSource: MixpanelEventSource.PortfolioRiskExposures,
        },
      ],
    },
    {
      label: 'Operations',
      route: '/operations',
      value: 'operations',
      tabs: [
        {
          label: 'Overview',
          route: '/overview',
          layoutType: 'fixed',
          content: 'Operations-Overview',
          activeFilters: ['MarketAccounts'],
          activeControls: {
            menuOptions: ['showZeroBalances'],
          },
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsOverview,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsOverview,
        },
        {
          label: 'Transactions',
          route: '/transactions',
          layoutType: 'flexible',
          getDefaultLayout: () => getOperationsLayout(),
          availablePanels: 'operations-transactions',
          activeFilters: ['MarketAccounts', 'DateRange'],
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsTransactions,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsTransactions,
        },
        {
          label: 'OTC Settlement',
          route: '/otc-settlement',
          layoutType: 'fixed',
          content: 'Operations-Settlement',
          // Settlement's filters aren't consistent across their views,
          // so maintaining prior internal-only filtering for now
          activeFilters: [],
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsSettlement,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsSettlement,
        },
        {
          label: 'Reconciliation',
          route: '/reconciliation',
          layoutType: 'fixed',
          content: 'Operations-Reconciliation',
          activeFilters: [],
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsReconciliation,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsReconciliation,
        },

        {
          label: 'Reports',
          route: '/reports',
          layoutType: 'fixed',
          content: 'Operations-Reports',
          activeFilters: ['MarketAccounts', 'DateRange'],
          activeControls: {
            menuOptions: ['showZeroBalances'],
          },
          mixpanelEvent: MixpanelEvent.NavigateToPortfolioOperationsReports,
          mixpanelEventSource: MixpanelEventSource.PortfolioOperationsReports,
        },
      ],
    },
  ];
  return result;
}
