import {
  ACTION,
  BlotterTable,
  Box,
  Button,
  EMPTY_ARRAY,
  Flex,
  IconName,
  LocalFilterInput,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Portal,
  Text,
  TreeViewTooltip,
  createTreeFromRollupMemberships,
  getSubAccountsForNode,
  logger,
  useBlotterTable,
  useDisclosure,
  useDynamicCallback,
  useGlobalToasts,
  useMixpanel,
  useObservable,
  type Column,
  type DrawerProps,
  type SubAccount,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-community';
import { useRoleAuth } from 'hooks';
import { compact } from 'lodash';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { useTheme } from 'styled-components';
import { NewSubAccountRollupDialog } from './NewSubAccountRollupDialog';
import { FlexContainer, FlexContent, FlexHeader } from './styles';
import { SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID } from './types';

interface SubAccountRollupsProps {
  drawer: DrawerProps;
  selectedSubAccountName: string | null;
  setSelectedSubAccountName: React.Dispatch<React.SetStateAction<string | null>>;
}

export function SubAccountRollups(props: SubAccountRollupsProps) {
  const { drawer, selectedSubAccountName, setSelectedSubAccountName } = props;
  const { add: addToast } = useGlobalToasts();
  const theme = useTheme();
  const [quickFilterText, setQuickFilterText] = useState('');
  const { deleteSubAccountRollup } = useSubAccounts();
  const mixpanel = useMixpanel();
  const dialog = useDisclosure();
  const { isAuthorized } = useRoleAuth();
  const addSubAccountRollupEnabled = isAuthorized(ACTION.EDIT_ROLLUPS);

  const handleOnEditRow = useDynamicCallback((subAccount: SubAccount) => {
    drawer.open();
    setSelectedSubAccountName(subAccount.Name);
  });

  const handleOnDeleteRow = useDynamicCallback((subAccount: SubAccount) => {
    deleteSubAccountRollup(subAccount.SubaccountID)
      .then(() => {
        addToast({
          text: `Sub account rollup deleted.`,
          variant: NotificationVariants.Positive,
        });
        // if the deleted subaccount was opened in the drawer, close the drawer
        if (selectedSubAccountName === subAccount.Name) {
          drawer.close();
        }
      })
      .catch(e => {
        logger.error(e as Error);
        addToast({
          text: `Could not delete sub account rollup ${e?.message ? `: ${e.message}` : ''}.`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const handleRollupCreated = useDynamicCallback((rollup: SubAccount) => {
    setSelectedSubAccountName(rollup.Name);
    drawer.open();
  });

  return (
    <>
      <Portal portalId={SUB_ACCOUNT_SETTINGS_PANEL_ACTIONS_PORTAL_ID}>
        {addSubAccountRollupEnabled && (
          <Button startIcon={IconName.Plus} onClick={() => dialog.open()} disabled={!addSubAccountRollupEnabled}>
            Create Sub Account Rollup
          </Button>
        )}
      </Portal>
      <FlexContainer h="100%" w="100%">
        <FlexHeader flexDirection="row" w="100%" justifyContent="space-between">
          <Flex flexDirection="row" justifyContent="flex-start" my={theme.spacingMedium}>
            <Text>Sub account rollups allow sub account data to be grouped within blotters.</Text>
          </Flex>
          <Flex flexDirection="row" justifyContent="flex-end" my={theme.spacingMedium}>
            <LocalFilterInput
              value={quickFilterText}
              onChange={e => {
                mixpanel.track(MixpanelEvent.FilterQuickSearch, {
                  [MixpanelEventProperty.Source]: 'SubAccountRollups',
                });
                setQuickFilterText(e);
              }}
              width="200px"
            />
          </Flex>
        </FlexHeader>
        <FlexContent>
          <SubAccountsBlotter
            onEditRow={handleOnEditRow}
            onDeleteRow={handleOnDeleteRow}
            quickFilterText={quickFilterText}
          />
        </FlexContent>
      </FlexContainer>
      <NewSubAccountRollupDialog onRollupCreated={handleRollupCreated} dialog={dialog} />
    </>
  );
}

type SubAccountRollupRow = SubAccount & {
  SubAccounts: string;
};

function SubAccountsBlotter({
  onEditRow,
  onDeleteRow,
  quickFilterText,
}: {
  onEditRow: (subAccount: SubAccount) => void;
  onDeleteRow: (subAccount: SubAccount) => void;
  quickFilterText: string;
}) {
  // In this list we render all subaccounts

  const { rollupMembershipsByChildParent, rollupMembershipsByParentChild } = useSubAccountRollupMemberships();
  const { subAccountsByID, allSubAccountRollups } = useSubAccounts();
  const { isAuthorized } = useRoleAuth();
  const canEditRollups = isAuthorized(ACTION.EDIT_ROLLUPS);

  const handleEditRow = useDynamicCallback((data: SubAccount) => onEditRow(data));
  const handleDeleteRow = useDynamicCallback((data: SubAccount) => onDeleteRow(data));

  const rollupTree = useMemo(() => {
    const tree = createTreeFromRollupMemberships(
      rollupMembershipsByParentChild,
      rollupMembershipsByChildParent,
      subAccountsByID || new Map()
    );
    return tree;
  }, [subAccountsByID, rollupMembershipsByParentChild, rollupMembershipsByChildParent]);

  const rows: SubAccountRollupRow[] = useMemo(() => {
    return (allSubAccountRollups || EMPTY_ARRAY).map(subAccount => {
      return {
        ...subAccount,
        SubAccounts: getSubAccountsForNode(rollupTree, subAccount)
          .map(account => account?.DisplayName ?? account?.Name ?? '')
          .join(', '),
      };
    });
  }, [allSubAccountRollups, rollupTree]);

  const observableRows = useObservable(
    () => of(rows).pipe(map(data => ({ data, initial: true, type: 'SubAccountRollups' }))),
    [rows]
  );

  const columns = useMemo(
    () =>
      compact([
        {
          title: 'Rollup Name',
          field: 'DisplayName',
          type: 'custom',
          sort: '+',
          sortable: true,
          params: {
            cellRenderer: ({ data }: { data: SubAccountRollupRow }) => {
              return (
                <Flex gap="spacingDefault" alignItems="center">
                  {data.DisplayName}
                  <TreeViewTooltip tree={rollupTree} subAccount={data} highlight={[data.SubaccountID.toString()]} />
                </Flex>
              );
            },
          },
        },
        {
          title: 'Sub Accounts',
          type: 'text',
          field: 'SubAccounts',
          flex: 1,
        },
        {
          id: 'edit',
          type: 'iconButton',
          pinned: 'right',
          frozen: true,
          width: 45,
          suppressColumnsToolPanel: true,
          params: {
            onClick: ({ node }: { node: IRowNode<SubAccount> }) => node.data && handleEditRow(node.data),
            icon: IconName.Pencil,
          },
        },
        canEditRollups
          ? {
              id: 'delete',
              type: 'iconButton',
              pinned: 'right',
              width: 45,
              frozen: true,
              suppressColumnsToolPanel: true,
              params: {
                onClick: ({ node }: { node: IRowNode<SubAccount> }) => node.data && handleDeleteRow(node.data),
                icon: IconName.Trash,
              },
            }
          : null,
      ]) satisfies Column[],
    [handleDeleteRow, handleEditRow, canEditRollups, rollupTree]
  );

  const blotterTable = useBlotterTable({
    rowID: 'Name' satisfies keyof SubAccountRollupRow,
    columns,
    dataObservable: observableRows,
    quickSearchParams: {
      filterText: quickFilterText,
    },
    gridOptions: {
      onRowDoubleClicked: ({ data }) => data != null && handleEditRow(data),
    },
  });

  return (
    <Box w="100%" h="100%">
      <BlotterTable {...blotterTable} />
    </Box>
  );
}
