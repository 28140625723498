import type { SubAccountReconMatchStatusEnum, TabProps } from '@talos/kyoko';

export interface BreakDetailsBlotterFilter {
  Statuses?: SubAccountReconMatchStatusEnum[];
}

export interface BreakDetailsBlotterTabProps extends TabProps {
  defaultFilter: Partial<BreakDetailsBlotterFilter>;
}

export const RECON_MATCHES_BLOTTER_PORTAL_ID = 'ReconMatchesBlotterPortal';
