import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import type { AppConfigState } from 'providers/AppConfigProvider/AppConfigProvider.types';
import type { AppState } from 'providers/AppStateProvider/types';

export type AppConfigReduxState = {
  config: AppConfigState;
  configDetails: {
    lastUpdated: number;
    changeCount: number;
  };
};
let appConfigActions: ReturnType<typeof createAppConfigSlice>['actions'] | undefined;
export const getAppConfigSliceActions = () => {
  if (!appConfigActions) {
    throw new Error('AppConfigSlice not initialized');
  }
  return appConfigActions;
};
export const createAppConfigSlice = (appConfig: AppConfigState) => {
  const slice = createSlice({
    name: 'appConfig',
    initialState: {
      config: appConfig,
      configDetails: {
        lastUpdated: Date.now(),
        changeCount: 0,
      },
    } satisfies AppConfigReduxState,
    reducers: {
      setConfig: (state, action: PayloadAction<AppConfigState>) => {
        state.config = castDraft(action.payload);
        state.configDetails.lastUpdated = Date.now();
        state.configDetails.changeCount += 1;
      },
    },
  });
  appConfigActions = slice.actions;
  return slice;
};

export const appConfigSelector = createSelector(
  (state: AppState) => state.appConfig,
  appConfigState => appConfigState.config
);
