import { useMemo } from 'react';
import { useCurrency, useIntl, useOrderIncrements, useSecurity } from '../../hooks';
import { OrdTypeEnum, Order, SideEnum, UnifiedLiquidityEnum, type ExecutionReport, type Security } from '../../types';
import { getSymbolsAcrossMarkets, isCounterCurrency } from '../../utils';
import { getPricingReference } from '../../utils/pricingMode';
import { Box, HStack } from '../Core';
import { InlineFormattedNumber, SmartTruncateEnum } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { QuoteSide, Side } from '../Side';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { UnifiedLiquidityTooltip } from '../UnifiedLiquidity';
import { MarketPart } from './MarketPart';
import { StrategyPart } from './StrategyPart';
import { Wrapper } from './styles';

import { defineMessages } from 'react-intl';
import { DDHOrderIcon } from '../DDH/DDHOrderIcon';
import { Currency } from '../FormattedNumber/styles';
import type { SmartSummaryProps } from './types';

export type { SmartSummaryProps } from './types';

const messages = defineMessages({
  buy: {
    defaultMessage: 'Buy',
    id: 'SmartSummary.buy',
  },
  sell: {
    defaultMessage: 'Sell',
    id: 'SmartSummary.sell',
  },
  ofCurrency: {
    defaultMessage: 'of {currency}',
    id: 'SmartSummary.ofCurrency',
  },
  withCurrency: {
    defaultMessage: 'with {currency}',
    id: 'SmartSummary.withCurrency',
  },
  forCurrency: {
    defaultMessage: 'for {currency}',
    id: 'SmartSummary.forCurrency',
  },
  quoteSummary: {
    defaultMessage: '<base></base> {Side, select, Buy {with} other {for}} <quote></quote>',
    id: 'SmartSummary.quoteSummary',
  },
});

export function SmartSummary({
  entity,
  type,
  showSymbol = false,
  showPrice = true,
  showQty = true,
  showQtyCurrency = false,
  showSide = true,
  qtyField = 'OrderQty',
  priceField = 'Price',
  showLastMarket = false,
  showStrategy = false,
  displayStrategy,
  showPriceReference = false,
  smartTruncate = SmartTruncateEnum.None,
  showFixingDetails = false,
  ...props
}: SmartSummaryProps) {
  const { formatMessage } = useIntl();

  const getSideLabel = (side: SideEnum) => {
    if (side === SideEnum.Buy) {
      return formatMessage(messages.buy);
    } else if (side === SideEnum.Sell) {
      return formatMessage(messages.sell);
    }
    return side;
  };

  const security = useSecurity(entity.Symbol);
  const currency = useCurrency(entity.Currency);
  const orderIncrements = useOrderIncrements(entity);

  const quantity: string | undefined = entity[qtyField];
  let price: string | undefined = entity[priceField];
  const isCcy = security != null && isCounterCurrency(entity.Currency, security);
  const underlyingSymbols = getSymbolsAcrossMarkets({
    Markets: entity.Markets,
    LegSummary: entity instanceof Order ? entity.LegSummary : undefined,
  });
  const showUnderlyingSymbols = entity.unifiedLiquidity === UnifiedLiquidityEnum.Enabled;
  const lastMarket = entity.Markets?.length === 1 ? entity.Markets?.at(0)?.Market : entity.LastMarket;

  if (type === 'order') {
    const showFees = entity.OrdType === OrdTypeEnum.LimitAllIn;
    if (priceField === 'AvgPx') {
      price = showFees ? entity.AvgPxAllIn : entity.AvgPx;
    }

    return (
      <Wrapper {...props}>
        {showSymbol && (
          <Tooltip
            disabled={!showUnderlyingSymbols || underlyingSymbols.length === 0}
            tooltip={<UnifiedLiquidityTooltip security={security} symbols={underlyingSymbols} />}
          >
            <HStack gap="spacingSmall">
              <Text color="colorTextImportant">
                {security?.DisplaySymbol}
                {showUnderlyingSymbols ? '+' : ''}
              </Text>
              {entity instanceof Order && <DDHOrderIcon order={entity} />}
            </HStack>
          </Tooltip>
        )}
        {showSide && entity.Side && <Side side={entity.Side}>{getSideLabel(entity.Side)}</Side>}
        {showQty && (
          <InlineFormattedNumber
            highlightAll
            number={quantity}
            increment={orderIncrements.quantityIncrement}
            specification={security?.SizeDisplaySpec}
            currency={currency?.Symbol}
            smartTruncate={smartTruncate}
          />
        )}
        {showQty && showQtyCurrency && security && (
          <Currency>
            {(() => {
              switch (true) {
                case entity.Side === SideEnum.Buy && isCcy:
                case entity.Side === SideEnum.Sell && isCcy:
                  return formatMessage(messages.ofCurrency, { currency: security.BaseCurrency });
                case entity.Side === SideEnum.Buy && !isCcy:
                  return formatMessage(messages.withCurrency, { currency: security.QuoteCurrency });
                case entity.Side === SideEnum.Sell && !isCcy:
                  return formatMessage(messages.forCurrency, { currency: security.QuoteCurrency });
                default:
                  return null;
              }
            })()}
          </Currency>
        )}
        {showQty && showPrice && price && <Icon icon={IconName.AtSymbol} />}
        {showPrice && (
          <PriceInlineFormatted
            entity={entity}
            price={price}
            security={security}
            showPriceReference={showPriceReference}
            showFees={showFees}
            smartTruncate={smartTruncate}
          />
        )}
        {showStrategy &&
          (displayStrategy ? (
            <Text color="colorTextImportant">{displayStrategy}</Text>
          ) : (
            <StrategyPart entity={entity} />
          ))}
        {showLastMarket && lastMarket && <MarketPart lastMarket={lastMarket} />}
      </Wrapper>
    );
  }

  if (type === 'trade') {
    return (
      <Wrapper {...props}>
        {showSymbol && <Text color="colorTextImportant">{security?.DisplaySymbol}</Text>}
        {showSide && entity.Side && <Side side={entity.Side}>{getSideLabel(entity.Side)}</Side>}
        {showQty && (
          <InlineFormattedNumber
            highlightAll
            number={quantity}
            increment={isCcy ? security?.DefaultPriceIncrement : security?.DefaultSizeIncrement}
            specification={security?.SizeDisplaySpec}
            currency={currency?.Symbol}
          />
        )}
        {showQty && showPrice && price != null && <Icon icon={IconName.AtSymbol} />}
        {showPrice && price != null && (
          <>
            <InlineFormattedNumber
              highlightAll
              number={price}
              increment={security?.DefaultPriceIncrement}
              specification={security?.PriceDisplaySpec}
              currency={isCcy ? entity.Currency : entity.AmountCurrency}
            />
          </>
        )}
        {showFixingDetails && entity.FixingDetails?.Fixing && (
          <Text color="colorTextSubtle">
            (Fixing:{' '}
            <InlineFormattedNumber
              number={entity.FixingDetails.Fixing}
              currency={security?.QuoteCurrency}
              specification={security?.PriceDisplaySpec}
              increment={security?.DefaultPriceIncrement}
            />
            )
          </Text>
        )}
      </Wrapper>
    );
  }

  const tradedSide: string = entity.TradedSide || '-';

  return (
    <Wrapper>
      {showSymbol && entity.Symbol}
      {showSide && <QuoteSide side={entity.TradedSide} />}

      {formatMessage(messages.quoteSummary, {
        base: () =>
          isCcy ? (
            <Text color="colorTextImportant">{security?.BaseCurrency}</Text>
          ) : (
            <InlineFormattedNumber
              highlightAll
              number={quantity}
              increment={security?.DefaultSizeIncrement}
              specification={security?.SizeDisplaySpec}
              currency={security?.BaseCurrency}
            />
          ),
        Side: tradedSide,
        quote: () =>
          isCcy ? (
            <InlineFormattedNumber
              highlightAll
              number={quantity}
              increment={security?.DefaultPriceIncrement}
              specification={security?.PriceDisplaySpec}
              currency={security?.QuoteCurrency}
            />
          ) : (
            <Text color="colorTextImportant">{security?.QuoteCurrency}</Text>
          ),
      })}
      {showLastMarket && lastMarket && <MarketPart lastMarket={lastMarket} />}
    </Wrapper>
  );
}

/**
 * Render a price, if `showPriceReference=true` and a PricingReference is set, that will be rendered instead
 * with the price available in a tooltip.
 */
export function PriceInlineFormatted({
  price,
  showPriceReference,
  security,
  entity,
  showFees = false,
  smartTruncate = SmartTruncateEnum.None,
}: {
  entity: Partial<Pick<ExecutionReport, 'PricingReference' | 'PricingMode'>>;
  showPriceReference: boolean;
  price: string | undefined;
  security: Security | undefined;
  showFees?: boolean;
  smartTruncate?: SmartTruncateEnum;
}) {
  const placedAmount = getPricingReference(entity);

  const EntityPrice = useMemo(
    () =>
      price ? (
        <InlineFormattedNumber
          highlightAll
          number={price}
          increment={security?.DefaultPriceIncrement}
          specification={security?.PriceDisplaySpec}
          currency={security?.QuoteCurrency}
          startIcon={showFees ? IconName.Fees : undefined}
          smartTruncate={smartTruncate}
        />
      ) : null,
    [
      price,
      security?.DefaultPriceIncrement,
      security?.PriceDisplaySpec,
      security?.QuoteCurrency,
      showFees,
      smartTruncate,
    ]
  );

  return (
    <>
      {showPriceReference && placedAmount ? (
        <Tooltip
          usePortal
          showUnderline
          disabled={EntityPrice === null}
          tooltip={<Text color="colorTextDefault">Executing Price: {EntityPrice}</Text>}
          targetStyle={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Box>
            {placedAmount.currency === '%' ? 'IV ' : null}
            <InlineFormattedNumber
              number={placedAmount.value}
              currency={placedAmount.currency}
              smartTruncate={smartTruncate}
            />
          </Box>
        </Tooltip>
      ) : (
        EntityPrice
      )}
    </>
  );
}
