import {
  Button,
  ButtonGroup,
  ButtonVariants,
  CopyButton,
  Divider,
  Flex,
  FormControlSizes,
  isTalosUser,
  MixpanelEvent,
  Text,
  useMixpanel,
  VStack,
} from '@talos/kyoko';
import { startCase } from 'lodash';
import { useCallback } from 'react';
import { setCurrentLayout } from '../../components/AppLayout/AppLayoutSlice';
import { useUser } from '../../hooks';
import { useAppLayoutContext } from '../../providers/AppLayoutContextProvider/AppLayoutContextProvider';
import { useAppStateDispatch, useAppStateSelector } from '../../providers/AppStateProvider';
import type { AppState } from '../../providers/AppStateProvider/types';

export function LayoutMenu() {
  const user = useUser();
  const mixpanel = useMixpanel();
  const dispatch = useAppStateDispatch();

  const { resetLayout, dockviewApiRef } = useAppLayoutContext();
  const selectedLayout = useAppStateSelector((state: AppState) => state.appLayout.currentLayout);
  const layoutSection = useAppStateSelector((state: AppState) => state.appLayout.layoutSection);
  const layoutOptions = useAppStateSelector((state: AppState) => state.appLayout.layoutOptions);
  const layoutList = Object.values(layoutOptions ?? {}).map(layout => ({ label: layout.label, value: layout.id }));

  const handleResetLayout = useCallback(() => {
    resetLayout();
    mixpanel.track(MixpanelEvent.ResetLayout);
  }, [mixpanel, resetLayout]);

  const handleChangeLayout = useCallback(
    (layoutId: string) => {
      if (layoutOptions) {
        dispatch(setCurrentLayout({ layoutConfig: layoutOptions[layoutId] }));
      }
    },
    [dispatch, layoutOptions]
  );

  return (
    <VStack gap="spacingDefault" p="spacingDefault" w="100%" minWidth={120} data-testid="layout-menu">
      <Text color="colorTextImportant" textAlign="left" w="100%">
        {startCase(layoutSection)} Layout
      </Text>
      <Divider />
      <Flex flexDirection="column" gap="spacingDefault" w="100%">
        <ButtonGroup size={FormControlSizes.Small} orientation="vertical">
          {layoutList.map(option => (
            <Button
              size={FormControlSizes.Small}
              key={option.value}
              onClick={() => handleChangeLayout(option.value)}
              variant={option.value === selectedLayout?.id ? ButtonVariants.Primary : ButtonVariants.Default}
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
        <Divider />
        <Button onClick={handleResetLayout} variant={ButtonVariants.Default} size={FormControlSizes.Small}>
          Reset layout
        </Button>
        {isTalosUser(user) && (
          <>
            <Divider />
            <CopyButton value={JSON.stringify(dockviewApiRef?.current?.toJSON(), null, 2)}>Copy JSON</CopyButton>
          </>
        )}
      </Flex>
    </VStack>
  );
}
