import {
  ACTION,
  Button,
  ButtonVariants,
  Dialog,
  EMPTY_ARRAY,
  FormGroup,
  FormRowStatus,
  FormTable,
  HStack,
  IconName,
  Input,
  LoaderWrapper,
  LocalFilterInput,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  SearchSelect,
  promisesHaveResolved,
  useDisclosure,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  type Column,
  type FormRow,
  type MarketAccount,
} from '@talos/kyoko';
import { Loader } from 'components/Loader';
import { useRoleAuth } from 'hooks';
import { useCustomers, useCustomersByName } from 'hooks/useCustomer';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useBlotterState } from '../../../providers/AppConfigProvider';
import {
  useCustomerMarketAccounts,
  type CustomerMarketAccountCreate,
} from '../../../providers/CustomerMarketAccountsProvider';
import { useAddCustomerMarketAccountValidation } from './useAddCustomerMarketAccountValidation';
import { useSortedCustomerMarketAccountsList } from './useSortedCustomerMarketAccounts';

export const CustomerMarketAccounts = () => {
  const { add: addToast } = useGlobalToasts();
  const [saving, setSaving] = useState(false);
  const { isAuthorized } = useRoleAuth();

  const [createForm, setCreateForm] = useState<Partial<CustomerMarketAccountCreate>>({});
  const { errors } = useAddCustomerMarketAccountValidation(createForm);
  const [allTouched, setAllTouched] = useState(false);

  const updateCreateForm = useCallback((key: keyof CustomerMarketAccountCreate, value: any) => {
    setCreateForm(prev => ({ ...prev, [key]: value }));
  }, []);

  const { filterValueCustomerUsers, setFilterValueCustomerUsers } = useBlotterState();

  const {
    customerMarketAccountsList,
    createCustomerMarketAccount,
    updateCustomerMarketAccount,
    deleteCustomerMarketAccount,
    getCustomerMarketAccounts,
    isLoaded: isCustomerMarketAccountsLoaded,
  } = useCustomerMarketAccounts();

  const customersByName = useCustomersByName();

  const getIsRemoveDisabled = useDynamicCallback(
    (rowData: MarketAccount) => getCustomerMarketAccounts(rowData?.Counterparty)?.length === 1
  );

  const [columns] = useState<Column[]>([
    {
      type: 'active',
      field: 'Status',
      title: 'Enable',
      width: 70,
    },
    {
      type: 'customer',
      field: 'Counterparty',
      title: 'Customer',
    },
    { type: 'text', field: 'Name', title: 'Name' },
    { type: 'text', field: 'SourceAccountID', title: 'Customer ID' },
    { type: 'text', field: 'DisplayName', title: 'Display Name' },
    { type: 'filler', id: 'filler1' },
    {
      type: 'remove',
      id: 'remove',
      params: {
        disabled: getIsRemoveDisabled,
        disabledTooltip: 'Cannot delete the last active market account of customer.',
      },
    },
  ]);

  const sortedCustomerMarketAccountsList = useSortedCustomerMarketAccountsList(customerMarketAccountsList);
  const customers = useCustomers();

  const formTable = useFormTable<MarketAccount>({
    rowID: 'Name',
    data: sortedCustomerMarketAccountsList ?? EMPTY_ARRAY,
    columns: columns ?? EMPTY_ARRAY,
    quickSearchParams: {
      filterText: filterValueCustomerUsers,
    },
  });

  const handleAddMktAccConfirm = useCallback(() => {
    createCustomerMarketAccount(createForm)
      .then(response => {
        addToast({
          text: `Customer Account added.`,
          variant: NotificationVariants.Positive,
        });
        formTable.addRow(response.data[0], FormRowStatus.None);
        setCreateForm({});
        setAllTouched(false);
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Error: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      });
  }, [createCustomerMarketAccount, createForm, addToast, formTable]);

  const createDialog = useDisclosure();

  const handleAddMktAccOpen = useCallback(() => {
    createDialog.open();
  }, [createDialog]);

  const handleDeleteOperation = useCallback(
    (row: FormRow<MarketAccount>) => {
      return deleteCustomerMarketAccount(row.data.MarketAccountID)
        .then(() => {
          row.remove(true);
          return row.data;
        })
        .catch((e: ErrorEvent) => {
          addToast({
            variant: NotificationVariants.Negative,
            text: `Error: ${e.message}`,
          });
        });
    },
    [addToast, deleteCustomerMarketAccount]
  );

  const handleUpdateOperation = useCallback(
    (row: FormRow<MarketAccount>) => {
      const { Status, DisplayName, Description } = row.data;
      return updateCustomerMarketAccount(row.data.MarketAccountID, {
        Status,
        DisplayName,
        Description,
      })
        .then(res => {
          row.setData(res.data[0]);
          return res;
        })
        .catch((e: ErrorEvent) => {
          addToast({
            variant: NotificationVariants.Negative,
            text: `Error: ${e.message}`,
          });
        });
    },
    [updateCustomerMarketAccount, addToast]
  );

  const handleSave = useCallback(() => {
    setSaving(true);
    const rows = formTable.getRows();
    const requests: Promise<any>[] = [];
    for (const row of rows) {
      switch (row.status) {
        case FormRowStatus.Removed:
          requests.push(handleDeleteOperation(row));
          break;
        case FormRowStatus.Updated:
          requests.push(handleUpdateOperation(row));
          break;
        default:
          break;
      }
    }
    Promise.allSettled(requests).then(promises => {
      setSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Customer Accounts saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Customer Accounts could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, formTable, handleDeleteOperation, handleUpdateOperation]);

  const headerActions = (
    <PanelActions>
      <LocalFilterInput
        placeholder="Filter by Customer or Customer ID"
        value={filterValueCustomerUsers}
        onChange={setFilterValueCustomerUsers}
      />
      <Button
        startIcon={IconName.Plus}
        onClick={handleAddMktAccOpen}
        variant={ButtonVariants.Positive}
        disabled={!isAuthorized(ACTION.DEALER_TRADING)}
      >
        Add Customer Account
      </Button>
      <Button
        variant={ButtonVariants.Primary}
        onClick={handleSave}
        disabled={saving || !formTable.isDirty || !isAuthorized(ACTION.DEALER_TRADING)}
      >
        Save
      </Button>
    </PanelActions>
  );

  return (
    <HStack h="100%" w="100%" gap="spacingSmall" overflow="hidden">
      <Panel>
        <PanelHeader>
          <h2>Customer Accounts</h2>
          {headerActions}
        </PanelHeader>
        <PanelContent>
          {!isCustomerMarketAccountsLoaded ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <FormTable {...formTable} />
          )}
        </PanelContent>
        <Dialog
          title="Add Customer Account"
          confirmLabel="Add"
          onConfirmMouseOver={() => setAllTouched(true)}
          showClose={true}
          onConfirm={handleAddMktAccConfirm}
          confirmDisabled={!isEmpty(errors)}
          alignContent="left"
          /* This width is set so that the tooltip below has enough space without being portalized temporarily */
          width={400}
          {...createDialog}
        >
          <FormGroup mb="spacingMedium" label="Customer*" error={allTouched && errors.Counterparty}>
            <SearchSelect
              selection={createForm.Counterparty ? customersByName?.get(createForm.Counterparty) : undefined}
              options={customers || []}
              getLabel={customer => customer.DisplayName}
              onChange={c => updateCreateForm('Counterparty', c?.Name)}
            />
          </FormGroup>
          <FormGroup
            mb="spacingMedium"
            label="Customer ID*"
            tooltip="Customer ID is a unique identifier for this Customer Account within the provided Customer. This identifier cannot be changed later."
            error={allTouched && errors.SourceAccountID}
            portalizeTooltip={false}
          >
            <Input
              onChange={e => updateCreateForm('SourceAccountID', e.target.value)}
              value={createForm.SourceAccountID || ''}
            />
          </FormGroup>
          <FormGroup mb="spacingMedium" label="Display Name">
            <Input
              onChange={e => updateCreateForm('DisplayName', e.target.value)}
              value={createForm.DisplayName || ''}
            />
          </FormGroup>
        </Dialog>
      </Panel>
    </HStack>
  );
};
