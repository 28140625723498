import { invariant } from '@epic-web/invariant';
import { type WebsocketRequest, logger, useCurrenciesContext } from '@talos/kyoko';
import { getIncludeCashFilter } from 'hooks/useIncludeCashFilter';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useCallback } from 'react';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import type { PortfolioRiskSubscriptionData } from '../../types/PortfolioRiskSubscriptionData';
import { ROLLUP_GROUP_PREFIX } from '../../types/types';
import { buildHierarchicalDataPath, convertToPortfolioRiskGridData } from '../../types/utils';
import type { useRequestStateTagging } from '../../useRequestStateTagging';
import type { ExtraStateType } from '../PortfolioManagementProvider.types';

function isBookLevelData(hierarchicalDataPath: string[]) {
  return hierarchicalDataPath.at(-3)?.startsWith(ROLLUP_GROUP_PREFIX);
}

function collectSingleSpotAssetUnderlyings(data: PortfolioRiskDataItem[]) {
  const pathDelimiter = '::';
  // Determine if the underlier has a single spot only data row
  const mapUnderlyingHasSingleSpotOnlyData = new Map<string, boolean>();
  data.forEach(item => {
    const pathToUnderlying = item.dataPath.slice(0, -1).join(pathDelimiter);
    if (!mapUnderlyingHasSingleSpotOnlyData.has(pathToUnderlying) && item.gridData.AssetType === 'Spot') {
      mapUnderlyingHasSingleSpotOnlyData.set(pathToUnderlying, true);
    } else {
      mapUnderlyingHasSingleSpotOnlyData.set(pathToUnderlying, false);
    }
  });

  data.forEach(item => {
    const pathToUnderlying = item.dataPath.slice(0, -1).join(pathDelimiter);
    if (mapUnderlyingHasSingleSpotOnlyData.get(pathToUnderlying) === true) {
      // Remove the underlier level from the data path
      item.dataPath.splice(-2, 1);
    }
  });
  return data;
}

export function useSubscriptionDataConverter<TRequest extends Omit<WebsocketRequest, 'tag'>>(
  tagMapRef: ReturnType<typeof useRequestStateTagging<TRequest, ExtraStateType>>['tagMapRef']
) {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { subAccountsByName, subAccountsByID } = useSubAccounts();
  const { rollupMembershipsByChildParent } = useSubAccountRollupMemberships();

  /** this mechanism converts the Portfolio Risk Subscription data into the data used in the actual grids */
  return useCallback(
    (messageData: PortfolioRiskSubscriptionData[], tag: string) => {
      const convertSubToDataResults = (row: PortfolioRiskSubscriptionData, tag: string): PortfolioRiskDataItem[] => {
        try {
          // get the extra state from the tag map, which ensures we have the correct state for this data stream
          const tagMapEntry = tagMapRef.current.get(tag);
          invariant(tagMapEntry?.extraState, `extraState field required to process risk data`);
          const { showRollupHierarchy, selectedPortfolioId, includeCash, treatStablecoinsAsCash, showZeroBalances } =
            tagMapEntry.extraState;
          const isRowValidForIncludeCash = getIncludeCashFilter(
            includeCash,
            treatStablecoinsAsCash,
            currenciesBySymbol
          );

          const isValidToShow = isRowValidForIncludeCash(row.Asset) && isRowValidForZeroBalances(row, showZeroBalances);
          if (!isValidToShow) {
            return [];
          }

          const resultData: PortfolioRiskDataItem[] = [];
          const hierarchicalDataPaths = buildHierarchicalDataPath({
            contextSubAccountId: selectedPortfolioId ?? -1,
            node: row,
            showRollupHierarchy,
            rollupMembershipsByChildParent,
            subAccountsByName,
            subAccountsByID,
          });

          // Using the hierarchical data paths, we can ensure we only count the data once by
          // skipping aggregation for all but the first data path
          const dataPaths = hierarchicalDataPaths
            .map(item => {
              return {
                dataPathJoined: item.join('::'),
                dataPath: item,
              };
            })
            .sort((a, b) => {
              return a.dataPathJoined.localeCompare(b.dataPathJoined);
            })
            .map((item, index) => {
              return {
                dataPath: item.dataPath,
                skipAggregation: index > 0,
              };
            });

          // convert the single row to multiple rows based on the hierarchical data paths, this
          // will allow us to show the data in the correct dynamic hierarchy in the grid
          for (const hierarchicalDataPath of dataPaths) {
            // If we are in rollup hierarchy mode, we only want to show the Book level data
            if (showRollupHierarchy && isBookLevelData(hierarchicalDataPath.dataPath)) {
              continue;
            }
            const convertedRow = convertToPortfolioRiskGridData(
              row,
              hierarchicalDataPath.dataPath,
              hierarchicalDataPath.skipAggregation
            );
            resultData.push(convertedRow);
          }
          return resultData;
        } catch (error) {
          const thrownError = new Error(`Error converting Portfolio Risk Subscription data`, {
            cause: error,
          });
          logger.error(thrownError, {
            extra: {
              row: JSON.stringify(row),
            },
          });
          throw thrownError;
        }
      };

      // convert each row of data into multiple rows based on the hierarchical data paths
      const convertedData = messageData.flatMap(datapoint => convertSubToDataResults(datapoint, tag));
      // remove the underlier level from the data path for underliers that only have spot data
      return collectSingleSpotAssetUnderlyings(convertedData);
    },
    [currenciesBySymbol, rollupMembershipsByChildParent, subAccountsByID, subAccountsByName, tagMapRef]
  );
}
function isRowValidForZeroBalances(row: PortfolioRiskSubscriptionData, showZeroBalances: boolean) {
  const isRowValidToShow = showZeroBalances || row.Position !== '0';
  return isRowValidToShow;
}
