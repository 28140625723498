import {
  abbreviateId,
  CareOrder,
  isCareOrder,
  isOrder,
  isQuote,
  Order,
  Quote,
  type Column,
  type QuoteMarket,
  type RowGroupsOpenedState,
  type TabProps,
  type TreeRow,
} from '@talos/kyoko';
import type { ValueGetterParams } from 'ag-grid-community';

export type OrderWithParentOrderID = RequireKeys<Order, 'ParentOrderID'>;
export type QuoteWithParentRFQID = RequireKeys<Quote, 'ParentRFQID'>;

export type CareOrderBlotterEntity = {
  rowID: string;
  dataPath: string[];
} & (CareOrderRow | OrderRow | QuoteRow | QuoteRowWithOrder);

export function createCareOrderBlotterEntity(data: CareOrder): CareOrderRow;
export function createCareOrderBlotterEntity(data: OrderWithParentOrderID): OrderRow;
export function createCareOrderBlotterEntity(data: QuoteWithParentRFQID): QuoteRow;
export function createCareOrderBlotterEntity<T>(data: T): CareOrderRow | OrderRow | QuoteRow {
  if (isOrder(data) && data.ParentOrderID) {
    return new OrderRow(data as OrderWithParentOrderID);
  }
  if (isQuote(data) && data.ParentRFQID) {
    return new QuoteRow(data as QuoteWithParentRFQID);
  }
  if (isCareOrder(data)) {
    return new CareOrderRow(data);
  }
  throw new Error('unknown data type');
}

export type QuoteRowWithOrder = QuoteRow & OrderWithParentOrderID & QuoteMarket;
export class QuoteRow extends Quote implements TreeRow {
  override ParentRFQID: string;

  groupColumnValueGetter(params: ValueGetterParams) {
    return this.RFQID;
  }

  groupColumnValueFormatter() {
    return abbreviateId(this.RFQID);
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(data: QuoteWithParentRFQID) {
    super(data);
    this.ParentRFQID = data.ParentRFQID;
  }

  get rowID() {
    return this.RFQID;
  }

  get dataPath(): string[] {
    return [this.ParentRFQID, this.RFQID];
  }

  /**
   * The QuoteRow is a special snowflake, since it's a combination of an RFQ and an order.
   *
   * For the properties, we do the following prioritization:
   * 1. Use property from the tradedMarket if it exists (which it should after a quote is accepted)
   * 2. Use property from the RFQ
   * 3. Use property from the order
   */
  enrichWith(order: OrderWithParentOrderID): QuoteRowWithOrder {
    const next = new QuoteRow(this);
    const tradedMarket = this.tradedMarket;
    return new Proxy(next, {
      get(quote, property) {
        if (tradedMarket != null && property in tradedMarket) {
          return tradedMarket[property];
        }
        if (property in quote) {
          return quote[property];
        }
        return order[property];
      },
    }) as QuoteRowWithOrder;
  }
}

export class OrderRow extends Order implements TreeRow {
  override ParentOrderID: string;

  groupColumnValueGetter(params: ValueGetterParams) {
    return this.OrderID;
  }

  groupColumnValueFormatter() {
    return abbreviateId(this.OrderID);
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  constructor(data: OrderWithParentOrderID) {
    super(data);
    this.ParentOrderID = data.ParentOrderID;
  }

  get rowID() {
    return this.OrderID;
  }
  get dataPath(): string[] {
    return [this.ParentOrderID, this.OrderID];
  }
}

export class CareOrderRow extends CareOrder implements TreeRow {
  groupColumnValueGetter(params: ValueGetterParams) {
    return this.OrderID;
  }

  groupColumnValueFormatter() {
    return abbreviateId(this.OrderID);
  }

  groupColumnFilterValueGetter(params: ValueGetterParams) {
    return this.groupColumnValueGetter(params);
  }

  get rowID() {
    return this.OrderID;
  }
  get dataPath(): string[] {
    return [this.OrderID];
  }
}

export function isCareOrderRow(row: unknown): row is CareOrder {
  return row instanceof CareOrderRow;
}

export function isQuoteRow(row: unknown): row is QuoteRow | QuoteRowWithOrder {
  return row instanceof QuoteRow;
}

export function isOrderRow(row: unknown): row is OrderWithParentOrderID {
  return row instanceof OrderRow;
}

// Below is tab-related stuff
export interface CareOrderBlotterTabProps extends TabProps {
  defaultColumns?: Column[];
  defaultRowGroupsOpened?: RowGroupsOpenedState;
}

export const CARE_ORDER_BLOTTER_PREFIX = 'care-order-blotter';
export const CARE_ORDER_BLOTTER_PORTAL_ID = 'care-order-blotter-portal';
