import {
  Alert,
  AlertVariants,
  ButtonVariants,
  Checkbox,
  Flex,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  InlineFormattedNumber,
  Text,
  Tooltip,
  useCurrency,
} from '@talos/kyoko';
import Big from 'big.js';
import { usePositionsFormDispatch } from 'containers/Portfolio/providers';
import type { Position } from 'containers/Portfolio/types';
import { memo, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { OngoingTransfersWarning } from '../OngoingTransfersWarning/OngoingTransfersWarning';
import { PositionsActionType, type AmountEntry } from '../PositionsFormReducer';
import { AvailableBalanceCell } from './AvailableBalanceCell';
import { SettlementAmountInput } from './SettlementAmountInput';
import {
  CheckboxWrapper,
  SettleAmountWrapper,
  SettleButton,
  SettlementBodyCell,
  SettlementNumber,
  StyledCurrencyDisplayer,
} from './styles';

export interface CurrencySettlementRowProps {
  position: Position;
  formState: AmountEntry;
  marketAccount: string;
  onSettleClick: (position: Position, amount: string) => void;
}

export const CurrencySettlementRow = memo(
  ({ position, marketAccount, formState, onSettleClick }: CurrencySettlementRowProps) => {
    const { inputValue, checked, wsAmount } = formState;
    const dispatch = usePositionsFormDispatch();
    const currency = useCurrency(position.Currency);
    const isOutgoing = Big(position.Amount).lt(0);

    const { colorTextWarning } = useTheme();

    const showWarning = useMemo(() => {
      if (!inputValue) {
        return false;
      }
      return Big(inputValue).gt(Big(wsAmount).abs());
    }, [inputValue, wsAmount]);

    const handleInputValueChange = useCallback(
      (change?: string | null) => {
        // only emit valid numbers
        if (change != null && isNaN(+change)) {
          return;
        }

        dispatch({
          type: PositionsActionType.AmountInputValueChange,
          payload: {
            marketAccount,
            currency: position.Currency,
            inputValue: change || '',
          },
        });
      },
      [dispatch, marketAccount, position]
    );

    const handleCheckedChange = useCallback(
      (checked: boolean) => {
        dispatch({
          type: PositionsActionType.AmountCheckedChange,
          payload: {
            marketAccount,
            currency: position.Currency,
            checked,
          },
        });
      },
      [dispatch, marketAccount, position]
    );

    const handleSettleNumberClick = useCallback(() => {
      if (isOutgoing) {
        dispatch({
          type: PositionsActionType.AmountClicked,
          payload: {
            marketAccount,
            currency: position.Currency,
          },
        });
      }
    }, [isOutgoing, dispatch, marketAccount, position]);

    const settleDisabled = useMemo(() => {
      if (inputValue === '') {
        return true;
      }

      const inputBig = Big(inputValue);
      if (!inputBig.gt(Big(0))) {
        return true;
      } else {
        return false;
      }
    }, [inputValue]);

    return (
      <tr data-testid="currency-settlement-row">
        <SettlementBodyCell width="1%">
          <CheckboxWrapper>
            <Checkbox checked={checked} onChange={e => handleCheckedChange(e.target.checked)} />
          </CheckboxWrapper>
        </SettlementBodyCell>
        <SettlementBodyCell data-testid="currency-settlement-row-currency">
          <StyledCurrencyDisplayer currency={position.Currency} />
        </SettlementBodyCell>
        <SettlementBodyCell data-testid="currency-settlement-row-available-balance">
          {currency && <AvailableBalanceCell marketAccount={marketAccount} currency={currency} />}
        </SettlementBodyCell>
        <SettlementBodyCell />
        <SettlementBodyCell style={{ textAlign: 'right' }} data-testid="currency-settlement-row-settlement-amount">
          <SettlementNumber onClick={handleSettleNumberClick} clickable={isOutgoing}>
            <InlineFormattedNumber
              number={position.Amount}
              currency={currency?.Symbol}
              increment={currency?.DefaultIncrement}
            />
          </SettlementNumber>
        </SettlementBodyCell>
        <SettlementBodyCell />
        {currency && (
          <>
            <SettlementBodyCell>
              <SettleAmountWrapper>
                <SettlementAmountInput value={inputValue} onChange={handleInputValueChange} currency={currency} />
              </SettleAmountWrapper>
            </SettlementBodyCell>

            {/* 115px below is a wide-enough width to not have the table column jitter as conditional warnings are added and removed from the dom */}
            <SettlementBodyCell width="115px">
              <Flex pl="spacingDefault" alignItems="center" gap="spacingDefault">
                <SettleButton
                  data-testid="currency-settlement-row-settle-button"
                  visible={checked}
                  onClick={() => onSettleClick(position, inputValue)}
                  variant={ButtonVariants.Default}
                  disabled={settleDisabled}
                >
                  <HStack gap="spacingSmall">
                    <Text>Settle</Text>
                    <OngoingTransfersWarning marketAccount={marketAccount} currency={position.Currency} />
                  </HStack>
                </SettleButton>

                {showWarning && (
                  <Tooltip
                    tooltip={
                      <Alert
                        variant={AlertVariants.Warning}
                        size={FormControlSizes.Small}
                        dismissable={false}
                        m="spacingSmall"
                      >
                        The manually entered amount to settle is larger than the perceived settleable amount.
                      </Alert>
                    }
                  >
                    <Icon icon={IconName.ExclamationSolid} color={colorTextWarning} />
                  </Tooltip>
                )}
              </Flex>
            </SettlementBodyCell>
          </>
        )}
      </tr>
    );
  }
);
