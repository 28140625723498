import { useCallback, useEffect, useState, type PropsWithChildren } from 'react';
import { map, scan, shareReplay } from 'rxjs/operators';

import { DEFAULT_BIGGER_PAGINATION_SIZE } from '../components/BlotterTable/helpers';
import { MarketAccountsContext, type MarketAccount } from '../contexts/MarketAccountsContext';
import { useObservable, useObservableValue } from '../hooks/useObservable';
import { useStaticSubscription } from '../hooks/useStaticSubscription';
import { wsScanToDoubleMap } from '../pipes/wsScanToDoubleMap';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { MARKET_ACCOUNT } from '../tokens/requestTypes';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import {
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  OrderMarketStatusEnum,
  type ApiPermissionActionEnum,
  type IOMSExecutionReport4203Markets,
} from '../types/types';

export const MarketAccountsProvider = function MarketAccountsProvider({
  children,
  permissionAction,
}: PropsWithChildren<{ permissionAction?: ApiPermissionActionEnum }>) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: subscription } = useStaticSubscription<MarketAccount>({
    name: MARKET_ACCOUNT,
    tag: 'MarketAccountsProvider',
    limit: DEFAULT_BIGGER_PAGINATION_SIZE,
    ...(permissionAction != null && { PermissionAction: permissionAction }),
  });

  const filteredSubscription = useObservable<SubscriptionResponse<MarketAccount>>(
    () =>
      subscription.pipe(
        map(res => ({
          ...res,
          data: res.data.filter((d: MarketAccount) => {
            // [sc-33537] Ignore Type=Unknown Market Accounts
            if (d.Type === MarketAccountTypeEnum.Unknown) {
              return false;
            } else {
              return true;
            }
          }),
        }))
      ),
    [subscription]
  );

  const marketAccountsByIDObservable = useObservable(
    () =>
      filteredSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.MarketAccountID, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  // MarketAccount.Name is a unique key
  const marketAccountsByNameObservable = useObservable(
    () =>
      filteredSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.Name, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const marketAccountsByCredentialIDByNameObservable = useObservable(
    () =>
      subscription.pipe(
        wsScanToDoubleMap({
          getKey1: ma => ma.CredentialID,
          getKey2: ma => ma.Name,
          newOuterMapEachUpdate: true,
          newInnerMapsEachUpdate: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const marketAccountsBySourceAccountIDObservable = useObservable<Map<string, MarketAccount>>(
    () =>
      filteredSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.SourceAccountID, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const marketAccountsByMarketObservable = useObservable<Map<string, MarketAccount[]>>(
    () =>
      filteredSubscription.pipe(
        wsScanToDoubleMap({
          getKey1: ma => ma.Market,
          getKey2: ma => ma.Name,
          newInnerMapsEachUpdate: false,
          newOuterMapEachUpdate: false,
        }),
        map(doubleMap => {
          const map = new Map<string, MarketAccount[]>();
          doubleMap.forEach((innerMap, key) => {
            map.set(key, [...innerMap.values()]);
          });
          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const marketAccountsByCounterpartyObservable = useObservable<Map<string, MarketAccount[]>>(
    () =>
      filteredSubscription.pipe(
        wsScanToDoubleMap({
          getKey1: ma => ma.Counterparty,
          getKey2: ma => ma.Name,
          newInnerMapsEachUpdate: false,
          newOuterMapEachUpdate: false,
        }),
        map(doubleMap => {
          const map = new Map<string, MarketAccount[]>();
          doubleMap.forEach((innerMap, key) => {
            map.set(key, [...innerMap.values()]);
          });
          return map;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [filteredSubscription]
  );

  const listMarketAccountsObservable = useObservable(
    () =>
      marketAccountsByIDObservable.pipe(
        map(memo => [...memo.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountsByIDObservable]
  );

  const activeMarketAccountsObservable = useObservable(
    () =>
      listMarketAccountsObservable.pipe(
        map(memo => memo.filter(marketAccount => marketAccount.Status === MarketAccountStatusEnum.Active))
      ),
    [listMarketAccountsObservable]
  );

  const activeCustomerMarketAccountsObservable = useObservable(
    () =>
      activeMarketAccountsObservable.pipe(
        map(memo => memo.filter(marketAccount => marketAccount.Type === MarketAccountTypeEnum.Customer))
      ),
    [activeMarketAccountsObservable]
  );

  // This is an iteration made on top of the iterations that filter down from
  // known market accounts => active market accounts => customer market account types.
  // If we get to a place where we're iterating over collections that are too large,
  // and this becomes a drain on performance, we can rework the internals of this
  // to apply the status and type filters in addition to the Map building all
  // on a single pass through the iterable. Downstream consumers won't need to
  // be affected as long as they're referencing this named export.
  const activeCustomerMarketAccountsByCounterpartyAndNameObservable = useObservable(
    () =>
      activeCustomerMarketAccountsObservable.pipe(
        scan((memo, marketAccounts) => {
          for (const marketAccount of marketAccounts) {
            if (!memo.has(marketAccount.Counterparty)) {
              memo.set(marketAccount.Counterparty, new Map());
            }
            memo.get(marketAccount.Counterparty)!.set(marketAccount.Name, marketAccount);
          }
          return memo;
        }, new Map<string, Map<string, MarketAccount>>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [activeCustomerMarketAccountsObservable]
  );

  const marketAccountsByID = useObservableValue(() => marketAccountsByIDObservable, [marketAccountsByIDObservable]);
  const marketAccountsByName = useObservableValue(
    () => marketAccountsByNameObservable,
    [marketAccountsByNameObservable]
  );
  const marketAccountsByCredentialIDByName = useObservableValue(
    () => marketAccountsByCredentialIDByNameObservable,
    [marketAccountsByCredentialIDByNameObservable]
  );
  const marketAccountsBySourceAccountID = useObservableValue(
    () => marketAccountsBySourceAccountIDObservable,
    [marketAccountsBySourceAccountIDObservable]
  );
  const marketAccountsByMarket = useObservableValue(
    () => marketAccountsByMarketObservable,
    [marketAccountsByMarketObservable]
  );
  const marketAccountsByCounterparty = useObservableValue(
    () => marketAccountsByCounterpartyObservable,
    [marketAccountsByCounterpartyObservable]
  );
  const marketAccountsList = useObservableValue(() => listMarketAccountsObservable, [listMarketAccountsObservable]);
  const activeMarketAccountsList = useObservableValue(
    () => activeMarketAccountsObservable,
    [activeMarketAccountsObservable]
  );
  const activeCustomerMarketAccountsList = useObservableValue(
    () => activeCustomerMarketAccountsObservable,
    [activeCustomerMarketAccountsObservable]
  );
  const activeCustomerMarketAccountsByCounterpartyAndName = useObservableValue(
    () => activeCustomerMarketAccountsByCounterpartyAndNameObservable,
    [activeCustomerMarketAccountsByCounterpartyAndNameObservable]
  );

  const marketAccountDisplayNameByName = useObservableValue(
    () =>
      marketAccountsByNameObservable.pipe(
        scan((prevMap, marketAccountMap) => {
          let emitNewValue = false;
          const newMap = new Map<string, string>();
          marketAccountMap.forEach((value, key) => {
            if (prevMap.get(key) !== value.DisplayName) {
              emitNewValue = true;
            }
            newMap.set(key, value.DisplayName);
          });
          return emitNewValue ? newMap : prevMap;
        }, new Map<string, string>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [marketAccountsByNameObservable],
    new Map<string, string>()
  );

  useEffect(() => {
    if (!isLoaded) {
      const hasLoadedNow = [
        marketAccountsByID,
        marketAccountsByName,
        marketAccountsByCredentialIDByName,
        marketAccountsBySourceAccountID,
        marketAccountsByMarket,
        marketAccountsByCounterparty,
        marketAccountsList,
        activeMarketAccountsList,
        activeCustomerMarketAccountsList,
        activeCustomerMarketAccountsByCounterpartyAndName,
        marketAccountDisplayNameByName,
      ].every(dataset => dataset != null);
      if (hasLoadedNow) {
        setIsLoaded(hasLoadedNow);
      }
    }
  }, [
    isLoaded,
    marketAccountsByID,
    marketAccountsByName,
    marketAccountsByCredentialIDByName,
    marketAccountsBySourceAccountID,
    marketAccountsByMarket,
    marketAccountsByCounterparty,
    marketAccountsList,
    activeMarketAccountsList,
    activeCustomerMarketAccountsList,
    activeCustomerMarketAccountsByCounterpartyAndName,
    marketAccountDisplayNameByName,
  ]);

  const isMarketAccountActive = useCallback((ma?: MarketAccount) => ma?.Status === MarketAccountStatusEnum.Active, []);
  const isMarketStatusError = useCallback(
    (m: Partial<IOMSExecutionReport4203Markets>) =>
      m.MarketStatus !== OrderMarketStatusEnum.Online && m.MarketStatus !== OrderMarketStatusEnum.Disabled,
    []
  );

  return (
    <MarketAccountsContext.Provider
      value={{
        isLoaded,
        marketAccountsByID: marketAccountsByID!,
        marketAccountsByName: marketAccountsByName!,
        marketAccountsByMarket: marketAccountsByMarket!,
        marketAccountsByCounterparty: marketAccountsByCounterparty!,
        marketAccountsList: marketAccountsList!,
        marketAccountsByCredentialIDByName: marketAccountsByCredentialIDByName!,
        marketAccountsBySourceAccountID: marketAccountsBySourceAccountID!,
        activeMarketAccountsList: activeMarketAccountsList!,
        activeCustomerMarketAccountsList: activeCustomerMarketAccountsList!,
        activeCustomerMarketAccountsByCounterpartyAndName: activeCustomerMarketAccountsByCounterpartyAndName!,
        isMarketAccountActive,
        isMarketStatusError,
        marketAccountDisplayNameByName,
      }}
    >
      {children}
    </MarketAccountsContext.Provider>
  );
};

/**
 * We hide Market Accounts with .Group starting with "_". Usually, they will have Group: "_Hidden".
 */
export function shouldHideMarketAccount(ma: MarketAccount) {
  return ma.Group?.startsWith('_');
}
