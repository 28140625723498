import type { GridApi, GridOptions } from 'ag-grid-community';
import { useEffect } from 'react';
import type { BlotterTableSort } from '..';
import { applySortsToColumns } from './applySortsToColumns';

export type UseBlotterTableInitialSetupArg<TRowType> = Pick<GridOptions, 'autoGroupColumnDef'> & {
  api: GridApi<TRowType> | undefined;
  columnDefs: NonNullable<GridOptions['columnDefs']>;
  sort?: BlotterTableSort<TRowType>;
  fitColumns: boolean;
  /** If the column update is custom, we don't want to apply the default column update logic */
  hasCustomColumnUpdate: boolean;
};

export function useBlotterTableInitialSetup<TRowType = any>({
  autoGroupColumnDef,
  columnDefs,
  sort: initialSorts,
  fitColumns,
  api,
  hasCustomColumnUpdate,
}: UseBlotterTableInitialSetupArg<TRowType>) {
  // Apply columns.
  // This useEffect has several dependencies, but the only one which should change is column defs. That can change quite often based on settings, runtime configs, etc.
  // So this useEffect should be seen as the useEffect which pushes any changes made to the column setup into the blotter itself both on init and during "runtime" (on-the-fly column def changes)
  useEffect(() => {
    if (api == null || hasCustomColumnUpdate) {
      return;
    }

    const { workingColumnDefs, workingAutoGroupColumnDef } = applySortsToColumns({
      api,
      columnDefs,
      autoGroupColumnDef,
      initialSorts,
    });

    // Propagate this now-ready set of columns to the blotter.
    api.setGridOption('columnDefs', workingColumnDefs);
    workingAutoGroupColumnDef && api.setGridOption('autoGroupColumnDef', workingAutoGroupColumnDef);

    if (fitColumns) {
      api.sizeColumnsToFit();
    }
  }, [api, columnDefs, fitColumns, initialSorts, autoGroupColumnDef, hasCustomColumnUpdate]);
}
