import {
  type EditingDrawerTab,
  Field,
  Flex,
  FormGroup,
  Input,
  type MarketAccount,
  NotificationVariants,
  logger,
  useDynamicCallback,
  useGlobalToasts,
  useMarketAccountsContext,
} from '@talos/kyoko';
import { compact, uniq } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Autocomplete } from '../../../components/Form';
import { useMarketAccountRequests } from '../../../hooks/useMarketAccountRequests';

export function useMarketAccountGeneralTab({ marketAccount }: { marketAccount: MarketAccount }): EditingDrawerTab {
  const { add: addToast } = useGlobalToasts();

  const { updateMarketAccount } = useMarketAccountRequests();
  const { marketAccountsList } = useMarketAccountsContext();
  const groupOptions = useMemo(() => {
    return compact(uniq(marketAccountsList.map(ma => ma.Group))).map(group => ({ id: group, label: group }));
  }, [marketAccountsList]);

  const update = useDynamicCallback((...params: Parameters<typeof updateMarketAccount>) => {
    updateMarketAccount(...params)
      .then(() => {
        addToast({
          text: `Market account ${marketAccount.DisplayName} updated.`,
          variant: NotificationVariants.Positive,
        });
      })
      .catch(e => {
        logger.error(e);
        addToast({
          text: `Could not update market account${e?.message ? `: ${e.message}` : ''}.`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const [displayName, setDisplayName] = useState(new Field<string>({ value: marketAccount.DisplayName }));
  const [group, setGroup] = useState(new Field<string>({ value: marketAccount.Group ?? '', isRequired: false }));

  // Annoying: if we set Group to "", backend responds with Group: undefined. So handle that here in the dirty check. MarketAccount.Group will never be "".
  const groupChanged = marketAccount.Group == null ? group.value !== '' : marketAccount.Group !== group.value;

  // We treat display name as required so dont need to do the same logic
  const displayNameChanged = displayName.value !== marketAccount.DisplayName;

  const isDirty = displayNameChanged || groupChanged;

  const handleSaveChanges = useCallback(async () => {
    if (isDirty) {
      update(marketAccount.Name, marketAccount.Counterparty, { Group: group.value, DisplayName: displayName.value });
    }
  }, [isDirty, update, displayName, group, marketAccount]);

  const handleTouchAll = useCallback(() => {
    [setDisplayName, setGroup].forEach(setter => setter(curr => curr.setTouched(true)));
  }, []);

  return {
    name: 'General',
    save: handleSaveChanges,
    hasError: displayName.hasError || group.hasError,
    isDirty,
    touchAll: handleTouchAll,
    viewable: true,
    component: (
      <Flex flexDirection="column" w="100%">
        <FormGroup label="Name" w="100%" error={displayName.errorString}>
          <Input
            onChange={e => setDisplayName(curr => curr.updateValue(e.target.value).validate())}
            value={displayName.value}
            invalid={!!displayName.errorString}
            data-testid="market-account-display-name-input"
          />
        </FormGroup>

        <FormGroup label="Group" w="100%" error={group.errorString}>
          <Autocomplete
            isCentered
            getItemValue={item => item.label}
            inputProps={{
              id: 'group',
              autoFocus: false,
              type: 'text',
              'data-testid': 'market-account-group-input',
            }}
            items={groupOptions}
            shouldItemRender={(item, value) => item.label.indexOf(value) !== -1}
            value={group.value}
            sortItems={(a, b) => a.label.localeCompare(b.label)}
            onChange={(e, val) => {
              setGroup(curr => curr.updateValue(val).validate());
            }}
            onSelect={val => {
              setGroup(curr => curr.updateValue(val).validate());
            }}
            invalid={!!group.errorString}
          />
        </FormGroup>
      </Flex>
    ),
  };
}
