import {
  MixpanelEvent,
  SubAccountTypeEnum,
  WarningSeverity,
  getParentTreesForSubAccount,
  getTypedKeys,
  type BlotterGroupingDefinition,
  type ISubaccount,
} from '@talos/kyoko';
import { compact, isEqual, pick, uniqWith } from 'lodash';
import type { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { PortfolioRiskDataItem } from './PortfolioRiskDataItem';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from './PortfolioRiskSubscriptionData';
import {
  ASSET_GROUP_PREFIX,
  BOOK_GROUP_PREFIX,
  GREEK_ORDER,
  ROLLUP_GROUP_PREFIX,
  UNDERLYING_GROUP_PREFIX,
} from './types';

export function getPositionRiskGrouping(): BlotterGroupingDefinition<'Sub Account', keyof PortfolioRiskGridData>[] {
  return compact([
    {
      key: 'Sub Account',
      label: 'By Sub Account',
      rowGroupColumns: ['SubAccount'],
      mixpanelEvent: MixpanelEvent.GroupAccounts,
      buttonDataTestId: 'by-sub-account-grouping-button',
    },
  ]);
}
export function convertToPortfolioRiskGridData(
  input: PortfolioRiskSubscriptionData,
  dataPath: string[],
  skipAggregation: boolean = false
): PortfolioRiskDataItem {
  const warnings = input.Warnings ?? [];
  if (skipAggregation) {
    warnings.push(
      "This asset's data is duplicated due to shared parents.  Be careful when looking at higher level aggregations above these rows."
    );
  }
  const PMSWarningColumnValue = warnings?.length
    ? {
        severity: WarningSeverity.MEDIUM,
        warnings,
      }
    : undefined;
  const newEntry: Omit<PortfolioRiskGridData, 'Pivot'> = {
    rowID: dataPath.join('@@@@@'),
    skipAggregation,
    dataPath,
    ...pick(input, [
      'ResultType',
      'Asset',
      'AssetType',
      'SubAccount',
      'Position',
      'Status',
      'BatchTime',
      'RiskValuationTime',
      'PV',
      'IV',
      'TenorBucket',
      'MoneynessBucket',
      'ExposureGroupAsset',
      'Delta',
      'Gamma',
      'Vega',
      'Vanna',
      'Volga',
      'Rho',
      'Theta',
    ]),
    optionPV: input.AssetType === 'Option' ? input.PV : undefined,
    PMSWarningColumnValue,
    MarkPrice: input.MarkPrice,
    MarkPriceCurrency: input.MarkPriceCurrency,
    Equivalent: {
      optionPV: input.AssetType === 'Option' ? input.Equivalent.PV : undefined,
      ...pick(input.Equivalent, [
        'Currency',
        'MarkPrice',
        'Position',
        'PositionConversionRate',
        'Weight',
        'CcyDeltaExposure',
      ]),
    },
  };
  const tenor = input.TenorBucket;
  const moneyness = input.MoneynessBucket;
  const newEntryPivot = getTypedKeys(GREEK_ORDER).reduce((result, next) => {
    if (input.ResultType === 'Asset') {
      const netRead = input.Equivalent?.[`Ccy${next}`]?.Net;
      const net = netRead ? parseFloat(netRead) : undefined;
      const grossRead = input.Equivalent?.[`Ccy${next}`]?.Gross;
      const gross = grossRead ? parseFloat(grossRead) : undefined;

      result[next] = {
        Tenor: {
          [tenor]: {
            Net: net,
            Gross: gross,
          },
        },
        Moneyness: {
          [moneyness]: {
            Net: net,
            Gross: gross,
          },
        },
      };
    }
    return result;
  }, {} as NonNullable<PortfolioRiskGridData['Pivot']>);

  const newEntryToAdd: PortfolioRiskGridData =
    input.ResultType === 'Asset'
      ? {
          ...newEntry,
          ResultType: 'Asset',
          Pivot: newEntryPivot,
        }
      : {
          ...newEntry,
          ResultType: 'AssetMissingMark',
          Pivot: {},
        };

  return new PortfolioRiskDataItem(newEntryToAdd, input);
}

type BuildHierarchicalDataPathArg = {
  /** The node to get the data path for */
  node: PortfolioRiskSubscriptionData;
  /** The contextual sub account ID */
  contextSubAccountId: number;
  /** Whether to show the rollup hierarchy (which only shows book-level underlying/instrument) */
  showRollupHierarchy: boolean;
} & Pick<ReturnType<typeof useSubAccountRollupMemberships>, 'rollupMembershipsByChildParent'> &
  Pick<ReturnType<typeof useSubAccounts>, 'subAccountsByName' | 'subAccountsByID'>;

// for a subaccount, if it's book, prefix with "Book::", if it's rollup, prefix with "Rollup::"
export function getSubAccountPathName(subAccount: ISubaccount): string {
  const prefix = subAccount.Type === SubAccountTypeEnum.Book ? BOOK_GROUP_PREFIX : ROLLUP_GROUP_PREFIX;
  return prefix + subAccount.Name;
}

/**
 * Get the AgGrid Data Paths for the node in question
 * @returns The AgGrid data path for the node.  If there are multiple outer array results, it means that
 * this node has multiple parents in the rollup hierarchy, which can be problematic for aggregation (and the UI warns on)
 */
export function buildHierarchicalDataPath(arg: BuildHierarchicalDataPathArg): string[][] {
  if (!arg.contextSubAccountId) {
    return [];
  }

  const { node, subAccountsByName, rollupMembershipsByChildParent, subAccountsByID, showRollupHierarchy } = arg;

  const subAccountID = subAccountsByName?.get(node.SubAccount)?.SubaccountID;
  if (!subAccountID) {
    console.error('No SubAccountID found for node', node);
    return [[node.SubAccount, node.Asset]];
  }

  const pathOfIdsArray = getParentTreesForSubAccount(subAccountID, rollupMembershipsByChildParent);

  const result = pathOfIdsArray.map(pathOfIds => {
    const pathOfNames = compact(
      pathOfIds.map(id => {
        const subAccount = subAccountsByID.get(id);
        return subAccount ? getSubAccountPathName(subAccount) : undefined;
      })
    ).reverse();
    const subAccount = subAccountsByID.get(subAccountID);
    if (!subAccount) {
      console.error('No SubAccount found for ID', subAccountID);
      return [node.SubAccount, node.Asset];
    }
    const parentAccounts = showRollupHierarchy ? [...pathOfNames] : [];
    const result = [
      ...parentAccounts,
      getSubAccountPathName(subAccount),
      `${UNDERLYING_GROUP_PREFIX}${node.ExposureGroupAsset}`,
      `${ASSET_GROUP_PREFIX}${node.Asset}`,
    ];
    return result;
  });
  return uniqWith(result, isEqual);
}
