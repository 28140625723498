import { type MarketAccount, request, useUserContext } from '@talos/kyoko';
import { useCallback } from 'react';

export const useMarketAccountRequests = () => {
  const { orgApiEndpoint } = useUserContext();

  const updateMarketAccount = useCallback(
    (
      name: string,
      counterparty: string,
      params: Partial<Pick<MarketAccount, 'Group' | 'DisplayName'>>
    ): Promise<MarketAccount> => {
      return orgApiEndpoint
        ? request<{ data: MarketAccount[] }>('PUT', `${orgApiEndpoint}/market-accounts/${name}`, {
            Counterparty: counterparty,
            Group: params.Group?.trim(),
            DisplayName: params.DisplayName?.trim(),
          }).then(res => {
            return res.data[0];
          })
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  return { updateMarketAccount };
};
