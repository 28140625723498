import { MixpanelEvent, useMixpanel } from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';
import { getPositionAutoHedgingRulesRoute } from '../../containers/Routes/routes';
import { useMarketTabs } from '../../providers';
import { useAppLayoutConfig } from '../../providers/AppLayoutConfig/AppLayoutConfigContext';
import { MarketTabType } from '../../providers/MarketTabs.types';

/**
 * Hook to navigate to the Position Autohedging setup tab
 */
export function useNavigateToHedgeRulesSetup() {
  const { onAdd: createNewPAHTab, onSelect: onSelectPAHTab, items: marketTabs, selectedIndex } = useMarketTabs();
  const mixpanel = useMixpanel();
  const { enableFlexibleLayout } = useAppLayoutConfig();

  /**
   * The index of the currently selected PAH market tab.
   * If a PAH tab is not currently selected, returns the index of the first PAH tab found.
   * Otherwise, returns -1 if none is found.
   */
  const existingPAHTabIndex = useMemo(() => {
    const matchingTabs = marketTabs.filter(mT => mT.type === MarketTabType.PositionAutoHedging);
    const currentTabID = marketTabs.at(selectedIndex)?.id;
    const matchingTabID = matchingTabs.find(t => t.id === currentTabID)?.id || matchingTabs.at(0)?.id;

    return marketTabs.findIndex(mT => mT.id === matchingTabID);
  }, [marketTabs, selectedIndex]);

  const { pathname } = useLocation();
  const history = useHistory();

  return useCallback(() => {
    mixpanel.track(MixpanelEvent.NavigateToHedgeRulesTab);
    if (enableFlexibleLayout) {
      history.push(getPositionAutoHedgingRulesRoute({}));
      return;
    }
    // need to navigate to trading if we're not on that sub-path
    // this is a crutch because of a useUpdateEffect in market tabs
    if (!pathname?.startsWith('/trading/')) {
      history.push('/trading');
    }

    if (existingPAHTabIndex === -1) {
      createNewPAHTab?.({
        type: MarketTabType.PositionAutoHedging,
        label: 'Position Autohedging Rules',
        isTemporary: true,
      });
      return;
    }
    onSelectPAHTab(existingPAHTabIndex);
  }, [mixpanel, createNewPAHTab, existingPAHTabIndex, history, pathname, onSelectPAHTab, enableFlexibleLayout]);
}
