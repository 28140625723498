import { Box, ConnectionStatusEnum, Flex, IconName, type DistributionBarProps } from '@talos/kyoko';
import Big from 'big.js';
import { usePortfolioExposures, usePositionsFormContext } from 'containers/Portfolio/providers';
import { EXPOSURE_WARNING_THRESHOLD, type PortfolioExposure } from 'containers/Portfolio/types';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { usePositionsConversions } from '../usePositionsConversions';
import { SettlementDistributionBarHeader } from './SettlementDistributionBarHeader';
import { useTotalTreasury } from './useTotalTreasury';

export interface SummedPositionBarsOutput {
  incoming: DistributionBarProps;
  outgoing: DistributionBarProps;
}

export const useSummedPositionBars = (): SummedPositionBarsOutput | undefined => {
  const totalTreasury = useTotalTreasury();
  const positionConversions = usePositionsConversions();
  const { exposuresByMarketAccount } = usePortfolioExposures();
  const { state } = usePositionsFormContext();
  const theme = useTheme();

  const parts = useMemo(() => {
    if (state == null || totalTreasury == null || positionConversions == null || !exposuresByMarketAccount) {
      return undefined;
    }

    let totalOutgoing = Big(0);
    let selectedOutgoing = Big(0);
    let selectedWarningOutgoing = Big(0);

    let totalIncoming = Big(0);
    let selectedIncoming = Big(0);

    state.forEach((mktAccMap, mktacc) => {
      let someOutgoingAboveZero = false;
      let pendingSelectedIncoming = Big(0);

      mktAccMap.forEach(entry => {
        const rate = positionConversions.get(entry.currency);
        if (!rate || rate.Rate == null || rate.Status !== ConnectionStatusEnum.Online) {
          return;
        }
        const inputValueBig = Big(entry.inputValue === '' ? '0' : entry.inputValue).times(rate.Rate);
        const wsAmountBig = Big(entry.wsAmount === '' ? '0' : entry.wsAmount)
          .abs()
          .times(rate.Rate);

        if (entry.isOutgoing) {
          totalOutgoing = totalOutgoing.plus(wsAmountBig);
          if (entry.checked) {
            if (inputValueBig.gt(0)) {
              someOutgoingAboveZero = true;
            }

            if (isMktaccWarning(mktacc, exposuresByMarketAccount)) {
              selectedWarningOutgoing = selectedWarningOutgoing.plus(inputValueBig);
            } else {
              selectedOutgoing = selectedOutgoing.plus(inputValueBig);
            }
          }
        } else {
          totalIncoming = totalIncoming.plus(wsAmountBig);

          // always always use full wsAmount irrespectively of the inputValue!
          pendingSelectedIncoming = pendingSelectedIncoming.plus(wsAmountBig);
        }
      });

      if (someOutgoingAboveZero) {
        selectedIncoming = selectedIncoming.plus(pendingSelectedIncoming);
      }
    });

    const combinedSelectedOutgoing = selectedWarningOutgoing.plus(selectedOutgoing);
    const totalOutgoingSmallerThanSelectedOutgoing = totalOutgoing.lt(combinedSelectedOutgoing);
    const selectedOutgoingRepresentation = totalOutgoingSmallerThanSelectedOutgoing
      ? combinedSelectedOutgoing
      : totalOutgoing;

    const treasuryMinusSelected = Big(totalTreasury).minus(selectedOutgoingRepresentation);
    const free = treasuryMinusSelected.lte(0) ? Big(0) : treasuryMinusSelected;

    const outgoingBar: DistributionBarProps = {
      roundRight: true,
      roundLeft: false,
      direction: 'ltr',
      sections: [
        {
          id: 'outgoingSection',
          header: (
            <Box pb="spacingMedium">
              <SettlementDistributionBarHeader
                label="Outgoing Settlement"
                value={selectedOutgoing.plus(selectedWarningOutgoing).toNumber()}
                wrapLabel={false}
                icon={IconName.ArrowRight}
                direction="ltr"
                valueColor={theme.colorTextPrimary}
              />
            </Box>
          ),
          parts: [
            {
              id: 'selectedOutgoingWarning',
              value: selectedWarningOutgoing,
              color: theme.colorTextWarning,
            },
            {
              id: 'selectedOutgoing',
              value: selectedOutgoing,
              color: theme.colorTextPrimary,
            },
            {
              id: 'totalOutgoing',
              value: totalOutgoingSmallerThanSelectedOutgoing
                ? Big(0)
                : totalOutgoing.minus(selectedOutgoing).minus(selectedWarningOutgoing), // diff
              color: theme.colorTextPrimaryMuted,
            },
          ],
        },
        {
          id: 'freeSection',
          header: (
            <Flex pb="spacingMedium" justifyContent="flex-end">
              <SettlementDistributionBarHeader
                label="Free"
                wrapLabel={false}
                value={free.toNumber()}
                direction="ltr"
                valueColor={theme.colorTextImportant}
              />
            </Flex>
          ),
          parts: [
            {
              id: 'free',
              value: free,
              color: theme.backgroundProgressBar,
            },
          ],
        },
      ],
    };

    const incomingBar: DistributionBarProps = {
      roundRight: false,
      roundLeft: true,
      direction: 'rtl',
      sections: [
        {
          id: 'incomingSection',
          header: (
            <Box pb="spacingMedium">
              <SettlementDistributionBarHeader
                label="Incoming Settlement"
                wrapLabel={false}
                value={selectedIncoming.toNumber()}
                icon={IconName.ArrowLeft}
                direction="rtl"
                valueColor={theme.colorTextNegative}
              />
            </Box>
          ),
          parts: [
            {
              id: 'totalIncoming',
              value: totalIncoming.minus(selectedIncoming), // diff
              color: theme.colorTextNegativeMuted,
            },
            {
              id: 'selectedIncoming',
              value: selectedIncoming,
              color: theme.colorTextNegative,
            },
          ],
        },
      ],
    };

    return {
      incoming: incomingBar,
      outgoing: outgoingBar,
    };
  }, [state, totalTreasury, positionConversions, theme, exposuresByMarketAccount]);

  return parts;
};

function isMktaccWarning(mktacc: string, exposures: Map<string, PortfolioExposure>): boolean {
  const exposure = exposures.get(mktacc);
  if (!exposure) {
    return false;
  }

  const limitVal = Big(exposure.ExposureLimit || '0');
  const positionVal = Big(exposure.Exposure || '0');

  if (limitVal.eq(0) || positionVal.eq(0)) {
    return false;
  }

  const usage = positionVal.div(limitVal);
  return usage.gte(Big(EXPOSURE_WARNING_THRESHOLD));
}
