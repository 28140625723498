import { memo, useCallback, useMemo } from 'react';
import { filter, map, shareReplay } from 'rxjs/operators';
import { v1 as uuid } from 'uuid';

import {
  ConnectionType,
  NEW_TRANSFER,
  TRANSFER,
  formattedDateForSubscription,
  getTreasuryLinkDirection,
  marketTypeToTreasuryLinkType,
  useDynamicCallback,
  useMarketAccountCurrenciesContext,
  useMarketAccountsContext,
  useMarketsContext,
  useObservable,
  useObservableValue,
  useSocketClient,
  useStaticSubscription,
  wsScanToDoubleMap,
  wsScanToMap,
  wsSubscriptionCache,
  type Market,
  type MarketAccount,
  type Transfer,
  type TreasuryLink,
} from '@talos/kyoko';

import { getEffectiveTreasuryLink } from '../containers/Portfolio/Addresses/hooks/useEffectiveTreasuryLink';
import {
  isMarketAccountSelectableAsDest,
  isMarketAccountSelectableAsSource,
  isValidDestinationForCurrency,
  isValidSourceForCurrency,
} from '../containers/Transfers/filtering';
import {
  getEmptyTransferForm,
  marketAccountToSourceDestinationOption,
  type TransferForm,
} from '../containers/Transfers/types';
import { usePortfolioAccounts } from './PortfolioAccountsProvider';
import { Transfers, type PrimeTransferParams } from './TransfersContext';

export const TransfersProvider = memo(function TransfersProvider(props: React.PropsWithChildren<unknown>) {
  const client = useSocketClient();
  const { treasuryLinksBySpecificnessKey } = usePortfolioAccounts();

  const { data: transferSubscription } = useStaticSubscription<Transfer>({
    name: TRANSFER,
    tag: TRANSFER,
    StartDate: formattedDateForSubscription(Sugar.Date.create('1 week ago')),
  });

  const cachedTransferSubscription = useMemo(
    () => transferSubscription.pipe(wsSubscriptionCache(t => t.TransferID)),
    [transferSubscription]
  );

  const { isMarketOnline, marketsByName } = useMarketsContext();
  const { acceptedCurrenciesByMarketAccountIDObs } = useMarketAccountCurrenciesContext();
  const { marketAccountsByID, marketAccountsByName } = useMarketAccountsContext();

  const transfersObs = useObservable(
    () =>
      cachedTransferSubscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.TransferID, newMapEachUpdate: false }),
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [cachedTransferSubscription]
  );

  const transfersByCurrencyObs = useMemo(
    () =>
      cachedTransferSubscription.pipe(
        wsScanToDoubleMap({
          getKey1: t => t.Currency,
          getKey2: t => t.TransferID,
          newOuterMapEachUpdate: false,
          newInnerMapsEachUpdate: false,
        }),
        map((doubleMap: Map<string, Map<string, Transfer>>) => {
          const newMap = new Map<string, Transfer[]>();
          doubleMap.forEach((value, key) => {
            // Just spreads all the unique transfers from the inner map into an array
            newMap.set(key, [...value.values()]);
          });
          return newMap;
        }),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      ),
    [cachedTransferSubscription]
  );

  // Provides you the non-initial stream of Transfer updates
  const transfersStreamObs = useObservable(
    () =>
      cachedTransferSubscription.pipe(
        filter(json => {
          return !json.initial;
        })
      ),
    [cachedTransferSubscription]
  );

  const initiateTransfer = useCallback(
    ({
      clientID = uuid(),
      currency,
      chainCurrency,
      amount,
      market,
      fromMarketAccountID,
      toMarketAccountID,
      fromSourceAccountID,
      toSourceAccountID,
      description,
      reference,
      transactTime = new Date(),
    }) => {
      client.registerPublication({
        type: NEW_TRANSFER,
        data: [
          {
            ClientID: clientID,
            Currency: currency,
            ChainCurrencySymbol: chainCurrency,
            Amount: amount,
            Market: market,
            TransactTime: formattedDateForSubscription(transactTime),
            FromMarketAccountID: fromMarketAccountID,
            ToMarketAccountID: toMarketAccountID,
            FromSourceAccountID: fromSourceAccountID,
            ToSourceAccountID: toSourceAccountID,
            Description: description,
            ReferenceData: reference,
          },
        ],
      });
    },
    [client]
  );

  const acceptedCurrenciesByMarketAccountID = useObservableValue(
    () => acceptedCurrenciesByMarketAccountIDObs,
    [acceptedCurrenciesByMarketAccountIDObs]
  );

  const getPrimeableTransfer = useDynamicCallback(
    (selections: PrimeTransferParams, tryWithLinkedAccount?: boolean): TransferForm | undefined => {
      if (acceptedCurrenciesByMarketAccountID == null || treasuryLinksBySpecificnessKey == null) {
        return undefined;
      }

      const asset = selections.asset;
      if (asset == null) {
        return undefined;
      }

      if (selections.source && selections.destination) {
        // Not yet supported
        return undefined;
      }

      const sourceMarketAccount = selections.source ? marketAccountsByID.get(selections.source) : undefined;
      const destinationMarketAccount = selections.destination
        ? marketAccountsByID.get(selections.destination)
        : undefined;

      const validatedSource = getValidatedSourceOrDestination({
        marketAccount: sourceMarketAccount,
        asset,
        isValid: ma =>
          isMarketAccountSelectableAsSource(ma) &&
          isValidSourceForCurrency(ma, asset, acceptedCurrenciesByMarketAccountID),
        treasuryLinksBySpecificnessKey,
        marketAccountsByName,
        marketsByName,
        tryWithLinkedAccount,
      });

      if (selections.source && validatedSource == null) {
        return undefined;
      }

      const validatedDestination = getValidatedSourceOrDestination({
        marketAccount: destinationMarketAccount,
        asset,
        isValid: ma =>
          isMarketAccountSelectableAsDest(ma) &&
          isValidDestinationForCurrency(ma, asset, acceptedCurrenciesByMarketAccountID),
        treasuryLinksBySpecificnessKey,
        marketAccountsByName,
        marketsByName,
        tryWithLinkedAccount,
      });

      if (selections.destination && validatedDestination == null) {
        return undefined;
      }

      // Now that we supposedly have either a validated source or destination, check that the market exists and is online for this transfer
      const providerName = validatedSource?.Market ?? validatedDestination?.Market;
      if (providerName == null) {
        return undefined;
      }

      const providerMarket = marketsByName.get(providerName);
      if (providerMarket == null || !isMarketOnline(providerMarket, ConnectionType.Transfers)) {
        return undefined;
      }

      return {
        ...getEmptyTransferForm(),
        asset,
        source: validatedSource ? marketAccountToSourceDestinationOption(validatedSource, asset) : undefined,
        destination: validatedDestination
          ? marketAccountToSourceDestinationOption(validatedDestination, asset)
          : undefined,
      };
    }
  );

  return (
    <Transfers.Provider
      value={{
        transfersObs,
        transfersByCurrencyObs,
        transfersStreamObs,
        initiateTransfer,
        getPrimeableTransfer,
      }}
    >
      {props.children}
    </Transfers.Provider>
  );
});

interface GetValidatedSourceOrDestinationParams {
  /** Original market account to check for validity */
  marketAccount: MarketAccount | undefined;
  /** The asset to use in combination with the market accounts to check validity */
  asset: string;
  /** Function to check validity */
  isValid: (ma: MarketAccount) => boolean;
  /** Map of treasury links used to get a linked account if needed */
  treasuryLinksBySpecificnessKey: Map<string, Map<string, TreasuryLink>>;
  marketAccountsByName: Map<string, MarketAccount>;
  marketsByName: Map<string, Market>;
  /** Whether or not to try to fall back to a linked account */
  tryWithLinkedAccount?: boolean;
}

/**
 * Validates the provided MarketAccount, and if valid, returns it back.
 * If it is not valid, attempts to fallback to a linked account if specified.
 * If no valid market account is found, returns undefined.
 * @returns a valid market account if found, or undefined otherwise
 */
function getValidatedSourceOrDestination({
  marketAccount,
  asset,
  isValid,
  treasuryLinksBySpecificnessKey,
  marketAccountsByName,
  marketsByName,
  tryWithLinkedAccount,
}: GetValidatedSourceOrDestinationParams) {
  if (marketAccount == null) {
    return undefined;
  }

  if (isValid(marketAccount)) {
    return marketAccount;
  }

  if (tryWithLinkedAccount) {
    const market = marketsByName.get(marketAccount.Market);
    if (!market) {
      return undefined;
    }

    const linkTypeFromMarketType = marketTypeToTreasuryLinkType(market.Type);
    if (!linkTypeFromMarketType) {
      return undefined;
    }

    const linkDirectionFromMarketType = getTreasuryLinkDirection(linkTypeFromMarketType, undefined);
    const linkedAccountName = getEffectiveTreasuryLink({
      treasuryLinksBySpecificnessKey,
      sourceOrDestinationField: 'DestinationMarketAccount',
      marketAccount: marketAccount.Name,
      currency: asset,
      type: linkTypeFromMarketType,
      direction: linkDirectionFromMarketType,
    })?.DestinationMarketAccount;

    if (linkedAccountName == null) {
      return undefined;
    }

    const linkedAccount = marketAccountsByName.get(linkedAccountName);
    if (linkedAccount != null && isValid(linkedAccount)) {
      return linkedAccount;
    }
  }

  return undefined;
}
