import { forwardRef, type ReactNode } from 'react';
import { AccordionChevron } from '../AccordionChevron';
import { useAccordionContext } from '../AccordionContext';
import { AccordionRowCell, ContainerRow } from './styles';

export interface AccordionRowHeaderProps {
  children: ReactNode;
  rowID?: string;
  className?: string;
}

export const AccordionRowHeader = forwardRef<HTMLTableSectionElement, AccordionRowHeaderProps>(
  ({ children, className, rowID }, ref) => {
    const { headerClickable, toggle, isOpen, alignChevron } = useAccordionContext();

    const chevron = (
      /* Be as small as possible */
      <AccordionRowCell style={{ width: '1%' }}>
        <AccordionChevron toggle={toggle} isOpen={isOpen} />
      </AccordionRowCell>
    );

    return (
      <ContainerRow
        className={className}
        ref={ref}
        clickable={headerClickable}
        onClick={e => headerClickable && toggle(e)}
        data-testid={rowID}
      >
        <tr>
          {alignChevron === 'left' && chevron}
          {children}
          {alignChevron === 'right' && chevron}
        </tr>
      </ContainerRow>
    );
  }
);
