import type { GridApi, GridOptions, RowClassRules } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import type { BlotterTableClientLocalFilter, Column, ColumnGroup } from '..';
import { useCallbackRef } from '../../../hooks/useCallbackRef';
import { WarningSeverity } from '../../../types';
import { EMPTY_OBJECT } from '../../../utils';
import { WARNING_ROW_CLASSNAME } from '../../AgGrid';
import { useBlotterTableContext, type BlotterTableContextProps } from '../BlotterTableContext';
import { BlotterDensity } from '../types';
import { alphabeticalGroupOrder, quickFilterParser } from '../utils';
import type { UseBlotterTableProps } from './types';
import { useBlotterTableMenus } from './useBlotterTableMainMenu';

/** UseBlotterTable's gridOption's prop intends to represent a GridOptions object to the developer,
 * but there are limited properties that useBlotterTable modifies behavior of, so this protects from that usage
 *
 * Props excluded:
 * - context: the BlotterTableContextProps is always included, so the main prop adds to it
 * - getRowId: the rowID prop is used for data transaction processing, so needed for useBlotterTable
 * - onSortChanged: useBlotterTable specially handles our sort +/- key format, so this is overriden for now (candidate for refactoring)
 * - colDefs: useBlotterTable utilizes our custom Column definition, so would cause a conflict
 * - isExternalFilterPresent, doesExternalFilterPass: clientLocalFilter overrides this behavior
 */
type UseBlotterTableGridOptions<TRow> = Omit<
  GridOptions<TRow>,
  'context' | 'getRowId' | 'onSortChanged' | 'colDefs' | 'isExternalFilterPresent' | 'doesExternalFilterPass'
>;

export type UseBlotterTableGridOptionsProps<TRow> = {
  gridOptions: UseBlotterTableGridOptions<TRow> | undefined;

  // The rest of the props are useBlotterTable inputs used to customize the gridOptions
  clientLocalFilter?: BlotterTableClientLocalFilter<TRow>;
  columnsToUse: (Column<any, any> | ColumnGroup)[];
  customBlotterContext: UseBlotterTableProps<TRow>['context'];
  density: UseBlotterTableProps<TRow>['density'];
  getParamsFormatted: GridOptions['processCellForClipboard'];
  onGridReady: NonNullable<GridOptions<TRow>['onGridReady']>;
  rowID: UseBlotterTableProps<TRow>['rowID'];
};

// memoization and customization of AgGrid GridOptions {@link GridOptions}
export function useBlotterTableGridOptions<TRow>({
  gridOptions,
  clientLocalFilter,
  columnsToUse,
  customBlotterContext,
  density,
  getParamsFormatted,
  onGridReady,
  rowID,
}: UseBlotterTableGridOptionsProps<TRow>): GridOptions<TRow> {
  const [api, setApi] = useState<GridApi | null>(null);
  const {
    rowHeightBlotterTableCompact,
    rowHeightBlotterTableDefault,
    rowHeightBlotterTableComfortable,
    rowHeightBlotterTableVeryComfortable,
  } = useTheme();
  const resolvedRowHeight =
    gridOptions?.rowHeight ??
    (density === BlotterDensity.Compact
      ? rowHeightBlotterTableCompact
      : density === BlotterDensity.Comfortable
      ? rowHeightBlotterTableComfortable
      : density === BlotterDensity.VeryComfortable
      ? rowHeightBlotterTableVeryComfortable
      : rowHeightBlotterTableDefault);

  // memoize the onGridReady callback in case someone else wants to use it
  const handleOnGridReady = useCallbackRef(onGridReady);
  // if user provides their own onGridReady, we need to call both
  const onGridReadyCallback = useCallback<NonNullable<GridOptions['onGridReady']>>(
    params => {
      setApi(params.api);
      gridOptions?.onGridReady?.(params);
      handleOnGridReady?.(params);
    },
    [gridOptions, handleOnGridReady]
  );

  const rowClassRules: RowClassRules<TRow> | undefined = useMemo(() => {
    if (columnsToUse.find(c => c.type === 'warning' && c.hide !== true)) {
      return {
        [WARNING_ROW_CLASSNAME]: params =>
          (
            params?.data as {
              warningSeverity?: WarningSeverity;
            }
          )?.warningSeverity === WarningSeverity.HIGH,
      };
    }
    return undefined;
  }, [columnsToUse]);

  const getRowId = useCallback<NonNullable<GridOptions['getRowId']>>(
    ({ data }) => (rowID ? data?.[rowID] : null),
    [rowID]
  );

  const { getMainMenuItems, getContextMenuItems } = useBlotterTableMenus(
    gridOptions?.getMainMenuItems,
    gridOptions?.getContextMenuItems
  );

  // Context
  const blotterTableContext = useBlotterTableContext();
  const agGridContext = useRef<BlotterTableContextProps | null>(null);
  useEffect(() => {
    agGridContext.current = {
      ...blotterTableContext,
      ...customBlotterContext,
    };
    api?.refreshCells({ force: true });
  }, [api, blotterTableContext, customBlotterContext]);

  const memoizedGridOptions = useMemo(() => {
    const startingGridOptions = (isEmpty(gridOptions) ? EMPTY_OBJECT : gridOptions) as GridOptions<TRow>;

    const outputOptions: GridOptions<TRow> = {
      ...startingGridOptions,
      animateRows: gridOptions?.animateRows ?? false,
      suppressAggFuncInHeader: gridOptions?.suppressAggFuncInHeader ?? true,
      onGridReady: onGridReadyCallback,
      rowHeight: resolvedRowHeight,
      rowClassRules: gridOptions?.rowClassRules ?? rowClassRules,
      getRowId,
      getMainMenuItems,
      getContextMenuItems,
      isExternalFilterPresent: () => clientLocalFilter !== undefined,
      doesExternalFilterPass: node => clientLocalFilter?.(node) ?? true,
      cacheQuickFilter: true,
      quickFilterParser: gridOptions?.quickFilterParser ?? quickFilterParser,
      initialGroupOrderComparator: gridOptions?.initialGroupOrderComparator ?? alphabeticalGroupOrder,
      // @ts-expect-error - The context should always be set
      context: agGridContext,
      processCellForClipboard: gridOptions?.processCellForClipboard ?? getParamsFormatted,
    };
    return outputOptions;
  }, [
    clientLocalFilter,
    getContextMenuItems,
    getMainMenuItems,
    getParamsFormatted,
    getRowId,
    gridOptions,
    onGridReadyCallback,
    resolvedRowHeight,
    rowClassRules,
  ]);

  return memoizedGridOptions;
}
