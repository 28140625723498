export interface ReconOverviewState {
  showZeroBalances?: boolean;
  showAllDecimals?: boolean;
  onlyShowBreaks?: boolean;
}

export const DEFAULT_RECON_OVERVIEW_STATE: ReconOverviewState = {
  showZeroBalances: false,
  showAllDecimals: false,
  onlyShowBreaks: false,
};

export interface BreakDetailsFilter {}

export interface BreakDetailsState {
  filter: BreakDetailsFilter;
  checkpointID: string;
  subAccounts: string[];
  asset: string;
  startTime: string;
  endTime: string;
  lastUpdateTime: string;
}

export const DEFAULT_BREAK_DETAILS_STATE: BreakDetailsState = {
  filter: {},
  checkpointID: '',
  subAccounts: [],
  asset: '',
  startTime: '',
  endTime: '',
  lastUpdateTime: '',
};
