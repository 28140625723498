import {
  BlotterTable,
  Button,
  Flex,
  IconName,
  LoaderSizes,
  MixpanelEvent,
  Portal,
  createCSVFileName,
  useBlotterTable,
  useMixpanel,
  useObservable,
  type Column,
  type CredentialTemplateFields,
  type MarketConfig,
  type MarketCredential,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { Loader, LoaderWrapper } from '../../../../components/Loader';
import { useCredentials } from '../useCredentials';
import { RIGHT_ITEMS_PORTAL_ID, type CredentialHistory, type ICredentialDrawerTab } from './types';

interface CredentialHistoryTabProps extends UseCredentialHistoryTab {
  selectedMarketConfig: MarketConfig | undefined;
}

type HydratedCredentialHistory = {
  RowID: string;
  RowIndex: number;
  Action: string;
  Description: string;
  UpdatedAt: string;
  Revision: number;
  User: string;
};

const columns: Column[] = [
  { type: 'text', field: 'RowID', sortable: true, hide: true },
  { type: 'text', field: 'RowIndex', sortable: true, hide: true, sort: '-' },
  { type: 'text', field: 'Action', width: 120 },
  { type: 'text', field: 'Description', width: 225 },
  { type: 'date', field: 'UpdatedAt', sortable: true, width: 120, title: 'Timestamp' },
  { type: 'text', field: 'Revision', width: 75, sortable: true },
  {
    type: 'user',
    field: 'User',
    width: 120,
    params: {
      valueFormatWithMap: 'id',
    },
  },
];

export function CredentialHistoryTab({ credential, selectedMarketConfig }: CredentialHistoryTabProps) {
  const mixpanel = useMixpanel();
  const { getCredentialHistory } = useCredentials();
  const [history, setHistory] = useState<CredentialHistory[] | undefined>([]);
  const [loading, setLoading] = useState(true);

  const availableCredentials = useMemo(() => selectedMarketConfig?.credentials || [], [selectedMarketConfig]);
  const credentialTemplateFields: Map<string, CredentialTemplateFields> = useMemo(() => {
    return new Map(
      (availableCredentials.find(ct => ct.type === credential?.ConnectionType)?.fields || []).map(ct => [ct.name, ct])
    );
  }, [availableCredentials, credential]);

  // This effect will run and fetch credential history whenever the credential will change
  useEffect(() => {
    setHistory([]);
    setLoading(true);
    getCredentialHistory(credential)
      .then(history => setHistory(history?.data ?? []))
      .finally(() => setLoading(false));
  }, [getCredentialHistory, credential]);

  const dataObservable = useObservable(
    () =>
      of(history ?? []).pipe(
        map((data: CredentialHistory[]) => {
          let rowIndex = 1;
          const result: HydratedCredentialHistory[] = [];
          data.forEach((ch: CredentialHistory) => {
            const row = {
              UpdatedAt: ch.UpdatedAt,
              Revision: ch.Revision,
              User: ch.UpdateUserID,
            };
            if (rowIndex === 1) {
              result.push({
                ...row,
                Action: 'Credential created',
                Description: '',
                RowID: `${rowIndex}`,
                RowIndex: rowIndex,
              });
              rowIndex++;
              return;
            }
            if (ch.Label) {
              result.push({
                ...row,
                Action: 'Label changed',
                Description: `Label changed to ${ch.Label}`,
                RowID: `${rowIndex}`,
                RowIndex: rowIndex,
              });
              rowIndex++;
            }
            if (ch.Mode) {
              result.push({
                ...row,
                Action: 'Mode changed',
                Description: `Mode changed to ${ch.Mode}`,
                RowID: `${rowIndex}`,
                RowIndex: rowIndex,
              });
              rowIndex++;
            }
            if (ch.SecretsChanged) {
              const secretsChanged = ch.SecretsChanged.map(key => credentialTemplateFields.get(key)?.label || key);
              result.push({
                ...row,
                Action: 'Secrets changed',
                Description: secretsChanged.join(', '),
                RowID: `${rowIndex}`,
                RowIndex: rowIndex,
              });
              rowIndex++;
            }
          });
          return { data: result, initial: true, type: 'CredentialHistory' };
        })
      ),
    [history, credentialTemplateFields]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'RowID',
    columns,
  });

  const handleExport = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: [credential.Label, 'History'].join('-'),
      }),
    });
  }, [blotterTable, credential?.Label, mixpanel]);

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <Loader size={LoaderSizes.SMALL} />
        </LoaderWrapper>
      )}
      <Flex flexDirection="column" w="100%" h="100%" style={{ display: loading ? 'none' : 'flex' }}>
        <Portal portalId={RIGHT_ITEMS_PORTAL_ID}>
          <Button startIcon={IconName.DocumentDownload} onClick={handleExport}>
            Export CSV
          </Button>
        </Portal>
        <BlotterTable background="backgroundDrawer" {...blotterTable} />
      </Flex>
    </>
  );
}

interface UseCredentialHistoryTab {
  credential: MarketCredential;
  marketConfigs: MarketConfig[];
}

export function useCredentialHistoryTab(props: UseCredentialHistoryTab): ICredentialDrawerTab {
  const { credential, marketConfigs } = props;
  const selectedMarketConfig = useMemo(
    () => marketConfigs.find(mktCfg => mktCfg.name === credential?.Market),
    [marketConfigs, credential]
  );

  return {
    name: 'History',
    component: <CredentialHistoryTab selectedMarketConfig={selectedMarketConfig} {...props} />,
    isDirty: false,
    viewable: true,
    save: () => Promise.resolve(),
  };
}
