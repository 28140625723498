import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import type { Leaves } from '../../../utils';
import type { AgGridSizeProps } from '../../AgGrid/types';
import { buildSizeAggFunc } from '../aggFuncs';
import type { SizeValue } from '../aggFuncs/types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface GreekColumnParams<T extends object = any> {
  /** Field to resolve the currency for for the number thats being shown (eg Position.DeltaCurrency) */
  currencyField?: Leaves<T>;
  /** Increment for rendering. Defaults to 0.01 */
  increment?: string;
  /** Whether or not to make the text red when negative */
  highlightNegative?: boolean;
}

const greek: ColDefFactory<Column<GreekColumnParams>> = column => ({
  width: 100,
  ...baseColumn(column),
  type: 'numericColumn',
  cellRenderer: 'sizeColumn',
  headerClass: () => 'ag-right-aligned-header',
  cellRendererParams: (params: ICellRendererParams<unknown, SizeValue>): AgGridSizeProps => {
    return { ...params, highlightNegative: column.params?.highlightNegative };
  },
  valueGetter: (params: ValueGetterParams<unknown>): SizeValue => {
    if (!params.data || !column.field) {
      return { value: '', currency: '' };
    }

    const value = get(params.data, column.field);
    const currency = column.params?.currencyField ? get(params.data, column.params.currencyField) : undefined;
    const currencyInfo = currency ? params.context.current.currenciesBySymbol?.get(currency) : undefined;

    let increment = column.params?.increment ?? '0.01';
    if (currencyInfo) {
      increment = currencyInfo.DefaultIncrement;
    }

    return { value, currency: currency ?? '', increment };
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, SizeValue>): string => {
    if (value == null) {
      return '';
    }
    return typeof value.value === 'string' ? value.value : value.value?.toFixed() ?? '';
  },
  aggFunc: column.aggregate ? buildSizeAggFunc() : undefined,
});

export const delta: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: true, increment: '0.01' } }),
});

export const gamma: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.01' } }),
});

export const vega: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.0001' } }),
});

export const theta: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.01' } }),
});

export const rho: ColDefFactory<Column<Omit<GreekColumnParams, 'highlightNegative' | 'increment'>>> = column => ({
  ...greek({ ...column, params: { ...column.params, highlightNegative: false, increment: '0.01' } }),
});
