import {
  formattedUTCDate,
  OptionTypeEnum,
  toBigWithDefault,
  type SelectItem,
  type StringSelectItem,
} from '@talos/kyoko';
import { OptionStrategies } from 'components/MultilegCombo/enums';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import { Option, OPTION_DATE_FORMAT } from './Option';
import { OptionStrategy, type OptionStrategyData } from './OptionStrategy';

/*
 * aka Call spread & Put spread
 * Strategy containing either 2 calls or 2 puts at a +/- ratio (buying one and selling one)
 * They have same expiration but different strike price
 * Call spread is used to bet on a limited increase in the underlying price
 */
export class VerticalSpread extends OptionStrategy {
  public static createFromBlank(referenceData: OMSReferenceDataState, partial?: Partial<OptionStrategyData>) {
    const data: OptionStrategyData = {
      name: OptionStrategies.VerticalSpread,
      legs: [Option.createFromBlank(referenceData), Option.createFromBlank(referenceData)],
      initiatingLegs: [true, false],
      ratios: ['1', '-1'],
      ...partial,
    };

    return new VerticalSpread(referenceData, data);
  }

  public updateExpiry = (expiry: StringSelectItem) => {
    const newData = {
      legs: [this._data.legs[0].updateExpiry(expiry), this._data.legs[1].updateExpiry(expiry)],
    };

    return this.create(newData);
  };

  public updateStrike = (strike: StringSelectItem, legIndex: number) => {
    const otherLegIndex = legIndex === 0 ? 1 : 0;
    const otherLegStrike = this._data.legs[otherLegIndex].data.strikeField.value;

    // Vertical spread means no same strikes
    const violateStrikeRule = strike.value === otherLegStrike?.value;
    const oppositeLegStrike = violateStrikeRule ? undefined : otherLegStrike;

    const newData = {
      ...this._data,
      legs: [
        this._data.legs[0].updateStrike(legIndex === 0 ? strike : oppositeLegStrike),
        this._data.legs[1].updateStrike(legIndex === 1 ? strike : oppositeLegStrike),
      ],
    };

    return this.ensureLegOrdering(newData);
  };

  public updateType = (type: SelectItem<OptionTypeEnum>) => {
    const newData = {
      ...this._data,
      legs: [this._data.legs[0].updateType(type), this._data.legs[1].updateType(type)],
    };

    return this.ensureLegOrdering(newData);
  };

  public updateRatio = (ratio: string, legIndex: number) => {
    const isRatioValid = legIndex === 0 ? toBigWithDefault(ratio, 0)?.gt(0) : toBigWithDefault(ratio, 0)?.lt(0);

    if (!ratio || ratio === '0' || ratio === '-' || !isRatioValid) {
      // ratio must maintain +1/-1 structure
      return this.create(this.data);
    }

    const copy = this._data.ratios.slice();
    copy[legIndex] = ratio;

    const newData = {
      ratios: copy,
    };

    return this.create(newData);
  };

  public getPrettyName(): string {
    const exchange = this.legs[0].data.exchangeField.value?.label;
    const coin = this.legs[0].data.coinField.value?.value;
    const type = this.legs[0].data.typeField.value?.value === OptionTypeEnum.Call ? 'CS' : 'PS';

    const leg1Expiry = this.legs[0].data.expiryField.value?.value
      ? formattedUTCDate(new Date(this.legs[0].data.expiryField.value?.value), OPTION_DATE_FORMAT).toUpperCase()
      : '';

    const leg1Strike = this.legs[0].data.strikeField.value?.value;
    const leg2Strike = this.legs[1].data.strikeField.value?.value;

    return `${exchange} ${coin} ${type} ${leg1Expiry} ${leg1Strike} ${leg2Strike}`;
  }

  protected create(newData: Partial<OptionStrategyData>) {
    const data = {
      ...this._data,
      ...newData,
    };
    return new VerticalSpread(this._referenceData, data);
  }

  private ensureLegOrdering(data: OptionStrategyData): VerticalSpread {
    const leg1Strike = data.legs[0].data.strikeField.value;
    const leg2Strike = data.legs[1].data.strikeField.value;

    if (leg1Strike && leg2Strike) {
      // Call spread implies that the higher strike should be on the 2nd leg (the one we are selling)
      // Put spread implies that the cheaper strike should be on the 2nd leg (the one we are selling)
      // Hence if order is wrong then we flip the strike values - (not the option itself)
      const type = data.legs[0].data.typeField.value?.value;
      const leg1StrikeBig = toBigWithDefault(leg1Strike.value, 0);
      const leg2StrikeBig = toBigWithDefault(leg2Strike.value, 0);
      const shouldFlip =
        (type === OptionTypeEnum.Call && leg2StrikeBig.lt(leg1StrikeBig)) ||
        (type === OptionTypeEnum.Put && leg2StrikeBig.gt(leg1StrikeBig));

      if (shouldFlip) {
        return this.create({
          legs: [
            data.legs[0].updateStrike(data.legs[1].data.strikeField.value),
            data.legs[1].updateStrike(data.legs[0].data.strikeField.value),
          ],
        });
      }
    }

    return this.create(data);
  }
}

VerticalSpread[immerable] = true;
