import { useDynamicCallback, useGlobalToasts, useServiceInstantiator, useUserContext } from '@talos/kyoko';
import type { PrimeOMSParams } from 'components/OMS/NewOrder/types';
import { OMSView } from 'components/OMS/OMSView';
import { useAppStateDispatch, useAppStateStore } from 'providers/AppStateProvider';
import type { MultilegTabData } from 'providers/MarketTabs.types';
import { primeNewOrderForm } from '../../OMS/NewOrder/OrderSlice';
import { openView } from '../../OMS/OMSSlice';
import { MultilegComboService } from '../MultilegComboService';

export const useMultilegComboService = (
  panelID: string,
  updateMultilegTabData: (panelData: MultilegTabData, label: string) => void
): MultilegComboService | undefined => {
  const store = useAppStateStore();
  const dispatch = useAppStateDispatch();
  const { orgApiEndpoint } = useUserContext();
  const { add: addToast } = useGlobalToasts();

  const primeOrderFormCallback = useDynamicCallback((params: PrimeOMSParams) => {
    dispatch(primeNewOrderForm(params));
    dispatch(openView(OMSView.NewOrderForm));
  });

  const service = useServiceInstantiator(
    () =>
      new MultilegComboService(
        store,
        orgApiEndpoint!,
        primeOrderFormCallback,
        updateMultilegTabData,
        addToast,
        panelID
      ),
    [addToast, orgApiEndpoint, primeOrderFormCallback, store, updateMultilegTabData, panelID]
  );

  return service;
};
