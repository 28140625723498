import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  CUSTOMER_ORDER,
  CUSTOMER_ORDER_SUMMARY,
  CustomerOrder,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  Toggle,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useSubscription,
  useWsBlotterTable,
  wsStitchWith,
  type BlotterTableFilter,
  type Column,
  type ColumnState,
  type CompositePipeFunction,
  type ICustomerOrderSummary,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';

import type { WebsocketRequest } from '@talos/kyoko';
import type { GetMainMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { pipe } from 'rxjs';
import { useFeatureFlag } from '../../../hooks';
import { useBlotterState } from '../../../providers/BlottersContext';
import { useCustomerOrdersColumns } from './useCustomerOrdersColumns';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useCustomerOrdersFilter,
  type CustomerOrdersTableFilter,
} from './useCustomerOrdersFilter';
import { useCustomerOrdersMenu } from './useCustomerOrdersMenu';

export interface FilteredCustomerOrderParams {
  blotterID: string;
  tabLabel?: string;
  defaultColumns?: Column[];
  defaultFilter?: CustomerOrdersTableFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  onRowDoubleClicked?: (event: RowDoubleClickedEvent<CustomerOrder>) => void;
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}

export function CustomerOrdersBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
  generateOrderDetailsRoute,
}: FilteredCustomerOrderParams) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const { enableMonitoringCustomerOrderSummaryColumns, setEnableMonitoringCustomerOrderSummaryColumns } =
    useBlotterState();
  const columnDefinitions = useCustomerOrdersColumns({ defaultColumns, enableMonitoringCustomerOrderSummaryColumns });
  const persistedBlotterTable = usePersistedBlotterTable<CustomerOrder>(blotterID, {
    columns: columnDefinitions,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const filterResults = useCustomerOrdersFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const CustomerOrdersMenu = useCustomerOrdersMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
    generateOrderDetailsRoute,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...CustomerOrdersMenu.columns],
    [CustomerOrdersMenu.columns, persistedBlotterTable.columns]
  );

  const { showBlotterPauseButton } = useFeatureFlag();

  /**
   * We need to keep the customerOrder sub and the customerOrderSummary sub aligned, we do this since they
   * should always be requesting the same data and support the same filters.
   */
  const [customerOrderSummaryRequest, setCustomerOrderSummaryRequest] = useState<WebsocketRequest | null>(null);
  const handleUpdatedCustomerOrderRequest = useCallback((request: WebsocketRequest | null) => {
    if (request === null) {
      setCustomerOrderSummaryRequest(null);
      return;
    }
    setCustomerOrderSummaryRequest({
      ...request,
      name: CUSTOMER_ORDER_SUMMARY,
      tag: 'CUSTOMER_RECENT_ORDER_SUMMARIES',
    });
  }, []);

  const { data: customerOrderSummariesObs } = useSubscription<ICustomerOrderSummary>(
    enableMonitoringCustomerOrderSummaryColumns ? customerOrderSummaryRequest : null
  );

  const enrichWithCustomerOrderSummaryFieldsPipe = useMemo(
    () =>
      pipe(
        wsStitchWith({
          getPrimaryTypeKey: customerOrder => customerOrder.OrderID,
          getSecondaryTypeKey: customerOrderSummary => customerOrderSummary.OrderID,
          secondarySource: customerOrderSummariesObs,
          stitch: (customerOrder, customerOrderSummary) => {
            customerOrder.enrichOrderWithCustomerSummary?.(customerOrderSummary);
            return customerOrder;
          },
        })
      ),
    [customerOrderSummariesObs]
  ) satisfies CompositePipeFunction<CustomerOrder>;

  const blotterTable = useWsBlotterTable<WebsocketRequest, CustomerOrder>({
    initialRequest: {
      tag: blotterID,
      name: CUSTOMER_ORDER,
      sort_by: '-SubmitTime',
    },
    onInnerRequestChanged: handleUpdatedCustomerOrderRequest,
    rowID: CustomerOrder.rowID satisfies keyof CustomerOrder,
    filter: onlyServerFilterKeys(filterResults.filter),
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: columnsWithMenu,
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    getExtraMainMenuItems,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    pipe: enrichWithCustomerOrderSummaryFieldsPipe,
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
    gridOptions: {
      onRowDoubleClicked,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: CustomerOrdersMenu.getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'CustomerOrders',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Toggle
              label="Summary Columns"
              size={FormControlSizes.Small}
              tooltip="Include Summary Columns in the table"
              checked={enableMonitoringCustomerOrderSummaryColumns}
              onChange={setEnableMonitoringCustomerOrderSummaryColumns}
            />

            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>

            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.SubmitTime} />
      <BlotterTable {...blotterTable} />
      {CustomerOrdersMenu.dialogs}
    </>
  );
}
