import { Button, Icon } from '@talos/kyoko';
import { CurrencyDisplayer } from 'containers/Portfolio/components/CurrencyDisplayer';
import styled from 'styled-components';

export const SettlementBodyContainer = styled.div`
  margin-right: auto;
  min-width: 400px;
  max-width: 1000px;
  padding: ${({ theme }) => theme.spacingBig}px 0;
`;

export const Th = styled.th`
  padding-bottom: ${({ theme }) => theme.spacingMedium}px;

  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  color: ${({ theme }) => theme.colorTextSubtle};
`;

export const CheckboxWrapper = styled.div`
  padding-right: ${({ theme }) => theme.spacingLarge}px;
`;

export const SettlementNumber = styled.span<{ clickable: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacingDefault}px;

  transition: color 200ms ease, font-size 200ms ease;
  white-space: nowrap;

  & ${Icon} {
    left: 100%;
    padding-left: ${({ theme }) => theme.spacingSmall}px;
    position: absolute;

    visibility: hidden;
    opacity: 0;

    transition: visibility 200ms, opacity 200ms ease;
  }

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;

    :hover {
      & ${Icon} {
        visibility: visible;
        opacity: 1;
      }
    }
  `}
`;

export const SettleAmountWrapper = styled.div`
  padding: ${({ theme }) => theme.spacingDefault}px 0px;
  min-width: 240px;
  width: 260px;
`;

export const SettleButton = styled(Button)<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const StyledCurrencyDisplayer = styled(CurrencyDisplayer)`
  color: ${({ theme }) => theme.colorTextAttention};
`;

export const SettlementBodyCell = styled.td`
  padding: 0;
`;
