import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  CreditBlotterExposure,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconButton,
  IconName,
  baseTreeGroupColumnDef,
  filterByCellValueMenuItem,
  stringColumnComparator,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMarketAccountsContext,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
} from '@talos/kyoko';
import { useMemo } from 'react';

import type {
  ColDef,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridOptions,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { compact } from 'lodash';
import { useCustomersByName } from '../../../../../../hooks/useCustomer';
import { useSettlementMonitoringFilters } from '../../providers/SettlementMonitoringFiltersProvider';
import { useSettlementMonitoringInteractions } from '../../providers/SettlementMonitoringInteractionsProvider';
import { useSettlementMonitoringState } from '../../providers/SettlementMonitoringStateProvider';
import { POSITION_MONITORING_BLOTTER_BUTTONS_PORTAL_ID } from '../../tokens';
import type { SettlementMonitoringFilter } from '../../types';
import {
  defaultPositionMonitoringByAccountColumns,
  defaultPositionMonitoringFilter,
  defaultPositionMonitoringRowGroupsOpened,
} from '../defaults';
import type { PositionMonitoringBlotterConfig, PositionMonitoringColumn } from '../types';
import type { ByAccountEntity } from './types';
import { usePositionMonitoringByAccountColumns } from './usePositionMonitoringByAccountColumns';
import { usePositionMonitoringByAccountObs } from './usePositionMonitoringByAccountObs';

export interface PositionMonitoringByAccountBlotterParams {
  blotterID: string;
  tag?: string;
  config: PositionMonitoringBlotterConfig;
  updateConfig: (updatedConfig: PositionMonitoringBlotterConfig) => void;
}

/**
 * The complexity in this blotter is due to the necessity of rendering the data as a tree, and also wanting to have two modes of operation:
 * the first being just group by MarketAccount, and the second grouping by Counterparty and MarketAccount.
 *
 * This necessitates a few more if statements in the interaction of the tree (grouping) column.
 */
export function PositionMonitoringByAccountBlotter({ blotterID, config }: PositionMonitoringByAccountBlotterParams) {
  const mixpanel = useMixpanel();
  const counterpartiesByName = useCustomersByName();
  const { marketAccountsByName } = useMarketAccountsContext();

  const defaultBlotterColumns = config.defaultColumns ?? defaultPositionMonitoringByAccountColumns;
  const defaultFilter = config.defaultFilter ?? defaultPositionMonitoringFilter;
  const defaultRowGroupsOpened = config.defaultRowGroupsOpened ?? defaultPositionMonitoringRowGroupsOpened;

  const defaultColumns = usePositionMonitoringByAccountColumns(defaultBlotterColumns);

  const persistedBlotterTable = usePersistedBlotterTable<ByAccountEntity>(blotterID, {
    columns: defaultColumns,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const { filterableProperties } = useSettlementMonitoringFilters();
  const { openClause } = useSettlementMonitoringInteractions();
  const {
    state: { groupByCounterparty },
  } = useSettlementMonitoringState();

  const getContextMenuItems: GetContextMenuItems<ByAccountEntity> = useDynamicCallback(
    (params: GetContextMenuItemsParams) => {
      const items = ['separator', ...getDefaultContextMenuItems(params)];

      const data = params.node?.data;
      if (data != null) {
        items.unshift({
          name: `Show JSON`,
          action: () => handleClickJson(data),
          icon: `<i class="ag-icon ${IconName.Braces}"/>`,
        });
      }

      if (openClause) {
        // We need to map the colId of the tree column to the column the tree column actually represents
        // this will be either the counterparty column or the MarketAccount column depending on mode
        const isTreeColumn = params.column?.getColId() === AGGRID_AUTOCOLUMN_ID;
        const treeColumnEffectiveColId: PositionMonitoringColumn = groupByCounterparty
          ? 'counterparty'
          : 'MarketAccount';
        const mappedColId = isTreeColumn ? treeColumnEffectiveColId : params.column?.getColId(); // else just do standard and get the colid of the column we right-clicked
        items.unshift(
          ...filterByCellValueMenuItem({
            params,
            filterableProperties,
            openClause,
            colIDToFilterBuilderKey,
            mixpanel,
            colID: mappedColId,
          })
        );
      }

      return compact(items);
    }
  );

  // Depending on the mode we're in, we build the tree data path differently.
  const getTreeDataPath = useDynamicCallback((item: ByAccountEntity) => {
    const baseDataPath =
      item instanceof CreditBlotterExposure ? [item.MarketAccount] : [item.MarketAccount, item.rowID];

    // If we're grouping by counterparty we will try to add on the counterparty name at the beginning of the path array
    if (groupByCounterparty) {
      if (item instanceof CreditBlotterExposure) {
        baseDataPath.unshift(item.Counterparty);
      } else {
        const counterpartyName = marketAccountsByName.get(item.MarketAccount)?.Counterparty;
        if (counterpartyName) {
          baseDataPath.unshift(counterpartyName);
        }
      }
    }

    return baseDataPath;
  });

  const getTreeColumnValue = useDynamicCallback((node: IRowNode<ByAccountEntity> | null) => {
    if (!node) {
      return '';
    }

    if (groupByCounterparty) {
      // If were grouping by counterparty, we only wanna show counterparties in this column...
      // the value we resolve from the node can be either a counterparty or a market account.
      // we just wanna resolve the counterparty display name
      const value = node?.data?.MarketAccount ?? node?.key ?? undefined;
      if (value == null) {
        return undefined;
      }

      // When groupByCounterparty = true, level 0 is keyed on counterparty (level 1 is keyed on market account, level 2 are the leaves)
      if (node.level === 0) {
        return value;
      } else {
        // value is a market account name
        return marketAccountsByName.get(value)?.Counterparty;
      }
    } else {
      return node?.data?.MarketAccount ?? node?.key;
    }
  });

  const getTreeColumnFormattedValue = useDynamicCallback((node: IRowNode<ByAccountEntity> | null) => {
    // Value here is either a market account name or a counterparty name.
    // Depending on the groupByCounterparty setting, we know which it is.
    const value = getTreeColumnValue(node);
    if (value == null) {
      return '';
    }

    if (groupByCounterparty) {
      const counterpartyName = value; // we know the value is a counterparty name
      const counterparty = counterpartyName ? counterpartiesByName?.get(counterpartyName) : undefined;
      return counterparty?.DisplayName ?? counterpartyName ?? 'Unknown Counterparty';
    } else {
      // were grouping by account, which means we wanna show in the form of "Counterparty - Account", so we need
      // to resolve both the counterparty and market account...
      // we know that the value is always a market account in this case
      const marketAccountName = value; // we know the value is a market account name
      const marketAccount = marketAccountName ? marketAccountsByName.get(marketAccountName) : undefined;
      const counterpartyName = marketAccount?.Counterparty;
      const counterparty = counterpartyName ? counterpartiesByName?.get(counterpartyName) : undefined;
      const marketAccountDisplayName = marketAccount?.SourceAccountID ?? marketAccountName ?? 'Unknown Account';
      const counterpartyDisplayName = counterparty?.DisplayName ?? counterpartyName ?? 'Unknown Counterparty';
      return `${counterpartyDisplayName} - ${marketAccountDisplayName}`;
    }
  });

  const autoGroupColumnDef: ColDef<any, any> = useMemo(
    () =>
      ({
        ...baseTreeGroupColumnDef,
        valueFormatter: (params: ValueFormatterParams<ByAccountEntity>) => {
          return getTreeColumnFormattedValue(params.node);
        },
        // This filterValueGetter is used to get the filtering context menu item in the right-click menu
        filterValueGetter: ({ node }: ValueGetterParams<ByAccountEntity>) => {
          return getTreeColumnValue(node);
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          return stringColumnComparator(getTreeColumnFormattedValue(nodeA), getTreeColumnFormattedValue(nodeB));
        },
        headerName: groupByCounterparty ? 'Counterparty' : 'Counterparty - Account',
        width: 300,
      } satisfies GridOptions['autoGroupColumnDef']),
    [getTreeColumnFormattedValue, getTreeColumnValue, groupByCounterparty]
  );

  const { dataObs, totalsDataObs } = usePositionMonitoringByAccountObs({ filter: defaultFilter });

  const blotterTable = useBlotterTable<ByAccountEntity>({
    dataObservable: dataObs,
    pinnedRowDataObs: totalsDataObs,
    rowID: 'rowID' satisfies keyof ByAccountEntity,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    columns: persistedBlotterTable.columns,
    sort: persistedBlotterTable.initialSort,
    gridOptions: {
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
      groupDisplayType: 'singleColumn',
      showOpenedGroup: true,
      autoGroupColumnDef,
      treeData: true,
      getDataPath: getTreeDataPath,
    },
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        portalId={POSITION_MONITORING_BLOTTER_BUTTONS_PORTAL_ID}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              data-testid="expand-all-groups-button"
              onClick={expandAllGroups}
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
      />

      <BlotterTable {...blotterTable} />
      {jsonModal}
    </>
  );
}

function colIDToFilterBuilderKey(id: string): keyof SettlementMonitoringFilter | undefined {
  switch (id as PositionMonitoringColumn) {
    case 'Asset':
      return 'Symbols';
    case 'MarketAccount':
      return 'MarketAccounts';
    default:
      return undefined;
  }
}
