import { DELETE, Patch, Post, request, type MultilegModel } from '@talos/kyoko';

/**
 * This interface is not part of our documented API and might end up breaking in the future
 */
export type MultilegResp =
  | {
      type: 'MultilegInstrument';
      data: {
        Symbol: string;
      }[];
    }
  | {
      message: string;
    };

export function createMultileg(multiLeg: MultilegModel, orgApiEndpoint: string) {
  return Post<MultilegResp>(orgApiEndpoint, `/multileg-instrument`, multiLeg, {
    headers: { 'Content-Type': 'application/json' },
  });
}

export function updateMultileg(multiLeg: MultilegModel, orgApiEndpoint: string) {
  // Backend API is dumb and wants 'UpdateReqLegs' instead of 'ReqLegs' as the Legs key
  const mutliLegUpdate: Omit<MultilegModel, 'ReqLegs'> & { UpdateReqLegs: MultilegModel['ReqLegs'] } = {
    ...multiLeg,
    UpdateReqLegs: multiLeg.ReqLegs,
  };

  return Patch<MultilegResp>(
    orgApiEndpoint,
    `/multileg-instrument/${encodeURIComponent(mutliLegUpdate.Symbol)}`,
    mutliLegUpdate,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
}

export function deleteMultileg(symbol: string, orgApiEndpoint: string) {
  return request(DELETE, `${orgApiEndpoint}/multileg-instrument/${encodeURIComponent(symbol)}`);
}
