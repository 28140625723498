import {
  Alert,
  AlertVariants,
  FormControlSizes,
  Icon,
  IconName,
  InlineFormattedNumber,
  Text,
  toBigWithDefault,
  Tooltip,
  type Transfer,
  useMarketAccountsContext,
} from '@talos/kyoko';
import Big from 'big.js';
import { useMemo } from 'react';
import { useOngoingTransfers, type UseOngoingTransfersParams } from './useOngoingTransfers';

type OngoingTransfersWarningProps = UseOngoingTransfersParams;

/**
 * Given a market account and a currency, will display a warning icon to the user if there already are ongoing transfers
 * related to settlement for the provided market account and currency combination.
 *
 * The warning will include a tooltip providing more detail about the ongoing transfers.
 */
export const OngoingTransfersWarning = (params: OngoingTransfersWarningProps) => {
  const ongoingTransfers = useOngoingTransfers(params);
  if (ongoingTransfers.length === 0) {
    return null;
  }

  return (
    <Tooltip tooltip={<OngoingTransfersWarningTooltipContent ongoingTransfers={ongoingTransfers} />}>
      <Icon icon={IconName.ExclamationSolid} color="colorTextWarning" data-testid="ongoing-transfers-warning-icon" />
    </Tooltip>
  );
};

const OngoingTransfersWarningTooltipContent = ({ ongoingTransfers }: { ongoingTransfers: Transfer[] }) => {
  const { marketAccountsByID } = useMarketAccountsContext();

  const cumAmt = useMemo(
    () => ongoingTransfers.reduce((acc, transfer) => acc.plus(toBigWithDefault(transfer.Amount, 0)), Big(0)),
    [ongoingTransfers]
  );

  const firstTransfer = ongoingTransfers[0];
  if (!firstTransfer) {
    return null;
  }

  const fromAccountLabel: string =
    marketAccountsByID.get(firstTransfer.FromMarketAccountID)?.DisplayName ??
    firstTransfer.FromMarketAccountID.toString();
  const toAccountLabel: string =
    marketAccountsByID.get(firstTransfer.ToMarketAccountID)?.DisplayName ?? firstTransfer.ToMarketAccountID.toString();
  const currency = firstTransfer.Currency;

  const plural = ongoingTransfers.length > 1;

  return (
    <Alert
      variant={AlertVariants.Warning}
      size={FormControlSizes.Small}
      dismissable={false}
      data-testid="ongoing-transfers-warning-content"
      m="spacingSmall"
    >
      <Text>
        There {plural ? 'are' : 'is'} {ongoingTransfers.length} transfer{plural ? 's' : ''} currently ongoing from{' '}
        <b>{fromAccountLabel}</b> to <b>{toAccountLabel}</b> for asset <b>{currency}</b> with a cumulative amount of{' '}
        <b>
          {/* round to not have to deal with the long decimal case and overflowing that results in a scrollbar in the tooltip */}
          <InlineFormattedNumber number={cumAmt} currency={currency} round />
        </b>
        .
      </Text>
    </Alert>
  );
};
