// Common re-usable field rules (should not depend on any field context since re-usable implies generic)

import type { WritableDraft } from 'immer';
import type { OrderState } from '../../providers/WLOrderForm/state/OrderSlice/types';
import { toBigWithDefault } from '../../utils';
import { FieldValidationLevel, type FieldValidationRule } from '../BaseField';
import type { NumericField } from '../NumericField';
import { fieldsMessages } from '../messages';

export const numberIsPositive: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context) {
    return null;
  }
  if (field.value !== '') {
    if (toBigWithDefault(field.value, 0).lte(0)) {
      return {
        message: fieldsMessages.dataNameMustBePositive,
        values: { dataName: field.name },
        level: FieldValidationLevel.Error,
      };
    }
  }
  return null;
};
