import styled, { keyframes } from 'styled-components';

export const fade = keyframes`
  0%, 100% {
    opacity: 0.1;
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 0.4;
  }
`;

export const Wrapper = styled.div<{ size: number }>`
  align-self: center;
  vertical-align: middle;
  display: inline-grid;
  grid-gap: ${({ size }) => Math.max(size / 15, 2)}px;
  grid: ${({ size }) =>
    Array(2)
      .fill(undefined)
      .map(() =>
        Array(3)
          .fill(undefined)
          .map(() => `${size / 3}px`)
          .join(' ')
      )
      .join(' / ')};
`;

export const Square = styled.div`
  opacity: 0.1;

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8) {
    background: ${({ theme }) => theme.backgroundLoader};
    animation: ${fade} 1200ms linear infinite;
  }
  &:nth-child(2) {
    animation-delay: 0;
  }
  &:nth-child(3) {
    animation-delay: 200ms;
  }
  &:nth-child(6) {
    animation-delay: 400ms;
  }
  &:nth-child(8) {
    animation-delay: 600ms;
  }
  &:nth-child(7) {
    animation-delay: 800ms;
  }
  &:nth-child(4) {
    animation-delay: 1000ms;
  }
`;
export const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
