import type {
  IPositionUpdate,
  MarketDataSnapshotLevel,
  MarketDataStatistics,
  OptionTypeEnum,
  Security,
} from '@talos/kyoko';

//
// All column fields
//
export const OPTION_LAST_PRICE = 'LastPrice';
export const OPTION_RHO = 'Rho';
export const OPTION_VEGA = 'Vega';
export const OPTION_GAMMA = 'Gamma';
export const OPTION_THETA = 'Theta';
export const OPTION_DELTA = 'Delta';
export const OPTION_VOLUME = 'Volume24h';
export const OPTION_BID_SIZE = 'BidSize';
export const OPTION_BID_IV = 'BestBidIV';
export const OPTION_BID_PRICE = 'BidPrice';
export const OPTION_SPREAD = 'Spread';
export const OPTION_MARK = 'Mark';
export const OPTION_OFFER_PRICE = 'OfferPrice';
export const OPTION_OFFER_IV = 'BestOfferIV';
export const OPTION_OFFER_SIZE = 'OfferSize';
export const OPTION_POSITION = 'Position';
export const OPTION_OPEN_INTEREST = 'OpenInterest';

export const ALL_OPTION_FIELDS = [
  OPTION_LAST_PRICE,
  OPTION_RHO,
  OPTION_VEGA,
  OPTION_GAMMA,
  OPTION_THETA,
  OPTION_DELTA,
  OPTION_VOLUME,
  OPTION_BID_SIZE,
  OPTION_BID_IV,
  OPTION_BID_PRICE,
  OPTION_SPREAD,
  OPTION_MARK,
  OPTION_OFFER_PRICE,
  OPTION_OFFER_IV,
  OPTION_OFFER_SIZE,
  OPTION_POSITION,
  OPTION_OPEN_INTEREST,
] as const;

export function getOptionColumnLabel(colID: string): string {
  return OPTION_HEADER_MAP[colID] || colID;
}

// These are records instead of maps as they enforce keys.
const OPTION_HEADER_MAP: Record<(typeof ALL_OPTION_FIELDS)[number], string> = {
  [OPTION_LAST_PRICE]: 'Last Traded',
  [OPTION_RHO]: 'Rho',
  [OPTION_VEGA]: 'Vega',
  [OPTION_GAMMA]: 'Gamma',
  [OPTION_THETA]: 'Theta',
  [OPTION_DELTA]: 'Delta',
  [OPTION_VOLUME]: 'Volume',
  [OPTION_BID_SIZE]: 'Size B',
  [OPTION_BID_IV]: 'Bid IV',
  [OPTION_BID_PRICE]: 'Bid',
  [OPTION_SPREAD]: 'Spread',
  [OPTION_MARK]: 'Mark',
  [OPTION_OFFER_PRICE]: 'Offer',
  [OPTION_OFFER_IV]: 'Offer IV',
  [OPTION_OFFER_SIZE]: 'Size O',
  [OPTION_POSITION]: 'Position',
  [OPTION_OPEN_INTEREST]: 'Open Interest',
};
export const DEFAULT_OPTION_COLUMNS_IS_VISIBLE: Record<(typeof ALL_OPTION_FIELDS)[number], boolean> = {
  [OPTION_LAST_PRICE]: false,
  [OPTION_RHO]: false,
  [OPTION_VEGA]: false,
  [OPTION_GAMMA]: false,
  [OPTION_THETA]: false,
  [OPTION_DELTA]: true,
  [OPTION_VOLUME]: true,
  [OPTION_BID_SIZE]: false,
  [OPTION_BID_IV]: true,
  [OPTION_BID_PRICE]: true,
  [OPTION_SPREAD]: false,
  [OPTION_MARK]: true,
  [OPTION_OFFER_IV]: true,
  [OPTION_OFFER_PRICE]: true,
  [OPTION_OFFER_SIZE]: false,
  [OPTION_POSITION]: true,
  [OPTION_OPEN_INTEREST]: false,
};

export const CME_AVAILABLE_OPTION_COLUMNS: Record<(typeof ALL_OPTION_FIELDS)[number], boolean> = {
  [OPTION_LAST_PRICE]: false,
  [OPTION_RHO]: false,
  [OPTION_VEGA]: false,
  [OPTION_GAMMA]: false,
  [OPTION_THETA]: false,
  [OPTION_DELTA]: false,
  [OPTION_VOLUME]: true,
  [OPTION_BID_SIZE]: true,
  [OPTION_BID_IV]: false,
  [OPTION_BID_PRICE]: true,
  [OPTION_SPREAD]: false,
  [OPTION_MARK]: true,
  [OPTION_OFFER_IV]: false,
  [OPTION_OFFER_PRICE]: true,
  [OPTION_OFFER_SIZE]: true,
  [OPTION_POSITION]: true,
  [OPTION_OPEN_INTEREST]: true,
};

export const OPTION_STRIKE_PRICE = 'StrikePrice';

export type OptionChainSideData = MarketDataStatistics & {
  // Position
  [OPTION_POSITION]: IPositionUpdate['Amount'];

  // Market Data
  [OPTION_BID_SIZE]: MarketDataSnapshotLevel['Size'];
  [OPTION_BID_PRICE]: MarketDataSnapshotLevel['Price'];
  [OPTION_SPREAD]: number;
  [OPTION_OFFER_PRICE]: MarketDataSnapshotLevel['Price'];
  [OPTION_OFFER_SIZE]: MarketDataSnapshotLevel['Size'];
  // Due to how column mapping with side works in columDefs, it is easier to just lump these
  // two value together as they are rendered in the same cell (i.e. uses same field for value).
  [OPTION_MARK]: Pick<MarketDataStatistics, 'MarkPrice' | 'MarkPriceIV'>;
};
export interface OptionChainDataRow {
  StrikePrice: NonNullable<Security['StrikePrice']>;
  Expiration: NonNullable<Security['Expiration']>;
  UnderlyingQuoteCurrency: Security['UnderlyingQuoteCurrency'];
  UnderlyingCode: Security['UnderlyingCode'];
  Market: string | undefined;
  rowID: string;
  UnderlyingPrice?: string;
  Currency?: string;
  QuoteCurrency?: string;
  [OptionTypeEnum.Put]?: Partial<OptionChainSideData>;
  [OptionTypeEnum.Call]?: Partial<OptionChainSideData>;
}

export const PRICE_BELOW_STRIKE_CLASSNAME = 'price-below-strike';
export const PRICE_ABOVE_STRIKE_CLASSNAME = 'price-above-strike';

export const ALL_EXPIRIES_ITEM = 'All Expiries';
/**
 * Only for internal usage. Tag that is sent to options pipe to signal that a json message
 * should act as "initial" and thereby reset the blotter table. Using json.initial would not be
 * possible because of the pipe consisting of 3 different types of subscriptions.
 */
export const CUSTOM_OPTION_RESET_TAG = 'CUSTOM_OPTION_RESET_TAG';
