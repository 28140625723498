import {
  EMPTY_ARRAY,
  ReconResolutionEnumLabels,
  getAgGridColId,
  useDefaultColumns,
  type Column,
  type ColumnDef,
  type ReconMismatch,
  type ReconResolutionEnum,
} from '@talos/kyoko';

import { useMemo } from 'react';

export const RECON_MISMATCH_ROW_COUNT_COLID = 'ReconMismatchRowCountColID';
export type ReconMismatchColumn = keyof ReconMismatch | typeof RECON_MISMATCH_ROW_COUNT_COLID;

interface UseReconMismatchColumns {
  defaultColumns?: ReconMismatchColumn[];
}

export function useReconMismatchColumns({ defaultColumns = EMPTY_ARRAY }: UseReconMismatchColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'ID',
              type: 'text',
            },
            {
              field: 'Asset',
              type: 'asset',
              sortable: true,
              params: { colorful: true },
              rowGroup: true,
              rowGroupIndex: 1,
              hide: true,
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
              sortable: true,
              rowGroup: true,
              rowGroupIndex: 0,
            },
            {
              type: 'rowCount',
              id: RECON_MISMATCH_ROW_COUNT_COLID,
              title: 'Total Mismatches',
              width: 124,
              aggregate: true,
            },
            {
              field: 'MissingDelta',
              type: 'number',
              title: 'Missing Delta',
              sortable: true,
              aggregate: true,
              params: { currencyField: 'Asset', highlightNegative: true, truncate: false },
            },
            {
              field: 'ResultAmount',
              type: 'number',
              title: 'Result Amount',
              sortable: true,
              params: { currencyField: 'Asset', highlightNegative: true, truncate: false },
            },
            {
              field: 'StartTime',
              type: 'date',
              title: 'Interval Start',
              sortable: true,
            },
            {
              field: 'EndTime',
              type: 'date',
              title: 'Interval End',
              sortable: true,
            },
            {
              field: 'State',
              type: 'reconMismatchStatus',
            },
            {
              field: 'Resolution',
              type: 'text',
              params: { getLabel: (item: string) => ReconResolutionEnumLabels[item as ReconResolutionEnum] },
            },
            {
              field: 'Comments',
              type: 'text',
            },
            {
              field: 'rowID',
              type: 'text',
            },
            {
              field: 'sortKey',
              type: 'text',
            },
          ] satisfies ColumnDef<ReconMismatch>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<ReconMismatch>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
}
