import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  CARE_NEW_ORDER_SINGLE,
  formattedDateForSubscription,
  isGridApiReady,
  logger,
  NotificationVariants,
  useConstant,
  useGlobalToasts,
  useSocketClient,
  type CareNewOrderSingle,
  type CareOrder,
  type DialogProps,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';
import type { IsRowSelectable, SelectionChangedEvent } from 'ag-grid-community';
import { ImportDialog, type ImportStep } from 'components/ImportDialog';
import { useGetCareOrdersQuery } from 'providers/AppStateProvider/streamingDataSlice';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { BehaviorSubject, filter } from 'rxjs';
import { PREVIEW_COLUMNS } from './columns';
import { useCareOrderImportParser } from './parsers/useCareOrderImportParser';
import type { ImportedCareOrder } from './types';

const IMPORT_TIMEOUT_MS = 10_000;

export function CareOrderImportDialog(props: DialogProps) {
  const [state, dispatch] = useReducer(careOrderImportSlice.reducer, careOrderImportSlice.getInitialState());
  const { add: addToast } = useGlobalToasts();
  const { close: closeDialog } = props;

  const { step, isImporting, selectedRows } = state;

  const isRowSelectable: IsRowSelectable<ImportedCareOrder> = useCallback(
    row => {
      if (row.data == null) {
        return false;
      }
      return !isImporting && row.data.isSelectable;
    },
    [isImporting]
  );

  // Clear out state when dialog is opened
  useEffect(() => {
    if (props.isOpen) {
      dispatch(careOrderImportSlice.actions.resetState());
    }
  }, [props.isOpen]);

  const dataSubject = useConstant(
    new BehaviorSubject<MinimalSubscriptionResponse<ImportedCareOrder> | undefined>(undefined)
  );
  const dataObservable = useMemo(() => dataSubject.asObservable().pipe(filter(x => x != null)), [dataSubject]);
  const parseFile = useCareOrderImportParser();

  const handleUpload = useCallback(
    async (file: File | undefined) => {
      if (file == null) {
        return;
      }
      try {
        const rows = await parseFile(file);
        dataSubject.next({
          initial: true,
          data: rows,
        });
        dispatch(careOrderImportSlice.actions.uploadFulfilled(rows));
      } catch (e) {
        return addToast({
          text: e instanceof Error ? e.message : 'Invalid file',
          variant: NotificationVariants.Negative,
        });
      }
    },
    [dataSubject, parseFile, addToast]
  );

  const client = useSocketClient();

  const { data } = useGetCareOrdersQuery({ tag: 'CareOrderImportDialog' });

  // Tracks state for a timeout callback, triggered when the UI doesn't complete the
  // import flow within a certain time frame after having clicked the "import X rows" button.
  const importTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  // This effects get run each time we receive new care orders,
  // and looks for the ones we're expecting to receive when clicking the "import X rows" button.
  useEffect(() => {
    if (!isImporting || data == null) {
      return;
    }
    const wantedClOrdIDs = new Set(selectedRows.map(row => row.ClOrdID));
    if (wantedClOrdIDs.size === 0) {
      throw new Error('No orders to import');
    }
    const careOrdersByClOrdID = new Map<string, CareOrder>();
    for (const [, order] of data) {
      if (wantedClOrdIDs.has(order.ClOrdID)) {
        careOrdersByClOrdID.set(order.ClOrdID, order);
      }
    }

    if (careOrdersByClOrdID.size !== selectedRows.length) {
      // Still waiting to recieve _all_ new care orders...
      return;
    }

    const rows: ImportedCareOrder[] = [];
    for (const selectedRow of selectedRows) {
      const careOrder = careOrdersByClOrdID.get(selectedRow.ClOrdID);
      if (careOrder == null) {
        throw new Error(`Missing care order for ClOrdID ${selectedRow.ClOrdID}`);
      }
      rows.push({ ...selectedRow, ...careOrder, isSelectable: false });
    }

    // Update internal state
    dataSubject.next({ initial: false, data: rows });
    dispatch(careOrderImportSlice.actions.importFulfilled());

    // More side effects
    clearTimeout(importTimeoutRef.current);
    addToast({
      text: 'Care orders successfully imported.',
      variant: NotificationVariants.Positive,
    });

    closeDialog();
  }, [data, isImporting, selectedRows, dataSubject, closeDialog, addToast]);

  const handleConfirm = useCallback(async () => {
    dispatch(careOrderImportSlice.actions.importPending());
    for (const row of selectedRows) {
      const request = {
        ...row,
        TransactTime: formattedDateForSubscription(new Date()),
      } as CareNewOrderSingle;
      client.registerPublication({
        type: CARE_NEW_ORDER_SINGLE,
        data: [request],
      });
      importTimeoutRef.current = setTimeout(() => {
        dispatch(careOrderImportSlice.actions.importRejected());
        addToast({
          text: 'Failed to import orders. Please try again.',
          variant: NotificationVariants.Negative,
        });
        logger.error(new Error('Care order import timed out'), { extra: { request } });
      }, IMPORT_TIMEOUT_MS);
    }
  }, [selectedRows, client, addToast]);

  const handleRowSelectionChanged = useCallback(({ api, source }: SelectionChangedEvent<ImportedCareOrder>) => {
    if (!isGridApiReady(api)) {
      return;
    }
    // without this check (Which helps ignore the state change on isImporting changing),
    // the row selection changes and the useEffect throws 'No orders to import'
    // Per @fhqvst, this (or the useEffect related to it) will be reworked
    if (source === 'selectableChanged') {
      return;
    }
    const rows = api.getSelectedRows();
    dispatch(careOrderImportSlice.actions.setSelectedRows(rows));
  }, []);

  const shouldPreselectRow = useCallback((data: ImportedCareOrder) => data.warning == null, []);

  const confirmLabel = useMemo(() => selectConfirmLabel(state), [state]);
  const confirmDisabled = useMemo(() => selectConfirmDisabled(state), [state]);

  return (
    <ImportDialog
      {...props}
      title="Import Care Orders"
      step={step}
      dataObservable={dataObservable}
      columns={PREVIEW_COLUMNS}
      onConfirm={handleConfirm}
      cancelLabel="Cancel"
      confirmDisabled={confirmDisabled}
      confirmLabel={confirmLabel}
      rowID="ClOrdID"
      onUpload={handleUpload}
      onSelectionChanged={handleRowSelectionChanged}
      isRowSelectable={isRowSelectable}
      shouldPreselectRow={shouldPreselectRow}
    />
  );
}

interface CareOrderImportState {
  isImporting: boolean;
  selectedRows: ImportedCareOrder[];
  step: ImportStep;
  confirmLabel: string;
}

const careOrderImportSlice = createSlice({
  name: 'careOrderImport',
  initialState: {
    isImporting: false,
    selectedRows: [],
    step: 'initial',
    confirmLabel: 'Import 0 rows',
  } as CareOrderImportState,
  reducers: {
    resetState(state) {
      state.step = 'initial';
      state = careOrderImportSlice.getInitialState();
    },
    uploadFulfilled(state, action: PayloadAction<ImportedCareOrder[]>) {
      state.selectedRows = action.payload.filter(row => row.warning == null);
      state.step = 'preview';
    },
    importPending(state) {
      state.isImporting = true;
    },
    importFulfilled(state) {
      state.isImporting = false;
      state.selectedRows = [];
    },
    importRejected(state) {
      state.isImporting = false;
    },
    setSelectedRows(state, action) {
      state.selectedRows = action.payload;
    },
  },
});

const selectConfirmLabel = (state: CareOrderImportState) => `Import ${state.selectedRows.length} row(s)`;

const selectConfirmDisabled = (state: CareOrderImportState) =>
  state.step === 'done' || state.isImporting || state.selectedRows.length === 0;
