import { AccordionGroup, VStack } from '@talos/kyoko';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { PM_OVERVIEW_BLOTTER_ID } from '../blotterIdTokens';
import { PortfolioManagementPositionRequestProvider } from '../PortfolioManagementPositionRequestProvider';
import type { BaseBlotterProps } from '../types';
import { PortfolioBreakdownBlotter } from './index';

export function PortfolioBreakdownBlotterWrapper() {
  const wrapperContext: BaseBlotterProps = {
    label: 'Portfolio Overview Blotter',
    id: `${PM_OVERVIEW_BLOTTER_ID}/v1`,
    tag: `${PM_OVERVIEW_BLOTTER_ID}`,
  };

  const { selectedPortfolioId } = usePortfolioViewStateSelector();

  return (
    <PortfolioManagementPositionRequestProvider subTag="portfolioBreakdown-position">
      <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
        <AccordionGroup key={wrapperContext.id}>
          <PortfolioBreakdownBlotter
            blotterID={wrapperContext.id}
            wrapperContext={wrapperContext}
            subAccountId={selectedPortfolioId}
          />
        </AccordionGroup>
      </VStack>
    </PortfolioManagementPositionRequestProvider>
  );
}
