import { MixpanelEvent, useMixpanel } from '@talos/kyoko';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type {
  GenerateOrderDetailsRoute,
  OrderDetailsPath,
  OrderRouteType,
} from '../containers/Trading/Markets/OrderDetails/types';

export function useNavigateToOrderDetails(generateOrderDetailsRoute: GenerateOrderDetailsRoute) {
  const mixpanel = useMixpanel();
  const history = useHistory();
  return useCallback(
    (orderID: string, type: OrderRouteType = 'order', tab: OrderDetailsPath = 'details') => {
      mixpanel.track(MixpanelEvent.OpenOrderDetails);
      history.push(generateOrderDetailsRoute({ orderID, tab, type }));
    },
    [history, mixpanel, generateOrderDetailsRoute]
  );
}
