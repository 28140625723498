import { useCallback, useEffect, useMemo, useRef, type ContextType, type PropsWithChildren } from 'react';

import {
  BlotterTableStorage,
  DefaultFavoriteSecuritiesConfig,
  DefaultFavoriteSecuritiesMethods,
  DefaultWatchlistSettingsConfig,
  DefaultWatchlistSettingsMethods,
  FavoriteSecuritiesContext,
  ROLE,
  RecentSymbolsContext,
  TabsStorage,
  WatchlistSettingsContext,
  useConstant,
  type TabsState,
  type User,
} from '@talos/kyoko';
import { DEFAULT_LAYOUT_ID } from 'components/AppLayout/Layouts/DefaultLayoutId';
import type { IAnalyticsOrderDetailsTab, IAnalyticsReportTab } from 'containers/Analytics/Reports/types';
import type { PortfolioViewLayoutState } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import { isUserRole } from 'hooks/useRoleAuth';
import { get, isEqual, keys, pick } from 'lodash';
import { usePortfolioAppConfigSettings } from 'providers/AppConfigProviderHooks/usePortfolioAppConfigSettings';
import { appConfigSelector, getAppConfigSliceActions } from 'providers/AppConfigReduxProvider/AppConfigSlice';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { BlottersContext } from 'providers/BlottersContext';
import { ContextGuideContext } from 'providers/ContextGuideContext';
import { OrderPresetsContext, type IOrderPreset } from 'providers/OrderPresetsContext';
import { PortfolioSettingsContext } from 'providers/PortfolioSettingContext';
import { useMethods } from 'react-use';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import type { LayoutSpec } from 'types/LayoutConfig';
import { DEFAULT_LAYOUT_SPEC } from '../../components/AppLayout/Layouts/DefaultLayoutSpec';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import type { AppLayoutConfigContextProps } from '../AppLayoutConfig/AppLayoutConfigContext';
import { AppLayoutConfigContext } from '../AppLayoutConfig/AppLayoutConfigContext';
import { DisplaySettingsContext } from '../DisplaySettingsProvider';
import { MarketTabsContext, useInitialMarketTabsState } from '../MarketTabsProvider';
import { OrderEntryContext } from '../OrderEntryContext';
import { SoundSettingsContext } from '../SoundContext';
import { TradingSettingsContext } from '../TradingSettingsContext';
import {
  ANALYTICS_REPORTS_TABS_ID,
  AnalyticsTabsContext,
  useAnalyticsTabsContext,
  type UseAnalyticsTabsContextProps,
} from '../useAnalyticsTabs';
import {
  MonitoringTabsContext,
  useMonitoringTabsContext,
  type MonitoringTab,
  type UseMonitoringTabsContextProps,
} from '../useMonitoringTabs';
import type { AppConfigState } from './AppConfigProvider.types';
import { convertMarketTabsToDockviewLayout } from './convertMarketTabsToDockviewLayout';
import { putAppConfig } from './putAppConfig';

export { useBlotterState } from '../BlottersContext';
export { useDisplaySettings } from '../DisplaySettingsProvider';
export { useMarketTabs } from '../MarketTabsProvider';
export { useOrderEntry } from '../OrderEntryContext';
export { useSoundSettings } from '../SoundContext';
export { useTradingSettings } from '../TradingSettingsContext';

export interface IDDHOrderDefault {
  id: string;
  symbol?: string;
  strategy?: string;
  parameters?: { key: string; value: any }[];
}

const createMethods = (state: AppConfigState) => ({
  // Display settings
  setShowAllInPrices: showAllInPrices => ({ ...state, showAllInPrices }),
  setShowFirmLiquidity: showFirmLiquidity => ({ ...state, showFirmLiquidity }),
  setShowTotalEstimates: showTotalEstimates => ({ ...state, showTotalEstimates }),
  setHomeCurrency: homeCurrency => ({ ...state, homeCurrency }),
  setPinSidebar: pinSidebar => ({ ...state, pinSidebar }),
  setLatestReleaseNotesRead: latestReleaseNotesRead => ({ ...state, latestReleaseNotesRead }),

  // Market tabs
  setMarketTabs: marketTabs => ({ ...state, marketTabs }),

  setSortCards: sortCards => ({ ...state, sortCards }),

  // Trading
  setAllowSyntheticCcy: allowSyntheticCcy => ({ ...state, allowSyntheticCcy }),
  setAllowedSlippage: allowedSlippage => ({ ...state, allowedSlippage }),
  setDefaultSubAccountId: defaultSubAccountId => ({ ...state, defaultSubAccountId }),
  setEnableGroups: enableGroups => ({ ...state, enableGroups }),
  setConfirmOrderCancels: confirmOrderCancels => ({ ...state, confirmOrderCancels }),
  setConfirmOrderResume: confirmOrderResume => ({ ...state, confirmOrderResume }),
  setRememberSubAccountId: rememberSubAccountId => ({ ...state, rememberSubAccountId }),
  setUseTradeAllocations: useTradeAllocations => ({ ...state, useTradeAllocations }),
  setCustomEOD: customEOD => ({ ...state, customEOD }),
  setClickToTradeAll: clickToTradeAll => ({ ...state, clickToTradeAll }),
  setClickToTradeDefaultSubaccount: clickToTradeDefaultSubaccount => ({ ...state, clickToTradeDefaultSubaccount }),
  setAllowSyntheticCrosses: allowSyntheticCrosses => ({ ...state, allowSyntheticCrosses }),
  setAlwaysCheckPriceReasonability: alwaysCheckPriceReasonability => ({
    ...state,
    alwaysCheckPriceReasonability,
  }),
  setEnableCustomerTradeBookingOnCPC: enableCustomerTradeBookingOnCPC => ({
    ...state,
    enableCustomerTradeBookingOnCPC,
  }),
  setDefaultOrderStrategy: orderStrategy => ({ ...state, defaultOrderStrategy: orderStrategy }),
  setDefaultOrderFormTab: orderTab => ({ ...state, defaultOrderFormTab: orderTab }),
  setEnableOldOrderForm: enableOldOrderForm => ({ ...state, enableOldOrderForm }),
  setEnableDerivativeContractDefault: enableDerivativeContractDefault => ({
    ...state,
    enableDerivativeContractDefault,
  }),
  setExclusivelyPrimePriceOnRePriming: exclusivelyPrimePriceOnRePriming => ({
    ...state,
    exclusivelyPrimePriceOnRePriming,
  }),
  setDefaultPriceOffsets: priceOffsets => ({ ...state, defaultPriceOffsets: priceOffsets }),
  setStartTimeEntryMode: startTimeEntryMode => ({ ...state, startTimeEntryMode }),
  setEndTimeEntryMode: endTimeEntryMode => ({ ...state, endTimeEntryMode }),
  setEnableAdvancedOrderDetails: enableAdvancedOrderDetails => ({ ...state, enableAdvancedOrderDetails }),
  setAlwaysShowBestBidOffer: alwaysShowBestBidOffer => ({ ...state, alwaysShowBestBidOffer }),
  setAlwaysShowMarketCardBestBidOffer: alwaysShowMarketCardBestBidOffer => ({
    ...state,
    alwaysShowMarketCardBestBidOffer,
  }),
  setShowOrderFormRollupSelector: showOrderFormRollupSelector => ({ ...state, showOrderFormRollupSelector }),
  setEnableNewMarketSelector: enableNewMarketSelector => ({ ...state, enableNewMarketSelector }),
  setBulkClosePositionQuoteCurrencies: bulkClosePositionQuoteCurrencies => ({
    ...state,
    bulkClosePositionQuoteCurrencies,
  }),
  setEnableReduceFirst: enableReduceFirst => ({ ...state, enableReduceFirst }),
  setEnableReduceOnly: enableReduceOnly => ({ ...state, enableReduceOnly }),

  // Trading -> Customer Pricing
  setEnableCustomerPricing: enableCustomerPricing => ({ ...state, enableCustomerPricing }),
  setEnableSalesWorkflow: enableSalesWorkflow => ({ ...state, enableSalesWorkflow }),
  setLinkCustomerHedgeSubaccount: linkCustomerHedgeSubaccount => ({ ...state, linkCustomerHedgeSubaccount }),
  setEnableCustomerBlotterColumns: enableCustomerBlotterColumns => ({ ...state, enableCustomerBlotterColumns }),
  setCustomerPricingInitialExpanded: customerPricingInitialExpanded => ({ ...state, customerPricingInitialExpanded }),
  setApplyCustomerPricingDetailsToOrderComment: applyCustomerPricingDetailsToOrderComment => ({
    ...state,
    applyCustomerPricingDetailsToOrderComment,
  }),
  setSelectedCustomerID: selectedCustomerID => ({ ...state, selectedCustomerID }),
  // Dealer
  setEnableMonitoringCustomerOrderSummaryColumns: enableMonitoringCustomerOrderSummaryColumns => ({
    ...state,
    enableMonitoringCustomerOrderSummaryColumns,
  }),
  setFilterValueCustomerUsers: filterValueCustomerUsers => ({ ...state, filterValueCustomerUsers }),
  setFilterValueAggregations: filterValueAggregations => ({ ...state, filterValueAggregations }),
  setFilterValueSecurityMaster: filterValueSecurityMaster => ({ ...state, filterValueSecurityMaster }),
  setFilterValueCustomerAddresses: filterValueCustomerAddresses => ({ ...state, filterValueCustomerAddresses }),
  setFilterValueCustomerBalances: filterValueCustomerBalances => ({ ...state, filterValueCustomerBalances }),
  setFilterValueCustomerTradingLimits: filterValueCustomerTradingLimits => ({
    ...state,
    filterValueCustomerTradingLimits,
  }),
  setFilterValueCustomerCredit: filterValueCustomerCredit => ({
    ...state,
    filterValueCustomerCredit,
  }),
  // Principal trading limits
  setFilterValueSubAccountTradingLimits: filterValueSubAccountTradingLimits => ({
    ...state,
    filterValueSubAccountTradingLimits,
  }),
  setFilterValueSubAccountWindowLimits: filterValueSubAccountWindowLimits => ({
    ...state,
    filterValueSubAccountWindowLimits,
  }),

  // Blotters
  setGroupMarketAccounts: groupMarketAccounts => ({ ...state, groupMarketAccounts }),
  setBalancesHiddenSymbols: balancesHiddenSymbols => ({ ...state, balancesHiddenSymbols }),
  setBalancesHiddenMarketAccountIDs: balancesHiddenMarketAccountIDs => ({ ...state, balancesHiddenMarketAccountIDs }),
  setBalancesMarketAccountIDsOrder: balancesMarketAccountIDsOrder => ({ ...state, balancesMarketAccountIDsOrder }),
  setBalancesCurrenciesOrder: balancesCurrenciesOrder => ({ ...state, balancesCurrenciesOrder }),
  setBlotterState: (blotterID, blotterState) => ({
    ...state,
    blotters: { ...state.blotters, [blotterID]: blotterState },
  }),
  clearBlotterState: (blotterID?: string) => {
    const nextState = {
      ...state,
      blotters: { ...state.blotters },
      blotters2: { ...state.blotters2 },
    } as AppConfigState;
    if (blotterID == null) {
      delete nextState.blotters;
      nextState.blotters2 = {};
    } else {
      delete nextState.blotters?.[blotterID];
      delete nextState.blotters2?.[blotterID];
    }
    return nextState;
  },
  setIsExpanded: (isExpanded: boolean) => ({ ...state, isExpanded }),
  setIsMinimized: (isMinimized: boolean) => ({ ...state, isMinimized }),

  setSessionBlotterHeight: (sessionBlotterHeight: number) => ({ ...state, sessionBlotterHeight }),
  setCustomerBalancesShowZeroBalances: (customerBalancesShowZeroBalances: boolean) => ({
    ...state,
    customerBalancesShowZeroBalances,
  }),

  setLastOrderFormUsed: lastOrderFormUsed => ({ ...state, lastOrderFormUsed }),

  // Symbol selector
  setRecentSymbols: recentSymbols => ({ ...state, recentSymbols }),

  // Order entry
  setLastStrategyUsed: lastStrategyUsed => ({ ...state, lastStrategyUsed }),
  setDDHDefaults: (ddhDefaults: IDDHOrderDefault[]) => ({ ...state, ddhDefaults }),

  // Sounds
  setEnableSoundEffects: enableSoundEffects => ({ ...state, enableSoundEffects }),
  setEnabledSounds: enabledSounds => ({ ...state, enabledSounds }),

  // V2-blotters storage
  setBlotterColumnState: (blotterID, columns) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], columns } },
  }),
  setBlotterSortState: (blotterID, sort) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], sort } },
  }),
  setBlotterFilterState: (blotterID, filter) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], filter } },
  }),
  setBlotterRowGroupsOpenedState: (blotterID, rowGroupsOpened) => ({
    ...state,
    blotters2: { ...state.blotters2, [blotterID]: { ...state.blotters2[blotterID], rowGroupsOpened } },
  }),

  // Tabs
  setTabsItems: (tabsID, items) => ({
    ...state,
    tabs: { ...state.tabs, [tabsID]: { ...state.tabs[tabsID], items } },
  }),
  setTabsSelectedIndex: (tabsID, selectedIndex) => ({
    ...state,
    tabs: { ...state.tabs, [tabsID]: { ...state.tabs[tabsID], selectedIndex } },
  }),

  // Watchlist
  setIsVisible: isVisible => ({
    ...state,
    isVisible,
  }),
  setShowOnlyFavorites: showOnlyFavorites => ({
    ...state,
    showOnlyFavorites,
  }),
  setSecurityTypes: securityTypes => ({
    ...state,
    securityTypes,
  }),
  setFilteredCurrencies: filteredCurrencies => ({
    ...state,
    filteredCurrencies,
  }),

  // Favorite securites
  setFavoriteSecurities: favoriteSecurities => ({
    ...state,
    favoriteSecurities,
  }),

  // Order presets
  setOrderPresets: (orderPresets: IOrderPreset[]) => ({
    ...state,
    orderPresetsList: orderPresets,
  }),

  deleteOrderPreset: (id: string) => {
    const orderPresetsList = state.orderPresetsList.filter(preset => preset.id !== id);
    return { ...state, orderPresetsList };
  },

  // Portfolio Management settings
  setEnablePMSLayout: (enablePMSLayout: boolean) => ({ ...state, enablePMSLayout: enablePMSLayout }),
  setTreatStablecoinsAsCash: (treatStablecoinsAsCash: boolean) => ({
    ...state,
    treatStablecoinsAsCash,
  }),
  setPortfolioViewState: (portfolioViewState: PortfolioViewLayoutState['viewState']) => ({
    ...state,
    portfolioViewState,
  }),

  // Context Guide
  setGuide: (guideID, value) => {
    return {
      ...state,
      contextGuide: { ...state.contextGuide, [guideID]: value },
    };
  },

  // Layout config
  setLayoutConfig: (section: string, layoutConfig: LayoutSpec) => {
    return {
      ...state,
      layoutConfigs: {
        ...state.layoutConfigs,
        [section]: {
          ...state.layoutConfigs[section],
          [layoutConfig.id]: layoutConfig,
        },
      },
    };
  },

  setSelectedLayoutId: (section: string, selectedLayoutId: string) => ({
    ...state,
    selectedLayoutIds: { ...state.selectedLayoutIds, [section]: selectedLayoutId },
  }),

  resetLayoutConfig: () => ({
    ...state,
    layoutConfigs: {
      trading: {
        default: structuredClone(DEFAULT_LAYOUT_SPEC),
      },
    },
    selectedLayoutIds: {
      trading: DEFAULT_LAYOUT_ID,
    },
  }),

  reImportTabs: () => {
    return {
      ...state,
      layoutConfigs: {
        ...state.layoutConfigs,
        trading: {
          default: convertMarketTabsToDockviewLayout(state.marketTabs),
        },
      },
      selectedLayoutIds: {
        ...state.selectedLayoutIds,
        trading: DEFAULT_LAYOUT_ID,
      },
    };
  },

  setEnableFlexibleLayout: enableFlexibleLayout => ({ ...state, enableFlexibleLayout }),
});

export type AppConfigStateMethods = ReturnType<typeof createMethods>;
export const AppConfigProvider = function AppConfigProvider({ user, children }: PropsWithChildren<{ user: User }>) {
  const dispatch = useAppStateDispatch();
  const reduxState = useAppStateSelector(appConfigSelector);
  const initialState = useConstant(reduxState);
  const { setConfig } = getAppConfigSliceActions();
  const [state, methods]: [AppConfigState, AppConfigStateMethods] = useMethods<typeof createMethods, AppConfigState>(
    createMethods as any,
    initialState
  ) as [AppConfigState, AppConfigStateMethods];
  const appConfigSubject = useConstant(new BehaviorSubject(initialState));

  useEffect(() => {
    if (state && !isEqual(appConfigSubject.value, state)) {
      dispatch(setConfig(state));
      appConfigSubject.next(state);
    }
  }, [appConfigSubject, dispatch, setConfig, state]);

  useEffect(() => {
    // Skip the first entry as we already initialize the BehaviorSubject with initialState
    const sub = appConfigSubject.pipe(skip(1), debounceTime(300)).subscribe(appConfig => {
      putAppConfig(appConfig);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [appConfigSubject]);

  const enableAdvancedOrderDetails = useMemo((): boolean => {
    // Initial state depends on user roles
    // https://talostrading.atlassian.net/browse/UI-3969
    if (state?.enableAdvancedOrderDetails === undefined) {
      return [ROLE.TALOS_ADMIN, ROLE.ADMIN, ROLE.TALOS_SUPPORT, ROLE.TALOS_VIEWER].some(role => isUserRole(user, role));
    } else {
      return state.enableAdvancedOrderDetails;
    }
  }, [user, state]);

  const { enableFlexibleUI } = useFeatureFlag();

  const {
    marketTabs: _marketTabs,
    saveOrDiscardTemporaryTab,
    prepareDeepDiveMarketTab,
    updateDeepDiveMarketTabData,
    updateDeepDiveMarketTabSymbol,
    updateOrderDetailsMarketTab,
    removeActiveTab,
    prepareReconDetailsMarketTab,
    prepareAccountLedgerEventsDetailsMarketTab,
  } = useInitialMarketTabsState({
    initialMarketTabs: initialState.marketTabs,
    setMarketTabs: methods.setMarketTabs,
    enableFlexibleLayout: initialState.enableFlexibleLayout && enableFlexibleUI,
  });

  const marketTabs = useMemo<ContextType<typeof MarketTabsContext>>(
    () => ({
      ..._marketTabs,
      sortCards: state.sortCards,
      setSortCards: methods.setSortCards,
      saveOrDiscardTemporaryTab,
      prepareDeepDiveMarketTab,
      prepareReconDetailsMarketTab,
      prepareAccountLedgerEventsDetailsMarketTab,
      updateDeepDiveMarketTabData,
      updateDeepDiveMarketTabSymbol,
      updateOrderDetailsMarketTab,
      removeActiveTab,
    }),
    [
      _marketTabs,
      state.sortCards,
      saveOrDiscardTemporaryTab,
      methods.setSortCards,
      prepareDeepDiveMarketTab,
      prepareReconDetailsMarketTab,
      prepareAccountLedgerEventsDetailsMarketTab,
      updateDeepDiveMarketTabData,
      updateDeepDiveMarketTabSymbol,
      updateOrderDetailsMarketTab,
      removeActiveTab,
    ]
  );

  const recentSymbols = useMemo<ContextType<typeof RecentSymbolsContext>>(
    () => ({
      recentSymbols: state.recentSymbols,
      setRecentSymbols: methods.setRecentSymbols,
    }),
    [state.recentSymbols, methods.setRecentSymbols]
  );

  const display = useMemo<ContextType<typeof DisplaySettingsContext>>(
    () => ({
      showAllInPrices: state.showAllInPrices,
      showFirmLiquidity: state.showFirmLiquidity,
      showTotalEstimates: state.showTotalEstimates,
      homeCurrency: state.homeCurrency,
      customEOD: state.customEOD,
      pinSidebar: state.pinSidebar,
      latestReleaseNotesRead: state.latestReleaseNotesRead,
      setShowAllInPrices: methods.setShowAllInPrices,
      setShowFirmLiquidity: methods.setShowFirmLiquidity,
      setShowTotalEstimates: methods.setShowTotalEstimates,
      setHomeCurrency: methods.setHomeCurrency,
      setCustomEOD: methods.setCustomEOD,
      setPinSidebar: methods.setPinSidebar,
      setLatestReleaseNotesRead: methods.setLatestReleaseNotesRead,
    }),
    [
      state.showAllInPrices,
      state.showFirmLiquidity,
      state.showTotalEstimates,
      state.homeCurrency,
      state.customEOD,
      state.pinSidebar,
      state.latestReleaseNotesRead,
      methods.setShowAllInPrices,
      methods.setShowFirmLiquidity,
      methods.setShowTotalEstimates,
      methods.setHomeCurrency,
      methods.setCustomEOD,
      methods.setPinSidebar,
      methods.setLatestReleaseNotesRead,
    ]
  );

  const trading = useMemo<ContextType<typeof TradingSettingsContext>>(
    () => ({
      allowSyntheticCcy: state.allowSyntheticCcy,
      setAllowSyntheticCcy: methods.setAllowSyntheticCcy,
      allowedSlippage: state.allowedSlippage,
      setAllowedSlippage: methods.setAllowedSlippage,
      defaultSubAccountId: state.defaultSubAccountId,
      setDefaultSubAccountId: methods.setDefaultSubAccountId,
      enableGroups: state.enableGroups,
      confirmOrderCancels: state.confirmOrderCancels,
      confirmOrderResume: state.confirmOrderResume,
      allowSyntheticCrosses: state.allowSyntheticCrosses,
      setEnableGroups: methods.setEnableGroups,
      rememberSubAccountId: state.rememberSubAccountId,
      setRememberSubAccountId: methods.setRememberSubAccountId,
      useTradeAllocations: state.useTradeAllocations,
      setUseTradeAllocations: methods.setUseTradeAllocations,
      clickToTradeAll: state.clickToTradeAll,
      setClickToTradeAll: methods.setClickToTradeAll,
      clickToTradeDefaultSubaccount: state.clickToTradeDefaultSubaccount,
      setClickToTradeDefaultSubaccount: methods.setClickToTradeDefaultSubaccount,
      setAllowSyntheticCrosses: methods.setAllowSyntheticCrosses,
      setConfirmOrderCancels: methods.setConfirmOrderCancels,
      setConfirmOrderResume: methods.setConfirmOrderResume,
      enableCustomerPricing: state.enableCustomerPricing,
      setEnableCustomerPricing: methods.setEnableCustomerPricing,
      enableSalesWorkflow: state.enableSalesWorkflow,
      setEnableSalesWorkflow: methods.setEnableSalesWorkflow,
      linkCustomerHedgeSubaccount: state.linkCustomerHedgeSubaccount,
      setLinkCustomerHedgeSubaccount: methods.setLinkCustomerHedgeSubaccount,
      enableDerivativeContractDefault: state.enableDerivativeContractDefault,
      exclusivelyPrimePriceOnRePriming: state.exclusivelyPrimePriceOnRePriming,
      setEnableOldOrderForm: methods.setEnableOldOrderForm,
      setEnableDerivativeContractDefault: methods.setEnableDerivativeContractDefault,
      setExclusivelyPrimePriceOnRePriming: methods.setExclusivelyPrimePriceOnRePriming,
      enableCustomerBlotterColumns: state.enableCustomerBlotterColumns,
      setEnableCustomerBlotterColumns: methods.setEnableCustomerBlotterColumns,
      customerPricingInitialExpanded: state.customerPricingInitialExpanded,
      setCustomerPricingInitialExpanded: methods.setCustomerPricingInitialExpanded,
      applyCustomerPricingDetailsToOrderComment: state.applyCustomerPricingDetailsToOrderComment,
      setApplyCustomerPricingDetailsToOrderComment: methods.setApplyCustomerPricingDetailsToOrderComment,
      selectedCustomerID: state.selectedCustomerID,
      setSelectedCustomerID: methods.setSelectedCustomerID,
      alwaysCheckPriceReasonability: state.alwaysCheckPriceReasonability,
      setAlwaysCheckPriceReasonability: methods.setAlwaysCheckPriceReasonability,
      enableCustomerTradeBookingOnCPC: state.enableCustomerTradeBookingOnCPC,
      setEnableCustomerTradeBookingOnCPC: methods.setEnableCustomerTradeBookingOnCPC,
      defaultOrderStrategy: state.defaultOrderStrategy,
      setDefaultOrderStrategy: methods.setDefaultOrderStrategy,
      defaultOrderFormTab: state.defaultOrderFormTab,
      setDefaultOrderFormTab: methods.setDefaultOrderFormTab,
      defaultPriceOffsets: state.defaultPriceOffsets,
      setDefaultPriceOffsets: methods.setDefaultPriceOffsets,
      startTimeEntryMode: state.startTimeEntryMode,
      setStartTimeEntryMode: methods.setStartTimeEntryMode,
      endTimeEntryMode: state.endTimeEntryMode,
      setEndTimeEntryMode: methods.setEndTimeEntryMode,
      enableAdvancedOrderDetails,
      setEnableAdvancedOrderDetails: methods.setEnableAdvancedOrderDetails,
      alwaysShowBestBidOffer: state.alwaysShowBestBidOffer,
      setAlwaysShowBestBidOffer: methods.setAlwaysShowBestBidOffer,
      alwaysShowMarketCardBestBidOffer: state.alwaysShowMarketCardBestBidOffer,
      setAlwaysShowMarketCardBestBidOffer: methods.setAlwaysShowMarketCardBestBidOffer,
      showOrderFormRollupSelector: state.showOrderFormRollupSelector,
      setShowOrderFormRollupSelector: methods.setShowOrderFormRollupSelector,
      enableNewMarketSelector: state.enableNewMarketSelector,
      setEnableNewMarketSelector: methods.setEnableNewMarketSelector,
      bulkClosePositionQuoteCurrencies: state.bulkClosePositionQuoteCurrencies,
      setBulkClosePositionQuoteCurrencies: methods.setBulkClosePositionQuoteCurrencies,
      enableReduceFirst: state.enableReduceFirst,
      setEnableReduceFirst: methods.setEnableReduceFirst,
      enableReduceOnly: state.enableReduceOnly,
      setEnableReduceOnly: methods.setEnableReduceOnly,
    }),
    [
      state.allowSyntheticCcy,
      state.allowedSlippage,
      state.defaultSubAccountId,
      state.enableGroups,
      state.confirmOrderCancels,
      state.confirmOrderResume,
      state.allowSyntheticCrosses,
      state.rememberSubAccountId,
      state.useTradeAllocations,
      state.clickToTradeAll,
      state.clickToTradeDefaultSubaccount,
      state.enableCustomerPricing,
      state.enableSalesWorkflow,
      state.linkCustomerHedgeSubaccount,
      state.enableDerivativeContractDefault,
      state.exclusivelyPrimePriceOnRePriming,
      state.enableCustomerBlotterColumns,
      state.customerPricingInitialExpanded,
      state.applyCustomerPricingDetailsToOrderComment,
      state.selectedCustomerID,
      state.alwaysCheckPriceReasonability,
      state.enableCustomerTradeBookingOnCPC,
      state.defaultOrderStrategy,
      state.defaultOrderFormTab,
      state.defaultPriceOffsets,
      state.startTimeEntryMode,
      state.endTimeEntryMode,
      enableAdvancedOrderDetails,
      state.alwaysShowBestBidOffer,
      state.alwaysShowMarketCardBestBidOffer,
      state.showOrderFormRollupSelector,
      state.enableNewMarketSelector,
      state.bulkClosePositionQuoteCurrencies,
      state.enableReduceFirst,
      state.enableReduceOnly,
      methods.setAllowSyntheticCcy,
      methods.setAllowedSlippage,
      methods.setDefaultSubAccountId,
      methods.setEnableGroups,
      methods.setRememberSubAccountId,
      methods.setUseTradeAllocations,
      methods.setClickToTradeAll,
      methods.setClickToTradeDefaultSubaccount,
      methods.setAllowSyntheticCrosses,
      methods.setConfirmOrderCancels,
      methods.setConfirmOrderResume,
      methods.setEnableCustomerPricing,
      methods.setLinkCustomerHedgeSubaccount,
      methods.setEnableSalesWorkflow,
      methods.setEnableOldOrderForm,
      methods.setEnableDerivativeContractDefault,
      methods.setExclusivelyPrimePriceOnRePriming,
      methods.setEnableCustomerBlotterColumns,
      methods.setCustomerPricingInitialExpanded,
      methods.setApplyCustomerPricingDetailsToOrderComment,
      methods.setSelectedCustomerID,
      methods.setAlwaysCheckPriceReasonability,
      methods.setEnableCustomerTradeBookingOnCPC,
      methods.setDefaultOrderStrategy,
      methods.setDefaultOrderFormTab,
      methods.setDefaultPriceOffsets,
      methods.setStartTimeEntryMode,
      methods.setEndTimeEntryMode,
      methods.setEnableAdvancedOrderDetails,
      methods.setAlwaysShowBestBidOffer,
      methods.setAlwaysShowMarketCardBestBidOffer,
      methods.setShowOrderFormRollupSelector,
      methods.setEnableNewMarketSelector,
      methods.setBulkClosePositionQuoteCurrencies,
      methods.setEnableReduceFirst,
      methods.setEnableReduceOnly,
    ]
  );

  const blotters = useMemo<ContextType<typeof BlottersContext>>(
    () => ({
      blotters: state.blotters,
      setBlotterState: methods.setBlotterState,
      clearBlotterState: methods.clearBlotterState,
      groupMarketAccounts: state.groupMarketAccounts,
      setGroupMarketAccounts: methods.setGroupMarketAccounts,
      balancesHiddenSymbols: state.balancesHiddenSymbols,
      setBalancesHiddenSymbols: methods.setBalancesHiddenSymbols,
      balancesHiddenMarketAccountIDs: state.balancesHiddenMarketAccountIDs,
      setBalancesHiddenMarketAccountIDs: methods.setBalancesHiddenMarketAccountIDs,
      balancesMarketAccountIDsOrder: state.balancesMarketAccountIDsOrder,
      setBalancesMarketAccountIDsOrder: methods.setBalancesMarketAccountIDsOrder,
      balancesCurrenciesOrder: state.balancesCurrenciesOrder,
      setBalancesCurrenciesOrder: methods.setBalancesCurrenciesOrder,
      filterValueCustomerUsers: state.filterValueCustomerUsers,
      enableMonitoringCustomerOrderSummaryColumns: state.enableMonitoringCustomerOrderSummaryColumns,
      setEnableMonitoringCustomerOrderSummaryColumns: methods.setEnableMonitoringCustomerOrderSummaryColumns,
      setFilterValueCustomerUsers: methods.setFilterValueCustomerUsers,
      filterValueAggregations: state.filterValueAggregations,
      setFilterValueAggregations: methods.setFilterValueAggregations,
      filterValueSecurityMaster: state.filterValueSecurityMaster,
      setFilterValueSecurityMaster: methods.setFilterValueSecurityMaster,
      filterValueCustomerAddresses: state.filterValueCustomerAddresses,
      setFilterValueCustomerAddresses: methods.setFilterValueCustomerAddresses,
      filterValueCustomerBalances: state.filterValueCustomerBalances,
      setFilterValueCustomerBalances: methods.setFilterValueCustomerBalances,
      filterValueCustomerTradingLimits: state.filterValueCustomerTradingLimits,
      setFilterValueCustomerTradingLimits: methods.setFilterValueCustomerTradingLimits,
      filterValueCustomerCredit: state.filterValueCustomerCredit,
      setFilterValueCustomerCredit: methods.setFilterValueCustomerCredit,
      filterValueSubAccountTradingLimits: state.filterValueSubAccountTradingLimits,
      setFilterValueSubAccountTradingLimits: methods.setFilterValueSubAccountTradingLimits,
      filterValueSubAccountWindowLimits: state.filterValueSubAccountWindowLimits,
      setFilterValueSubAccountWindowLimits: methods.setFilterValueSubAccountWindowLimits,
      isExpanded: state.isExpanded,
      setIsExpanded: methods.setIsExpanded,
      isMinimized: state.isMinimized,
      setIsMinimized: methods.setIsMinimized,
      sessionBlotterHeight: state.sessionBlotterHeight,
      setSessionBlotterHeight: methods.setSessionBlotterHeight,
      customerBalancesShowZeroBalances: state.customerBalancesShowZeroBalances,
      setCustomerBalancesShowZeroBalances: methods.setCustomerBalancesShowZeroBalances,
    }),
    [
      state.blotters,
      state.groupMarketAccounts,
      state.balancesHiddenSymbols,
      state.balancesHiddenMarketAccountIDs,
      state.balancesMarketAccountIDsOrder,
      state.balancesCurrenciesOrder,
      state.filterValueCustomerUsers,
      state.enableMonitoringCustomerOrderSummaryColumns,
      state.filterValueAggregations,
      state.filterValueSecurityMaster,
      state.filterValueCustomerAddresses,
      state.filterValueCustomerBalances,
      state.filterValueCustomerTradingLimits,
      state.filterValueCustomerCredit,
      state.filterValueSubAccountTradingLimits,
      state.filterValueSubAccountWindowLimits,
      state.isExpanded,
      state.isMinimized,
      state.sessionBlotterHeight,
      state.customerBalancesShowZeroBalances,
      methods.setBlotterState,
      methods.clearBlotterState,
      methods.setGroupMarketAccounts,
      methods.setBalancesHiddenSymbols,
      methods.setBalancesHiddenMarketAccountIDs,
      methods.setBalancesMarketAccountIDsOrder,
      methods.setBalancesCurrenciesOrder,
      methods.setEnableMonitoringCustomerOrderSummaryColumns,
      methods.setFilterValueCustomerUsers,
      methods.setFilterValueAggregations,
      methods.setFilterValueSecurityMaster,
      methods.setFilterValueCustomerAddresses,
      methods.setFilterValueCustomerBalances,
      methods.setFilterValueCustomerTradingLimits,
      methods.setFilterValueCustomerCredit,
      methods.setFilterValueSubAccountTradingLimits,
      methods.setFilterValueSubAccountWindowLimits,
      methods.setIsExpanded,
      methods.setIsMinimized,
      methods.setSessionBlotterHeight,
      methods.setCustomerBalancesShowZeroBalances,
    ]
  );

  const orderEntry = useMemo<ContextType<typeof OrderEntryContext>>(
    () => ({
      lastStrategyUsed: state.lastStrategyUsed,
      setLastStrategyUsed: methods.setLastStrategyUsed,
      lastOrderFormUsed: state.lastOrderFormUsed,
      setLastOrderFormUsed: methods.setLastOrderFormUsed,
      ddhDefaults: state.ddhDefaults,
      ddhDefaultsByID: new Map(state.ddhDefaults.map(item => [item.id, item])),
      saveDDHDefaults: (ddhDefault: IDDHOrderDefault) => {
        const ddhDefaults = state.ddhDefaults ? [...state.ddhDefaults] : [];
        const foundIndex = ddhDefaults.findIndex(item => item.id === ddhDefault.id);
        if (foundIndex > -1) {
          ddhDefaults[foundIndex] = ddhDefault;
        } else {
          ddhDefaults.push(ddhDefault);
        }
        methods.setDDHDefaults(ddhDefaults);
      },
    }),
    [state.lastStrategyUsed, state.lastOrderFormUsed, state.ddhDefaults, methods]
  );

  const sounds = useMemo<ContextType<typeof SoundSettingsContext>>(
    () => ({
      enableSoundEffects: state.enableSoundEffects,
      enabledSounds: state.enabledSounds,
      setEnableSoundEffects: methods.setEnableSoundEffects,
      setEnabledSounds: methods.setEnabledSounds,
    }),
    [state.enableSoundEffects, state.enabledSounds, methods.setEnableSoundEffects, methods.setEnabledSounds]
  );

  const watchlist = useMemo<ContextType<typeof WatchlistSettingsContext>>(
    () => ({
      ...(pick(state, keys(DefaultWatchlistSettingsConfig)) as typeof DefaultWatchlistSettingsConfig),
      ...(pick(methods, keys(DefaultWatchlistSettingsMethods)) as typeof DefaultWatchlistSettingsMethods),
    }),
    [methods, state]
  );

  const favoriteSecurities = useMemo<ContextType<typeof FavoriteSecuritiesContext>>(
    () => ({
      ...(pick(state, keys(DefaultFavoriteSecuritiesConfig)) as typeof DefaultFavoriteSecuritiesConfig),
      ...(pick(methods, keys(DefaultFavoriteSecuritiesMethods)) as typeof DefaultFavoriteSecuritiesMethods),
    }),
    [methods, state]
  );

  // Not a big fan of this
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const getBlotterState = useCallback(key => stateRef.current.blotters2[key], []);
  const blotterStorage = useMemo<ContextType<typeof BlotterTableStorage>>(
    () => ({
      getState: getBlotterState,
      setColumnState: methods.setBlotterColumnState,
      setSortState: methods.setBlotterSortState,
      setFilterState: methods.setBlotterFilterState,
      setRowGroupsOpenedState: methods.setBlotterRowGroupsOpenedState,
    }),
    [
      getBlotterState,
      methods.setBlotterColumnState,
      methods.setBlotterSortState,
      methods.setBlotterFilterState,
      methods.setBlotterRowGroupsOpenedState,
    ]
  );

  const getTabsState = useCallback(key => state.tabs[key], [state.tabs]);
  const tabsStorage = useMemo<ContextType<typeof TabsStorage>>(
    () => ({
      getState: getTabsState,
      setSelectedIndex: methods.setTabsSelectedIndex,
      setItems: methods.setTabsItems,
    }),
    [getTabsState, methods.setTabsItems, methods.setTabsSelectedIndex]
  );

  const monitoringTabsProps = useMemo<UseMonitoringTabsContextProps>(() => {
    const tabId = 'monitoring-blotters';
    const saveState = getTabsState(tabId) as TabsState<MonitoringTab>;
    return {
      initialTabs: saveState?.items ?? [],
      initialSelectedIndex: saveState?.selectedIndex ?? 0,
      saveTabs: newItems => {
        methods.setTabsItems(tabId, newItems);
      },
    };
  }, [getTabsState, methods]);
  const monitoringTabs = useMonitoringTabsContext(monitoringTabsProps);

  const analyticsTabsProps = useMemo(() => {
    const saveState = getTabsState(ANALYTICS_REPORTS_TABS_ID) as TabsState<
      IAnalyticsReportTab | IAnalyticsOrderDetailsTab
    >;
    return {
      initialTabs: saveState?.items ?? [],
      initialSelectedIndex: saveState?.selectedIndex ?? 0,
      saveTabs: newItems => {
        methods.setTabsItems(ANALYTICS_REPORTS_TABS_ID, newItems);
      },
    } satisfies UseAnalyticsTabsContextProps;
  }, [getTabsState, methods]);
  const analyticsTabs = useAnalyticsTabsContext(analyticsTabsProps);

  const orderPresets = useMemo<ContextType<typeof OrderPresetsContext>>(
    () => ({
      orderPresetsList: state.orderPresetsList,
      orderPresetsByID: new Map(state.orderPresetsList.map(p => [p.id, p])),
      createOrderPreset: (preset: IOrderPreset) => {
        methods.setOrderPresets([...state.orderPresetsList, preset].sort((a, b) => a.name.localeCompare(b.name)));
      },
      updateOrderPreset: (id: string, preset: Omit<IOrderPreset, 'id'>) => {
        methods.setOrderPresets(state.orderPresetsList.map(p => (p.id === id ? { id: p.id, ...preset } : p)));
      },
      deleteOrderPreset: (id: string) => {
        methods.deleteOrderPreset(id);
      },
    }),
    [state.orderPresetsList, methods]
  );

  const portfolioSettings = usePortfolioAppConfigSettings(state, methods);

  const contextGuide = useMemo<ContextType<typeof ContextGuideContext>>(
    () => ({
      contextGuide: state.contextGuide,
      setGuide: methods.setGuide,
      getGuide: (guideID: string) => get(state.contextGuide, guideID, false),
    }),
    [state, methods]
  );

  const layoutConfig: AppLayoutConfigContextProps = useMemo(() => {
    return {
      setSelectedLayoutId: methods.setSelectedLayoutId,
      selectedLayoutIds: state.selectedLayoutIds,
      setLayoutConfig: methods.setLayoutConfig,
      layoutConfigs: state.layoutConfigs,
      enableFlexibleLayout: state.enableFlexibleLayout,
      setEnableFlexibleLayout: methods.setEnableFlexibleLayout,
      resetLayoutConfig: methods.resetLayoutConfig,
      reImportTabs: methods.reImportTabs,
    };
  }, [
    state.layoutConfigs,
    state.selectedLayoutIds,
    methods.setLayoutConfig,
    state.enableFlexibleLayout,
    methods.setEnableFlexibleLayout,
    methods.resetLayoutConfig,
    methods.reImportTabs,
    methods.setSelectedLayoutId,
  ]);

  if (
    recentSymbols == null ||
    display == null ||
    marketTabs == null ||
    trading == null ||
    blotters == null ||
    orderEntry == null ||
    blotterStorage == null ||
    tabsStorage == null ||
    orderPresets == null ||
    portfolioSettings == null
  ) {
    return null;
  }

  return (
    <AppLayoutConfigContext.Provider value={layoutConfig}>
      <BlotterTableStorage.Provider value={blotterStorage}>
        <OrderEntryContext.Provider value={orderEntry}>
          <RecentSymbolsContext.Provider value={recentSymbols}>
            <DisplaySettingsContext.Provider value={display}>
              <MarketTabsContext.Provider value={marketTabs}>
                <TradingSettingsContext.Provider value={trading}>
                  <SoundSettingsContext.Provider value={sounds}>
                    <BlottersContext.Provider value={blotters}>
                      <TabsStorage.Provider value={tabsStorage}>
                        <WatchlistSettingsContext.Provider value={watchlist}>
                          <FavoriteSecuritiesContext.Provider value={favoriteSecurities}>
                            <MonitoringTabsContext.Provider value={monitoringTabs}>
                              <AnalyticsTabsContext.Provider value={analyticsTabs}>
                                <OrderPresetsContext.Provider value={orderPresets}>
                                  <PortfolioSettingsContext.Provider value={portfolioSettings}>
                                    <ContextGuideContext.Provider value={contextGuide}>
                                      {children}
                                    </ContextGuideContext.Provider>
                                  </PortfolioSettingsContext.Provider>
                                </OrderPresetsContext.Provider>
                              </AnalyticsTabsContext.Provider>
                            </MonitoringTabsContext.Provider>
                          </FavoriteSecuritiesContext.Provider>
                        </WatchlistSettingsContext.Provider>
                      </TabsStorage.Provider>
                    </BlottersContext.Provider>
                  </SoundSettingsContext.Provider>
                </TradingSettingsContext.Provider>
              </MarketTabsContext.Provider>
            </DisplaySettingsContext.Provider>
          </RecentSymbolsContext.Provider>
        </OrderEntryContext.Provider>
      </BlotterTableStorage.Provider>
    </AppLayoutConfigContext.Provider>
  );
};
