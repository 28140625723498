import { Button, VStack } from '@talos/kyoko';
import { useCallback } from 'react';
import { useAppLayoutContext } from '../../providers/AppLayoutContextProvider/AppLayoutContextProvider';

export function EmptyGrid() {
  const { resetLayoutAndTabs } = useAppLayoutContext();
  const handleReset = useCallback(() => {
    try {
      resetLayoutAndTabs();
    } catch (e) {
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [resetLayoutAndTabs]);

  return (
    <VStack h="100%" w="100%">
      <p>An unexpected error occurred with the layout.</p>
      <p>
        If this issue persists, please get in touch with Talos support and we will resolve the problem as soon as
        possible.
      </p>
      <Button onClick={handleReset}>Reset layout</Button>
      <p>
        <a href="mailto:support@talos.com">support@talos.com</a>
      </p>
      <p>{import.meta.env.VITE_SUPPORT_PHONE}</p>
    </VStack>
  );
}
