import { AccordionGroup, Box } from '@talos/kyoko';
import { BlotterContainer } from '../../BlotterContainer';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import { PM_PERFORMANCE_BLOTTER_ID } from '../blotterIdTokens';
import { PortfolioManagementPositionRequestProvider } from '../PortfolioManagementPositionRequestProvider';
import type { BaseBlotterProps } from '../types';
import { PortfolioPerformanceBlotter } from './index';

export function PortfolioPerformanceBlotterWrapper() {
  const wrapperContext: BaseBlotterProps = {
    label: 'Portfolio Performance Blotter',
    id: `${PM_PERFORMANCE_BLOTTER_ID}/v1`,
    tag: `${PM_PERFORMANCE_BLOTTER_ID}`,
  };
  const { selectedPortfolioId } = usePortfolioViewStateSelector();

  return (
    <Box w="100%" h="100%" data-testid="portfolio-performance-blotter-wrapper">
      <PortfolioManagementPositionRequestProvider subTag="portfolioPerformance-position">
        <BlotterContainer>
          <AccordionGroup key={wrapperContext.id}>
            <PortfolioPerformanceBlotter
              blotterID={wrapperContext.id}
              wrapperContext={wrapperContext}
              subAccountId={selectedPortfolioId}
            />
          </AccordionGroup>
        </BlotterContainer>
      </PortfolioManagementPositionRequestProvider>
    </Box>
  );
}
