import { useCallback, useInsertionEffect, useRef } from 'react';

/**
 * Imported from Chakra-UI: https://github.com/chakra-ui/chakra-ui/blob/14d022046ebd2e6acfb7996c1bdeae18d65a4330/packages/react/src/hooks/use-callback-ref.ts
 * - This is a more idiomatic React-ish way of useDynamicCallback, as it uses `useInsertionEffect` to update the ref, which performs
 * the useEffect as part of the render phase, before any other effects can fire and use it
 *
 * This hook is user-land implementation of the experimental `useEffectEvent` hook.
 * React docs: https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 */
export function useCallbackRef<Args extends unknown[], Return>(
  callback: ((...args: Args) => Return) | undefined,
  deps: React.DependencyList = []
) {
  const callbackRef = useRef<typeof callback>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useInsertionEffect(() => {
    callbackRef.current = callback;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args: Args) => callbackRef.current?.(...args), deps);
}
