import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  CareExecutionReport,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useCareExecutionReportColumns,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterTableFilter,
  type Column,
  type ColumnState,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useMemo } from 'react';

import { DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS } from '@talos/kyoko';
import type { GetMainMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useCareExecutionReportsFilter,
  type CareExecutionReportsTableFilter,
} from './useCareExecutionReportsFilter';
import { useCareExecutionReportsMenu } from './useCareExecutionReportsMenu';

export interface FilteredCareExecutionReportsParams {
  blotterID: string;
  tabLabel?: string;
  defaultColumns?: Column[];
  defaultFilter?: CareExecutionReportsTableFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  onRowDoubleClicked?: (params: RowDoubleClickedEvent) => void;
}

export function CareExecutionReportsBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
}: FilteredCareExecutionReportsParams) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const defaultColumnDefinitions = useCareExecutionReportColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<CareExecutionReport>(blotterID, {
    columns: defaultColumnDefinitions,
    filter: defaultFilter,
    sort: '-Timestamp',
  });

  const filterResults = useCareExecutionReportsFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const menu = useCareExecutionReportsMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...menu.columns],
    [menu.columns, persistedBlotterTable.columns]
  );

  const blotterTable = useWsBlotterTable<WebsocketRequest, CareExecutionReport>({
    initialRequest: {
      tag: blotterID,
      name: 'CareExecutionReport',
      sort_by: '-Timestamp',
    },
    rowID: CareExecutionReport.rowID,
    initialSort: persistedBlotterTable.initialSort,
    filter: onlyServerFilterKeys(filterResults.filter),
    clientLocalFilter,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: columnsWithMenu,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    getExtraMainMenuItems,
    gridOptions: {
      onRowDoubleClicked,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: menu.getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'CareExecutionReports',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.Timestamp} />
      <BlotterTable {...blotterTable} />
      {menu.dialogs}
    </>
  );
}
