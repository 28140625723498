import { useAppLayoutSection } from 'components/AppLayout/hooks/useAppLayoutSection';
import { createContext, useCallback, useContext } from 'react';
import type { LayoutSpec, Section } from 'types/LayoutConfig';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export interface AppLayoutConfigContextProps {
  selectedLayoutIds: Record<Section, string>;
  layoutConfigs: Record<Section, Record<string, LayoutSpec>>;
  setLayoutConfig: (section: string, value: LayoutSpec) => void;
  setSelectedLayoutId: (section: string, value: string) => void;
  enableFlexibleLayout: boolean;
  setEnableFlexibleLayout: (value: boolean) => void;
  resetLayoutConfig: () => void;
  reImportTabs: () => void;
}

export const DefaultLayoutsConfig: Omit<
  AppLayoutConfigContextProps,
  'setLayoutConfig' | 'setEnableFlexibleLayout' | 'resetLayoutConfig' | 'reImportTabs' | 'setSelectedLayoutId'
> = {
  layoutConfigs: {
    trading: {},
  },
  selectedLayoutIds: {
    trading: 'default',
  },
  enableFlexibleLayout: true,
};

/**
 * Provide method for updating AppConfig related to the AppLayout
 */
export const AppLayoutConfigContext = createContext<AppLayoutConfigContextProps>({
  layoutConfigs: DefaultLayoutsConfig.layoutConfigs,
  selectedLayoutIds: DefaultLayoutsConfig.selectedLayoutIds,
  setSelectedLayoutId: () => {},
  setLayoutConfig: () => {},
  setEnableFlexibleLayout: () => {},
  enableFlexibleLayout: false,
  resetLayoutConfig: () => {},
  reImportTabs: () => {},
});
AppLayoutConfigContext.displayName = 'LayoutConfigContext';

export const useAppLayoutConfig = () => {
  const { enableFlexibleUI } = useFeatureFlag();
  const layoutSection = useAppLayoutSection();
  const {
    layoutConfigs,
    setLayoutConfig,
    selectedLayoutIds,
    setSelectedLayoutId,
    setEnableFlexibleLayout,
    enableFlexibleLayout,
    resetLayoutConfig,
    reImportTabs,
  } = useContext(AppLayoutConfigContext);

  const updateLayoutConfig = useCallback(
    (layoutConfig: Partial<LayoutSpec> & Pick<LayoutSpec, 'id'>) => {
      const prevConfig = layoutConfigs[layoutSection]?.[layoutConfig.id] ?? {};
      const newConfig = { ...prevConfig, ...layoutConfig };
      setLayoutConfig(layoutSection, newConfig);
    },
    [setLayoutConfig, layoutSection, layoutConfigs]
  );

  const selectedLayoutId = selectedLayoutIds[layoutSection];

  const updateSelectedLayoutId = useCallback(
    (id: string) => {
      setSelectedLayoutId(layoutSection, id);
    },
    [layoutSection, setSelectedLayoutId]
  );

  return {
    selectedLayoutId,
    layoutSection,
    layoutConfigs: layoutConfigs[layoutSection],
    layoutConfig: layoutConfigs[layoutSection]?.[selectedLayoutId],
    updateLayoutConfig,
    updateSelectedLayoutId,
    setEnableFlexibleLayout,
    enableFlexibleLayout: enableFlexibleUI && enableFlexibleLayout,
    resetLayoutConfig,
    reImportTabs,
  };
};
