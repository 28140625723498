import {
  ACTION,
  API_PERMISSION_WILDCARD,
  Accordion,
  AccordionBody,
  ApiPermissionActionEnum,
  BlotterDensity,
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  Divider,
  EMPTY_ARRAY,
  FilterBuilder,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  IconName,
  IndicatorBadge,
  IndicatorBadgeSizes,
  IndicatorDot,
  IndicatorDotVariants,
  LoaderSizes,
  LocalFilterInput,
  MixpanelEvent,
  MixpanelEventProperty,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Toggle,
  createCSVFileName,
  removeEmptyFilters,
  useAccordion,
  useBlotterTable,
  useDrawer,
  useDynamicCallback,
  useFilterBuilder,
  useMixpanel,
  useObservable,
  useUserContext,
  withAccordionGroup,
  type FilterableProperty,
  type Organization,
  type ROLE,
  type User,
} from '@talos/kyoko';
import { Loader, LoaderWrapper } from 'components/Loader';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS } from '@talos/kyoko';
import { isEqual } from 'lodash';
import { useSubAccountPermissionFilters, useSubAccounts, useUsersContext } from 'providers';
import { useEffectOnce, usePrevious } from 'react-use';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { prettifyRole } from 'tokens/roles';
import { useRoleAuth } from '../../../hooks';
import { EditUserDrawer } from './EditUserDrawer';
import { NewUserDrawer } from './NewUserDrawer';
import { useUsersBlotterTableMenu } from './UsersBlotterTableMenu';
import { useColumns } from './useColumns';
import { useUserSettings } from './useUserSettings';
import { useUserFilter } from './useUsersFilter';

export const Users = withAccordionGroup(function Users() {
  const { users, userByID, refetchUsers } = useUsersContext();
  const { isAuthorized } = useRoleAuth();
  const { viewableRoles } = useUserSettings();
  const mixpanel = useMixpanel();
  const { filterIDsByUserName, subAccountPermissionFiltersByFilterID } = useSubAccountPermissionFilters();
  const { subAccountsByName } = useSubAccounts();
  const { getOrganization } = useUserContext();
  const [organization, setOrganization] = useState<Organization | undefined>();
  const [selectedUserID, setSelectedUserID] = useState<string | undefined>(undefined);
  const selectedUser = useMemo(
    () => (selectedUserID ? userByID.get(selectedUserID) : undefined),
    [userByID, selectedUserID]
  );

  const [userNames, setUserNames] = useState<Set<string>>(new Set(users.map(u => u.Name)));
  useEffect(() => {
    const newUserNamesSet = new Set(users.map(u => u.Name));
    // If size equal, usernames are unaffected as they are stable and unique
    if (userNames.size === newUserNamesSet.size) {
      return;
    }

    setUserNames(newUserNamesSet);
  }, [userNames, users]);

  const { tradeSubAccountsByUser, viewSubAccountsByUser } = useMemo(() => {
    const tradeSubAccountsByUser = new Map<string, Set<string>>();
    const viewSubAccountsByUser = new Map<string, Set<string>>();

    for (const userName of Array.from(userNames || [])) {
      const userTradeSet = new Set<string>();
      const userViewSet = new Set<string>();

      const permissionFilterIDs = filterIDsByUserName?.get(userName);
      for (const permissionFilterID of permissionFilterIDs || []) {
        const permission = subAccountPermissionFiltersByFilterID?.get(permissionFilterID);
        if (permission) {
          userViewSet.add(permission.Filter.SubAccount);
          if (permission.Action === ApiPermissionActionEnum.Write) {
            userTradeSet.add(permission.Filter.SubAccount);
          }
        }
      }
      tradeSubAccountsByUser.set(userName, userTradeSet);
      viewSubAccountsByUser.set(userName, userViewSet);
    }

    return { tradeSubAccountsByUser, viewSubAccountsByUser };
  }, [filterIDsByUserName, subAccountPermissionFiltersByFilterID, userNames]);

  const [quickFilter, setQuickFilter] = useState<string>('');
  const dataObservable = useObservable(
    () =>
      of(users ?? []).pipe(
        map(data => {
          const hydratedData = data.map(user => {
            const tradeSubAccountsSet = tradeSubAccountsByUser.get(user.Name) || new Set<string>();
            const viewSubAccountsSet = viewSubAccountsByUser.get(user.Name) || new Set<string>();

            return {
              tradeSubAccounts: tradeSubAccountsSet.has(API_PERMISSION_WILDCARD)
                ? 'All Sub Accounts'
                : Array.from(tradeSubAccountsSet)
                    .map(name => subAccountsByName?.get(name)?.DisplayName || name)
                    .join(', '),
              viewSubAccounts: viewSubAccountsSet.has(API_PERMISSION_WILDCARD)
                ? 'All Sub Accounts'
                : Array.from(viewSubAccountsSet)
                    .map(name => subAccountsByName?.get(name)?.DisplayName || name)
                    .join(', '),
              ...user,
            };
          });

          return { data: hydratedData, initial: true, type: 'User' };
        })
      ),
    [users, subAccountsByName, tradeSubAccountsByUser, viewSubAccountsByUser]
  );

  const newUserDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });

  const editUserDrawer = useDrawer({
    position: 'relative',
    width: 720,
    placement: 'right',
  });

  const handleEditUser = useDynamicCallback((user: User) => {
    if (user.DeletedAt) {
      return;
    }
    setSelectedUserID(user.ID ?? undefined);
    newUserDrawer.close();
    editUserDrawer.open();
  });

  const handleEditUserUpdated = useCallback(async () => {
    refetchUsers();
  }, [refetchUsers]);

  const handleNewUser = useCallback(() => {
    setSelectedUserID(undefined);
    editUserDrawer.close();
    newUserDrawer.open();
    mixpanel.track(MixpanelEvent.InviteNewUser);
  }, [newUserDrawer, editUserDrawer, mixpanel]);

  const handleOnNewUserAdded = useCallback(async () => {
    newUserDrawer.close();
    refetchUsers();
  }, [newUserDrawer, refetchUsers]);

  const refetchUsersList = useDynamicCallback(() => {
    const serverFilter = filter ? onlyServerFilterKeys(filter) : undefined;
    refetchUsers(serverFilter?.ShowDeletedUsers === true);
  });

  const usersBlotterTableMenu = useUsersBlotterTableMenu({
    onDeleteUser: refetchUsersList,
    onEditUser: handleEditUser,
    onReEnableUser: refetchUsersList,
    ssoEnabled: organization?.SSOEnabled === true,
  });

  const { clientSideFilter, changeFilter, filter, onlyServerFilterKeys } = useUserFilter();
  const prevFilter = usePrevious(onlyServerFilterKeys(filter));

  const columns = useColumns({
    availableRoles: viewableRoles,
    showDeletedUsers: filter?.ShowDeletedUsers === true,
    handleEditUser,
    usersBlotterTableMenu,
  });

  const blotterTable = useBlotterTable<User>({
    dataObservable,
    columns,
    rowID: 'ID' satisfies keyof User,
    clientLocalFilter: clientSideFilter,
    density: BlotterDensity.Comfortable,
    sort: '+Name',
    quickSearchParams: {
      filterText: quickFilter,
    },
    gridOptions: {
      onRowDoubleClicked: ({ data }) => data != null && handleEditUser(data),
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: usersBlotterTableMenu.getContextMenuItems,
    },
  });

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Users',
      }),
    });
  }, [blotterTable]);

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [
      {
        key: 'Roles',
        label: 'Role',
        icon: IconName.Users,
        options: viewableRoles,
        getOptionLabel: (role: string) => prettifyRole(role as ROLE),
      },
    ],
    [viewableRoles]
  );

  const filterBuilderAccordion = useAccordion({ id: 'userFilter', initialOpen: true });
  const filterBuilder = useFilterBuilder({
    initialFilterClauses: EMPTY_ARRAY,
    properties: filterableProperties,
  });

  const { filterClausesByPropertyKey } = filterBuilder;

  const handleShowDeletedUsers = useDynamicCallback((checked: boolean) => {
    changeFilter(curr => ({
      ...curr,
      ShowDeletedUsers: checked,
    }));
  });

  useEffect(() => {
    changeFilter(curr => {
      const newFilter = removeEmptyFilters({
        ...curr,
        Roles: filterClausesByPropertyKey.get('Roles')?.selections,
      });
      if (isEqual(curr, newFilter)) {
        return curr;
      }
      return newFilter;
    });
  }, [filterClausesByPropertyKey, changeFilter]);

  useEffect(() => {
    if (filter) {
      const serverFilter = onlyServerFilterKeys(filter);
      if (!isEqual(serverFilter, prevFilter)) {
        refetchUsers(serverFilter?.ShowDeletedUsers === true);
      }
    }
  }, [filter, prevFilter, onlyServerFilterKeys, refetchUsers]);

  useEffectOnce(() => {
    getOrganization().then(result => {
      setOrganization(result);
    });
  });

  if (users == null) {
    return (
      <LoaderWrapper>
        <Loader size={LoaderSizes.SMALL} />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Panel>
        <PanelHeader alignItems="center">
          <HStack gap="spacingDefault">
            <h2>Users</h2>
            <IndicatorBadge size={IndicatorBadgeSizes.Large} children={users.length} />
            {organization?.SSOEnabled && (
              <IndicatorBadge
                size={IndicatorBadgeSizes.Large}
                children={
                  <HStack gap="spacingSmall">
                    <IndicatorDot show variant={IndicatorDotVariants.Positive} />
                    SSO Enabled
                  </HStack>
                }
              />
            )}
          </HStack>
          <PanelActions>
            <Toggle
              size={FormControlSizes.Small}
              checked={filter.ShowDeletedUsers}
              onChange={handleShowDeletedUsers}
              label="Show Deleted Users"
            />
            <LocalFilterInput
              value={quickFilter}
              onChange={evt => {
                setQuickFilter(evt);
                mixpanel.track(MixpanelEvent.FilterQuickSearch, {
                  [MixpanelEventProperty.Source]: 'Users',
                });
              }}
              width="200px"
            />
            <FilterBuilderToggleButton
              filterClauses={filterBuilder.filterClauses}
              isOpen={filterBuilderAccordion.isOpen}
              onClick={() => filterBuilderAccordion.toggle()}
              size={FormControlSizes.Default}
            />
            <Button startIcon={IconName.DocumentDownload} onClick={handleExport}>
              Export CSV
            </Button>
            {isAuthorized(ACTION.EDIT_USERS) && (
              <>
                <Divider orientation="vertical" alignSelf="stretch" />
                <Button
                  startIcon={IconName.Plus}
                  variant={ButtonVariants.Positive}
                  onClick={handleNewUser}
                  data-testid="invite-new-user-button"
                >
                  Invite New User
                </Button>
              </>
            )}
          </PanelActions>
        </PanelHeader>
        <Box background="colors.gray.main" w="100%" px="spacingBig">
          <Accordion {...filterBuilderAccordion}>
            <AccordionBody>
              <FilterBuilder {...filterBuilder} />
            </AccordionBody>
          </Accordion>
        </Box>
        <PanelContent>
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <EditUserDrawer user={selectedUser} {...editUserDrawer} onSaved={handleEditUserUpdated} />
      <NewUserDrawer {...newUserDrawer} onSaved={handleOnNewUserAdded} />
    </>
  );
});
