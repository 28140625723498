import { createContext, useContext } from 'react';

const defaultValue: InlineFormattedNumberContextProps = { showAllDecimals: false };
export const InlineFormattedNumberContext = createContext<InlineFormattedNumberContextProps>(defaultValue);

export interface InlineFormattedNumberContextProps {
  showAllDecimals: boolean;
}

export function useInlineFormattedNumberContext() {
  return useContext(InlineFormattedNumberContext);
}
