import { forwardRef, type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';
import type { BoxProps } from '../Core';
import { Tooltip } from '../Tooltip';
import { Title, Wrapper, type TagSizes, type TagVariants } from './styles';

export { TagSizes, TagVariants } from './styles';

export type TagProps = {
  variant?: TagVariants;
  title?: string;
  tooltip?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  size?: TagSizes;
  children?: ReactNode;
  style?: CSSProperties;
} & Omit<BoxProps, 'prefix'>;

export const Tag = styled(
  forwardRef<HTMLDivElement, TagProps>(
    ({ children, variant, title = '', prefix, suffix, tooltip, size, ...props }, ref) => {
      const tagContent = (
        <Wrapper variant={variant} size={size} {...props} ref={ref}>
          {title !== '' && <Title variant={variant}>{title}</Title>}
          {prefix}
          <span>{children}</span>
          {suffix}
        </Wrapper>
      );
      return tooltip ? <Tooltip tooltip={tooltip}>{tagContent}</Tooltip> : tagContent;
    }
  )
)``;
