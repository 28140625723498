import { toBigWithDefault, useObservableValue, useWSFilterPipe, wsScanToMap } from '@talos/kyoko';
import Big from 'big.js';
import { createContext, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { asyncScheduler, map, shareReplay, throttleTime, type Observable } from 'rxjs';
import type { PositionsTableFilter } from '../../../Blotters/PositionsV3/types';
import type { UnifiedPosition } from '../../../Blotters/PositionsV3/Unified/UnifiedPosition';
import { useUnifiedPositionsBalancesObs } from '../../../Blotters/PositionsV3/Unified/useUnifiedPositionsBalancesObs';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { OpsPosition } from '../types';

export const OperationsOverviewPositionsContext = createContext<OperationsOverviewPositionsContextProps | undefined>(
  undefined
);

export type OperationsOverviewPositionsContextProps = {
  positionsData: OpsPosition[] | undefined;
  positionsDataObs: Observable<OpsPosition[]>;
};

export function useOperationsOverviewPositions() {
  const context = useContext(OperationsOverviewPositionsContext);
  if (context === undefined) {
    throw new Error(
      'Missing OperationsOverviewPositionsContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

function getPositionKey(p: OpsPosition) {
  return p.rowID;
}

export const OperationsOverviewPositionsProvider = function OperationsOverviewPositionsProvider({
  children,
}: PropsWithChildren) {
  const { opsOverviewFilter, showZeroBalances, selectedMarketAccountIds } = usePortfolioViewStateSelector();
  const filter: PositionsTableFilter = useMemo(() => {
    return {
      ...opsOverviewFilter,
      MarketAccounts: selectedMarketAccountIds,
    };
  }, [opsOverviewFilter, selectedMarketAccountIds]);

  const backendFilter: PositionsTableFilter = useMemo(
    () => ({
      MarketAccounts: filter.MarketAccounts,
      Symbols: filter.Symbols,
    }),
    [filter.MarketAccounts, filter.Symbols]
  );

  const positionsObs = useUnifiedPositionsBalancesObs({
    tag: 'operations-overview-page',
    filter: backendFilter,
    showZeroBalances,
  });

  const filterFunc = useCallback(
    (item: OpsPosition) => {
      if (filter.Markets && filter.Markets.length > 0) {
        // If market for no reason is not attached, then filter out
        if (!item.market) {
          return false;
        }

        if (!filter.Markets.includes(item.market)) {
          return false;
        }
      }

      if (filter.AssetTypes && filter.AssetTypes.length > 0 && !filter.AssetTypes.includes(item.AssetType)) {
        return false;
      }

      return true;
    },
    [filter.Markets, filter.AssetTypes]
  );

  const wsFilterPipe = useWSFilterPipe({ getUniqueKey: getPositionKey, filterFunc });

  const positionsDataObs = useMemo(
    () =>
      positionsObs.pipe(
        wsScanToMap({ getUniqueKey: pos => pos.rowID, newMapEachUpdate: false }),
        throttleTime(1000, asyncScheduler, { leading: true, trailing: true }),
        map(mapping => {
          // Calculate totals for each market account level, then build OpsPositions using this information
          const opsPositions: OpsPosition[] = [];
          const positionsArr = [...mapping.values()];
          const totals = getTotalPositionPerMarketAccount(positionsArr);
          for (const position of positionsArr) {
            const total = totals.get(position.MarketAccount);
            if (!total) {
              continue;
            }

            opsPositions.push(new OpsPosition(position, total));
          }

          // Return an array of built OpsPositions here now including knowledge of their share of the total
          return opsPositions;
        }),
        // Convert to a MinimalSubscriptionResponse so we can use the wsFilterPipe natively
        map(opsPositions => ({ initial: true, data: opsPositions })),
        wsFilterPipe,
        map(json => json.data), // we only return the data array. This will always be an absolute data array.
        shareReplay({ bufferSize: 1, refCount: true }) // lastly, replay and multicast the output
      ),
    [positionsObs, wsFilterPipe]
  );

  const positionsData = useObservableValue(() => {
    return positionsDataObs;
  }, [positionsDataObs]);

  const value = useMemo(() => {
    return {
      positionsData,
      positionsDataObs,
    };
  }, [positionsData, positionsDataObs]);

  return (
    <OperationsOverviewPositionsContext.Provider value={value}>{children}</OperationsOverviewPositionsContext.Provider>
  );
};

function getTotalPositionPerMarketAccount(positions: UnifiedPosition[]): Map<string, Big> {
  return positions.reduce((agg, position) => {
    const mktAccSumSoFar = agg.get(position.MarketAccount) ?? Big(0);
    agg.set(position.MarketAccount, mktAccSumSoFar.plus(toBigWithDefault(position.Equivalent?.Amount, 0).abs()));
    return agg;
  }, new Map<string, Big>());
}
