import { logger, type FixingIndex } from '@talos/kyoko';
import { format as formatTime, parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

export function FixingTime({ fixingIndex }: { fixingIndex: FixingIndex }) {
  const [fixingTime, setFixingTime] = useState(formatFixingTime(fixingIndex));

  // Re-render once a minute
  const [count, setCount] = useState(0);
  useInterval(() => setCount(i => i + 1), 60_000);

  useEffect(() => setFixingTime(formatFixingTime(fixingIndex)), [fixingIndex, count]);

  return <>{fixingTime}</>;
}

export function formatFixingTime(fixingIndex: FixingIndex) {
  try {
    // TODO fhqvst Use timestamp from fixing timezone instead of local time
    const now = new Date();
    const indexTimestamp = parse(`${fixingIndex.ValidNominalIndexTime}`, 'kkmmss', now);
    const timeZoneName = new Intl.DateTimeFormat('en', {
      timeZone: fixingIndex.NominalIndexTimeZone,
      timeZoneName: 'short',
    })
      .formatToParts(now)
      .find(part => part.type === 'timeZoneName')?.value;
    return `${formatTime(indexTimestamp, 'dd MMM yyyy - h aaa').toUpperCase()} ${timeZoneName}`;
  } catch (e) {
    logger.error(e as Error);
    return '';
  }
}
