import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  CUSTOMER_TRADE,
  CustomerTrade,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useCustomerTradeColumns,
  useDynamicCallback,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterTableFilter,
  type BlotterTableSort,
  type Column,
  type ColumnState,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers/OrgConfigurationProvider';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useCustomerTradeFilter,
  type CustomerTradeFilter,
} from './useCustomerTradeFilter';
import { useCustomerTradeMenu } from './useCustomerTradeMenu';

export interface CustomerTradesBlotterTableProps {
  /** Unique id per blotter instance */
  blotterID: string;
  /** ID for common blotter type persistance; usually a subset of the blotterId to represent blotter area 'type' */
  blotterPersistID?: string;
  orderID?: string;
  rfqID?: string;
  tabLabel?: string;
  defaultColumns?: (Column | keyof CustomerTrade)[];
  defaultFilter?: CustomerTradeFilter;
  defaultSort?: BlotterTableSort<CustomerTrade>;
  persistColumns?: boolean;
  persistFilter?: boolean;
  persistSort?: boolean;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  onRowDoubleClicked?: (event: RowDoubleClickedEvent<CustomerTrade>) => void;
}

export function CustomerTradesBlotterTable({
  orderID,
  rfqID,
  blotterID,
  blotterPersistID = blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  defaultSort = '-TransactTime',
  persistFilter,
  persistColumns,
  persistSort,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
}: CustomerTradesBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();

  const columnDefinitions = useCustomerTradeColumns({ defaultColumns });
  const persistedBlotterTable = usePersistedBlotterTable<CustomerTrade>(blotterPersistID, {
    columns: columnDefinitions,
    filter: defaultFilter,
    sort: defaultSort,
    persistColumns,
    persistFilter,
    persistSort,
  });

  const filterResults = useCustomerTradeFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const CustomerTradeMenu = useCustomerTradeMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...CustomerTradeMenu.columns],
    [CustomerTradeMenu.columns, persistedBlotterTable.columns]
  );

  const { showBlotterPauseButton } = useFeatureFlag();

  // This call owns the true filter state
  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: CUSTOMER_TRADE,
      tag: blotterID,
      sort_by: '-TransactTime',
      ...(orderID != null && { OrderID: orderID }),
      ...(rfqID != null && { RFQID: rfqID }),
    },
    filter: onlyServerFilterKeys(filterResults.filter),
    initialSort: persistedBlotterTable.initialSort,
    rowID: CustomerTrade.rowID,
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: columnsWithMenu,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    getExtraMainMenuItems,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    pauseParams: {
      showPauseButton: showBlotterPauseButton,
    },
    gridOptions: {
      onRowDoubleClicked,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: CustomerTradeMenu.getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'CustomerTrades',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.TransactTime} />
      <BlotterTable {...blotterTable} />
      {CustomerTradeMenu.dialogs}
    </>
  );
}
