import type { SelectItem } from '@talos/kyoko';
import { OptionTypeEnum, toBigWithDefault, type StringSelectItem } from '@talos/kyoko';
import { OptionStrategies } from 'components/MultilegCombo/enums';
import { immerable } from 'immer';
import type { OMSReferenceDataState } from '../../types';
import { OPTION_SIDES, Option } from './Option';
import { OptionStrategy, type OptionStrategyData } from './OptionStrategy';

/*
 * Strategy containing both a call and a put with different strike prices but same expiration
 * Useful if you think there will be a large price movement on either direction
 */
export class Strangle extends OptionStrategy {
  public static createFromBlank(referenceData: OMSReferenceDataState, partial?: Partial<OptionStrategyData>) {
    const data: OptionStrategyData = {
      name: OptionStrategies.Strangle,
      legs: [Option.createFromBlank(referenceData), Option.createFromBlank(referenceData).updateType(OPTION_SIDES[1])],
      initiatingLegs: [true, false],
      ratios: ['1', '1'],
      ...partial,
    };

    return new Strangle(referenceData, data);
  }

  public updateExpiry = (expiry: StringSelectItem) => {
    const newData = {
      legs: [this._data.legs[0].updateExpiry(expiry), this._data.legs[1].updateExpiry(expiry)],
    };

    return this.create(newData);
  };

  public updateStrike = (strike: StringSelectItem, legIndex: number) => {
    const otherLegIndex = legIndex === 0 ? 1 : 0;
    const otherLegStrike = this._data.legs[otherLegIndex].data.strikeField.value;

    const violateStrikeRule = strike.value === otherLegStrike?.value;
    const oppositeLegStrike = violateStrikeRule ? undefined : otherLegStrike;

    const newData = {
      legs: [
        this._data.legs[0].updateStrike(legIndex === 0 ? strike : oppositeLegStrike),
        this._data.legs[1].updateStrike(legIndex === 1 ? strike : oppositeLegStrike),
      ],
    };
    return this.create(newData);
  };

  public updateType = (type: SelectItem<OptionTypeEnum>, legIndex: number) => {
    const oppositeType = type.value === OptionTypeEnum.Call ? OPTION_SIDES[1] : OPTION_SIDES[0];

    const newData = {
      legs: [
        this._data.legs[0].updateType(legIndex === 0 ? type : oppositeType),
        this._data.legs[1].updateType(legIndex === 1 ? type : oppositeType),
      ],
    };
    return this.create(newData);
  };

  public updateRatio = (ratio: string, legIndex: number) => {
    const isRatioNegative = toBigWithDefault(ratio, 0)?.lt(0);

    if (!ratio || ratio === '0' || ratio === '-' || isRatioNegative) {
      // ratio must be a non-zero positive number
      return this.create(this.data);
    }

    const copy = this._data.ratios.slice();
    copy[legIndex] = ratio;

    const newData = {
      ratios: copy,
    };

    return this.create(newData);
  };

  public getPrettyName(): string {
    // todo when supported
    return '';
  }

  protected create(newData: Partial<OptionStrategyData>) {
    const data = {
      ...this._data,
      ...newData,
    };
    return new Strangle(this._referenceData, data);
  }
}

Strangle[immerable] = true;
