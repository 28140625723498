import type { TypedGroupviewPanelState } from '../../types/LayoutConfig';

export function addTypeToLayoutPanelParams(config: any) {
  return {
    ...config,
    dockViewLayout: {
      ...config.dockViewLayout,
      panels: Object.entries<TypedGroupviewPanelState>(config.dockViewLayout.panels ?? {}).reduce(
        (res: Record<string, TypedGroupviewPanelState>, entry: [key: string, value: TypedGroupviewPanelState]) => {
          const [key, value] = entry;
          if (value.contentComponent === undefined) {
            return res;
          }
          value.params = value?.params ?? {};
          value.params.type = value.contentComponent;
          res[key] = value;
          return res;
        },
        {}
      ),
    },
  };
}
