import {
  AGGRID_AUTOCOLUMN_ID,
  autoGroupSortingDefaults,
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Flex,
  FormControlSizes,
  getTreeRowBlotterGroupColDef,
  IconButton,
  IconName,
  InlineFormattedNumberContext,
  type MinimalSubscriptionResponse,
  Toggle,
  useBlotterTable,
  useBlotterTableExtrasMenu,
  usePersistedBlotterTable,
} from '@talos/kyoko';
import type { ColDef, RowDoubleClickedEvent } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import type { Observable } from 'rxjs';
import { RECON_OVERVIEW_BLOTTER_PORTAL_ID, RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL } from '../tokens';
import { ReconAssetRow, type SubAccountReconOverviewRow } from './reconCheckpointRows';
import { useSubAccountReconOverviewBlotterColumns } from './useSubAccountReconOverviewBlotterColumns';
import { useSubAccountReconOverviewBlotterMenu } from './useSubAccountReconOverviewBlotterMenu';

interface SubAccountReconOverviewBlotterProps {
  dataObservable: Observable<MinimalSubscriptionResponse<SubAccountReconOverviewRow>>;
  /**
   * Called when the user clicks on the Deepdive/Details button of a ReconAssetRow.
   * Don't provide this if you don't want the blotter to render any of these buttons.
   */
  onViewCheckpointDetails?: (assetRow: ReconAssetRow) => void;
  /** Called when the user clicks on Resolve for a ReconAssetRow. Don't provide if you don't want to enable this for the user. */
  onResolveCheckpoint?: (assetRow: ReconAssetRow) => void;
  blotterID: string;
  /** Whether or not were rendering this blotter in the modal. Does some different stuff */
  isInModal?: boolean;

  /** Current state of the ShowZeroBalances toggle */
  showZeroBalances: boolean;
  setShowZeroBalances: (value: boolean) => void;

  showAllDecimals: boolean;
  setShowAllDecimals: (value: boolean) => void;

  onlyShowBreaks?: boolean;
  setOnlyShowBreaks?: (value: boolean) => void;
}

function getDataPath(data: SubAccountReconOverviewRow) {
  return data.dataPath;
}

export const SubAccountReconOverviewBlotter = ({
  onViewCheckpointDetails,
  onResolveCheckpoint,
  blotterID,
  dataObservable,
  isInModal,
  showZeroBalances,
  setShowZeroBalances,
  showAllDecimals,
  setShowAllDecimals,
  onlyShowBreaks,
  setOnlyShowBreaks,
}: SubAccountReconOverviewBlotterProps) => {
  const defaultColumns = useSubAccountReconOverviewBlotterColumns();

  const persistedBlotterTable = usePersistedBlotterTable<SubAccountReconOverviewRow>(blotterID, {
    columns: defaultColumns,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const autoGroupColumnDef: ColDef<SubAccountReconOverviewRow> = useMemo(
    () => ({
      headerName: 'Sub Accounts - Instrument - Market Accounts',
      width: 400,
      colId: AGGRID_AUTOCOLUMN_ID,
      editable: false,
      suppressColumnsToolPanel: false,
      cellRendererParams: {
        suppressCount: true,
      },
      sortable: true,
      pinned: 'left',
      ...autoGroupSortingDefaults,
      ...getTreeRowBlotterGroupColDef(),
    }),
    []
  );

  const {
    columns: menuColumns,
    getContextMenuItems,
    dialogs,
  } = useSubAccountReconOverviewBlotterMenu({
    onDetailsClicked: onViewCheckpointDetails,
    onResolveClicked: onResolveCheckpoint,
    isInModal,
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...menuColumns],
    [persistedBlotterTable.columns, menuColumns]
  );

  const handleDoubleClickRow = useCallback(
    ({ data }: RowDoubleClickedEvent<SubAccountReconOverviewRow>) => {
      if (data instanceof ReconAssetRow) {
        onViewCheckpointDetails?.(data);
      }
    },
    [onViewCheckpointDetails]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'rowID' satisfies keyof SubAccountReconOverviewRow,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    columns: columnsWithMenu,
    sort: persistedBlotterTable.initialSort,
    gridOptions: {
      onRowDoubleClicked: handleDoubleClickRow,
      autoGroupColumnDef,
      treeData: true,
      getDataPath: getDataPath,
      tooltipInteraction: true,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
      groupDefaultExpanded: -1, // expand all rows by default
    },
  });

  const { expandAllGroups, collapseAllLevelsGreaterThan } = blotterTable;
  const extrasMenuPopover = useBlotterTableExtrasMenu();

  return (
    <Flex flexDirection="column" w="100%" h="100%" data-testid="sub-account-recon-overview-blotter">
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        portalId={isInModal ? RECON_OVERVIEW_BLOTTER_PORTAL_ID_MODAL : RECON_OVERVIEW_BLOTTER_PORTAL_ID}
        prefix={
          isInModal ? null : ( // if we're in a modal, there'll only be one checkpoint, so we dont render these buttons
            <>
              <IconButton
                icon={IconName.ListExpand}
                size={FormControlSizes.Small}
                variant={ButtonVariants.Default}
                onClick={expandAllGroups}
                data-testid="expand-all-button"
              />
              <IconButton
                icon={IconName.ListCollapse}
                size={FormControlSizes.Small}
                variant={ButtonVariants.Default}
                // collapse all, but only levels greater than the root level (0)
                onClick={() => collapseAllLevelsGreaterThan(0)}
              />
            </>
          )
        }
        suffix={
          <>
            <BlotterTableExtrasMenu dataTestID="recon-overview-extras-menu" {...extrasMenuPopover}>
              <Toggle
                size={FormControlSizes.Small}
                checked={showZeroBalances}
                onChange={setShowZeroBalances}
                label="Show Zeros"
                data-testid="show-zeros-toggle"
              />
              <Toggle
                size={FormControlSizes.Small}
                checked={showAllDecimals}
                onChange={setShowAllDecimals}
                label="Show All Decimals"
                data-testid="show-all-decimals-toggle"
              />
              {setOnlyShowBreaks && (
                <Toggle
                  size={FormControlSizes.Small}
                  checked={onlyShowBreaks ?? false}
                  onChange={setOnlyShowBreaks}
                  label="Only Show Breaks"
                  data-testid="only-show-breaks-toggle"
                />
              )}
            </BlotterTableExtrasMenu>
          </>
        }
      />
      <InlineFormattedNumberContext.Provider value={useMemo(() => ({ showAllDecimals }), [showAllDecimals])}>
        <BlotterTable {...blotterTable} />
      </InlineFormattedNumberContext.Provider>
      {dialogs}
    </Flex>
  );
};
