import {
  SideEnum,
  abbreviateId,
  format,
  isCounterCurrency,
  isOrderComplete,
  useSecurity,
  type CustomerOrder,
  type CustomerTrade,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCustomerDisplayName, useCustomerOrder, useCustomerTrade } from 'hooks';

interface ConstructCopyOrderTextProps {
  customerOrderID?: string;
  tag: string;
}

type ExecutionCopyText = string | undefined;
interface OrderCopyTextProps {
  customerOrder: CustomerOrder | undefined;
  security: Security | undefined;
  customerName: string;
}

export function constructCustomerOrderCopyText({
  customerOrder,
  security,
  customerName,
}: OrderCopyTextProps): ExecutionCopyText {
  // Order must have a Counterparty and Security
  if (!customerOrder?.Counterparty || !security) {
    return undefined;
  }

  // Order must be complete and have a non-zero CumQty
  if (!isOrderComplete(customerOrder.OrdStatus) || Big(customerOrder.CumQty).eq(0)) {
    return undefined;
  }

  const isCcy = isCounterCurrency(customerOrder.Currency, security);

  const side = customerOrder.Side === SideEnum.Buy ? 'bought' : 'sold';
  const symbol = customerOrder.Symbol;

  const price = customerOrder.AvgPx;
  const priceCurrency = isCcy ? customerOrder.Currency : customerOrder.AmountCurrency;

  const amount = isCcy ? customerOrder.CumQty : customerOrder.CumAmt;
  const amountCurrency = isCcy ? customerOrder.Currency : customerOrder.AmountCurrency;

  const quantity = isCcy ? customerOrder.CumAmt : customerOrder.CumQty;
  const quantityCurrency = isCcy ? customerOrder.AmountCurrency : customerOrder.Currency;

  const id = `#${abbreviateId(customerOrder.OrderID)}`;

  // Example: '#01e6 Small Customer bought ETH-USD | 10.1500 ETH at 3,630.73 USD for 36,851.88 USD'
  return `${id} ${customerName} ${side} ${symbol} | ${format(quantity, {
    spec: security.DefaultSizeIncrement,
  })} ${quantityCurrency} at ${format(price, {
    spec: security.DefaultPriceIncrement,
  })} ${priceCurrency} for ${format(amount, {
    spec: security.DefaultPriceIncrement,
  })} ${amountCurrency}`;
}

export const useCopyCustomerOrderExecutionText = ({
  customerOrderID,
  tag,
}: ConstructCopyOrderTextProps): ExecutionCopyText => {
  const customerOrder = useCustomerOrder({ orderID: customerOrderID, tag: `${tag}/useCopyCustomerOrderExecutionText` });
  const security = useSecurity(customerOrder?.Symbol);
  const customerName = useCustomerDisplayName(customerOrder?.Counterparty);

  return constructCustomerOrderCopyText({ customerOrder, security, customerName });
};

interface TradeCopyTextProps {
  customerTrade: CustomerTrade | undefined;
  security: Security | undefined;
  customerName: string;
}

interface ConstructCopyTradeTextProps {
  tradeID?: string;
}

export function constructCustomerTradeCopyText({
  customerTrade,
  security,
  customerName,
}: TradeCopyTextProps): ExecutionCopyText {
  // Trade must have a Counterparty and Security
  if (!customerTrade?.Counterparty || !security) {
    return undefined;
  }

  const isCcy = isCounterCurrency(customerTrade.Currency, security);

  const side = customerTrade.Side === SideEnum.Buy ? 'bought' : 'sold';
  const symbol = customerTrade.Symbol;

  const price = customerTrade.Price;
  const priceCurrency = isCcy ? customerTrade.Currency : customerTrade.AmountCurrency;

  const amount = isCcy ? customerTrade.Quantity : customerTrade.Amount;
  const amountCurrency = isCcy ? customerTrade.Currency : customerTrade.AmountCurrency;

  const quantity = isCcy ? customerTrade.Amount : customerTrade.Quantity;
  const quantityCurrency = isCcy ? customerTrade.AmountCurrency : customerTrade.Currency;

  const id = `#${abbreviateId(customerTrade.TradeID)}`;

  // Example: '#3ac1 Small Customer bought ETH-USD | 0.1500 ETH at 3,629.71 USD for 544.46 USD'
  return `${id} ${customerName} ${side} ${symbol} | ${format(quantity, {
    spec: security.DefaultSizeIncrement,
  })} ${quantityCurrency} at ${format(price, {
    spec: security.DefaultPriceIncrement,
  })} ${priceCurrency} for ${format(amount, {
    spec: security.DefaultPriceIncrement,
  })} ${amountCurrency}`;
}

export const useCopyCustomerTradeExecutionText = ({ tradeID }: ConstructCopyTradeTextProps): ExecutionCopyText => {
  const customerTrade = useCustomerTrade({ tradeID });
  const security = useSecurity(customerTrade?.Symbol);
  const customerName = useCustomerDisplayName(customerTrade?.Counterparty);

  return constructCustomerTradeCopyText({ customerTrade, security, customerName });
};
