import {
  ACTION,
  Button,
  ButtonVariants,
  CustomerExecutionStrategyEnum,
  FormControlSizes,
  HamburgerMenu,
  IconName,
  MixpanelEvent,
  NotificationVariants,
  ORD_TEXT_TO_STATUS_MAPPING,
  OrderStatusText,
  abbreviateId,
  filterByCellValueMenuItem,
  isOrderComplete,
  request,
  useDisclosure,
  useDynamicCallback,
  useEndpointsContext,
  useGetDefaultContextMenuItems,
  useGlobalDialog,
  useGlobalToasts,
  useJsonModal,
  useMixpanel,
  type Column,
  type ColumnDef,
  type CustomerOrder,
  type FilterableProperty,
  type UseFilterBuilderOutput,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { GenerateOrderDetailsRoute } from 'containers/Trading/Markets/OrderDetails/types';
import { compact, isNil } from 'lodash';
import { useMemo, useState, type ReactNode } from 'react';
import { useRoleAuth } from '../../../hooks';
import { useNavigateToOrderDetails } from '../../../hooks/useNavigateToOrderDetails';
import { CancelOrderDialog, ForceFillOrderDialog, ModifyOrderSpreadDialog } from './Dialogs';
import { useCopyCustomerOrderTextMenuItem } from './useCopyCustomerOrderTextMenuItem';
import { colIDToFilterBuilderKey } from './useCustomerOrdersFilter';

export function CustomerOrdersMenu({
  data: customerOrder,
  onModifySpread,
  onForceCancel,
  onCancel,
  onForceFill,
  ...params
}: ICellRendererParams & {
  onShowJson(data: CustomerOrder): void;
  onCancel(data: CustomerOrder): void;
  onForceCancel(data: CustomerOrder): void;
  onModifySpread(data: CustomerOrder): void;
  onForceFill(data: CustomerOrder): void;
}) {
  const { isAuthorized } = useRoleAuth();
  return (
    <>
      <Button
        size={FormControlSizes.Small}
        width="100%"
        ghost={true}
        onClick={() => onModifySpread(customerOrder)}
        disabled={isOrderComplete(customerOrder.OrdStatus)}
      >
        Modify spread
      </Button>
      <Button
        size={FormControlSizes.Small}
        width="100%"
        ghost={true}
        onClick={() => onCancel(customerOrder)}
        disabled={isOrderComplete(customerOrder.OrdStatus) || !isAuthorized(ACTION.CANCEL_ORDER)}
      >
        Cancel
      </Button>
      {isAuthorized(ACTION.FORCE_FILL_ORDER) && (
        <Button size={FormControlSizes.Small} width="100%" ghost={true} onClick={() => onForceFill(customerOrder)}>
          Force fill
        </Button>
      )}
      {isAuthorized(ACTION.FORCE_CANCEL_ORDER) && (
        <Button
          size={FormControlSizes.Small}
          width="100%"
          ghost={true}
          onClick={() => onForceCancel(customerOrder)}
          variant={ButtonVariants.Negative}
        >
          Force cancel
        </Button>
      )}
      <HamburgerMenu {...params} data={customerOrder} />
    </>
  );
}

export function useCustomerOrdersMenu({
  openClause,
  filterableProperties,
  generateOrderDetailsRoute,
}: {
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  filterableProperties: FilterableProperty<string>[];
  generateOrderDetailsRoute: GenerateOrderDetailsRoute;
}): {
  columns: Column[];
  getContextMenuItems: (params: GetContextMenuItemsParams) => (MenuItemDef | string)[];
  dialogs: ReactNode;
} {
  const mixpanel = useMixpanel();
  const { handleClickJson, jsonModal } = useJsonModal();
  const { orgApiEndpoint } = useEndpointsContext();
  const { open: openGlobalDialog } = useGlobalDialog();
  const { add: addToast } = useGlobalToasts();
  const { isAuthorized } = useRoleAuth();
  const showForceFill = useDynamicCallback((data: CustomerOrder) => {
    return (
      !isOrderComplete(data.OrdStatus) &&
      isAuthorized(ACTION.DEALER_TRADING) &&
      isAuthorized(ACTION.FORCE_FILL_ORDER) &&
      ORD_TEXT_TO_STATUS_MAPPING[OrderStatusText.Open].includes(data.OrdStatus)
    );
  });

  const defaultContextMenuItems = useGetDefaultContextMenuItems();
  const copyCustomerOrderExecutionTextMenuItem = useCopyCustomerOrderTextMenuItem();

  const [customerOrderID, setCustomerOrderID] = useState<string | undefined>();
  const forceFillOrderDialog = useDisclosure();
  const modifyCustomerOrderSpreadDialog = useDisclosure();
  const cancelCustomerOrderDialog = useDisclosure();

  const getOrderContextMenuItem = useDynamicCallback((order: CustomerOrder) => {
    if (!isAuthorized(ACTION.VIEW_MONITORING_ORDER_CONTEXT)) {
      return null;
    }

    return {
      name: 'Order Context (Talos only)',
      action: () => {
        mixpanel.track(MixpanelEvent.OpenCustomerOrderContext);
        window.open(`${orgApiEndpoint}/customer/orders/${order.OrderID}/context`, '_blank');
      },
      icon: `<i class="ag-icon ${IconName.ExternalLink}"/>`,
    };
  });

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<CustomerOrder>) => {
    const data = params?.node?.data;
    const groupData = params?.node?.groupData;
    if (isNil(data) && isNil(groupData)) {
      return [];
    }

    const items = compact([
      ...filterByCellValueMenuItem({ params, filterableProperties, openClause, colIDToFilterBuilderKey, mixpanel }),
      ...(data
        ? [
            getOrderContextMenuItem(data),
            data.ExecutionStrategy !== CustomerExecutionStrategyEnum.OrderAcceptance && {
              name: 'Open details',
              action: () => handleOpenHedgeOrderDetails(data),
              icon: `<i class="ag-icon ${IconName.Deepdive}"/>`,
            },
            copyCustomerOrderExecutionTextMenuItem(data),
            {
              name: `Modify spread`,
              action: () => handleModifySpread(data),
              disabled: isOrderComplete(data.OrdStatus),
            },
            {
              name: `Cancel`,
              action: () => handleCancel(data),
              disabled: isOrderComplete(data.OrdStatus),
            },
            showForceFill(data)
              ? {
                  name: `Force fill`,
                  action: () => handleForceFill(data),
                }
              : null,
            isAuthorized(ACTION.FORCE_CANCEL_ORDER)
              ? {
                  name: `Force cancel`,
                  action: () => handleForceCancel(data),
                  disabled: isOrderComplete(data.OrdStatus),
                }
              : null,
            {
              name: `Show JSON`,
              action: () => handleClickJson(data),
            },
            'separator',
          ]
        : []),
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const onOpenDetails = useNavigateToOrderDetails(generateOrderDetailsRoute);

  const handleOpenHedgeOrderDetails = useDynamicCallback((data: CustomerOrder) => {
    onOpenDetails(data.OrderID, 'customer-order', 'details');
  });

  const handleCancel = useDynamicCallback((data: CustomerOrder) => {
    setCustomerOrderID(data.OrderID);
    cancelCustomerOrderDialog.open();
  });

  const handleModifySpread = useDynamicCallback((data: CustomerOrder) => {
    setCustomerOrderID(data.OrderID);
    modifyCustomerOrderSpreadDialog.open();
  });

  // Force fill
  const handleForceFill = useDynamicCallback((data: CustomerOrder) => {
    setCustomerOrderID(data.OrderID);
    forceFillOrderDialog.open();
  });

  // Force cancel
  const handleForceCancel = useDynamicCallback((data: CustomerOrder) => {
    openGlobalDialog({
      title: 'Force cancel customer order',
      content: <>{`Are you sure you want to force cancel customer order #${abbreviateId(data.OrderID)}?`}</>,
      onConfirm: () => {
        request('PUT', `${orgApiEndpoint}/admin/customer/orders/${data.OrderID}/force-cancel`)
          .then(() => {
            addToast({
              text: `Order force cancelled.`,
              variant: NotificationVariants.Positive,
            });
          })
          .catch((e: ErrorEvent) =>
            addToast({
              text: `Could not force cancel customer order: ${e.message}`,
              variant: NotificationVariants.Negative,
            })
          );
      },
    });
  });

  const dialogs = useMemo(
    () => (
      <>
        {jsonModal}
        <ModifyOrderSpreadDialog {...modifyCustomerOrderSpreadDialog} customerOrderID={customerOrderID} />
        <CancelOrderDialog {...cancelCustomerOrderDialog} customerOrderID={customerOrderID} />
        <ForceFillOrderDialog {...forceFillOrderDialog} customerOrderID={customerOrderID} />
      </>
    ),
    [jsonModal, modifyCustomerOrderSpreadDialog, cancelCustomerOrderDialog, forceFillOrderDialog, customerOrderID]
  );

  const columns = useMemo(
    () =>
      compact([
        {
          type: 'hamburgerMenu',
          id: 'rowMenu',
          params: {
            renderItems: params => (
              <CustomerOrdersMenu
                {...params}
                onShowJson={handleClickJson}
                onModifySpread={handleModifySpread}
                onCancel={handleCancel}
                onForceFill={handleForceFill}
                onForceCancel={handleForceCancel}
              />
            ),
          },
        },
      ] satisfies ColumnDef<CustomerOrder>[]),
    [handleCancel, handleForceFill, handleModifySpread, handleClickJson, handleForceCancel]
  );

  return {
    columns,
    getContextMenuItems,
    dialogs,
  };
}
