import Big from 'big.js';
import type { WritableDraft } from 'immer';
import { defineMessages } from 'react-intl';
import {
  FieldValidationLevel,
  numberIsPositive,
  validatePrecision,
  type DateField,
  type FieldValidationRule,
  type NumericField,
} from '../../../../fields';
import { format, isCounterCurrency, toBigWithDefault } from '../../../../utils';
import type { OrderState } from './types';

const messages = defineMessages({
  minIncrementIsMinIncr: {
    id: 'providers.WLOrderForm.state.OrderSlice.minIncrementIsMinIncr',
    defaultMessage: 'Min increment is {minIncr} {currency}',
  },
  minQuantityIsMinSizeCurrency: {
    id: 'providers.WLOrderForm.state.OrderSlice.minQuantityIsMinSizeCurrency',
    defaultMessage: 'Min quantity is {minSize} {currency}',
  },
  quantityExceedsRejectionThreshold: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityExceedsRejectionThreshold',
    defaultMessage: 'Quantity exceeds rejection threshold {threshold} {currency}',
  },
  quantityExceedsWarningThreshold: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityExceedsWarningThreshold',
    defaultMessage: 'Quantity exceeds warning threshold {threshold} {currency}',
  },
  quantityIsRequired: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityIsRequired',
    defaultMessage: 'Quantity is required',
  },
  quantityShouldBeANumber: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityShouldBeANumber',
    defaultMessage: 'Quantity should be a number',
  },
  marketAccountIsRequired: {
    id: 'providers.WLOrderForm.state.OrderSlice.marketAccountIsRequired',
    defaultMessage: 'Market Account is required',
  },
  pleaseSelectAnOrderSide: {
    id: 'providers.WLOrderForm.state.OrderSlice.pleaseSelectAnOrderSide',
    defaultMessage: 'Please select an order side',
  },
  displayNameIsRequired: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameIsRequired',
    defaultMessage: '{displayName} is required',
  },
  displayNameShouldBeANumber: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameShouldBeANumber',
    defaultMessage: '{displayName} should be a number',
  },
  displayNameShouldBeGreaterThanZero: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameShouldBeGreaterThanZero',
    defaultMessage: '{displayName} should be greater than 0',
  },
  dateCannotBeInThePast: {
    id: 'providers.WLOrderForm.state.OrderSlice.dateCannotBeInThePast',
    defaultMessage: 'Date cannot be in the past',
  },
  displayNameCannotBeAfter: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameCannotBeAfter',
    defaultMessage: "{displayName} can't be after {prettyName}",
  },
  displayNameCannotBeBefore: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameCannotBeBefore',
    defaultMessage: "{displayName} can't be before {prettyName}",
  },
  displayNameShouldBeADate: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameShouldBeADate',
    defaultMessage: '{displayName} should be a date',
  },
  invalidPercentageValue: {
    id: 'providers.WLOrderForm.state.OrderSlice.invalidPercentageValue',
    defaultMessage: 'Invalid percentage value',
  },
  displayNameMustBeAValidInterval: {
    id: 'providers.WLOrderForm.state.OrderSlice.displayNameMustBeAValidInterval',
    defaultMessage: '{displayName} must be a valid interval',
  },
  endTimeCannotBeBeforeStartTime: {
    id: 'providers.WLOrderForm.state.OrderSlice.endTimeCannotBeBeforeStartTime',
    defaultMessage: 'End Time cannot be before Start Time',
  },
  startTimeCannotBeAfterEndTime: {
    id: 'providers.WLOrderForm.state.OrderSlice.startTimeCannotBeAfterEndTime',
    defaultMessage: 'Start Time cannot be after End Time',
  },
  timeCannotBeInThePast: {
    id: 'providers.WLOrderForm.state.OrderSlice.timeCannotBeInThePast',
    defaultMessage: 'Time cannot be in the past',
  },
  quantityCannotBeLessThanAlreadyExecutedQuantity: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantityCannotBeLessThanAlreadyExecutedQuantity',
    defaultMessage: 'Quantity cannot be less than already executed quantity: {CumQty}',
  },
});

export function validateQuantity(state: WritableDraft<OrderState>) {
  state.form.quantityField = state.form.quantityField.validate(
    [
      numberIsPositive,
      quantitySymbolValidation,
      quantityTradingLimitsValidation,
      quantityMoreThanMinSize,
      quantityNotLessThanModifiedOrderCumQtyValidation,
    ],
    state
  );
}

const quantitySymbolValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context) {
    return null;
  }

  const form = context.form;
  const security = form.symbolField.value;
  if (!security) {
    return null;
  }
  const { MinAmtIncrement, MinPriceIncrement, MinSizeIncrement } = security;

  // If this is a counter currency order, use the MinAmtIncrement if it's provided, otherwise, fall
  // back to the MinPriceIncrement field. If it's not counter currency, use MinSizeIncrement.
  const minIncr = isCounterCurrency(form.orderCurrencyField.value, security)
    ? MinAmtIncrement || MinPriceIncrement
    : MinSizeIncrement;

  if (!validatePrecision(minIncr, field.value)) {
    return {
      message: context.intl.formatMessage(messages.minIncrementIsMinIncr, {
        minIncr,
        currency: form.orderCurrencyField.value,
      }),
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

const quantityMoreThanMinSize: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (field, context) => {
  if (!context) {
    return null;
  }

  const form = context.form;
  const security = form.symbolField.value;
  const ccConversionRate = context.dependencies.ccConversionRate;
  if (!security) {
    return null;
  }
  const { MinPriceIncrement, MinimumSize, MinAmtIncrement, QuoteCurrency, BaseCurrency } = security;
  let minSize = MinimumSize;
  // If this is a counter currency order we must still be able to validate the minimum allowed value.
  // `security.minimumSize` is given in the base currency, and there's currently no corresponding property for the counter currency.
  // When dealing with a counter-currency, we want to first find the conversion rate of the BaseCurrency (above),
  // and then multiply that conversion rate by the MinimumSize. This gives us the minimum amount in units of
  // QuoteCurrency at this point in time. Then, we need to round UP to the nearest precision of MinAmt or MinPrice increment.
  if (
    isCounterCurrency(form.orderCurrencyField.value, security) &&
    ccConversionRate?.Rate != null &&
    ccConversionRate.EquivalentCurrency === QuoteCurrency &&
    ccConversionRate.Asset === BaseCurrency
  ) {
    const [_, precision = ''] = (MinAmtIncrement || MinPriceIncrement)?.split('.') ?? [];
    minSize = Big(ccConversionRate.Rate)
      .times(MinimumSize)
      .round(precision.length, 3 /* BigJS ROUND_UP */)
      .toFixed(precision.length);
  }

  if (toBigWithDefault(field.value, 0).lt(minSize)) {
    return {
      message: context.intl.formatMessage(messages.minQuantityIsMinSizeCurrency, {
        minSize,
        currency: form.orderCurrencyField.value,
      }),
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

const quantityNotLessThanModifiedOrderCumQtyValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (
  field,
  context
) => {
  if (!context || !context.orderBeingModified) {
    return null;
  }

  // Ensure that the quantity is not less than the modified order's CumQty
  if (field.value && toBigWithDefault(field.value, 0).gte(toBigWithDefault(context.orderBeingModified.CumQty, 0))) {
    return null;
  } else {
    return {
      message: context.intl.formatMessage(messages.quantityCannotBeLessThanAlreadyExecutedQuantity, {
        CumQty: context.orderBeingModified.CumQty,
      }),
      level: FieldValidationLevel.Error,
    };
  }
};

const quantityTradingLimitsValidation: FieldValidationRule<NumericField, WritableDraft<OrderState>> = (
  field,
  context
) => {
  if (!context?.dependencies.tradingLimitsValidation) {
    return null;
  }
  const { reject, warn, limit } = context.dependencies.tradingLimitsValidation;
  if (!limit) {
    return null;
  }

  if (reject) {
    return {
      message: context.intl.formatMessage(messages.quantityExceedsRejectionThreshold, {
        threshold: format(limit.RejectThreshold),
        currency: limit.ThresholdCurrency,
      }),
      level: FieldValidationLevel.Error,
    };
  }
  if (warn) {
    return {
      message: context.intl.formatMessage(messages.quantityExceedsWarningThreshold, {
        threshold: format(limit.WarnThreshold),
        currency: limit.ThresholdCurrency,
      }),
      level: FieldValidationLevel.Warning,
    };
  }
  return null;
};

export const notInPastValidation: FieldValidationRule<DateField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !field.hasValue) {
    return null;
  }

  const now = new Date();
  const time = field.value!;

  if (now.valueOf() - new Date(time).valueOf() > 0) {
    return {
      message: context.intl.formatMessage(messages.timeCannotBeInThePast),
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};

export const endTimeValidation: FieldValidationRule<DateField, WritableDraft<OrderState>> = (field, context) => {
  if (!context || !context.form.parameters.StartTime?.hasValue || !field.value) {
    return null;
  }

  const startTime = context.form.parameters.StartTime.value;
  const endTime = context.form.parameters.EndTime.value;

  const comparingAgainstEndTime = field.name.toLocaleLowerCase().includes('start');

  if (new Date(startTime).valueOf() - new Date(endTime).valueOf() >= 0) {
    let message = context.intl.formatMessage(messages.endTimeCannotBeBeforeStartTime);
    if (comparingAgainstEndTime) {
      message = context.intl.formatMessage(messages.startTimeCannotBeAfterEndTime);
    }

    return {
      message,
      level: FieldValidationLevel.Error,
    };
  }

  return null;
};
