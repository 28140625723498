import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ACTION,
  AggregationType,
  BlotterDensity,
  BlotterTable,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Dialog,
  HStack,
  IconName,
  LocalFilterInput,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  Text,
  useBlotterTable,
  useDisclosure,
  useDynamicCallback,
  useGlobalToasts,
  useMarketAccountsContext,
  useMixpanel,
  useObservable,
  type Column,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-enterprise';
import { useRoleAuth } from 'hooks';
import { useAggregations } from 'hooks/useAggregations';
import { useBlotterState } from 'providers/AppConfigProvider';
import { map, of } from 'rxjs';
import type { AggregationWithAccounts } from 'types';
import { useAggregationBlotterTableMenu } from './AggregationBlotterTableMenu';
import { NewAggregationDialog, useAddEditAggregationDialog } from './NewAggregationDialog';

export const TradingAggregation = () => {
  const { listAggregationsWithAccounts, deleteAggregation } = useAggregations();
  const [aggregations, setAggregations] = useState<AggregationWithAccounts[]>();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const { marketAccountsByName } = useMarketAccountsContext();
  const { add: addToast } = useGlobalToasts();
  const { filterValueAggregations, setFilterValueAggregations } = useBlotterState();
  const { isAuthorized } = useRoleAuth();
  const [aggregation, setAggregation] = useState<AggregationWithAccounts | null>(null);
  const mixpanel = useMixpanel();

  const dataObservable = useObservable(
    () => of(aggregations ?? []).pipe(map(data => ({ data, initial: true, type: 'Aggregation' }))),
    [aggregations]
  );

  const refreshAggregations = useCallback(() => {
    listAggregationsWithAccounts(AggregationType.User)
      .then(data => setAggregations(data))
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Could not list aggregations: ${e.message}`,
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, listAggregationsWithAccounts]);

  useEffect(() => {
    refreshAggregations();
  }, [refreshAggregations]);

  const handleDeleteAggregation = useCallback(
    (aggregation: AggregationWithAccounts) => {
      deleteAggregation(aggregation.Name)
        .then(() => {
          addToast({
            text: `Aggregation removed.`,
            variant: NotificationVariants.Positive,
          });
          refreshAggregations();
        })
        .catch(e => {
          addToast({
            text: (e as Error).message,
            variant: NotificationVariants.Negative,
          });
        });
    },
    [addToast, deleteAggregation, refreshAggregations]
  );

  const deleteAggregationDialog = useDisclosure();

  const editAggregationDialog = useAddEditAggregationDialog({
    onSaved: refreshAggregations,
    isAdding: isAdding,
  });

  const handleEditAggregation = useDynamicCallback((aggregation: AggregationWithAccounts) => {
    setIsAdding(false);
    editAggregationDialog.onEdit(aggregation);
  });

  const aggregationBlotterTableMenu = useAggregationBlotterTableMenu({
    openDeleteDialog: deleteAggregationDialog.open,
    onEditAggregation: handleEditAggregation,
    setAggregation: setAggregation,
  });

  const columns = useMemo<Column[]>(
    () =>
      isAuthorized(ACTION.EDIT_AGGREGATION_SETTINGS)
        ? [
            { type: 'text', sortable: true, field: 'Name', title: 'Name', width: 150 },
            { type: 'text', sortable: true, field: 'DisplayName', title: 'DisplayName', width: 150 },
            {
              type: 'tradingMarketAccounts',
              field: 'Accounts',
              title: 'Accounts',
              flex: 1,
              params: {
                marketAccountsByName: marketAccountsByName,
              },
            },
            {
              id: 'edit',
              type: 'iconButton',
              pinned: 'right',
              width: 60,
              suppressColumnsToolPanel: true,
              params: {
                onClick: ({ node }: { node: IRowNode }) => handleEditAggregation(node.data),
                icon: IconName.Pencil,
              },
            },
            aggregationBlotterTableMenu.column,
          ]
        : [
            { type: 'text', sortable: true, field: 'Name', title: 'Name', width: 150 },
            { type: 'text', sortable: true, field: 'DisplayName', title: 'DisplayName', width: 150 },
            {
              type: 'tradingMarketAccounts',
              field: 'Accounts',
              title: 'Accounts',
              flex: 1,
              params: {
                marketAccountsByName: marketAccountsByName,
              },
            },
          ],
    [handleEditAggregation, aggregationBlotterTableMenu.column, marketAccountsByName, isAuthorized]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    columns,
    rowID: 'DisplayName',
    density: BlotterDensity.Comfortable,
    sort: '+DisplayName',
    quickSearchParams: {
      filterText: filterValueAggregations,
    },
    gridOptions: {
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: aggregationBlotterTableMenu.getContextMenuItems,
    },
  });

  const headerActions = (
    <PanelActions>
      <LocalFilterInput
        placeholder="Filter by Name"
        value={filterValueAggregations}
        onChange={e => {
          mixpanel.track(MixpanelEvent.FilterQuickSearch, {
            [MixpanelEventProperty.Source]: 'TradingAggregations',
          });
          setFilterValueAggregations(e);
        }}
      />
      <Button
        startIcon={IconName.Plus}
        onClick={() => {
          setIsAdding(true);
          editAggregationDialog.onAdd();
        }}
        variant={ButtonVariants.Positive}
        disabled={!isAuthorized(ACTION.EDIT_AGGREGATION_SETTINGS)}
        data-testid="add-aggregation-button"
      >
        Add Aggregation
      </Button>
    </PanelActions>
  );

  if (aggregations == null) {
    return null;
  }

  return (
    <HStack h="100%" w="100%" gap="spacingSmall" overflow="hidden">
      <Panel>
        <PanelHeader>
          <h2>Trading Aggregations</h2>
          {headerActions}
        </PanelHeader>
        <PanelContent>
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
      <NewAggregationDialog {...editAggregationDialog} width={400} />
      <Dialog
        {...deleteAggregationDialog}
        title="Delete Aggregation"
        onConfirm={() => (aggregation ? handleDeleteAggregation(aggregation) : {})}
        confirmLabel="Delete Aggregation"
        showClose={true}
        variant={ButtonVariants.Negative}
      >
        <Text>Are you sure you want to delete aggregation &quot;{aggregation?.DisplayName}&quot;?</Text>
      </Dialog>
    </HStack>
  );
};
