import { defineMessages } from 'react-intl';
import { Button, type ButtonProps } from '../Button';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { useAccordionGroupContext } from './AccordionGroup/AccordionGroupContext';

const messages = defineMessages({
  collapseAll: {
    defaultMessage: 'Collapse All',
    id: 'Accordion.collapseAll',
  },
  expandAll: {
    defaultMessage: 'Expand All',
    id: 'Accordion.expandAll',
  },
});

type ToggleAccordionsButtonProps = Omit<ButtonProps, 'onClick' | 'children'>;

export const ToggleAccordionsButton = (props: ToggleAccordionsButtonProps) => {
  const { toggleAllAccordions, toggleAllToOpen } = useAccordionGroupContext();
  return (
    <Button {...props} onClick={toggleAllAccordions} endIcon={toggleAllToOpen ? IconName.Plus : IconName.Minus}>
      <FormattedMessage {...(toggleAllToOpen ? messages.expandAll : messages.collapseAll)} />
    </Button>
  );
};
