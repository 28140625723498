import {
  Button,
  ButtonVariants,
  type ExportGridMode,
  FormControlSizes,
  type GetValueForGroupNodeContext,
  IconName,
  MixpanelEvent,
  type PopoverState,
  type UseBlotterTable,
  createExportFileName,
  useCurrenciesContext,
  useMixpanel,
  useSecuritiesContext,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { getPortfolioRiskHierarchyFormattedValue } from './PortfolioRiskBlotter/useRollupTreeGridBuilders';

export const RiskBlotterExportMenuItems = ({
  exportDataAsCSV,
  exportDataAsExcel,
  label,
  handleClose,
}: {
  exportDataAsCSV: UseBlotterTable<unknown>['exportDataAsCSV'];
  exportDataAsExcel: UseBlotterTable<unknown>['exportDataAsExcel'];
  label: string;
  handleClose: PopoverState['close'];
}) => {
  const mixpanel = useMixpanel();

  const handleClickExport = useCallback(
    (mode: ExportGridMode) => {
      mixpanel.track(mode === 'CSV' ? MixpanelEvent.ExportRows : MixpanelEvent.ExportRowsToExcel);
      const fileName = createExportFileName({
        name: label,
        mode,
      });
      switch (mode) {
        case 'CSV': {
          mixpanel.track(MixpanelEvent.ExportRows);
          exportDataAsCSV({
            fileName,
            skipRowGroups: false,
          });
          break;
        }
        case 'Excel': {
          mixpanel.track(MixpanelEvent.ExportRowsToExcel);
          exportDataAsExcel({
            fileName,
            sheetName: fileName,
          });
          break;
        }
      }
    },
    [exportDataAsCSV, exportDataAsExcel, label, mixpanel]
  );

  const exportModes: ExportGridMode[] = ['Excel', 'CSV'];
  return (
    <>
      {exportModes.map(mode => (
        <Button
          key={mode}
          startIcon={IconName.DocumentDownload}
          variant={ButtonVariants.Default}
          size={FormControlSizes.Small}
          onClick={() => {
            handleClickExport(mode);
            handleClose();
          }}
        >
          Export {mode}
        </Button>
      ))}
    </>
  );
};

/** Get Blotter AgGrid context object addition to support Excel and CSV Export */
export function useGroupedDataExportContext(): Pick<GetValueForGroupNodeContext, 'getValueForGroupedNode'> {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();

  return useMemo(() => {
    function getGroupedColumnValue(node: IRowNode): string {
      const hierarchyArray = [
        getPortfolioRiskHierarchyFormattedValue(node, {
          currenciesBySymbol,
          securitiesBySymbol,
        }),
      ];

      // AgGrid has a root parent which is not useful for the hierarchy
      while (node.parent?.level != null && node.parent.level >= 0) {
        node = node.parent;
        hierarchyArray.unshift(
          getPortfolioRiskHierarchyFormattedValue(node, {
            currenciesBySymbol,
            securitiesBySymbol,
          })
        );
      }
      return hierarchyArray.join(' > ');
    }
    const result: Pick<GetValueForGroupNodeContext, 'getValueForGroupedNode'> = {
      getValueForGroupedNode: (node, mode) => {
        return getGroupedColumnValue(node);
      },
    };
    return result;
  }, [currenciesBySymbol, securitiesBySymbol]);
}
