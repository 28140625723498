import { defineMessages } from 'react-intl';

export const fieldsMessages = defineMessages({
  dataNameIsRequired: {
    id: 'fields.dataNameIsRequired',
    defaultMessage: '{dataName} is required',
  },
  dataNameMustBePositive: {
    id: 'fields.dataNameMustBePositive',
    defaultMessage: '{dataName} must be positive',
  },
  dataNameExceedsMaximumDpsOfScale: {
    id: 'fields.dataNameExceedsMaximumDpsOfScale',
    defaultMessage: '{dataName} exceeds maximum dps of {scale}',
  },
  minQuantityIsMinSizeCurrency: {
    id: 'fields.minQuantityIsMinSizeCurrency',
    defaultMessage: 'Min quantity is {minSize} {currency}',
  },
});
