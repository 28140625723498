import type { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../Core';

type IndicatorProps = {
  targetPercent: number | undefined;
  color: string;
  renderIndicator: boolean;
};

export const MeterTargetIndicatorWrapper = ({
  children,
  color,
  targetPercent,
  renderIndicator,
}: PropsWithChildren<IndicatorProps>) => {
  return (
    <MeterWrapper>
      <Indicator color={color} targetPercent={targetPercent} renderIndicator={renderIndicator}>
        <svg width="12.5" height="20" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.24061 20.8537C4.0515 21.1269 4.24701 21.5 4.57925 21.5H10.4208C10.753 21.5 10.9485 21.1269 10.7594 20.8537L7.83864 16.6348C7.67486 16.3983 7.32514 16.3983 7.16136 16.6348L4.24061 20.8537Z"
            fill="currentColor"
          />
          <path
            d="M10.7594 3.14631C10.9485 2.87315 10.753 2.5 10.4208 2.5L4.57925 2.5C4.24701 2.5 4.0515 2.87315 4.24061 3.14631L7.16136 7.36517C7.32514 7.60175 7.67486 7.60175 7.83864 7.36517L10.7594 3.14631Z"
            fill="currentColor"
          />
        </svg>
      </Indicator>
      {children}
    </MeterWrapper>
  );
};

/**
 * This is the indicator that shows the target position on the meter.
 * It is a thin line on the meter, and the pseudo-elements are two arrows pointing at it.
 */
const Indicator = styled(Box)<IndicatorProps>`
  z-index: 1;
  position: relative;

  svg {
    display: none;
    visibility: hidden;
  }

  ${({ targetPercent, theme, color, renderIndicator }) =>
    renderIndicator &&
    targetPercent !== undefined &&
    css`
      position: absolute;
      top: 0px;
      bottom: 0px;
      transform: translateX(-100%);
      background-color: ${theme.colors.gray['040']};
      text-align: center;
      width: 1px;
      ${targetPercent === 0
        ? css`
            // It needs to be placed dead center, between the two meters
            left: calc(100% + 3px);
          `
        : targetPercent < 0
        ? css`
            right: min(${Math.abs(targetPercent || 0) * 100}%, 100%);
          `
        : css`
            left: min(${Math.abs(targetPercent || 0) * 100}%, 100%);
          `}

      svg {
        display: unset;
        visibility: visible;
        color: ${color};
        position: absolute;
        text-align: center;
        transform: translate(-50%, -35%);
      }
    `}
`;

const MeterWrapper = styled(Box)`
  width: 100%;
  position: relative;
`;
