import { Alert, AlertVariants, FormControlSizes, Icon, IconName, Tooltip } from '@talos/kyoko';
import { useSourceAccounts } from 'containers/Portfolio/providers/SourceAccountsProvider';
import type { TransferInstruction } from 'containers/Portfolio/types';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

interface PotentialAccountWarningProps {
  transferInstruction: TransferInstruction;
}

export const PotentialAccountWarning = ({ transferInstruction }: PotentialAccountWarningProps) => {
  const { sourceAccountsByCurrency } = useSourceAccounts();
  const { colorTextWarning } = useTheme();

  // Transfers are made from the source account to the destination account.
  // Check that the receiving destination account supports the currency, otherwise display a warning.
  const showWarning = useMemo(() => {
    if (!sourceAccountsByCurrency) {
      return false;
    }

    const accountsInCurrency = sourceAccountsByCurrency?.get(transferInstruction.Currency);
    if (!accountsInCurrency) {
      return false;
    }

    return !accountsInCurrency.find(account => account.Name === transferInstruction.DestinationMarketAccount);
  }, [sourceAccountsByCurrency, transferInstruction]);

  if (!showWarning) {
    return null;
  }

  return (
    <Tooltip
      tooltip={
        <Alert variant={AlertVariants.Warning} size={FormControlSizes.Small} dismissable={false} m="spacingSmall">
          This receiving account doesn&apos;t seem to support {transferInstruction.Currency}. Review your account setup.
        </Alert>
      }
    >
      <Icon icon={IconName.ExclamationSolid} color={colorTextWarning} />
    </Tooltip>
  );
};
