import { defineMessages } from 'react-intl';

export const orderSliceMessages = defineMessages({
  limitPrice: {
    id: 'providers.WLOrderForm.state.OrderSlice.limitPrice',
    defaultMessage: 'Limit Price',
  },
  stopPrice: {
    id: 'providers.WLOrderForm.state.OrderSlice.stopPrice',
    defaultMessage: 'Stop Price',
  },
  startTime: {
    id: 'providers.WLOrderForm.state.OrderSlice.startTime',
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: 'providers.WLOrderForm.state.OrderSlice.endTime',
    defaultMessage: 'End Time',
  },
  clipSize: {
    id: 'providers.WLOrderForm.state.OrderSlice.clipSize',
    defaultMessage: 'Clip Size',
  },
  clipInterval: {
    id: 'providers.WLOrderForm.state.OrderSlice.clipInterval',
    defaultMessage: 'Clip Interval',
  },
  variance: {
    id: 'providers.WLOrderForm.state.OrderSlice.variance',
    defaultMessage: 'Variance',
  },
  triggerPrice: {
    id: 'providers.WLOrderForm.state.OrderSlice.triggerPrice',
    defaultMessage: 'Trigger Price',
  },
  marketAccount: {
    id: 'providers.WLOrderForm.state.OrderSlice.marketAccount',
    defaultMessage: 'Market Account',
  },
  quantity: {
    id: 'providers.WLOrderForm.state.OrderSlice.quantity',
    defaultMessage: 'Quantity',
  },
  strategy: {
    id: 'providers.WLOrderForm.state.OrderSlice.strategy',
    defaultMessage: 'Strategy',
  },
});
