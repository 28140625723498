import type { Colors } from 'styled-components';
import { useConstant } from '../../hooks';
import { HedgeControlStatusEnum, type IHedgePositionStatus } from '../../types';
import { toBig, type AbbreviateOptions, type Leaves } from '../../utils';
import { AbbrLimit } from '../AbbrLimit';
import { Box, Flex, HStack, VStack, type BoxProps } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { IconName } from '../Icons';
import { LeftMiddleRightFlexbox } from '../LeftMiddleRightFlexbox';
import { Meter } from '../Meter';
import { Table, Tbody, Td, Th, Tr } from '../Table';
import { Text } from '../Text';
import { JITTooltip } from '../Tooltip';
import {
  getPositionThresholdMeterValues,
  type PositionThresholdMeterValuesArgs,
} from './getPositionThresholdMeterPercents';
import { MeterTargetIndicatorWrapper } from './styles';

type PositionThresholdMeterProps = {
  value: PositionThresholdMeterValuesArgs;
  updated?: Record<'LongPositionLimit' | 'ShortPositionLimit' | 'TargetPosition' | 'TargetAsset', boolean>;
};

const ABBREVIATE_OPTIONS: AbbreviateOptions = {
  lowestThreshold: 0,
  decimalThreshold: 0.0001,
  decimalIncrement: 4,
};

export function PositionThresholdMeter({ value, updated }: PositionThresholdMeterProps) {
  const jitWrapperProps: BoxProps = useConstant({ w: '100%' });

  const { currentPercent, targetPercent } = getPositionThresholdMeterValues(value);
  const positionAtZero = currentPercent === 0;
  const meterColor = iconColorByHedgePositionStatus[value.HedgeControlStatus ?? HedgeControlStatusEnum.Disabled];
  return (
    <VStack
      w="100%"
      h="100%"
      py="spacingSmall"
      justifyContent="space-between"
      fontSize="fontSizeTiny"
      color="colorTextDefault"
      position="relative"
      zIndex={0}
    >
      <JITTooltip tooltip={<PositionThresholdMeterTooltipContent value={value} />} wrapperProps={jitWrapperProps}>
        <Flex flexDirection="column" w="100%" gap="spacingSmall" position="relative">
          <HStack w="100%" gap="spacingTiny" mb="1px">
            <MeterTargetIndicatorWrapper
              renderIndicator={targetPercent <= 0}
              targetPercent={targetPercent}
              color={meterColor}
            >
              <Meter
                value={currentPercent < 0 ? currentPercent * -1 : 0}
                showLabel={false}
                alignBar="right"
                showInitialAnimation={false}
                color={meterColor}
                roundStartingEdge
                height={6}
              />
            </MeterTargetIndicatorWrapper>
            <Box
              minWidth="1px"
              background={positionAtZero ? meterColor : 'backgroundProgressBar'}
              alignSelf="stretch"
              my="1px"
            />
            <MeterTargetIndicatorWrapper
              targetPercent={targetPercent}
              color={meterColor}
              renderIndicator={targetPercent > 0}
            >
              <Meter
                value={currentPercent > 0 ? currentPercent : 0}
                showLabel={false}
                color={meterColor}
                showInitialAnimation={false}
                roundStartingEdge
                height={6}
              />
            </MeterTargetIndicatorWrapper>
          </HStack>
          <LeftMiddleRightFlexbox
            left={
              <Text
                fontStyle={updated?.ShortPositionLimit || updated?.TargetAsset ? 'italic' : undefined}
                display="flex"
              >
                {toBig(value.ShortPositionLimit)?.gt(0) ? '-' : ''}
                <AbbrLimit
                  currency={value.TargetAsset}
                  value={toBig(value.ShortPositionLimit)?.abs().toFixed(4)}
                  options={ABBREVIATE_OPTIONS}
                />
              </Text>
            }
            middle={
              <>
                <AbbrLimit value="0" options={ABBREVIATE_OPTIONS} />
              </>
            }
            right={
              <Text
                fontStyle={updated?.LongPositionLimit || updated?.TargetAsset ? 'italic' : undefined}
                display="flex"
              >
                <AbbrLimit
                  currency={value.TargetAsset}
                  value={toBig(value.LongPositionLimit)?.abs().toFixed(4)}
                  options={ABBREVIATE_OPTIONS}
                />
              </Text>
            }
          />
        </Flex>
      </JITTooltip>
    </VStack>
  );
}

function PositionThresholdMeterTooltipContent({ value }: PositionThresholdMeterProps) {
  const { CurrentPosition, TargetAsset, LongPositionLimit, ShortPositionLimit, TargetPosition } = value;

  return (
    <Table data-testid="position-table">
      <Tbody>
        <Tr data-testid="short-limit-threshold">
          <Th>Short Limit Threshold</Th>
          <Td align="right">
            <InlineFormattedNumber round number={ShortPositionLimit} currency={TargetAsset} />
          </Td>
        </Tr>
        <Tr data-testid="target-position">
          <Th>Target Position</Th>
          <Td align="right">
            <InlineFormattedNumber round number={TargetPosition} currency={TargetAsset} />
          </Td>
        </Tr>
        <Tr data-testid="long-limit-threshold">
          <Th>Long Limit Threshold</Th>
          <Td align="right">
            <InlineFormattedNumber round number={LongPositionLimit} currency={TargetAsset} />
          </Td>
        </Tr>
        <Tr data-testid="current-position">
          <Th>Current Position</Th>
          <Td align="right">
            <InlineFormattedNumber round number={CurrentPosition} currency={TargetAsset} />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}

export const iconByHedgePositionStatus: Record<IHedgePositionStatus['HedgeControlStatus'], IconName> = {
  Disabled: IconName.PauseCircle,
  Error: IconName.CloseCircle,
  Rejected: IconName.ExclamationCircleSolid,
  Hedging: IconName.CheckCircle,
  Waiting: IconName.Clock,
  Disabling: IconName.DotsVertical,
  Incomplete: IconName.CircleDotted,
  Complete: IconName.CheckCircle,
  Synchronizing: IconName.Refresh,
  Tripped: IconName.Scale,
};

export const iconColorByHedgePositionStatus: Record<IHedgePositionStatus['HedgeControlStatus'], Leaves<Colors>> = {
  Disabled: 'gray.090',
  Error: 'red.lighten',
  Rejected: 'yellow.lighten',
  Hedging: 'green.lighten',
  Waiting: 'blue.lighten',
  Disabling: 'gray.060',
  Incomplete: 'gray.090',
  Complete: 'green.lighten',
  Synchronizing: 'blue.lighten',
  Tripped: 'yellow.lighten',
};
