import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MarketTrade,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useMarketTradeColumns,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterTableFilter,
  type BlotterTableSort,
  type Column,
  type ColumnState,
} from '@talos/kyoko';
import type { GetMainMenuItemsParams, GridOptions } from 'ag-grid-community';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import { useMemo } from 'react';
import { useMarketTradeMenu } from './MarketTradeMenu';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useMarketTradeFilter,
  type MarketTradeFilter,
} from './useMarketTradeFilter';

export interface MarketTradesBlotterTableProps {
  blotterID: string;
  tabLabel?: string;
  parentOrderID?: string;
  parentRFQID?: string;

  defaultColumns?: (Column | keyof MarketTrade)[];
  defaultFilter?: MarketTradeFilter;
  defaultSort?: BlotterTableSort<MarketTrade>;
  persistColumns?: boolean;
  persistFilter?: boolean;
  persistSort?: boolean;

  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
  onRowDoubleClicked?: NonNullable<GridOptions['onRowDoubleClicked']>;
}

export function MarketTradesBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  defaultSort = '-TransactTime',
  persistFilter,
  persistColumns,
  persistSort,
  initialIsOpen,
  onCloneTab,
  onRowDoubleClicked,
}: MarketTradesBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const defaultColumnDefinitions = useMarketTradeColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<MarketTrade>(blotterID, {
    columns: defaultColumnDefinitions,
    filter: defaultFilter,
    sort: defaultSort,
    persistFilter,
    persistColumns,
    persistSort,
  });

  const filterResults = useMarketTradeFilter({
    persistedBlotterTable,
  });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const marketTradeMenu = useMarketTradeMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterBuilderProps.properties,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, ...marketTradeMenu.columns],
    [marketTradeMenu.columns, persistedBlotterTable.columns]
  );

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      tag: blotterID,
      name: 'MarketTrade',
      sort_by: '-TransactTime',
    },
    rowID: MarketTrade.rowID,
    initialSort: persistedBlotterTable.initialSort,
    filter: onlyServerFilterKeys(filterResults.filter),
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: columnsWithMenu,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    getExtraMainMenuItems,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    gridOptions: {
      onRowDoubleClicked,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: marketTradeMenu.getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'MarketTrades',
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.TransactTime} />
      <BlotterTable {...blotterTable} />
      {marketTradeMenu.dialogs}
    </>
  );
}
