import {
  useDefaultColumns,
  type AgGridPriceProps,
  type Column,
  type ColumnDef,
  type ISubaccount,
  type SizeColumnParams,
  type Tree,
} from '@talos/kyoko';
import type { AssetColumnProps } from '@talos/kyoko/src/components/BlotterTable/columns/columns';
import { useFeatureFlag, useRollupTreeRef } from 'hooks';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import type { PortfolioManagementBlotterDataItem } from '../../types/PortfolioManagementBlotterDataItem';
import { usePnlTagColumns } from '../usePnlTagColumns';
import { getPnLColumn } from '../utils';

function getVisibleColumns(): Array<keyof ReturnType<typeof usePortfolioBreakdownColumnDefs>['mapping']> {
  return ['pmsWarnings', 'position', 'positionHome', 'priceHome', 'avgCostHome', 'deltaExpNet', 'pnl24H'];
}

export const usePortfolioBreakdownColumns = (): {
  defaultColumns: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const rollupTreeRef = useRollupTreeRef();
  const { mapping } = usePortfolioBreakdownColumnDefs(rollupTreeRef, homeCurrency);
  const { extraColMapping, visiblePnlTagColumns } = usePnlTagColumns('breakdown', homeCurrency);

  const fullMapping = useMemo(() => {
    const fullMappingInput = {
      ...mapping,
      ...extraColMapping,
    };
    return new Map<string, Column>(
      Object.entries(fullMappingInput).filter(([, value]) => value !== undefined) as [string, Column][]
    );
  }, [extraColMapping, mapping]);

  const fullVisibleColumns = useMemo(() => {
    return [...getVisibleColumns(), ...visiblePnlTagColumns].filter(item => !!fullMapping.get(item));
  }, [fullMapping, visiblePnlTagColumns]);

  const defaultColumns = useDefaultColumns(fullVisibleColumns, fullMapping);
  return {
    defaultColumns,
  };
};

function usePortfolioBreakdownColumnDefs(
  rollupTreeRef: React.MutableRefObject<Tree<ISubaccount>>,
  homeCurrency: string
) {
  const { showSubAccountPositionPnLTagColumns } = useFeatureFlag();
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        field: 'gridData.PMSWarningColumnValue',
        type: 'pmsWarnings',
        title: { intlKey: 'warning' },
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'gridData.SubAccount',
        type: 'subAccountName',
        title: { intlKey: 'subAccount' },
        sortable: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'gridData.Asset',
        type: 'asset',
        title: { intlKey: 'asset' },
        pinned: 'left',
        sortable: true,
        width: 150,
        params: {
          assetTypeField: 'gridData.AssetType',
          colorful: true,
        } satisfies AssetColumnProps<PortfolioManagementBlotterDataItem>,
        enableRowGroup: true,
      },
      weight: {
        id: 'Weight',
        field: 'gridData.Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
      },
      position: {
        id: 'Position',
        field: 'gridData.Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'gridData.Asset',
          trimTrailingZeroes: true,
          showCurrency: false,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        id: 'PositionHome',
        type: 'size',
        field: 'gridData.Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
          highlightNegative: true,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      price: {
        id: 'MarkPrice',
        field: 'gridData.MarkPrice',
        type: 'price',
        title: { intlKey: 'markPrice' },
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.MarkPriceCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Most recent mark price for the instrument.',
      },
      priceHome: {
        id: 'MarkPriceHome',
        type: 'size',
        field: 'gridData.Equivalent.MarkPrice',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        title: { intlKey: 'markPriceHomeCurrency' },
        titleIntlKeyValues: { homeCurrency },
        description: "Most recent mark price for the instrument, converted to the user's home currency.",
      },
      avgCost: {
        id: 'AvgCost',
        title: 'Avg Cost',
        field: 'position.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'position.AvgCostCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Average cost for the instrument provided by the venue.',
      },
      avgCostHome: {
        id: 'AvgCostHome',
        title: `Avg Cost (${homeCurrency})`,
        field: 'position.Equivalent.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.Equivalent.Currency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Average cost for the instrument provided by the venue.',
      },
      deltaExpNet: {
        id: 'DeltaExpNet',
        field: 'gridData.Equivalent.CcyDeltaExposure.Net',
        type: 'size',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        title: `Net Delta Exp (${homeCurrency})`,
        width: 150,
        description: `Net Delta Exposure: Net equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
      },
      deltaExpGross: {
        id: 'DeltaExpGross',
        field: 'gridData.Equivalent.CcyDeltaExposure.Gross',
        type: 'size',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        },
        sortable: true,
        aggregate: true,
        title: `Gross Delta Exp (${homeCurrency})`,
        width: 150,
        description: `Gross Delta Exposure: Gross equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
      },
      pnl24H: getPnLColumn({
        id: 'PnL24H',
        title: '24H PnL',
        field: 'position.PnLLookbacks.H24.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 24 hours to the current price mark.',
      }),
    } as const satisfies Record<string, ColumnDef<PortfolioManagementBlotterDataItem>>;

    if (showSubAccountPositionPnLTagColumns) {
      const excludedColumns = ['pnl24H'] satisfies Array<keyof typeof mapping>;
      const mappingWithoutExcludedColumns = {
        ...mapping,
        ...Object.fromEntries(excludedColumns.map(column => [column, undefined])),
      };

      return {
        mapping: mappingWithoutExcludedColumns,
      };
    }
    return {
      /** This mapping (as const) is being used for constant typing downstream - See {@link getVisibleColumns} */
      mapping,
    };
  }, [homeCurrency, rollupTreeRef, showSubAccountPositionPnLTagColumns]);
}
