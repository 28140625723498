import { Orientation, type SerializedDockview } from 'dockview';
import { DEFAULT_COLUMNS } from '../../../tokens/appconfig';
import type { TypedGroupviewPanelState } from '../../../types/LayoutConfig';
import { BRANCH, LEAF } from '../../Layout/tokens';
import { BLOTTER_PANELS, BLOTTERS_GROUP } from './shared';

// TODO Remove options if not enabled
export const DEFAULT_MAIN_PANELS: Record<string, TypedGroupviewPanelState> = {
  marketCards: {
    id: 'marketCards',
    contentComponent: 'marketCards',
    title: 'Market',
    params: { columns: DEFAULT_COLUMNS, type: 'marketCards' },
  },
  options: {
    id: 'options',
    contentComponent: 'options',
    title: 'Options',
    params: {
      type: 'options',
    },
  },
};

export const WATCHLIST_PANELS: Record<string, TypedGroupviewPanelState> = {
  watchlist: {
    id: 'watchlist',
    contentComponent: 'watchlist',
    title: 'Watchlist',
    params: {
      type: 'watchlist',
    },

    // maximumWidth: MAX_WATCHLIST_WIDTH,
    // minimumWidth: MIN_WATCHLIST_WIDTH,
  },
};

export const DEFAULT_PANELS: Record<string, TypedGroupviewPanelState> = {
  ...WATCHLIST_PANELS,
  ...DEFAULT_MAIN_PANELS,
  ...BLOTTER_PANELS,
};

export const DEFAULT_GRID: SerializedDockview['grid'] = {
  root: {
    type: BRANCH,
    data: [
      {
        type: BRANCH,
        data: [
          {
            type: BRANCH,
            data: [
              {
                type: LEAF,
                visible: true,
                data: { views: ['watchlist'], activeView: 'watchlist', id: 'watchlists', initialWidth: 420 },
                size: 370,
              },
              {
                type: LEAF,
                data: {
                  views: ['marketCards', 'options'],
                  activeView: 'marketCards',
                  id: 'main',
                },
                size: 1100,
              },
            ],
            size: 700,
          },
          BLOTTERS_GROUP,
        ],
        size: 1100,
      },
      // OMS_TAB_GROUP,
      // FEED_TAB_GROUP,
    ],
  },
  width: 1600,
  height: 900,
  orientation: Orientation.HORIZONTAL,
};

export const DEFAULT_LAYOUT: SerializedDockview = {
  grid: DEFAULT_GRID,
  panels: DEFAULT_PANELS,
};
