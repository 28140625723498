import { identity, values } from 'lodash';
import { useMemo } from 'react';
import { MarketAccountTypeEnum } from '../../../../types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

export const useMarketAccountTypesFilter = () => {
  return useMemo(
    () =>
      ({
        key: 'MarketAccountTypes',
        label: 'Market Account Type',
        icon: IconName.Puzzle,
        options: values(MarketAccountTypeEnum),
        getOptionLabel: identity,
      } as const satisfies FilterableProperty),
    []
  );
};
