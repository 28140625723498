import {
  ACTION,
  Alert,
  AlertVariants,
  AppwideDrawerContentType,
  Box,
  Button,
  ButtonGroup,
  ButtonVariants,
  CurrencySelect,
  Dialog,
  DrawerContent,
  DrawerFooter,
  EMPTY_ARRAY,
  Flex,
  FormControlSizes,
  FormGroup,
  FormSection,
  HStack,
  HedgeControlStatusEnum,
  Icon,
  IconName,
  InlineFormattedNumber,
  LoaderTalos,
  MixpanelEvent,
  MixpanelEventSource,
  MixpanelSourceProvider,
  ModeEnum,
  NotificationVariants,
  NumberInput,
  SearchSelect,
  Text,
  Toasts,
  VStack,
  setAlpha,
  useAppwideDrawerContext,
  useCurrenciesContext,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  useObservableValue,
  useSecuritiesContext,
  useStrategiesContext,
  useToasts,
  type Aggregation,
  type AggregationWithAccounts,
  type Currency,
  type IHedgeRule,
  type ISubaccount,
  type OrderStrategy,
} from '@talos/kyoko';
import { getFormGroupMarginBottom } from '@talos/kyoko/src/components/Form/FormGroup/styles';
import Big from 'big.js';
import { flatten, noop } from 'lodash';
import { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { map, of } from 'rxjs';
import styled, { useTheme } from 'styled-components';
import {
  useHedgeRuleCreationMutation,
  useHedgeRuleModificationMutation,
  useHedgeRuleQuery,
} from '../../containers/Trading/Markets/PositionAutoHedgingRules/queries';
import { useFeatureFlag, useRoleAuth } from '../../hooks';
import { useAggregationsQuery } from '../../hooks/useAggregations';
import { ContextGuideContext, useSubAccountRollupMemberships, useSubAccounts } from '../../providers';
import { useHedgePositionStatus, type IHedgePositionStatusRow } from '../../providers/HedgePositionStatusProvider';
import { AggregationsSelector } from '../AggregationsSelector';
import type { HedgeRulePostPayload } from '../AutoHedging/types';
import { useNavigateToHedgeRulesSetup } from '../AutoHedging/useNavigateToHedgeRulesSetup';
import { GuideButton } from '../GuideButton';
import { ToggleParameter } from '../OMS/Parameters/ToggleParameter';
import { SectionTitle } from '../SectionTitle';
import { PositionAutoHedgingCard, useCurrentPosition } from './PositionAutoHedgingCard';
import { PositionAutoHedgingStatus, PositionControlActions } from './PositionAutoHedgingStatus';
import {
  getInitialState,
  init,
  reducer,
  setHedgeRule,
  touchAll,
  updateField,
  updateMode,
  usePositionAutoHedgingDrawerDependencies,
} from './reducer';
import type { EditIHedgeRuleForm, InitReducerPayload } from './types';
import {
  selectFormHasChanged,
  selectFormIsValid,
  selectFormValues,
  selectFormValuesValid,
  selectHedgeSymbol,
  selectIsHedgeRuleOutOfSync,
} from './utils';

export const usePositionAutoHedgingRulesDrawer = () => {
  const mixpanel = useMixpanel();
  const { openDrawer } = useAppwideDrawerContext();

  const handleOnClickOpenRules = useNavigateToHedgeRulesSetup();

  const openPositionAutoHedgingRulesDrawer = useDynamicCallback(
    (payload?: { hedgeRuleID: IHedgeRule['HedgeRuleID'] }) => {
      mixpanel.track(MixpanelEvent.OpenAutoHedgePositionRuleDrawer);
      openDrawer({
        type: AppwideDrawerContentType.AutoHedgePositionRule,
        title: 'Position Autohedging Rule',
        renderHeaderContent: () => (
          <HStack gap="spacingSmall">
            <Button ghost size={FormControlSizes.Small} onClick={handleOnClickOpenRules}>
              <HStack color="colorTextSubtle" gap="spacingTiny">
                <Icon size={12} icon={IconName.ExternalLink} />
                <Text>Open Rules</Text>
              </HStack>
            </Button>
            <GuideButton guideID="auto-hedge-position-rule" />
          </HStack>
        ),
        renderContent: () => (
          <MixpanelSourceProvider value={MixpanelEventSource.PositionAutoHedgingDrawer}>
            <DrawerLoaderWrapper hedgeRuleID={payload?.hedgeRuleID} />
          </MixpanelSourceProvider>
        ),
      });
    }
  );

  return {
    openPositionAutoHedgingRulesDrawer,
  };
};

type DrawerLoaderWrapperProps = {
  hedgeRuleID?: IHedgeRule['HedgeRuleID'];
};

function DrawerLoaderWrapper({ hedgeRuleID: initialHedgeRuleID, ...rest }: DrawerLoaderWrapperProps) {
  // Keep track of the current hedge rule ID, this will change from undefined to the actual ID once the a rule is created.
  const [hedgeRuleID, setHedgeRuleID] = useState<string | undefined>(initialHedgeRuleID);

  const hedgeRuleQuery = useHedgeRuleQuery(hedgeRuleID);
  const aggregationsQuery = useAggregationsQuery();

  const { hedgePositionStatusObs } = useHedgePositionStatus();
  const hedgePositionStatus = useObservableValue(
    // Since this runs after the initial render, we deduce if it is loading based on if the hedgePositionStatus
    // is null or status | undefined. Null means it is loading, undefined means it is not found.
    // all a workaround to ensure we don't get jumping jsx.
    () => {
      const hedgeStatus = hedgePositionStatusObs.pipe(map(json => (hedgeRuleID ? json.get(hedgeRuleID) : undefined)));
      if (hedgeStatus) {
        return hedgeStatus;
      }

      return of(null);
    },
    [hedgePositionStatusObs, hedgeRuleID],
    null
  );

  const isHedgePositionStatusLoading = hedgePositionStatus === null;

  if ((hedgeRuleID && hedgeRuleQuery.isLoading) || aggregationsQuery.isLoading || isHedgePositionStatusLoading) {
    return (
      <DrawerContent h="100%">
        <LoaderTalos />
      </DrawerContent>
    );
  }

  return (
    <>
      <AutoHedgingRulesDrawer
        key={hedgeRuleID}
        hedgeRuleID={hedgeRuleID}
        aggregations={aggregationsQuery.data ?? EMPTY_ARRAY}
        hedgePositionStatus={hedgePositionStatus}
        setHedgeRuleID={setHedgeRuleID}
        {...rest}
      />
    </>
  );
}

type AutoHedgingRulesDrawerProps = {
  hedgeRuleID: string | undefined;
  aggregations: AggregationWithAccounts[];
  hedgePositionStatus: IHedgePositionStatusRow | undefined;
  setHedgeRuleID: (hedgeRuleID: string) => void;
};

enum AutoHedgerValidationLevel {
  BUTTON,
  POSITION_THRESHOLD_MODAL,
}

function AutoHedgingRulesDrawer({
  aggregations,
  hedgeRuleID,
  hedgePositionStatus,
  setHedgeRuleID,
}: AutoHedgingRulesDrawerProps) {
  const { isAuthorized } = useRoleAuth();
  const { globalHedgeRule } = useHedgePositionStatus();
  const globalHedgeControlStatus = globalHedgeRule?.HedgeControlStatus;
  const { closeDrawer } = useAppwideDrawerContext();
  const { getGuide } = useContext(ContextGuideContext);
  const isGuideEnabled = getGuide('auto-hedge-position-rule');

  const { securitiesBySymbol } = useSecuritiesContext();
  const symbolLabelGetter = useCallback(
    (symbol: string) => {
      return securitiesBySymbol.get(symbol)?.DisplaySymbol ?? symbol;
    },
    [securitiesBySymbol]
  );
  const mixpanel = useMixpanel();
  const toastProps = useToasts({ defaultProps: { timeout: 3000, dismissable: true } });
  const addToast = toastProps.add;
  const { currenciesList: currencies, currenciesBySymbol } = useCurrenciesContext();
  const { spotTradingPairsByBaseCurrency, spotTradingPairsByQuoteCurrency } = useSecuritiesContext();
  const { allSubAccounts: subaccounts } = useSubAccounts();
  const { autoHedgingStrategiesList } = useStrategiesContext();
  const { rollupMembershipsByParentChild } = useSubAccountRollupMemberships();

  const { positionHedgerHomeCurrency } = useFeatureFlag();
  const hedgeRuleQuery = useHedgeRuleQuery(hedgeRuleID);
  const queryHedgeRule = hedgeRuleQuery.data;

  const [state, dispatch] = useReducer(
    reducer,
    {
      hedgeRule: queryHedgeRule,
      currencies,
      strategies: autoHedgingStrategiesList,
      currenciesBySymbol,
      subaccounts,
      aggregations,
      rollupMembershipsByParentChild,
      spotTradingPairsByBaseCurrency,
      spotTradingPairsByQuoteCurrency,
      positionHedgerHomeCurrency,
    } satisfies InitReducerPayload,
    initialArg => reducer(getInitialState(initialArg), init(initialArg))
  );

  const hedgeRuleBeingChanged = state.hedgeRuleBeingChanged ?? queryHedgeRule;

  usePositionAutoHedgingDrawerDependencies({
    dispatch,
    currencies,
    currenciesBySymbol,
    strategies: autoHedgingStrategiesList,
    subaccounts,
    aggregations,
    rollupMembershipsByParentChild,
    spotTradingPairsByBaseCurrency,
    spotTradingPairsByQuoteCurrency,
    positionHedgerHomeCurrency,
  });

  const handleOnChangeGetter = useMemo(() => {
    return {
      Asset: (value?: Currency) => dispatch(updateField({ key: 'Asset', value })),
      TargetAsset: (value?: Currency) => dispatch(updateField({ key: 'TargetAsset', value })),
      TargetAccount: (value?: ISubaccount) => dispatch(updateField({ key: 'TargetAccount', value })),
      HedgeStrategy: (value?: OrderStrategy) => dispatch(updateField({ key: 'HedgeStrategy', value })),
      LongPositionLimit: (value?: string) => dispatch(updateField({ key: 'LongPositionLimit', value })),
      ShortPositionLimit: (value?: string) => dispatch(updateField({ key: 'ShortPositionLimit', value })),
      TargetPosition: (value?: string) => dispatch(updateField({ key: 'TargetPosition', value })),
      AccountID: (value?: ISubaccount) => dispatch(updateField({ key: 'AccountID', value })),
      HedgeAggregation: (value?: AggregationWithAccounts) => dispatch(updateField({ key: 'HedgeAggregation', value })),
    } satisfies Record<keyof EditIHedgeRuleForm, () => void>;
  }, [dispatch]);

  const formIsValid = selectFormIsValid(state);
  const hasFormChanged = selectFormHasChanged(state);

  const canSubmit = formIsValid && hasFormChanged && isAuthorized(ACTION.EDIT_AUTOHEDGING);

  const onChangeMode = useCallback(
    (mode: ModeEnum) => {
      dispatch(updateMode(mode));
    },
    [dispatch]
  );

  const createHedgeRuleMutation = useHedgeRuleCreationMutation();
  const modifyHedgeRuleMutation = useHedgeRuleModificationMutation();

  const isMutating = createHedgeRuleMutation.isPending || modifyHedgeRuleMutation.isPending;
  const isLoading = isMutating || hedgeRuleQuery.isLoading;

  const onCreateHedgeRule = useCallback(
    async (newHedgeRule: HedgeRulePostPayload & { Mode: ModeEnum }) => {
      return createHedgeRuleMutation
        .mutateAsync(newHedgeRule)
        .then(hedgeRule => {
          dispatch(setHedgeRule(hedgeRule));
          addToast({
            text: 'Successfully created!',
            variant: NotificationVariants.Positive,
          });
          setHedgeRuleID(hedgeRule.HedgeRuleID);
        })
        .catch(() => {
          addToast({
            text: 'Failed to create',
            variant: NotificationVariants.Negative,
          });
        });
    },
    [createHedgeRuleMutation, addToast, setHedgeRuleID]
  );

  const onModifyHedgeRule = useCallback(
    async (hedgeRule: IHedgeRule) => {
      return modifyHedgeRuleMutation
        .mutateAsync({ id: hedgeRule.HedgeRuleID, payload: hedgeRule })
        .then(hedgeRule => {
          dispatch(setHedgeRule(hedgeRule));
          addToast({
            text: 'Successfully updated!',
            variant: NotificationVariants.Positive,
          });
          setHedgeRuleID(hedgeRule.HedgeRuleID);
        })
        .catch(() => {
          addToast({
            text: 'Failed to update',
            variant: NotificationVariants.Negative,
          });
        });
    },
    [modifyHedgeRuleMutation, addToast, setHedgeRuleID]
  );

  const { position: convertedPosition, source } = useCurrentPosition({
    account: state.form.AccountID.value?.Name,
    asset: state.form.Asset.value?.Symbol,
    targetAsset: state.form.TargetAsset.value?.Symbol,
    hedgePositionStatus,
  });

  const hedgeRuleChangedWarningDialog = useDisclosure();

  const handleSubmitHedgeRule = useCallback(
    async (level: AutoHedgerValidationLevel) => {
      const hedgeRuleForm = selectFormValues(state.form);
      const isValid = selectFormValuesValid(hedgeRuleForm);
      if (!isValid || !hasFormChanged) {
        return;
      }
      const isCurrentlyHedging = hedgePositionStatus?.HedgeControlStatus === HedgeControlStatusEnum.Hedging;
      const isCurrentlyDisabled = hedgePositionStatus?.HedgeControlStatus === HedgeControlStatusEnum.Disabled;

      const isGlobalHedgingDisabled =
        globalHedgeControlStatus === HedgeControlStatusEnum.Disabled ||
        globalHedgeControlStatus === HedgeControlStatusEnum.Error;

      const checkPositionValidation = !isGlobalHedgingDisabled && !isCurrentlyHedging && !isCurrentlyDisabled;

      if (level <= AutoHedgerValidationLevel.BUTTON && convertedPosition && checkPositionValidation) {
        /** if the converted position breaches short/long thresholds we open a dialog that requires user confirmation */
        const isShortThresholdBreached = Big(Big(hedgeRuleForm.ShortPositionLimit).mul(-1)).gte(convertedPosition);
        const isLongThresholdBreached = Big(convertedPosition).gte(hedgeRuleForm.LongPositionLimit);

        if (isShortThresholdBreached || isLongThresholdBreached) {
          hedgeRuleChangedWarningDialog.open();
          return;
        }
      }

      if (!state.hedgeRuleBeingChanged) {
        mixpanel.track(MixpanelEvent.ClickCreateHedgeRule);
        await onCreateHedgeRule({ ...hedgeRuleForm, Mode: state.command.mode });
      } else {
        const newHedgeRule = { ...state.hedgeRuleBeingChanged, ...hedgeRuleForm };
        mixpanel.track(MixpanelEvent.ClickUpdateHedgeRule);
        await onModifyHedgeRule(newHedgeRule);
      }
    },
    [
      state.form,
      state.hedgeRuleBeingChanged,
      state.command.mode,
      hasFormChanged,
      hedgePositionStatus?.HedgeControlStatus,
      globalHedgeControlStatus,
      convertedPosition,
      hedgeRuleChangedWarningDialog,
      mixpanel,
      onCreateHedgeRule,
      onModifyHedgeRule,
    ]
  );

  const limitError = useMemo(() => {
    const arr = flatten([
      state.form.ShortPositionLimit.errorString.split(', '),
      state.form.TargetPosition.errorString.split(', '),
      state.form.LongPositionLimit.errorString.split(', '),
    ]).filter(Boolean);
    return arr.at(0);
  }, [
    state.form.ShortPositionLimit.errorString,
    state.form.TargetPosition.errorString,
    state.form.LongPositionLimit.errorString,
  ]);

  const hedgeSymbol = selectHedgeSymbol(state);

  const hedgeRuleOutOfSync =
    hedgeRuleBeingChanged &&
    queryHedgeRule &&
    // We only want to show the out of sync message when we got the latest hedge rule from backend.
    !hedgeRuleQuery.isFetching &&
    /* Its not sufficient to only check the Revision here, since the revision is updated when enabling/disabling the rule */
    selectIsHedgeRuleOutOfSync({ hedgeRuleBeingChanged: hedgeRuleBeingChanged, latestHedgeRule: queryHedgeRule });

  const theme = useTheme();

  return (
    <>
      <DrawerContent p="0" h="100%" position="relative" overflow="auto" gridTemplateRows="min-content auto min-content">
        {isLoading && <LoadingOverlay />}

        {hedgeRuleBeingChanged && hedgePositionStatus && isAuthorized(ACTION.HEDGER_SYNCHRONIZATION) && (
          <VStack gap="spacingSmall" alignSelf="center">
            <PositionControlActions
              addToast={addToast}
              hedgeRule={hedgeRuleBeingChanged}
              hedgePositionStatus={hedgePositionStatus}
            />
          </VStack>
        )}
        <FormSection>
          {!hedgeRuleBeingChanged && (
            <>
              <FormGroup inline label="Enabled" alignItems="center" data-testid="mode-toggle" mb="spacingComfortable">
                <ToggleParameter
                  off={ModeEnum.Disabled}
                  on={ModeEnum.Enabled}
                  onChange={onChangeMode}
                  value={state.command.mode}
                  size={FormControlSizes.Default}
                />
              </FormGroup>
              {isGuideEnabled && (
                <FormGroup data-testid="guide-mode">
                  <Alert variant={AlertVariants.Guide} p="spacingDefault" dismissable={false}>
                    <Text color="colorTextDefault">
                      {state.command.mode === ModeEnum.Enabled ? (
                        <>
                          The rule will start as <Text color="colorTextImportant">Enabled</Text>. The hedger will
                          monitor the position and make hedging decisions.
                        </>
                      ) : (
                        <>
                          The rule will start as <Text color="colorTextImportant">Disabled</Text>. The hedger will
                          monitor the position but not make any hedging decisions.
                        </>
                      )}
                    </Text>
                  </Alert>
                </FormGroup>
              )}
            </>
          )}

          {hedgeRuleOutOfSync && (
            <FormGroup>
              <Alert
                data-testid="hedge-rule-out-of-sync"
                variant={AlertVariants.Notice}
                dismissable={false}
                alignItems="center"
                p="spacingDefault"
              >
                The rule has been updated. Refresh to see the latest changes.
                <Button
                  data-testid="refresh-hedge-rule-button"
                  size={FormControlSizes.Tiny}
                  onClick={() => {
                    dispatch(setHedgeRule(queryHedgeRule));
                  }}
                >
                  Refresh
                </Button>
              </Alert>
            </FormGroup>
          )}

          {hedgeRuleBeingChanged && hedgePositionStatus && (
            <FormGroup>
              <PositionAutoHedgingStatus
                hasFormChanged={hasFormChanged}
                hedgePositionStatus={hedgePositionStatus}
                hedgeRule={hedgeRuleBeingChanged}
                addToast={addToast}
              />
            </FormGroup>
          )}

          <FormGroup>
            <PositionAutoHedgingCard
              form={state.form}
              position={convertedPosition}
              source={source}
              hedgePositionStatus={hedgePositionStatus}
              hedgeRule={hedgeRuleBeingChanged}
              mode={state.command.mode}
            />
          </FormGroup>

          <SectionTitle
            showNumber
            textTransform="uppercase"
            fontSize="fontSizeMd"
            fontWeight="400"
            mb="spacingComfortable"
            color="colorTextDefault"
            title="Monitoring"
          />

          <Flex gap="spacingComfortable">
            <FormGroup label="Monitored Rollup" error={state.form.AccountID.errorString} flex={1}>
              <SearchSelect
                readOnly={state.form.AccountID.isDisabled}
                data-testid="monitored-rollup-id"
                disabled={state.form.AccountID.isDisabled}
                touched={state.form.AccountID.isTouched}
                invalid={state.form.AccountID.hasError}
                selection={state.form.AccountID.value}
                options={state.form.AccountID.availableItems}
                onChange={handleOnChangeGetter['AccountID']}
                maxHeight={300}
                dropdownPlacement="bottom-start"
                getGroup={getSubaccountGroup}
                getLabel={getSubaccountLabel}
                initialSortByLabel={false}
              />
            </FormGroup>
            <FormGroup label="Monitored Asset" error={state.form.Asset.errorString} flex={1} mb="18px">
              <CurrencySelect
                data-testid="asset"
                readOnly={state.form.Asset.isDisabled}
                disabled={state.form.Asset.isDisabled}
                touched={state.form.Asset.isTouched}
                invalid={state.form.Asset.hasError}
                value={state.form.Asset.value}
                options={state.form.Asset.availableItems}
                onChange={handleOnChangeGetter['Asset']}
              />
            </FormGroup>
          </Flex>

          {isGuideEnabled && (
            <FormGroup data-testid="guide-monitor">
              <Alert variant={AlertVariants.Guide} p="spacingDefault" dismissable={false}>
                <Text color="colorTextDefault">
                  The hedger monitors the{' '}
                  <Text color="colorTextImportant">{state.form.Asset.value?.Symbol ?? 'Monitored Asset'}</Text> position
                  in{' '}
                  <Text color="colorTextImportant">
                    {state.form.AccountID.value ? getSubaccountLabel(state.form.AccountID.value) : 'Monitored Rollup'}
                  </Text>
                  .
                </Text>
              </Alert>
            </FormGroup>
          )}

          <SectionTitle
            showNumber
            textTransform="uppercase"
            fontSize="fontSizeMd"
            fontWeight="400"
            mb="spacingComfortable"
            color="colorTextDefault"
            title="Thresholds"
          />
          <FormGroup
            label="Target Asset"
            error={state.form.TargetAsset.errorString}
            warning={state.form.TargetAsset.warningString}
            data-testid="target-asset-form-group"
          >
            <CurrencySelect
              data-testid="target-asset"
              disabled={state.form.TargetAsset.isDisabled}
              touched={state.form.TargetAsset.isTouched}
              invalid={state.form.TargetAsset.hasError}
              value={state.form.TargetAsset.value}
              options={state.form.TargetAsset.availableItems}
              onChange={handleOnChangeGetter['TargetAsset']}
            />
          </FormGroup>
          <FormGroup error={limitError} data-testid="limit-form-group">
            <Flex gap="spacingComfortable">
              <FormGroup label="Short Threshold" mb="0" flex={1}>
                <NumberInput
                  data-testid="short-threshold"
                  placeholder="500"
                  disabled={state.form.ShortPositionLimit.isDisabled}
                  invalid={state.form.ShortPositionLimit.hasError}
                  value={state.form.ShortPositionLimit.displayValue}
                  onChange={handleOnChangeGetter['ShortPositionLimit']}
                  suffix={state.form.TargetAsset.value?.Symbol}
                  touched={state.form.ShortPositionLimit.isTouched}
                  min="0"
                />
              </FormGroup>
              <FormGroup label="Target Position" mb="0" flex={1}>
                <NumberInput
                  data-testid="target-position"
                  placeholder="-200"
                  disabled={state.form.TargetPosition.isDisabled}
                  invalid={state.form.TargetPosition.hasError}
                  value={state.form.TargetPosition.displayValue}
                  onChange={handleOnChangeGetter['TargetPosition']}
                  suffix={state.form.TargetAsset.value?.Symbol}
                  touched={state.form.TargetPosition.isTouched}
                />
              </FormGroup>
              <FormGroup label="Long Threshold" mb="0" flex={1}>
                <NumberInput
                  data-testid="long-threshold"
                  placeholder="700"
                  disabled={state.form.LongPositionLimit.isDisabled}
                  invalid={state.form.LongPositionLimit.hasError}
                  value={state.form.LongPositionLimit.displayValue}
                  onChange={handleOnChangeGetter['LongPositionLimit']}
                  suffix={state.form.TargetAsset.value?.Symbol}
                  touched={state.form.LongPositionLimit.isTouched}
                  min="0"
                />
              </FormGroup>
            </Flex>
          </FormGroup>

          {isGuideEnabled && (
            <FormGroup data-testid="guide-thresholds">
              <Alert variant={AlertVariants.Guide} p="spacingDefault" dismissable={false}>
                <Text color="colorTextDefault">
                  When the short position exceeds{' '}
                  <Text color="colorTextImportant">
                    {state.form.ShortPositionLimit.value && state.form.TargetAsset.value ? (
                      <InlineFormattedNumber
                        number={state.form.ShortPositionLimit.value}
                        currency={state.form.TargetAsset.value.Symbol}
                        round
                        trimTrailingZeroes
                      />
                    ) : (
                      'Short Threshold'
                    )}
                  </Text>{' '}
                  or the long position exceeds{' '}
                  <Text color="colorTextImportant">
                    {state.form.LongPositionLimit.value && state.form.TargetAsset.value ? (
                      <InlineFormattedNumber
                        number={state.form.LongPositionLimit.value}
                        currency={state.form.TargetAsset.value.Symbol}
                        round
                        trimTrailingZeroes
                      />
                    ) : (
                      'Long Threshold'
                    )}
                  </Text>
                  . The hedger will place an order to close the position to{' '}
                  <Text color="colorTextImportant">
                    {state.form.TargetPosition.value && state.form.TargetAsset.value ? (
                      <InlineFormattedNumber
                        number={state.form.TargetPosition.value}
                        currency={state.form.TargetAsset.value.Symbol}
                        round
                        trimTrailingZeroes
                      />
                    ) : (
                      'Target Position'
                    )}
                    .
                  </Text>
                </Text>
              </Alert>
            </FormGroup>
          )}

          <SectionTitle
            showNumber
            textTransform="uppercase"
            fontSize="fontSizeMd"
            fontWeight="400"
            mb="spacingComfortable"
            color="colorTextDefault"
            title="Action"
          />

          <FormGroup
            data-testid="hedge-symbol-form-group"
            label="Hedge Symbol"
            error={state.form.TargetAsset.warningString}
            help={
              <>
                <Icon size={12} icon={IconName.InformationCircle} /> Derived from the monitored and target asset.
              </>
            }
          >
            <SearchSelect
              onChange={noop}
              getLabel={symbolLabelGetter}
              data-testid="hedge-symbol"
              readOnly
              selection={hedgeSymbol}
              options={EMPTY_ARRAY}
            />
          </FormGroup>

          <FormGroup label="Hedge Account" error={state.form.TargetAccount.errorString}>
            <SearchSelect
              data-testid="hedge-account"
              disabled={state.form.TargetAccount.isDisabled}
              invalid={state.form.TargetAccount.hasError}
              touched={state.form.TargetAccount.isTouched}
              selection={state.form.TargetAccount.value}
              options={state.form.TargetAccount.availableItems}
              onChange={handleOnChangeGetter['TargetAccount']}
              maxHeight={300}
              dropdownPlacement="bottom-start"
              getGroup={getSubaccountGroup}
              getLabel={getSubaccountLabel}
              initialSortByLabel={false}
            />
          </FormGroup>

          <FormGroup label="Hedge Strategy" error={state.form.HedgeStrategy.errorString}>
            <SearchSelect
              data-testid="hedge-strategy"
              disabled={state.form.HedgeStrategy.isDisabled}
              touched={state.form.HedgeStrategy.isTouched}
              invalid={state.form.HedgeStrategy.hasError}
              selection={state.form.HedgeStrategy.value}
              options={state.form.HedgeStrategy.availableItems}
              onChange={handleOnChangeGetter['HedgeStrategy']}
              maxHeight={300}
              dropdownPlacement="bottom-start"
              getLabel={getStrategyLabel}
              getDescription={getStrategyDescription}
              initialSortByLabel={false}
            />
          </FormGroup>

          <AggregationsSelector
            symbol={hedgeSymbol}
            data-testid="hedge-aggregation"
            disabled={state.form.HedgeAggregation.isDisabled}
            touched={state.form.HedgeAggregation.isTouched}
            invalid={state.form.HedgeAggregation.hasError}
            selection={state.form.HedgeAggregation.value}
            options={state.form.HedgeAggregation.availableItems}
            onChange={handleOnChangeGetter['HedgeAggregation']}
            maxHeight={300}
            dropdownPlacement="bottom-start"
            getLabel={getAggregationLabel}
            initialSortByLabel={false}
            placeholder="Select Aggregation"
            marketSelectionsHeight="100px"
            formGroupProps={{ label: 'Hedge Aggregation', error: state.form.HedgeAggregation.errorString }}
          />

          {isGuideEnabled && (
            <FormGroup data-testid="guide-action" mt={getFormGroupMarginBottom(theme)}>
              <Alert variant={AlertVariants.Guide} p="spacingDefault" dismissable={false}>
                <Text color="colorTextDefault">
                  The hedge order will be on the symbol:{' '}
                  <Text color="colorTextImportant">
                    {hedgeSymbol ? symbolLabelGetter(hedgeSymbol) : 'Hedge Symbol'}
                  </Text>
                  {', '}
                  use strategy{': '}
                  <Text color="colorTextImportant">
                    {state.form.HedgeStrategy.value
                      ? getStrategyLabel(state.form.HedgeStrategy.value)
                      : 'Hedge Strategy'}
                  </Text>
                  {', '}
                  aggregation{': '}
                  <Text color="colorTextImportant">
                    {state.form.HedgeAggregation.value
                      ? getAggregationLabel(state.form.HedgeAggregation.value)
                      : 'Hedge Aggregation'}
                  </Text>{' '}
                  and be booked into the sub account{': '}
                  <Text color="colorTextImportant">
                    {state.form.TargetAccount.value
                      ? getSubaccountLabel(state.form.TargetAccount.value)
                      : 'Hedge Account'}
                  </Text>
                  .
                </Text>
              </Alert>
            </FormGroup>
          )}
        </FormSection>

        <Box /* Height of 64px to account for the drawer footer */ h="64px" />
      </DrawerContent>
      <ToastsWrapper>
        <Toasts {...toastProps} />
      </ToastsWrapper>
      <DrawerFooter position="absolute" bottom="0" left="0" right="0" background="backgroundDrawer">
        <ButtonGroup
          gap="spacingSmall"
          onMouseEnter={() => {
            dispatch(touchAll());
          }}
        >
          <Button variant={ButtonVariants.Default} onClick={closeDrawer}>
            Cancel
          </Button>
          <Button
            data-testid="submit-hedge-rule"
            variant={hedgeRuleID ? ButtonVariants.Primary : ButtonVariants.Positive}
            onClick={() => handleSubmitHedgeRule(AutoHedgerValidationLevel.BUTTON)}
            disabled={!canSubmit}
            flex={4}
          >
            {hedgeRuleID ? 'Update' : 'Create'} Hedge Rule
          </Button>
        </ButtonGroup>
      </DrawerFooter>

      <Dialog
        {...hedgeRuleChangedWarningDialog}
        usePortal={true}
        portalID="drawer-portal"
        stretchButtons
        onConfirm={() => handleSubmitHedgeRule(AutoHedgerValidationLevel.POSITION_THRESHOLD_MODAL)}
        title="Threshold Warning"
        dataTestId="current-position-warning-dialog"
      >
        The current position{' '}
        <InlineFormattedNumber
          number={convertedPosition}
          currency={state.form.TargetAsset.value?.Symbol}
          round
          estimate={source === 'conversion'}
        />{' '}
        exceeds the Short/Long Threshold and the hedger will start hedging upon saving.
      </Dialog>
    </>
  );
}

const getAggregationLabel = (aggregation: Aggregation) => aggregation.DisplayName ?? aggregation.Name;
const getStrategyLabel = (strategy: OrderStrategy) => strategy.DisplayName ?? strategy.Name;
const getStrategyDescription = (strategy: OrderStrategy) => strategy.Description;

function getSubaccountGroup(item: ISubaccount) {
  return item.Type;
}

function getSubaccountLabel(item: ISubaccount) {
  return item.DisplayName;
}

export const LoadingOverlay = () => {
  const theme = useTheme();

  return (
    <LoaderTalos
      wrapperStyle={{
        position: 'absolute',
        inset: 0,
        zIndex: 1,
        background: setAlpha(0.7, theme.colors.gray.main),
      }}
    />
  );
};

const ToastsWrapper = styled.div`
  position: absolute;
  bottom: 45px;
  padding: ${({ theme }) => `0 ${theme.spacingMedium}px ${theme.spacingMedium}px`};
  width: 100%;
  z-index: 3;
`;
