import type { GroupPanelViewState, ISerializedLeafNode, SerializedDockview, SerializedGridObject } from 'dockview';

/**
 * Get the group with the provided groupId from the layout
 * @param layout A Dockview layout object
 * @param groupId
 * @returns The desired group or undefined if not found
 */
export function getLayoutGroupById(
  layout: SerializedDockview,
  groupId: string
): ISerializedLeafNode<GroupPanelViewState> | undefined {
  let result: SerializedGridObject<GroupPanelViewState> | undefined = undefined;
  searchLayoutForGroupId(layout.grid.root, groupId);
  return result;

  function searchLayoutForGroupId(layoutGroup: SerializedGridObject<GroupPanelViewState>, id: string) {
    if (Array.isArray(layoutGroup.data)) {
      const data = layoutGroup.data;
      for (const item of data) {
        const res = searchLayoutForGroupId(item, id);
        if (res) {
          return result;
        }
      }
    } else {
      const data = layoutGroup.data;
      if (data.id === id && layoutGroup.type === 'leaf') {
        result = layoutGroup;
        return result;
      }
    }
  }
}
