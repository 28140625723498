import type { CsvExportParams } from 'ag-grid-community';
import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useIntl } from '../../hooks';
import { MixpanelEvent } from '../../tokens';
import { Button, ButtonVariants } from '../Button';
import { Box, Flex, VStack } from '../Core';
import { Dialog } from '../Dialog';
import type { DialogProps } from '../Dialog/types';
import { Checkbox } from '../Form/Checkbox';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';

const messages = defineMessages({
  export: {
    defaultMessage: 'Export',
    id: 'BlotterTable.export',
  },
  exportAllData: {
    defaultMessage: 'Export All Data',
    id: 'BlotterTable.exportAllData',
  },
  exportFilteredData: {
    defaultMessage: 'Export Filtered Data',
    id: 'BlotterTable.exportFilteredData',
  },
  exportCSV: {
    defaultMessage: 'Export CSV',
    id: 'BlotterTable.exportCSV',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'BlotterTable.cancel',
  },
});

export interface ExportButtonProps {
  onExportAction: () => void;
}

export const ExportButton = (props: ExportButtonProps) => {
  return (
    <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
      <Button
        startIcon={IconName.DocumentDownload}
        variant={ButtonVariants.Default}
        size={FormControlSizes.Small}
        onClick={() => props.onExportAction()}
      >
        <FormattedMessage {...messages.export} />
      </Button>
    </Flex>
  );
};

export interface CSVExportDialogProps extends DialogProps {
  handleExportServerData: () => void;
  handleExportLocalData: (params: CsvExportParams) => void;
}

export const ExportDialog = ({ handleExportLocalData, handleExportServerData, ...props }: CSVExportDialogProps) => {
  const mixpanel = useMixpanel();
  const [exportType, setExportType] = useState<'filtered' | 'all'>('filtered');
  const { formatMessage } = useIntl();

  const handleConfirm = useCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows, {
      type: exportType,
    });
    if (exportType === 'filtered') {
      handleExportLocalData({});
    }
    if (exportType === 'all') {
      handleExportServerData();
    }
  }, [exportType, handleExportLocalData, handleExportServerData, mixpanel]);

  return (
    <Dialog
      {...props}
      onConfirm={handleConfirm}
      confirmLabel={formatMessage(messages.export)}
      cancelLabel={formatMessage(messages.cancel)}
      title={formatMessage(messages.exportCSV)}
      stretchButtons
    >
      <Box>
        <VStack w="200px" h="50px" justifyContent="space-between" alignItems="flex-start">
          <Checkbox checked={exportType === 'filtered'} onChange={() => setExportType('filtered')}>
            <FormattedMessage {...messages.exportFilteredData} />
          </Checkbox>
          <Checkbox checked={exportType === 'all'} onChange={() => setExportType('all')}>
            <FormattedMessage {...messages.exportAllData} />
          </Checkbox>
        </VStack>
      </Box>
    </Dialog>
  );
};
