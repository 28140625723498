import {
  Box,
  Button,
  ButtonVariants,
  Card,
  CurrencyRenderer,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  InlineFormattedNumber,
  SecurityRenderer,
  Text,
  useCurrenciesContext,
  useJsonModal,
  useSecuritiesContext,
  VStack,
} from '@talos/kyoko';
import type { PortfolioRiskDataItem } from 'containers/Portfolio/PortfolioManagement/types/PortfolioRiskDataItem';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

export const InspectorBasicInfo = ({ dataItem }: { dataItem: PortfolioRiskDataItem }) => {
  const theme = useTheme();
  const { handleClickJson, jsonModal } = useJsonModal();
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();

  const currency = currenciesBySymbol.get(dataItem.gridData.Asset);
  const security = securitiesBySymbol.get(dataItem.gridData.Asset);
  const underlyingCurrency = security?.UnderlyingQuoteCurrency
    ? currenciesBySymbol.get(security.UnderlyingQuoteCurrency)
    : undefined;
  const qtyIncrement = currency?.DefaultIncrement ?? underlyingCurrency?.DefaultIncrement;

  const basicInfoHeader = useMemo(() => {
    if (currency) {
      return (
        <HStack justifyContent="space-between">
          <CurrencyRenderer dataTestId="inspector-title-currency" currency={currency} colorful />
          <Text color="colorTextImportant" size="fontSizeMd">
            Spot
          </Text>
        </HStack>
      );
    } else if (security) {
      return (
        <HStack justifyContent="space-between">
          <SecurityRenderer data-testid="inspector-title-security" colorful security={security} />
          <Text color="colorTextImportant" size="fontSizeMd">
            {security.ProductType}
          </Text>
        </HStack>
      );
    }
    return null;
  }, [currency, security]);

  return (
    <Box data-testid="inspector-basic-info" p="spacingMedium" borderBottom={`1px solid ${theme.colors.gray['010']}`}>
      <VStack gap="spacing8">
        <HStack w="100%" justifyContent="space-between">
          <div />
          <Button
            variant={ButtonVariants.Default}
            size={FormControlSizes.Tiny}
            onClick={() => {
              handleClickJson({
                riskData: dataItem.riskData,
                security,
                currency,
              });
            }}
          >
            <HStack color="gray.090" gap="3px">
              <Icon icon={IconName.Braces} />
              Show JSON
            </HStack>
          </Button>
        </HStack>
        <Card display="flex" flexDirection="column" w="100%">
          <VStack data-testid="inspector-basic-info-qty" gap="spacing8" w="100%" alignItems="stretch">
            {basicInfoHeader}
            <HStack justifyContent="space-between">
              <Text>Position Qty</Text>
              <Text color="colorTextImportant" size="fontSizeMd">
                <InlineFormattedNumber
                  number={dataItem.gridData.Position}
                  align="right"
                  trimTrailingZeroes
                  increment={qtyIncrement}
                />
              </Text>
            </HStack>
          </VStack>
        </Card>
      </VStack>
      {jsonModal}
    </Box>
  );
};
