import { logger, useSecuritiesContext } from '@talos/kyoko';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useLazyGetCareOrdersQuery } from 'providers/AppStateProvider/streamingDataSlice';
import { useCallback } from 'react';
import type { ImportedCareOrder } from '../types';
import * as aladdin from './aladdin';
import type { CareOrderImportContext } from './types';

export function useCareOrderImportParser(): (file: File) => Promise<ImportedCareOrder[]> {
  const { securitiesBySymbol } = useSecuritiesContext();
  const [fetchCareOrders] = useLazyGetCareOrdersQuery();
  const { careOrderImportParser } = useFeatureFlag();

  return useCallback(
    async (file: File) => {
      // NOTE: Currently, wsEndpointBuilder does not support lazy queries.
      // The code below works only because the import dialog is only visible in the care orders blotter, where there's already an active WS subscription.
      // By passing `true` as the second argument here, we cheat by reading the cached value. If we didn't do this, the promise would never resolve.
      const careOrders = await fetchCareOrders({ tag: 'useCareOrderImportParser' }, true);

      if (careOrders.data == null) {
        throw new Error('Could not fetch existing care orders, please try again.');
      }

      const careOrdersByGroup = new Map();
      for (const [, careOrder] of careOrders.data) {
        careOrdersByGroup.set(careOrder.Group, careOrder);
      }

      const context: CareOrderImportContext = {
        securitiesBySymbol,
        careOrdersByGroup,
      };

      switch (careOrderImportParser) {
        case 'aladdin':
          return aladdin.parse(file, context);
        default:
          logger.error(new Error(`Unknown care order import parser: ${careOrderImportParser}`));
          return Promise.reject('Invalid parser, please contact support.');
      }
    },
    [careOrderImportParser, securitiesBySymbol, fetchCareOrders]
  );
}
