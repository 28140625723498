import type { GroupPanelViewState, ISerializedLeafNode, SerializedDockview, SerializedGridObject } from 'dockview';
import { BRANCH, LEAF } from '../../../components/Layout/tokens';

/** Get the largest group from the provided layout, based on area culculated using size */
export function getLargestLayoutGroup(
  layout: SerializedDockview
): ISerializedLeafNode<GroupPanelViewState> | undefined {
  // Size of the branch the leaf belongs to
  let branchSize = 0;
  let largetsGroupBranchSize = 0;
  let largestGroup: SerializedGridObject<GroupPanelViewState> | undefined = undefined;
  searchLayoutForLargestGroup(layout.grid.root);
  function searchLayoutForLargestGroup(layoutGroup: SerializedGridObject<GroupPanelViewState>) {
    if (layoutGroup.type === LEAF) {
      const size = layoutGroup.size ?? 0;
      if (largestGroup == null || size * branchSize > largetsGroupBranchSize * (largestGroup.size ?? 0)) {
        largestGroup = layoutGroup;
        largetsGroupBranchSize = branchSize;
      }
    } else if (layoutGroup.type === BRANCH) {
      // Casting to get around type not being narrowed by checking the type property
      const data = layoutGroup.data as SerializedGridObject<GroupPanelViewState>[];
      branchSize = layoutGroup.size ?? branchSize;
      // Search each part of the branch
      data.forEach(item => searchLayoutForLargestGroup(item));
    }
  }
  return largestGroup;
}
