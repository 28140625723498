import type { GetContextMenuItemsParams, GetMainMenuItemsParams, GridOptions } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { logger } from '../../../utils';
import { useGetDefaultContextMenuItems } from '../contextMenu';
import { getAllNodesInGroup, removeUnnecessarySeparators } from '../utils';

export const useBlotterTableMenus = (
  getExtraMainMenuItems?: GridOptions['getMainMenuItems'],
  getContextMenuItems?: GridOptions['getContextMenuItems']
) => {
  // Default context menu items
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  // Context menu
  // This function intercepts the getting of context menu items (called on context menu open) and performs some uniform logic
  const getContextMenuItemsResolved = useCallback(
    (params: GetContextMenuItemsParams) => {
      const rightClickedNode = params.node;
      if (rightClickedNode == null) {
        // Shouldn't happen
        logger.warn('No right clicked node found when attempting to build the BlotterTable AgGrid Context Menu');
        return [];
      }
      const selectedNodes = params.api.getSelectedNodes().filter(node => node.displayed);
      const isRightClickedNodeInSelection = selectedNodes.some(node => node.id === rightClickedNode.id);

      if (!isRightClickedNodeInSelection) {
        params.api.deselectAll();
      }

      if (rightClickedNode.group) {
        // If we have right clicked on a group row, we make sure to select the group row itself as well as all of its children before proceeding
        const nodes = [rightClickedNode, ...getAllNodesInGroup(rightClickedNode)];
        params.api.setNodesSelected({ nodes, newValue: true, source: 'rowClicked' });
      } else if (!rightClickedNode.rowPinned) {
        // Case of right-clicking on a normal row (also making sure its not pinned, aggrid doesnt like that), we select it
        rightClickedNode.setSelected(true, false);
      }

      // Either call the specified function or fallback to our default
      const items = getContextMenuItems?.(params) ?? getDefaultContextMenuItems(params);
      return removeUnnecessarySeparators(items);
    },
    [getContextMenuItems, getDefaultContextMenuItems]
  );

  // Main menu
  const getMainMenuItems = useMemo(() => {
    return (params: GetMainMenuItemsParams) => {
      const extras = getExtraMainMenuItems ? getExtraMainMenuItems(params) : [];
      if (extras.length > 0) {
        extras.push('separator');
      }
      return removeUnnecessarySeparators(extras.concat(['autoSizeThis', 'autoSizeAll', 'separator', 'resetColumns']));
    };
  }, [getExtraMainMenuItems]);

  return { getMainMenuItems, getContextMenuItems: getContextMenuItemsResolved };
};
