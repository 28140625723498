import {
  NotificationVariants,
  useGlobalToasts,
  type LedgerAccountTypeEnum,
  type MixpanelEventSource,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMarketTabs, useSubAccounts } from '../../../../providers';
import { useAppLayoutConfig } from '../../../../providers/AppLayoutConfig/AppLayoutConfigContext';
import { getAccountLedgerEventsDetailsRoute } from '../../../Routes/routes';

export function useNavigateToAccountLedgerEvents() {
  const { subAccountsByID } = useSubAccounts();
  const { enableFlexibleLayout } = useAppLayoutConfig();
  const { prepareAccountLedgerEventsDetailsMarketTab } = useMarketTabs();
  const { add: addToast } = useGlobalToasts();
  const history = useHistory();

  return useCallback(
    ({
      account,
      asset,
      type,
      source,
    }: {
      account: number | string;
      asset: string;
      type: LedgerAccountTypeEnum;
      source?: MixpanelEventSource;
    }) => {
      const accountName = typeof account === 'string' ? account : subAccountsByID.get(account)?.Name;
      if (accountName == null) {
        addToast({
          text: 'Unable to navigate to account ledger events',
          variant: NotificationVariants.Negative,
        });
        return;
      }
      if (enableFlexibleLayout) {
        history.push(getAccountLedgerEventsDetailsRoute({ asset, account: accountName, accountType: type }));
      } else {
        return prepareAccountLedgerEventsDetailsMarketTab({
          asset,
          account: accountName,
          accountType: type,
          source,
        });
      }
    },
    [addToast, prepareAccountLedgerEventsDetailsMarketTab, subAccountsByID, enableFlexibleLayout, history]
  );
}
