import {
  useCallback,
  useState,
  type ChangeEventHandler,
  type DragEventHandler,
  type InputHTMLAttributes,
  type LabelHTMLAttributes,
  type ReactNode,
} from 'react';
import { Button, ButtonVariants } from '../Button';
import { VStack } from '../Core';
import { Icon, IconName } from '../Icons';
import { Text } from '../Text';
import { Wrapper } from './styles';

export interface FileAreaProps extends Omit<LabelHTMLAttributes<HTMLLabelElement>, 'onChange'> {
  icon?: IconName;
  label?: ReactNode;
  description?: ReactNode;
  buttonLabel?: string;
  onChange?: (file?: File) => void;
  innerRef?: React.RefObject<HTMLInputElement>;
  value?: string;
  accept?: InputHTMLAttributes<HTMLInputElement>['accept'];
}

export function FileArea({
  icon = IconName.Download,
  label = 'Drag and drop files here.',
  description,
  buttonLabel = 'Browse...',
  accept,
  onChange,
  innerRef,
  value,
}: FileAreaProps) {
  // Allow both controlled and uncontrolled usage of this component
  const [uncontrolledValue, setUncontrolledValue] = useState<string>('');

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const file = e.target.files?.[0];
      onChange?.(file);

      // Make sure the onChange handler gets called even if the same file is selected
      setUncontrolledValue('');
      return false;
    },
    [onChange]
  );

  const handleDrop: DragEventHandler<HTMLLabelElement> = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      const file = e.dataTransfer.files[0];
      onChange?.(file);

      // Make sure the onChange handler gets called even if the same file is selected
      setUncontrolledValue('');
      return false;
    },
    [onChange]
  );

  const [isDragging, setIsDragging] = useState(false);
  const handleDragOver: DragEventHandler<HTMLLabelElement> = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragging(true);
  }, []);
  const handleDragLeave: DragEventHandler<HTMLLabelElement> = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragging(false);
  }, []);

  return (
    <Wrapper
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      isDragging={isDragging}
      data-testid="file-area"
    >
      <VStack gap="spacingDefault">
        <Icon icon={icon} size={18} />
        <Text color="colorTextImportant">{label}</Text>
        {description && <Text color="colorTextSecondary">{description}</Text>}
        <Button forwardedAs="span" variant={ButtonVariants.Primary}>
          {buttonLabel}
        </Button>
      </VStack>
      <input
        type="file"
        data-testid="file-area-input"
        ref={innerRef}
        value={value ?? uncontrolledValue}
        accept={accept}
        onChange={handleChange}
        style={INPUT_STYLE}
      />
    </Wrapper>
  );
}

const INPUT_STYLE = {
  visibility: 'hidden',
  position: 'absolute',
  zIndex: -1,
} as const;
