import { createContext, memo, useCallback, useContext, useMemo, useState, type PropsWithChildren } from 'react';
import { map, shareReplay, tap } from 'rxjs/operators';

import {
  CUSTOMER_MARKET_ACCOUNT,
  DEFAULT_BIGGER_PAGINATION_SIZE,
  DELETE,
  MarketAccountStatusEnum,
  PATCH,
  POST,
  request,
  useObservable,
  useObservableValue,
  useSubscription,
  useUserContext,
  wsScanToMap,
  type MarketAccount,
  type Response,
} from '@talos/kyoko';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

export type CustomerMarketAccountPatch = Partial<Pick<MarketAccount, 'DisplayName' | 'Description' | 'Status'>>;
export type CustomerMarketAccountCreate = Partial<
  Pick<MarketAccount, 'DisplayName' | 'Counterparty' | 'SourceAccountID' | 'Description'>
>;

export interface CustomerMarketAccountContextProps {
  customerMarketAccountsByName: Map<string, MarketAccount>;
  customerMarketAccountsList: MarketAccount[] | undefined;
  createCustomerMarketAccount(payload: CustomerMarketAccountCreate): Promise<Response<MarketAccount>>;
  updateCustomerMarketAccount(mktAccID: number, payload: CustomerMarketAccountPatch): Promise<Response<MarketAccount>>;
  deleteCustomerMarketAccount(mktAccID: number): Promise<Response<any>>;
  getCustomerMarketAccounts(customer: string): MarketAccount[];
  getActiveCustomerMarketAccounts(customer: string): MarketAccount[];
  isLoaded: boolean;
}

const CustomerMarketAccountContext = createContext<CustomerMarketAccountContextProps | null>(null);
CustomerMarketAccountContext.displayName = 'CustomerMarketAccountContext';

export function useCustomerMarketAccounts() {
  const context = useContext(CustomerMarketAccountContext);
  if (context == null) {
    throw new Error('Missing CustomerMarketAccountContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const CustomerMarketAccountsProvider = memo(function CustomerMarketAccountsProvider(
  props: PropsWithChildren<unknown>
) {
  const { disableCustomerMarketAccounts } = useFeatureFlag();
  const [isLoaded, setIsLoaded] = useState(disableCustomerMarketAccounts);

  const { data: customerMktAccSub } = useSubscription<MarketAccount>(
    /* [DEAL-4517] Allow disabling the loading of all Customer Market Accounts.
     * If the feature flag disableCustomerMarketAccounts is enabled, we will set this request to null. */
    !disableCustomerMarketAccounts
      ? {
          name: CUSTOMER_MARKET_ACCOUNT,
          tag: 'CustomerMarketAccountsProvider',
          limit: DEFAULT_BIGGER_PAGINATION_SIZE,
        }
      : null,
    { loadAll: true }
  );

  const customerMktAccByNameObs = useObservable(
    () =>
      customerMktAccSub.pipe(
        tap(net => setIsLoaded(true)),
        wsScanToMap({ getUniqueKey: d => d.Name, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerMktAccSub]
  );

  const customerMktAccsListObs = useObservable(
    () =>
      customerMktAccByNameObs.pipe(
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerMktAccByNameObs]
  );

  const customerMarketAccountsByName = useObservableValue(
    () => customerMktAccByNameObs,
    [customerMktAccByNameObs],
    new Map()
  );
  const customerMarketAccountsList = useObservableValue(() => customerMktAccsListObs, [customerMktAccsListObs]);

  const getCustomerMarketAccounts = useCallback(
    (customer: string) => {
      return customerMarketAccountsList?.filter(ma => ma.Counterparty === customer) || [];
    },
    [customerMarketAccountsList]
  );
  const getActiveCustomerMarketAccounts = useCallback(
    (customer: string) => {
      return getCustomerMarketAccounts(customer)?.filter(ma => ma.Status === MarketAccountStatusEnum.Active) || [];
    },
    [getCustomerMarketAccounts]
  );

  const { orgApiEndpoint } = useUserContext();
  const endpoint = `${orgApiEndpoint}/customer-accounts`;

  const updateCustomerMarketAccount = useCallback(
    (mktAccID: number, payload: CustomerMarketAccountPatch) => {
      return request<Response<MarketAccount>>(PATCH, `${endpoint}/${mktAccID.toString()}`, payload);
    },
    [endpoint]
  );

  const createCustomerMarketAccount = useCallback(
    (payload: CustomerMarketAccountCreate) => {
      return request<Response<MarketAccount>>(POST, endpoint, payload);
    },
    [endpoint]
  );

  const deleteCustomerMarketAccount = useCallback(
    (mktAccID: number) => {
      return request(DELETE, `${endpoint}/${mktAccID.toString()}`, null);
    },
    [endpoint]
  );

  const value = useMemo(() => {
    return {
      customerMarketAccountsByName,
      customerMarketAccountsList,
      createCustomerMarketAccount,
      updateCustomerMarketAccount,
      deleteCustomerMarketAccount,
      getCustomerMarketAccounts,
      getActiveCustomerMarketAccounts,
      isLoaded,
    };
  }, [
    customerMarketAccountsByName,
    customerMarketAccountsList,
    createCustomerMarketAccount,
    updateCustomerMarketAccount,
    deleteCustomerMarketAccount,
    getCustomerMarketAccounts,
    getActiveCustomerMarketAccounts,
    isLoaded,
  ]);

  return <CustomerMarketAccountContext.Provider value={value}>{props.children}</CustomerMarketAccountContext.Provider>;
});
