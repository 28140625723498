import type {
  ColDef,
  GridApi,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import Big from 'big.js';
import { get, isArray, isObject, isPlainObject, keys, sortBy } from 'lodash';
import type { ReactNode } from 'react';

import {
  ConnectionModeEnum,
  ConnectionStatusEnum,
  CustomerBalanceTransactionTypeEnum,
  ExposureStatusEnum,
  ModeEnum,
  ProductTypeEnum,
  QuoteStatusEnum,
  type ReconStateEnum,
  type SideEnum,
} from '../../../types/types';
import {
  formattedDate,
  formattedDateWithMilliseconds,
  formattedTime,
  getFilledBaseQuantity,
  getFilledCounterAmount,
  getFilledNotional,
  getOpenNotional,
  getProductGroupLabel,
  getProductType,
  getStatusColor,
  getTotalNotional,
  logger,
  percentToBps,
  prettyName,
  productTypeToString,
  toBigWithDefault,
  type AmountObject,
  type DateFnInput,
  type Leaves,
  type ProductGroup,
} from '../../../utils';
import { AgGridCurrency, AgGridSecurity, AgGridToggle } from '../../AgGrid';
import { Button } from '../../Button';
import { Flex, HStack } from '../../Core';
import { FormControlSizes } from '../../Form/types';
import { FormRowStatus } from '../../FormTable/types';
import { ICON_SIZES, Icon, IconName } from '../../Icons';
import { MarketColorDot } from '../../MarketColor';
import { Side, TWO_WAY_SIDES_FILTER } from '../../Side';
import {
  AddressStatus,
  CareOrderStatus,
  ExposureStatus,
  LoanQuoteStatus,
  OrderStatus,
  QuoteStatus,
  ReconMismatchStatus,
  TradeStatus,
  TransferStatus,
  getLoanQuoteStatusText,
  getOrderStatusText,
  type OrderStatusText,
} from '../../Status';
import { LoanStatus, getLoanStatusText } from '../../Status/LoanStatus';
import { LoanTransactionStatus, getLoanTransactionStatusText } from '../../Status/LoanTransactionStatus';
import { Text } from '../../Text';
import { JITTooltip, Tooltip } from '../../Tooltip';
import { CustomerRolePillWrapper } from '../styles';
import { baseColumn } from './baseColumn';
import {
  amountObjectComparator,
  amountObjectValueFormatter,
  dateStringComparator,
  numericColumnComparator,
  stringColumnComparator,
} from './utils';

import type { CustomCellEditorProps } from 'ag-grid-react';
import { defineMessages } from 'react-intl';
import type { MarketAccount } from '../../../contexts/MarketAccountsContext';
import { strategyTranslations } from '../../../hooks';
import { CustomerOrder, ReconStateEnumLabels, type Order } from '../../../types';
import type { AggregationMarket } from '../../../types/Aggregation';
import type { CustomerRole } from '../../../types/CustomerRole';
import type { CustomerUser } from '../../../types/CustomerUser';
import type { Loan } from '../../../types/Loan';
import type { LoanQuote } from '../../../types/LoanQuote';
import type { LoanTransaction } from '../../../types/LoanTransaction';
import type { Security } from '../../../types/Security';
import type {
  AgGridButtonProps,
  AgGridCurrencyParams,
  AgGridCurrencyProps,
  AgGridFormattedNumberProps,
  AgGridIconButtonProps,
  AgGridSearchSelectDropdownProps,
  AgGridSecurityProps,
} from '../../AgGrid/types';
import { InlineFormattedNumber } from '../../FormattedNumber';
import { IndicatorBadge, IndicatorBadgeSizes, IndicatorBadgeVariants } from '../../IndicatorBadge';
import { FormattedMessage } from '../../Intl';
import { messages as columnHeaderTranslations } from './columnHeaderTranslations';
import type { SizeColumnParams } from './size.types';
import { toggle } from './toggle';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  transfer: {
    defaultMessage: 'Transfer',
    id: 'BlotterTable.columns.transfer',
  },
  withdraw: {
    defaultMessage: 'Withdraw',
    id: 'BlotterTable.columns.withdraw',
  },
  deposit: {
    defaultMessage: 'Deposit',
    id: 'BlotterTable.columns.deposit',
  },
  twoWay: {
    defaultMessage: '2-way',
    id: 'BlotterTable.columns.twoWay',
  },
  withdrawal: {
    defaultMessage: 'Withdrawal',
    id: 'BlotterTable.columns.withdrawal',
  },
  crypto: {
    defaultMessage: 'Crypto',
    id: 'BlotterTable.columns.crypto',
  },
  fiat: {
    defaultMessage: 'Fiat',
    id: 'BlotterTable.columns.fiat',
  },
});

type TextColumnParams = {
  getLabel?: (value: string, params: ValueFormatterParams<any, any>) => string;
  placeholder?: string;
  color?: string;
};

export const text: ColDefFactory<Column<TextColumnParams>> = column => ({
  ...baseColumn(column),
  cellEditor: 'input',
  valueFormatter: params => {
    if (column.params?.getLabel) {
      return column.params?.getLabel(params.value, params);
    }

    if (isPlainObject(params.value) || Array.isArray(params.value)) {
      try {
        return JSON.stringify(params.value);
      } catch (e) {
        logger.error(e as Error);
        return params.value;
      }
    }
    return params.value;
  },
  cellRenderer: params => {
    if (params?.column?.isCellEditable(params.node!) && column.params?.placeholder != null && !params.valueFormatted) {
      return <Text color={column?.params?.color ?? 'colorTextSubtle'}>{column.params.placeholder}</Text>;
    }
    return <Text color={column?.params?.color}>{params.valueFormatted ?? ''}</Text>;
  },
});

/**
 * Given an array of strings, formats to one comma-concatenated string. The value of the cell is unchanged.
 */
export const textArray: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value }: ValueFormatterParams<unknown, unknown>): string => {
    if (isArray(value)) {
      return value.join(', ');
    }
    return '';
  },
});

export const email: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellEditor: 'input',
  inputType: 'email',
  valueSetter: params => {
    if (column.field == null) {
      return false;
    }
    // Remove whitespaces
    params.data[column.field] = params.newValue.replace(/\s/g, '');
    return true;
  },
});

export type DateParams = { milliseconds?: boolean };
export const date: ColDefFactory<Column<DateParams>> = column => ({
  ...baseColumn(column),
  initialWidth: column.params?.milliseconds ? 200 : 160,
  comparator: dateStringComparator,
  valueFormatter: ({ value }: ValueFormatterParams<unknown, DateFnInput>) => {
    if (!value || value === '0') {
      return '';
    }
    try {
      return (column.params?.milliseconds ? formattedDateWithMilliseconds(value) : formattedDate(value)) ?? '';
    } catch (e) {
      return '';
    }
  },
  sortingOrder: ['desc', 'asc'],
});

export type IdParams = { maxLength?: number };
export const id: ColDefFactory<Column<IdParams>> = column => ({
  minWidth: 85,
  ...baseColumn(column),
  cellRenderer: ({ value }) => (
    <div>{value != null && typeof value === 'string' ? value?.substring(0, column.params?.maxLength || 4) : value}</div>
  ),
});

export const customer: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, any>) => {
    if (!value) {
      return '';
    }
    return context.current.customersByName?.get(value)?.DisplayName || value;
  },
});

// Note that this coldef works with single roles. Confusingly though they are represented as [Role]
// TODO this column definition is coupled to Customer land and should be called "customerRole" and be moved out of kyoko column defs
export const role: ColDefFactory<Column> = column => {
  const customerRolesMapByName: Map<string, CustomerRole> | undefined = column.params?.customerRoles;
  if (customerRolesMapByName == null) {
    throw new Error('customerRolesMapByName cannot be null for the role column');
  }
  return {
    ...baseColumn(column),
    valueGetter: ({ data }: { data: CustomerUser }) => {
      if (data.Roles === undefined || data.Roles.length === 0) {
        return '';
      } else if (data.Roles.length === 1) {
        // When creating a new row, we have a string as the role instead of a CustomerRole object
        if (typeof data.Roles[0] === 'string') {
          return customerRolesMapByName?.get(data.Roles[0])?.DisplayName;
        }
        return data.Roles[0].DisplayName;
      } else {
        // This shouldn't happen, but we'll still handle it gracefully
        return data.Roles.map(role => role.DisplayName).join(', ');
      }
    },
    cellRenderer: ({ value, context }) => {
      if (!value) {
        return '';
      }
      return <CustomerRolePillWrapper theme={context.current.theme}>{value}</CustomerRolePillWrapper>;
    },
    cellEditor: 'searchSelectDropdown',
    cellEditorPopup: true,
    suppressKeyboardEvent: params => params.event.key === 'Enter',
    cellEditorParams: (params: CustomCellEditorProps) => {
      if (customerRolesMapByName != null) {
        return {
          ...params,
          useSearchSelectParams: {
            getLabel: value => customerRolesMapByName?.get(value[0])?.DisplayName ?? '',
            // Each individual item must be represented as [name] where name is the Role
            items: [...customerRolesMapByName.keys()].map(name => [name]) || [],
          },
        } satisfies AgGridSearchSelectDropdownProps<string[]>;
      }
    },
  };
};

export const tradingMarketAccounts: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: ({ data }) => {
    const marketAccounts = column.params.marketAccountsByName;
    const getDisplayName = (name: string) => {
      if (marketAccounts.has(name)) {
        return marketAccounts.get(name).DisplayName;
      } else {
        return name;
      }
    };
    const accounts: Map<string, AggregationMarket> = data.Accounts;
    const filteredAccounts = Array.from(accounts.values())
      .filter(marketAccount => {
        return marketAccount.Mode === ModeEnum.Enabled;
      })
      .map<{
        key: string;
        displayName: string;
        market: string;
      }>(marketAccount => {
        const displayName = getDisplayName(marketAccount.MarketAccount || '');
        return {
          key: marketAccount.Market || displayName,
          displayName,
          market: marketAccount.Market ?? '',
        };
      })
      .filter(account => account.displayName !== '')
      .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
    return (
      <Tooltip
        usePortal
        placement="auto"
        tooltip={
          <>
            {filteredAccounts.map((marketAccount, index) => {
              return (
                <Flex alignItems="center" key={marketAccount.key} justifyContent="flex-start">
                  <MarketColorDot market={marketAccount.market} />
                  <span style={{ marginLeft: '10px' }}>{marketAccount.displayName}</span>
                </Flex>
              );
            })}
          </>
        }
      >
        {filteredAccounts
          .map(marketAccount => {
            return marketAccount.displayName;
          })
          .join(', ')}
      </Tooltip>
    );
  },
});

export const cumBaseQty: ColDefFactory<Column<SizeColumnParams>> = column => ({
  ...baseColumn(column),
  type: 'numericColumn',
  cellRenderer: 'sizeColumn',
  valueGetter: ({ data, context }): AmountObject => {
    const security = context.current.securitiesBySymbol?.get(data.Symbol);
    return getFilledBaseQuantity(data, security);
  },
  valueFormatter: amountObjectValueFormatter,
  comparator: amountObjectComparator,
  headerClass: 'ag-right-aligned-header',
});

type PercentParams = AgGridFormattedNumberProps;
export const percent: ColDefFactory<Column<PercentParams>> = column => ({
  maxWidth: 240,
  ...baseColumn(column),
  type: 'numericColumn',
  minWidth: 70,
  headerClass: column.headerClass ?? 'ag-right-aligned-header',
  cellEditor: 'input',
  cellRenderer: 'formattedNumberColumn',
  cellRendererParams: { ...column.params, unit: '%' },
  valueParser: ({ oldValue, newValue }) => {
    const value = newValue?.trim();
    try {
      Big(value).div(100);
      return value;
    } catch (e) {
      return oldValue;
    }
  },
  valueGetter: params => {
    try {
      const value = params.colDef.field != null ? get(params.data, params.colDef.field) : null;
      if (value == null || value === '') {
        return value;
      }
      const percent = Big(value).times(100).toFixed();
      return percent;
    } catch (e) {
      return null;
    }
  },
  comparator: numericColumnComparator,
});

/** Grabs the product type off of the the security associated with your data. The security is retrieved using data.Symbol */
export const productType: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    valueGetter: ({ data, context }) => {
      if (data == null) {
        return '';
      }

      const symbol = column.field ? get(data, column.field) : data.Symbol;
      if (!symbol) {
        return '';
      }

      const security = context.current.securitiesBySymbol?.get(symbol);
      return getProductType(security);
    },
    valueFormatter: ({ value }: ValueFormatterParams<unknown, ProductGroup>) => {
      if (value == null) {
        return '';
      }
      return getProductGroupLabel(value);
    },
  };
};

/** Grabs the product type using the given field. Prettifies before rendering. */
export const productTypeField: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value }: ValueFormatterParams<unknown, ProductTypeEnum | undefined>) => {
    return value ? productTypeToString(value) : '';
  },
});

export const filledNotional: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    type: 'numericColumn',
    minWidth: 70,
    maxWidth: 180,

    cellRenderer: 'sizeColumn',
    valueGetter: ({ data, context }): AmountObject => {
      const security = context.current.securitiesBySymbol?.get(data.Symbol);
      return getFilledNotional(data, security);
    },
    valueFormatter: amountObjectValueFormatter,
    comparator: amountObjectComparator,
    headerClass: 'ag-right-aligned-header',
  };
};

export const openNotional: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    type: 'numericColumn',
    minWidth: 70,
    maxWidth: 180,

    cellRenderer: 'sizeColumn',
    valueGetter: ({ data, context }): AmountObject => {
      const security = context.current.securitiesBySymbol?.get(data.Symbol);
      return getOpenNotional(data, security);
    },
    valueFormatter: amountObjectValueFormatter,
    comparator: amountObjectComparator,
    headerClass: 'ag-right-aligned-header',
  };
};

export const totalNotional: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    type: 'numericColumn',
    minWidth: 70,
    maxWidth: 180,

    cellRenderer: 'sizeColumn',
    valueGetter: ({ data, context }): AmountObject => {
      const security = context.current.securitiesBySymbol?.get(data.Symbol);
      return getTotalNotional(data, security);
    },
    valueFormatter: amountObjectValueFormatter,
    comparator: amountObjectComparator,
    headerClass: 'ag-right-aligned-header',
  };
};

export const filledCounterAmount: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    type: 'numericColumn',
    minWidth: 70,
    maxWidth: 180,

    cellRenderer: 'sizeColumn',
    valueGetter: ({ data, context }): AmountObject => {
      const security = context.current.securitiesBySymbol?.get(data.Symbol);
      return getFilledCounterAmount(data, security);
    },
    valueFormatter: amountObjectValueFormatter,
    comparator: amountObjectComparator,
    headerClass: 'ag-right-aligned-header',
  };
};

type OrderStatusValue = { statusText: OrderStatusText; Text?: string } | undefined;
export const orderStatus: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    valueGetter: ({ data }: ValueGetterParams): OrderStatusValue => {
      // Support nested properties to get order status
      if (column.field?.includes('.')) {
        data = get(data, column.field.substring(0, column.field.lastIndexOf('.')));
      }
      if (data == null) {
        return;
      }
      const { DecisionStatus, OrdStatus, CumQty, OrderQty, PricingMode, Text } = data;
      const statusText = getOrderStatusText({
        ordStatus: OrdStatus,
        decisionStatus: DecisionStatus,
        cumQty: CumQty,
        orderQty: OrderQty,
        pricingMode: data instanceof CustomerOrder ? undefined : PricingMode,
      });

      return { statusText, Text };
    },

    valueFormatter: ({ value }: ValueFormatterParams<unknown, OrderStatusValue>) => {
      if (!value) {
        return '';
      }
      return value.statusText;
    },
    cellRenderer: (params: ICellRendererParams) => {
      let data = params.data;
      // Support nested properties to get order status
      if (column.field?.includes('.')) {
        data = get(data, column.field.substring(0, column.field.lastIndexOf('.')));
        if (data == null) {
          return null;
        }
      }
      if (data == null) {
        return params.value;
      }
      const { OrdStatus, OrderQty, CumQty, DecisionStatus, Text, PricingMode } = data;
      return (
        <OrderStatus
          theme={params.context.current.theme}
          ordStatus={OrdStatus}
          decisionStatus={DecisionStatus}
          cumQty={CumQty}
          orderQty={OrderQty}
          pricingMode={data instanceof CustomerOrder ? undefined : PricingMode}
          text={Text}
          iconPlacement="left"
        />
      );
    },
    minWidth: 180,
    maxWidth: 220,
    flex: 1,
  };
};

export const transferStatus: ColDefFactory<Column> = column => {
  return {
    width: 100,
    ...baseColumn(column),
    cellRenderer: params => {
      const data = params.data;
      if (data == null) {
        return params.value;
      }
      return (
        // data.RejectText for CustomerBalanceTransaction messages
        <TransferStatus theme={params.context.current.theme} status={data.Status} text={data.Text || data.RejectText} />
      );
    },
  };
};

export const addressStatus: ColDefFactory<Column> = column => {
  return {
    width: 100,
    ...baseColumn(column),
    cellRenderer: params => {
      const data = params.data;
      if (data == null) {
        return params.value;
      }
      return <AddressStatus theme={params.context.current.theme} status={data.Status} text={data.Text} />;
    },
  };
};

export const tradeStatus: ColDefFactory<Column> = column => ({
  width: 128,
  ...baseColumn(column),
  field: 'TradeStatus',
  colId: 'TradeStatus',
  cellRenderer: params => {
    if (params.data == null) {
      return params.value;
    }
    return (
      <TradeStatus
        tradeStatus={params.data.TradeStatus}
        text={params.data.Text}
        iconPlacement="left"
        theme={params.context.current.theme}
      />
    );
  },
});

export const careOrderStatus: ColDefFactory<Column> = column => ({
  width: 128,
  field: 'status',
  ...baseColumn(column),
  cellRenderer: params => {
    if (params.data == null) {
      return params.value;
    }
    return <CareOrderStatus status={params.value} text={params.data.Text} iconPlacement="left" />;
  },
});

export const quoteStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  field: 'QuoteStatus',
  colId: 'QuoteStatus',
  cellRenderer: params => {
    if (params.data == null) {
      return params.value;
    }
    return (
      <QuoteStatus
        quoteStatus={params.data.QuoteStatus}
        iconPlacement="left"
        text={params.data.Text}
        theme={params.context.current.theme}
      />
    );
  },
});

export const loanQuoteStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  field: 'QuoteStatus',
  colId: 'QuoteStatus',
  valueGetter: ({ data, context }: ValueGetterParams<LoanQuote>) => {
    const user = context.current.user;
    const side = context.current.side;
    if (!data) {
      return undefined;
    }
    return getLoanQuoteStatusText({
      status: data.QuoteStatus,
      // @ts-expect-error This Column Def should be in Marketplace
      side,
      revision: data.Revision,
      // @ts-expect-error This Column Def should be in Marketplace
      participant: user.Participant,
      recipients: data.TermSheetRecipients,
    });
  },
  cellRenderer: ({ data, value, context }) => {
    if (data == null) {
      return value;
    }
    return (
      <LoanQuoteStatus
        status={data.QuoteStatus}
        recipients={data.TermSheetRecipients}
        revision={data.Revision}
        iconPlacement="left"
        text={data.Text}
        participant={context.current.user.Participant}
        side={context.current.side}
        theme={context.current.theme}
      />
    );
  },
});

export const loanStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  field: 'Status',
  colId: 'Status',
  valueGetter: ({ data, context }: ValueGetterParams<Loan>) => {
    if (!data) {
      return undefined;
    }
    const side = context.current.side;
    const user = context.current.user;
    const transactionsByLoanId = context.current.loanTransactionsByLoanId;
    const transactions = transactionsByLoanId
      ? // @ts-expect-error This Column Def should be in Marketplace
        [...(transactionsByLoanId.get(data.LoanID)?.values() ?? [])]
      : undefined;

    const status = getLoanStatusText({
      status: data.Status,
      side,
      transactions,
      // @ts-expect-error This Column Def should be in Marketplace
      participant: user?.Participant,
    });
    return status;
  },
  cellRenderer: ({ data, value, context }) => {
    if (data == null) {
      return value;
    }
    const transactionsByLoanId = context.current.loanTransactionsByLoanId;
    const transactions = transactionsByLoanId
      ? [...(transactionsByLoanId.get(data.LoanID)?.values() ?? [])]
      : undefined;
    return (
      <LoanStatus
        status={data.Status}
        iconPlacement="left"
        text={data.Text}
        transactions={transactions}
        side={context.current.side}
        theme={context.current.theme}
        participant={context.current.user?.Participant}
      />
    );
  },
});

export const loanTransactionStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  field: 'Status',
  colId: 'Status',
  valueGetter: ({ data, context }: ValueGetterParams<LoanTransaction>) => {
    const user = context.current.user;
    if (!data) {
      return undefined;
    }

    // @ts-expect-error This Column Def should be in Marketplace
    const direction = data.FromParticipant === user.Participant ? 'Outbound' : 'Inbound';
    const status = getLoanTransactionStatusText({
      status: data.Status,
      direction,
    });
    return status;
  },
  cellRenderer: ({ data, value, context }) => {
    if (data == null) {
      return value;
    }
    const user = context.current.user;
    const direction = data.FromParticipant === user.Participant ? 'Outbound' : 'Inbound';
    return (
      <LoanTransactionStatus
        status={data.Status}
        iconPlacement="left"
        text={data.Text}
        direction={direction}
        theme={context.current.theme}
      />
    );
  },
});

export const reconMismatchStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: ({ value }: ICellRendererParams<unknown, ReconStateEnum | undefined>) => {
    if (!value) {
      return null;
    }
    return <ReconMismatchStatus status={value} />;
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, ReconStateEnum | undefined>) => {
    if (!value) {
      return '';
    }

    return ReconStateEnumLabels[value];
  },
});

export const transactionType: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    cellRenderer: (params: ICellRendererParams) => {
      const value = params.context.current.intl.formatCell(params, messages);
      return (
        <HStack alignItems="center" gap="spacingDefault">
          <Icon
            icon={params.value === CustomerBalanceTransactionTypeEnum.Deposit ? IconName.Download : IconName.Upload}
            size={ICON_SIZES.LARGE}
            style={{ marginRight: params.context.current.theme?.spacingSmall }}
          />
          {value}
        </HStack>
      );
    },
  };
};

export const addressRoutingType: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    cellRenderer: (params: ICellRendererParams) => params.context.current.intl.formatCell(params, messages),
  };
};

export const processStep: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  colId: 'ProcessStep',
  cellRendererParams: column.params,
  cellRenderer: 'processStepColumn',
});

export const quoteValue: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  type: 'numericColumn',
  minWidth: 70,
  maxWidth: 180,

  cellRenderer: 'priceColumn',
  cellRendererParams: column.params,
  valueGetter: ({ data, context }) => {
    const { TradedPx, TradedAmt, Symbol, Currency } = data;
    const { QuoteCurrency } = (column.params?.securities ?? context.current.securitiesBySymbol)?.get(Symbol) ?? {};
    const isCcy = QuoteCurrency === Currency;
    const value = !isCcy
      ? TradedAmt
      : Big(TradedAmt || 0)
          .times(TradedPx || 1)
          .toFixed();
    return value;
  },
  comparator: numericColumnComparator,
});

export type SecurityColumnParams = AgGridSecurityProps;

export const security: ColDefFactory<Column<SecurityColumnParams>> = column => ({
  width: 100,
  ...baseColumn(column),
  valueGetter: (params: ValueGetterParams<unknown>) => {
    if (!column.field || !params.node?.data) {
      return undefined;
    }

    return get(params.node.data, column.field) || (column.params?.showAsteriskOnEmpty ? '*' : '');
  },
  tooltipValueGetter: params => params.data?.description,
  cellRenderer: 'securityColumn',
  cellRendererParams: column.params,
  valueFormatter: ({ context, value }) => {
    const security: Security | undefined = context.current.securitiesBySymbol?.get(value);
    return security?.DisplaySymbol ?? value;
  },
});

export const currency: ColDefFactory<Column<AgGridCurrencyParams>> = column => ({
  ...baseColumn(column),
  cellRenderer: 'currencyColumn',
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  cellRendererParams: column.params,
  suppressKeyboardEvent: params => params.event.key === 'Enter',
  cellEditorParams: (params: CustomCellEditorProps) => {
    const currencies = column?.params?.currencies ?? params.context.current.currenciesBySymbol ?? new Map();
    return {
      ...params,
      useSearchSelectParams: {
        items: [...currencies.values()].map(currency => currency.Symbol),
        getLabel: item => item,
      },
    } satisfies AgGridSearchSelectDropdownProps<string>;
  },
  valueGetter: ({ data, context }) => {
    const { symbolField, securityCurrencyField } = column.params ?? {};
    if (symbolField && securityCurrencyField) {
      const symbol = get(data, symbolField);
      const security = context.current.securitiesBySymbol?.get(symbol);
      const currency = get(security, securityCurrencyField);
      if (currency) {
        return currency;
      }
    }
    if (!column.field || !data) {
      return undefined;
    }

    return get(data, column.field);
  },
});

export interface AssetColumnProps<T extends object = any>
  extends Partial<AgGridCurrencyProps>,
    Partial<AgGridSecurityProps> {
  assetTypeField?: Leaves<T>;
}

export const asset: ColDefFactory<Column<AssetColumnProps>> = (column => ({
  ...baseColumn(column),
  cellRendererParams: column.params,
  cellRenderer: (params: ICellRendererParams) => {
    const assetTypeProp = column.params?.assetTypeField || '';

    const isCurrency =
      assetTypeProp && params.data
        ? get(params.data, assetTypeProp) === ProductTypeEnum.Spot
        : params.context?.current?.currenciesBySymbol?.has(params.value);
    return isCurrency ? (
      <AgGridCurrency {...column.params} {...params} />
    ) : (
      <AgGridSecurity {...column.params} {...params} />
    );
  },
})) satisfies ColDefFactory<Column<AssetColumnProps>>;

export const side: ColDefFactory<Column> = column => ({
  width: 60,
  ...baseColumn(column),
  cellRenderer: (params: ICellRendererParams) => {
    const displayValue = params.context.current.intl.formatCell(params, columnHeaderTranslations);
    return (
      <Side theme={params.context.current.theme} side={params.value}>
        {displayValue}
      </Side>
    );
  },
});

export const quoteSide: ColDefFactory<Column> = column => ({
  width: 60,
  ...baseColumn(column),
  cellRenderer: ({ value, context }) => {
    return (
      <Side theme={context.current.theme} side={value}>
        {value ?? <FormattedMessage {...messages.twoWay} />}
      </Side>
    );
  },
  valueGetter: ({ data }) => {
    const value = column.field ? get(data, column.field) : undefined;
    const fallbackValue = column?.params?.fallbackField == null ? undefined : data[column.params.fallbackField];
    return value ?? fallbackValue;
  },
  valueFormatter: ({ data }) => data.Side ?? TWO_WAY_SIDES_FILTER,
  filterValueGetter: ({ data }) => data.Side ?? TWO_WAY_SIDES_FILTER,
});

export const strategy: ColDefFactory<Column> = column => ({
  width: 96,
  ...baseColumn(column),
  valueGetter: ({ data }: ValueGetterParams<unknown>) => {
    const field = column.field;
    if (!field || !data) {
      return undefined;
    }

    const strategyName: string | undefined = get(data, field);
    if (strategyName) {
      return strategyName;
    }

    // For orders. If we weren't able to resolve any specific strategy, this might be an RFQ Order. In that case,
    // there'll be an RFQID. If we find that, just show RFQ as the strategy.
    if ('RFQID' in (data as Order)) {
      return 'RFQ';
    }

    return undefined;
  },
  valueFormatter: (params: ValueFormatterParams<unknown, string | undefined>) => {
    return (
      params.context.current.intl.formatCell(params, strategyTranslations, params.context.current.strategiesByName) ??
      ''
    );
  },
});

export const filler: ColDefFactory<Column> = column => ({
  ...baseColumn({ ...column, exportable: false }),
  headerName: '',
  headerClass: 'no-handle',
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  suppressHeaderMenuButton: true,
  flex: 1,
});

export interface FilledPercentColumnParams {
  cumQtyField?: string;
  orderQtyField?: string;
  [key: string]: any; // wildcard to catch the crazy stuff we're doing in this column... not touching this for now
}

export interface FilledPercentColumnValue {
  value: string;
  color: string | undefined;
}

export const filledPercent: ColDefFactory<Column<FilledPercentColumnParams>> = column => ({
  ...baseColumn(column),
  cellRenderer: 'meterColumn',
  cellRendererParams: {
    showInitialAnimation: false,
    ...column.params,
  },
  menuTabs: [],
  minWidth: 120,
  maxWidth: 480,
  flex: 1,
  sortable: false,
  // Todo: this column should be rewritten
  valueGetter: ({ data, api, node, context }): FilledPercentColumnValue => {
    const cumQty = column.params?.cumQtyField ? get(data, column.params.cumQtyField) : data.cumQty ?? data.CumQty;
    const orderQty = column.params?.orderQtyField
      ? get(data, column.params.orderQtyField)
      : data.orderQty ?? data.OrderQty;

    const cumQtyBig = toBigWithDefault(cumQty, 0);
    const orderQtyBig = toBigWithDefault(orderQty, 0);

    let value = '0';
    if (!orderQtyBig.eq('0') && !cumQtyBig.eq('0')) {
      const percent = cumQtyBig.div(orderQtyBig);
      if (percent.gt('0.99') && cumQtyBig.lt(orderQtyBig)) {
        // Ensure that we don't show '100.00%' when the order isn't fully filled
        // by adding extra precision and rounding down between 99% and 100%
        value = percent.toFixed(4, Big.roundDown);
      } else if (cumQtyBig.gt(0) && percent.lt('0.01')) {
        // Ensure that we don't show '0.00%' when the order is partially filled
        value = percent.toFixed(4, Big.roundUp);
      } else {
        value = percent.toFixed(4);
      }
    }

    const nodeOrdStatusValue =
      node != null
        ? api.getCellValue<OrderStatusValue>({ colKey: 'ordStatus', rowNode: node }) ??
          api.getCellValue<OrderStatusValue>({ colKey: 'OrdStatus', rowNode: node })
        : undefined;

    return {
      value,
      color: getStatusColor(nodeOrdStatusValue?.statusText ?? undefined, context.current.theme),
    };
  },
});

export const exposureUtilization: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: 'meterColumn',
  cellRendererParams: {
    showInitialAnimation: false,
    ...column.params,
  },
  menuTabs: [],
  minWidth: 140,
  with: 180,
  maxWidth: 480,
  flex: 1,
  valueGetter: ({ data, context }: ValueGetterParams<any>) => {
    if (!data.Exposure || !data.ExposureLimit) {
      return { value: null };
    }
    const limit = Big(data.ExposureLimit);
    const exposure = Big(data.Exposure);

    if (limit.eq(0) || exposure.eq(0)) {
      return { value: '0' };
    }

    const value = Big(exposure).div(limit).toFixed();
    if (context.current.theme == null) {
      return { value };
    }
    const { colorTextPositive, colorTextWarning } = context.current.theme;
    const color = Big(value).gte(1) ? colorTextWarning : colorTextPositive;
    return {
      value,
      color,
    };
  },
});

// To be used with OMSQuote4401 data
export const quoteMarkets: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data, colDef }) => {
    const markets = data[colDef.field as string];
    const quoteStatus = data.QuoteStatus;
    const isQuoteDone = quoteStatus === QuoteStatusEnum.Filled;

    let value = markets;
    if (column.params?.showFilledOnly && isQuoteDone) {
      value = markets.filter(m => m.QuoteStatus === QuoteStatusEnum.Filled);
    }
    return value;
  },
  valueFormatter: ({ value, context }) => {
    if (value == null) {
      return '';
    }
    return sortBy(value.map(({ Market }) => context.current.marketDisplayNameByName?.get(Market) ?? Market))?.join(
      ', '
    );
  },
});

export const marketColor: ColDefFactory<Column> = column => ({
  width: 32,
  ...baseColumn(column),
  cellRendererParams: column.params,
  headerName: '',
  cellRenderer: ({ value }) => {
    return (
      <Flex alignItems="center">
        <MarketColorDot market={value} />
      </Flex>
    );
  },
});

export const button: ColDefFactory<Column<AgGridButtonProps & { alignment?: ColDef['type'] }>> = column => ({
  ...baseColumn({ ...column, exportable: false }),
  headerName: (typeof column.title === 'string' && column.title) || '',
  menuTabs: [],
  cellRenderer: 'buttonColumn',
  cellRendererParams: column.params,
  type: column.params?.alignment,
});

export const iconButton: ColDefFactory<Column<AgGridIconButtonProps & { alignment?: ColDef['type'] }>> = column => ({
  ...baseColumn({ ...column, exportable: false }),
  headerName: '',
  menuTabs: [],
  cellRenderer: 'iconButtonColumn',
  cellRendererParams: column.params,
  type: column.params?.alignment,

  suppressColumnsToolPanel: true,
});

export const orderButton: ColDefFactory<Column> = column => ({
  ...baseColumn({ ...column, exportable: false }),
  headerName: column?.params?.side,
  menuTabs: [],
  width: 100,
  cellRenderer: ({ node, context }) => {
    if (node.group) {
      return <></>;
    }

    const side: SideEnum = column.params?.side;
    const baseCurrency: string = column.params?.baseCurrencyField
      ? get(node.data, column.params.baseCurrencyField)
      : undefined;
    const quoteCurrency: string = column.params?.quoteCurrencyField
      ? get(node.data, column.params.quoteCurrencyField)
      : undefined;
    const marketAccountName: string = column.params?.marketAccountField
      ? get(node.data, column.params.marketAccountField)
      : undefined;

    if (!baseCurrency || !quoteCurrency || !marketAccountName || !side || quoteCurrency === baseCurrency) {
      return <></>;
    }

    const symbol = `${baseCurrency}-${quoteCurrency}`;
    const security: Security | undefined = context.current?.securitiesBySymbol?.get(symbol);

    const marketAccount: MarketAccount | undefined = context.current?.marketAccountsByName?.get(marketAccountName);
    const marketAccountSupported = marketAccount && security && security.Markets?.includes(marketAccount.Market);

    const disabled = security == null || !marketAccountSupported;

    const handleClick = () => column.params?.onClick?.(security, marketAccountName, side, node);

    return (
      <Button onClick={handleClick} size={FormControlSizes.Small} disabled={disabled}>
        {side} {baseCurrency}
      </Button>
    );
  },
});

export const connectionStatus: ColDefFactory<Column> = column => ({
  ...iconButton(column),
  headerName: (typeof column.title === 'string' && column.title) || '',
  valueGetter: ({ node }) => get(node?.data || {}, column.field || ''),
  comparator: (a, b) =>
    typeof a === 'string' && typeof b === 'string' ? stringColumnComparator(a, b) : numericColumnComparator(a, b),
  cellRendererParams: ({ context, node }): AgGridIconButtonProps => {
    const status = get(node.data, column.field || '') as
      | ConnectionStatusEnum
      | ConnectionModeEnum
      | ModeEnum
      | true
      | false;
    let color = context.current.theme.colorTextMuted;
    switch (status) {
      case true:
        color = context.current.theme.colorTextPositive;
        break;
      case false:
        color = context.current.theme.colorTextNegative;
        break;
      case ConnectionModeEnum.Up:
      case ConnectionStatusEnum.Online:
      case ModeEnum.Enabled:
        color = context.current.theme.colorTextPositive;
        break;
      case ConnectionModeEnum.Down:
      case ConnectionStatusEnum.Disabled:
      case ConnectionStatusEnum.Offline:
      case ModeEnum.Disabled:
        color = context.current.theme.colorTextMuted;
        break;
      case ConnectionStatusEnum.Starting:
      case ConnectionStatusEnum.Stopping:
        color = context.current.theme.colorTextWarning;
        break;
      case ConnectionStatusEnum.Error:
      case ConnectionStatusEnum.Stale:
      case ConnectionStatusEnum.Unavailable:
      case ConnectionStatusEnum.NotInSession:
      case ConnectionStatusEnum.Keepalive:
        color = context.current.theme.colorTextNegative;
        break;
      default:
        color = context.current.theme.colorTextMuted;
    }
    return {
      icon: IconName.DotSolid,
      color: color,
      onClick: () => {},
      dataValue: String(status),
    };
  },
});

/*
Special columns
 */

// Escape hatch for truly custom columns
export const custom: ColDefFactory<Column<ColDef>> = column => ({
  ...baseColumn(column),
  ...column.params,
});

export interface RemoveParams {
  onClick?: (props: { node: RowNode; api: GridApi }) => void;
  allowOnlyForAddedRows?: boolean;
  disabled?: (data: any) => boolean;
  disabledTooltip?: string;
}

export const remove: ColDefFactory<Column<RemoveParams>> = ({ ...column }) => ({
  ...baseColumn(column),
  colId: 'remove',
  cellRenderer: 'iconButton',
  headerClass: 'no-handle',
  headerName: '',
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  suppressHeaderMenuButton: true,
  width: 40,
  resizable: false,
  tooltipValueGetter: ({ data }) => (column.params?.disabled?.(data) ? column.params.disabledTooltip : undefined),
  tooltipComponent: 'customTooltip',
  cellRendererParams: ({ context, node }) => {
    const row = context.current.getRow(node.id);
    if (column.params?.allowOnlyForAddedRows && row.status !== FormRowStatus.Added) {
      return { disabled: true };
    }
    return {
      icon: IconName.Trash,
      onClick: ({ node }: { node: RowNode }) => {
        if (node.data.formRow.status !== FormRowStatus.Removed) {
          row.remove();
          column.params?.onClick?.({ node, api: context.current.api });
        }
      },
      disabled: column.params?.disabled?.(node.data),
    };
  },
});

export const mode: ColDefFactory<Column> = column => ({
  ...toggle(column),
  headerClass: 'ag-center-aligned-cell',
  valueGetter: ({ node }) =>
    column.field && (get(node?.data, column.field) === 'Enabled' || get(node?.data, column.field) === true),
  valueSetter: params => {
    if (column.field == null) {
      return false;
    }
    if (typeof params.data[column.field] === 'boolean') {
      params.data[column.field] = params.newValue;
    } else {
      params.data[column.field] = params.newValue ? 'Enabled' : 'Disabled';
    }
    return true;
  },
});

type ModeWithMessageCellRendererParams = ICellRendererParams<any, boolean> & {
  checked: boolean;
  icon?: ReactNode;
  onChange: (value: boolean) => void;
  tooltip?: ReactNode;
};

export const modeWithMessage: ColDefFactory<Column> = column => ({
  ...mode(column),
  headerName: '',
  cellRenderer: ({
    checked,
    icon = <Icon icon={IconName.ExclamationCircleSolid} color="colors.yellow.lighten" />,
    onChange,
    tooltip,
    ...props
  }: ModeWithMessageCellRendererParams) => {
    return (
      <HStack gap="spacingDefault" w="100%" justifyContent="center">
        <AgGridToggle {...props} value={checked} setValue={onChange} />
        {tooltip && (
          <Tooltip tooltip={tooltip} usePortal={true}>
            {icon}
          </Tooltip>
        )}
      </HStack>
    );
  },
  cellRendererParams: (params: ICellRendererParams<any, boolean>) => {
    const cellRendererParams = column.params?.cellRendererParams?.(params) ?? {};
    return {
      ...cellRendererParams,
      onChange: (value: boolean) => {
        params?.setValue?.(value);
        cellRendererParams?.onChange?.(value);
      },
    };
  },
});

export const active: ColDefFactory<Column> = column => ({
  ...toggle(column),
  headerClass: 'ag-center-aligned-cell',
  valueGetter: ({ node }) => column.field && node?.data[column.field] === 'Active',
  valueSetter: params => {
    if (column.field == null) {
      return false;
    }
    params.data[column.field] = params.newValue ? 'Active' : 'Inactive';
    return true;
  },
});

export const formError: ColDefFactory<Column> = column => ({
  ...baseColumn,
});

export const time: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  initialWidth: 75,
  comparator: dateStringComparator,
  valueFormatter: ({ value }: ValueFormatterParams<unknown, DateFnInput>) => {
    if (!value || value === '0') {
      return '';
    }
    try {
      return formattedTime(value);
    } catch (e) {
      logger.error(e as Error);
      return '*Invalid Time*';
    }
  },
  headerClass: 'ag-right-aligned-cell',
  type: 'rightAligned',
  sortingOrder: ['desc', 'asc'],
});

export const json: ColDefFactory<Column> = column => ({
  ...baseColumn({ ...column, exportable: false }),
  width: 60,
  cellRenderer: function JSONCellRenderer() {
    return (
      <span style={{ flex: 1, justifyContent: 'flex-start' }}>
        <Icon icon={IconName.Braces} style={{ width: '5px' }} />
      </span>
    );
  },
  valueGetter: ({ data }) => JSON.stringify(data),
  valueFormatter: ({ data }) => JSON.stringify(data, null, 2),
  tooltipComponent: 'customTooltip',
});

/** Cell renderer for bitmap-like json:
 * ```
 *  {[key: string]: boolean}
 * ```
 * which renders keys that have truthy values. */
export const bitmap: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data }) => {
    const field = column.field;
    if (!field) {
      return '';
    }
    const bitmap = data[field];
    if (isObject(bitmap)) {
      return keys(bitmap)
        .filter(key => bitmap[key])
        .join(', ');
    }
    return '';
  },
});

export const loanParticipant: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data, context, colDef }) => {
    const value = colDef.field != null ? data[colDef.field] : null;
    return context.current.loanParticipantsByName?.get(value)?.DisplayName ?? value;
  },
});

export const exposureStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: ({ data, value }) => {
    if (value) {
      return (
        // Exposure Status shouldn't show the yellow indicator
        <ExposureStatus status={value} text={data?.Status === ExposureStatusEnum.Offline ? data?.Text : null} />
      );
    }
    return null;
  },
  width: 125,
});

export const bps: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data }: ValueGetterParams) => {
    if (!data) {
      return '';
    }
    return percentToBps(data[column.field!]);
  },
  cellRenderer: ({ data, value }) => {
    if (!data || !value) {
      return '';
    }
    return <InlineFormattedNumber number={value} currency="BPS" />;
  },
});

export const pricingAggregation: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ context, value }: ValueFormatterParams) => {
    if (!value) {
      return '';
    }
    return context.current.aggregationsByName?.get(value)?.DisplayName ?? value;
  },
});

export const feeMode: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value }: ValueFormatterParams) => {
    if (!value) {
      return '';
    }
    return prettyName(value);
  },
});

type RevisionData = { Revision?: string };
export const revision: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  suppressMenu: true,
  sortable: true,
  suppressMovable: true,
  headerValueGetter: () => 'Revision Indicator',
  pinned: 'left',
  headerClass: 'hide-header-label',
  maxWidth: 32,
  minWidth: 32,
  valueGetter: ({ data }: ValueGetterParams<RevisionData>) => {
    if (!data) {
      return '';
    }
    if (data.Revision && data.Revision?.toString() !== '0') {
      return 'M';
    }

    return '';
  },
  cellRenderer: ({ value }: ICellRendererParams<RevisionData, string>) => {
    if (value && value === 'M') {
      return (
        <JITTooltip tooltip="The revision of this trade indicates that it has been amended">
          <HStack gap="spacingTiny">
            <IndicatorBadge square size={IndicatorBadgeSizes.Small} variant={IndicatorBadgeVariants.Modified}>
              M
            </IndicatorBadge>
          </HStack>
        </JITTooltip>
      );
    }
    return null;
  },
});
