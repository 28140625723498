import { Flex, Icon, Text, abbreviate, type IconName } from '@talos/kyoko';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

interface SettlementDistributionBarHeaderProps {
  label: string;
  value: number;
  icon?: IconName;
  valueColor: string;
  direction?: 'ltr' | 'rtl';
  wrapLabel?: boolean;
}

export const SettlementDistributionBarHeader = ({
  label,
  value,
  icon,
  valueColor,
  direction = 'ltr',
  wrapLabel = true,
}: SettlementDistributionBarHeaderProps) => {
  const { homeCurrency } = useDisplaySettings();
  const { fontSizeBig, colorTextImportant } = useTheme();

  const paddingRule = useMemo(() => {
    if (direction === 'ltr') {
      return { pl: 'spacingDefault' };
    } else {
      return { pr: 'spacingDefault' };
    }
  }, [direction]);

  return (
    <Flex
      data-testid={`bar-header-${label}`}
      justifyContent={direction === 'ltr' ? 'flex-start' : 'flex-end'}
      {...paddingRule}
    >
      <Flex
        justifyContent={direction === 'ltr' ? 'flex-start' : 'flex-end'}
        flexDirection="column"
        gap="spacingDefault"
      >
        <Text
          style={{ whiteSpace: wrapLabel ? 'normal' : 'nowrap', textAlign: direction === 'ltr' ? 'left' : 'right' }}
        >
          {label}
        </Text>
        <Flex
          flexDirection={direction === 'ltr' ? 'row-reverse' : 'row'}
          justifyContent="flex-end"
          alignItems="center"
          gap="spacingSmall"
        >
          {icon && <Icon icon={icon} color={valueColor} size={14} />}
          <Text
            size={fontSizeBig}
            color={valueColor ?? colorTextImportant}
            style={{ whiteSpace: 'nowrap', lineHeight: 1 }}
          >
            {abbreviate(value)} <Text style={{ opacity: 0.7 }}>{homeCurrency}</Text>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
