import { forwardRef, useEffect } from 'react';
import type { CreateMultilegDialogProps } from './types';

import {
  Box,
  Button,
  ButtonVariants,
  Dialog,
  HStack,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  isMultileg,
  useDynamicCallback,
  useMixpanel,
  useSecurity,
} from '@talos/kyoko';
import { noop } from 'lodash';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { ErrorBoundary } from '../../ErrorBoundary';
import { getMultilegInstrumentType } from '../../OMS/NewOrder/utils/mappers';
import { Delta1SpreadScreen } from '../Delta1SpreadScreen';
import { MultilegComboDetails } from '../MultilegComboDetails';
import { MultilegComboHeader } from '../MultilegComboHeader';
import {
  clear,
  primeMultilegState,
  selectInstrumentField,
  selectIsEditing,
  selectIsLoading,
  selectIsValid,
  selectResolvedSymbol,
  setIsEditing,
  setIsLoading,
} from '../MultilegComboSlice';
import { OptionStrategyScreen } from '../OptionStrategyScreen';
import { isMultilegOptionComboType } from '../enums';
import { useMultilegComboService, useRowEnricher } from '../hooks';

const panelID = 'MODAL';

// This modal shares state and actions with the MultilegCombo component, but is used in a non-OMS context when
// creating or editing a multileg symbol from secmaster and Market Data cards. We could consider making it more
// prop driven and less reliant on the OMS state, but for now we can reset the state when the dialog closes
export const CreateMultilegDialog = forwardRef<HTMLDivElement | null, CreateMultilegDialogProps>(
  function CreateMultilegDialog(
    { isOpen, symbol, useSymbolLabel = 'Prime Order', close, onUseSymbol }: CreateMultilegDialogProps,
    ref
  ) {
    const dispatch = useAppStateDispatch();
    const resolvedSymbol = useAppStateSelector(state => selectResolvedSymbol(state, panelID));
    const instrumentField = useAppStateSelector(state => selectInstrumentField(state, panelID));
    const security = useSecurity(symbol);

    useRowEnricher({ tag: 'CreateMultilegDialog', panelID });

    useEffect(() => {
      if (isOpen && security && isMultileg(security)) {
        const syntheticProductType = security.MultilegDetails?.SyntheticProductType;
        const multilegInstrumentType = getMultilegInstrumentType(syntheticProductType);
        setTimeout(() => {
          // Unfortunate workaround - when we update a multileg security, the useSecurity (SecuritiesContext) will be updated first
          // and the OMS reference data will sync up shortly after - without the delay we risk displaying outdated securities info
          dispatch(
            primeMultilegState({
              panelID,
              multilegType: multilegInstrumentType!,
              security,
              syntheticProductType,
            })
          );
          dispatch(setIsEditing({ isEditing: true, panelID }));
        }, 0);
      } else {
        dispatch(primeMultilegState({ panelID }));
      }
    }, [isOpen, security, dispatch]);

    return (
      <Dialog
        isOpen={isOpen}
        close={close}
        width={1400}
        title={`${!resolvedSymbol ? 'New' : 'Edit'} Multileg Symbol`}
        ref={ref}
        alignContent="left"
        showClose={true}
        showCancel={false}
        showConfirm={false}
        closeOnClickOutside={false}
        // This should be true, but the if you have blotter table dropdown open when you hit escape it crashes the page.
        // Something to do with the AgGrid trying to access the DOM after it's been removed.
        closeOnEscape={false}
        customActions={<Actions onUseSymbol={onUseSymbol} close={close} useSymbolLabel={useSymbolLabel} />}
        data-testid="create-multileg-dialog"
      >
        <ErrorBoundary>
          <MultilegComboHeader
            symbol={resolvedSymbol}
            instrumentType={instrumentField?.value?.value}
            panelID={panelID}
          />

          <HStack
            gap="spacingComfortable"
            justifyContent="space-between"
            w="100%"
            py="spacingComfortable"
            borderTop="solid 1px"
            borderBottom="solid 1px"
            borderColor="colors.gray.010"
          >
            <MultilegComboDetails security={security} panelID={panelID} isModal />
          </HStack>

          {isMultilegOptionComboType(instrumentField?.value?.value) ? (
            <OptionStrategyScreen panelID={panelID} />
          ) : (
            <Delta1SpreadScreen panelID={panelID} />
          )}
        </ErrorBoundary>
      </Dialog>
    );
  }
);

function Actions({
  onUseSymbol,
  useSymbolLabel,
  close,
}: {
  onUseSymbol?: (symbol: string) => void;
  useSymbolLabel: string;
  close: () => void;
}) {
  const mixpanel = useMixpanel();
  const dispatch = useAppStateDispatch();
  const service = useMultilegComboService(panelID, noop);

  const isValid = useAppStateSelector(state => selectIsValid(state, panelID));
  const isLoading = useAppStateSelector(state => selectIsLoading(state, panelID));
  const isEditing = useAppStateSelector(state => selectIsEditing(state, panelID));
  const resolvedSymbol = useAppStateSelector(state => selectResolvedSymbol(state, panelID));

  const handleUseSymbol = useDynamicCallback(async () => {
    // If we are editing or creating we should upsert the symbol, but we allow an override for using the symbol
    if (isEditing || !resolvedSymbol || !onUseSymbol) {
      dispatch(setIsLoading({ isLoading: true, panelID }));
      await service?.upsertMultilegSecurityAndPrimeOMS(false);
    } else if (onUseSymbol) {
      onUseSymbol(resolvedSymbol);
    }
    // We keep the dialog open to allow editing display name etc. after creation
    if (resolvedSymbol) {
      close();
    }
  });

  const handleDelete = useDynamicCallback(async () => {
    dispatch(setIsLoading({ isLoading: true, panelID }));
    await service?.deleteSymbol();
    close();
  });

  const handleEditOrCancel = useDynamicCallback(async () => {
    if (isEditing) {
      dispatch(setIsEditing({ isEditing: false, panelID }));
    } else {
      mixpanel.track(MixpanelEvent.EditMultileg, {
        [MixpanelEventProperty.Source]: MixpanelEventSource.MultilegModal,
      });
      dispatch(setIsEditing({ isEditing: true, panelID }));
    }
  });

  return (
    <HStack
      w="100%"
      justifyContent="spacingBetween"
      p="spacingMedium"
      borderTop="solid 1px"
      borderColor="colors.gray.010"
    >
      <Box>
        <Button
          onClick={handleDelete}
          disabled={isLoading || !resolvedSymbol}
          variant={ButtonVariants.Negative}
          id="multileg-deepdive-delete"
        >
          Delete Symbol
        </Button>
      </Box>
      <HStack ml="auto" gap="spacingComfortable">
        {resolvedSymbol ? (
          <Button onClick={handleEditOrCancel} disabled={isLoading} id="multileg-deepdive-edit">
            {isEditing ? 'Cancel' : 'Edit Symbol'}
          </Button>
        ) : null}
        {!resolvedSymbol ? (
          // Should revert to the original state if initiated as editing a symbol
          <Button onClick={() => dispatch(clear(panelID))} disabled={isLoading}>
            Clear
          </Button>
        ) : null}

        {/* We should validate local state before attempting an update and highlight user errors */}
        <Button
          onClick={handleUseSymbol}
          disabled={!isValid || isLoading}
          loading={isLoading}
          variant={resolvedSymbol ? ButtonVariants.Primary : ButtonVariants.Positive}
        >
          {isEditing ? 'Update Symbol' : resolvedSymbol ? useSymbolLabel : 'Create Symbol'}
        </Button>
      </HStack>
    </HStack>
  );
}
