import {
  AssetTransaction,
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_MAX_ROWS,
  FormControlSizes,
  IconName,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  columnToColumnState,
  createCSVFileName,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  useAccordionFilterBuilder,
  useAssetTransactionColumns,
  useBlotterTableExtrasMenu,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterTableFilter,
  type Column,
  type ColumnState,
  type WebsocketRequest,
} from '@talos/kyoko';
import { compact } from 'lodash';

import type { GetContextMenuItemsParams, GetMainMenuItemsParams } from 'ag-grid-community';
import { OrgConfigurationKey, useOrgConfiguration } from 'providers';
import {
  colIDToFilterBuilderKey,
  onlyServerFilterKeys,
  useAssetTransactionsFilter,
  type AssetTransactionsFilter,
} from './useAssetTransactionsFilter';

export interface AssetTransactionsBlotterTableProps {
  blotterID: string;
  tabLabel?: string;
  defaultColumns?: Column[];
  defaultFilter?: AssetTransactionsFilter;
  initialIsOpen?: boolean;

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab?: (filter: BlotterTableFilter, columns: ColumnState[]) => void;
}

export function AssetTransactionsBlotterTable({
  blotterID,
  tabLabel,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  onCloneTab,
}: AssetTransactionsBlotterTableProps) {
  const { getConfig } = useOrgConfiguration();
  const mixpanel = useMixpanel();
  const columns = useAssetTransactionColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<AssetTransaction>(blotterID, {
    columns: columns,
    filter: defaultFilter,
    sort: '-TransactTime',
  });

  const filterResults = useAssetTransactionsFilter({
    persistedBlotterTable,
  });

  const { blotterTableFilterProps, filterBuilderProps } = filterResults;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps,
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams) => {
    return filterByColumnMainMenuItems({
      params,
      colIDToFilterBuilderKey,
      openClause: filterBuilderAccordion.openClause,
      mixpanel,
    });
  });

  const { handleClickJson, jsonModal } = useJsonModal();
  const defaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams) => {
    const data = params.node?.data;
    const items = compact([
      ...filterByCellValueMenuItem({
        params,
        filterableProperties: filterBuilderProps.properties,
        openClause: filterBuilderAccordion.openClause,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      ...(data
        ? [
            {
              name: `Show JSON`,
              action: () => handleClickJson(data),
            },
            'separator',
          ]
        : []),
      ...defaultContextMenuItems(params),
    ]);
    return items;
  });

  const blotterTable = useWsBlotterTable<WebsocketRequest, AssetTransaction>({
    initialRequest: {
      tag: blotterID,
      name: AssetTransaction.StreamName,
      sort_by: '-TransactTime',
    },
    filter: onlyServerFilterKeys(filterResults.filter),
    rowID: AssetTransaction.rowID,
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: persistedBlotterTable.columns,
    getExtraMainMenuItems,
    startingRowLimit: getConfig(OrgConfigurationKey.BlotterRowsMax, DEFAULT_MAX_ROWS),
    gridOptions: {
      getContextMenuItems,
    },
  });

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleCloneTab = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.CloneTab);
    onCloneTab?.(filterResults.filter, blotterTable.getColumns().map(columnToColumnState));
    extrasMenuPopover.close();
  });

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: AssetTransaction.BlotterExportName,
        tabLabel,
      }),
    });
    extrasMenuPopover.close();
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTableFilterProps}
        {...blotterTable.blotterTableFiltersProps}
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
              Export
            </Button>
            {onCloneTab && (
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
            )}
          </BlotterTableExtrasMenu>
        }
      />
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.TransactTime} />
      <BlotterTable {...blotterTable} />
      {jsonModal}
    </>
  );
}
