import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  Button,
  ButtonVariants,
  Checkbox,
  Dialog,
  Flex,
  FormControlSizes,
  HelpIcon,
  Icon,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Panel,
  PanelContent,
  PanelHeader,
  Text,
  useDisclosure,
  useGlobalDialog,
  useGlobalToasts,
  useMixpanel,
} from '@talos/kyoko';

import { ButtonWrapper, FormGroup, Tooltip } from '@talos/kyoko';
import { FormHeader, FormRow } from 'components/Form';
import { useSounds, useSoundSettings } from 'providers';
import { useBlotterState } from 'providers/AppConfigProvider';
import { usePortfolioSettings } from 'providers/PortfolioSettingContext';
import { SOUND_OPTIONS } from 'providers/SoundContext';
import { useFeatureFlag } from '../../../hooks';
import { useAppLayoutConfig } from '../../../providers/AppLayoutConfig/AppLayoutConfigContext';
import { SoundOptions } from '../styles';

export const General = function General() {
  const theme = useTheme();
  const mixpanel = useMixpanel();
  const { clearBlotterState } = useBlotterState();
  const { add } = useGlobalToasts();
  const { playSound } = useSounds();
  const { enableSoundEffects, setEnableSoundEffects, enabledSounds, setEnabledSounds } = useSoundSettings();
  const { enablePortfolioManagementSystem, enableFlexibleUI } = useFeatureFlag();
  const { enableFlexibleLayout, setEnableFlexibleLayout, resetLayoutConfig, reImportTabs } = useAppLayoutConfig();
  const [reImportLoading, setReImportLoading] = useState(false);
  const [resetLayoutLoading, setResetLayoutLoading] = useState(false);
  const { open } = useGlobalDialog();

  const handleConfirm = useCallback(() => {
    clearBlotterState();
    mixpanel.track(MixpanelEvent.ResetBlotterColumns);
    add({
      text: 'Blotter column settings cleared.',
      variant: NotificationVariants.Positive,
    });
  }, [clearBlotterState, add, mixpanel]);

  const blotterResetWarnDialog = useDisclosure();
  const { enablePMSLayout, setEnablePMSLayout, treatStablecoinsAsCash, setTreatStablecoinsAsCash } =
    usePortfolioSettings();

  const handleEnableFlexibleLayout = useCallback(() => {
    mixpanel.track(MixpanelEvent.EnableFlexibleLayout, {
      [MixpanelEventProperty.Enabled]: !enableFlexibleLayout,
    });
    setEnableFlexibleLayout(!enableFlexibleLayout);

    // Allow config to be updated and refresh the page to load new config
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, [mixpanel, setEnableFlexibleLayout, enableFlexibleLayout]);

  const handleConfirmReImportTabs = useCallback(() => {
    mixpanel.track(MixpanelEvent.ReImportTabsForFlexibleLayout);
    reImportTabs();
    setReImportLoading(true);

    // Allow config to be updated and refresh the page to load new config
    setTimeout(() => {
      setReImportLoading(false);
      window.location.reload();
    }, 1000);
  }, [reImportTabs, mixpanel]);

  const handleReImportTabs = useCallback(() => {
    open({
      title: 'Re-import Tabs',
      content: (
        <Flex flexDirection="column" gap="spacingDefault">
          <div>Are you sure you want to re-import your tabs?</div>
          <div>This will replace your current layout.</div>
        </Flex>
      ),
      confirmLabel: 'Re-import',
      cancelLabel: 'Cancel',
      onConfirm: handleConfirmReImportTabs,
    });
  }, [open, handleConfirmReImportTabs]);

  const handleConfirmResetLayoutConfig = useCallback(() => {
    mixpanel.track(MixpanelEvent.ResetLayoutAndTabs);
    resetLayoutConfig();
    setResetLayoutLoading(true);

    // Allow config to be updated and refresh the page to load new config
    setTimeout(() => {
      setResetLayoutLoading(false);
      window.location.reload();
    }, 1000);
  }, [resetLayoutConfig, mixpanel]);

  const handleResetLayoutConfig = useCallback(() => {
    open({
      title: 'Reset Layout',
      content: (
        <Flex flexDirection="column" gap="spacingDefault">
          <div>Are you sure you want to reset the layout config to default?</div>
          <div>This will remove all tabs and any market data cards.</div>
        </Flex>
      ),
      confirmLabel: 'Reset',
      cancelLabel: 'Cancel',
      onConfirm: handleConfirmResetLayoutConfig,
    });
  }, [open, handleConfirmResetLayoutConfig]);

  return (
    <Panel data-testid="settings-general-panel">
      <PanelHeader>
        <h2>General</h2>
      </PanelHeader>
      <PanelContent>
        <FormHeader>Blotters</FormHeader>
        <FormRow>
          <Button
            onClick={() => {
              blotterResetWarnDialog.open();
            }}
          >
            Reset Blotter Columns
          </Button>
          <div style={{ marginLeft: theme.spacingLarge }}>This will reset all columns to their default values.</div>
        </FormRow>

        <FormHeader>Portfolio</FormHeader>
        <FormRow key="formRow-treatStableCoinsAsCash">
          <Checkbox
            checked={treatStablecoinsAsCash}
            onChange={e => {
              mixpanel.track(MixpanelEvent.PMSTreatStablecoinsAsCash, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              add({
                text: `Portfolio Management System: Treat Stablecoins as Cash ${
                  e.target.checked ? 'enabled' : 'disabled'
                }`,
                variant: NotificationVariants.Positive,
              });
              setTreatStablecoinsAsCash(e.target.checked);
            }}
          >
            Treat Stablecoins as Cash{' '}
            <HelpIcon
              tooltip={
                <>
                  When <strong>&apos;Include Cash&apos;</strong> is <strong>disabled</strong> (in the Sub Accounts
                  blotter or the Portfolio Management System views):
                  <p />
                  If checked: Stablecoins will be treated as cash and will not show in the blotter
                  <p />
                  If unchecked: Stablecoins will be treated as regular assets and will always show
                </>
              }
            />
          </Checkbox>
        </FormRow>
        {enablePortfolioManagementSystem && (
          <FormRow key="formRow-showPMS">
            <Checkbox
              data-testid="checkbox-show-pms-features"
              checked={enablePMSLayout}
              onChange={e => {
                mixpanel.track(MixpanelEvent.ShowPortfolioManagementSystem, {
                  [MixpanelEventProperty.Enabled]: e.target.checked,
                });
                add({
                  text: `Portfolio Management System features ${e.target.checked ? 'enabled' : 'disabled'}`,
                  variant: NotificationVariants.Positive,
                });
                setEnablePMSLayout(e.target.checked);
              }}
            >
              Show Portfolio Management System features{' '}
              <HelpIcon
                tooltip={
                  <>
                    (Beta) Display the new Portfolio Management System layout when inside the{' '}
                    <strong>&apos;Portfolio&apos;</strong> tab from the main menu
                  </>
                }
              />
            </Checkbox>
          </FormRow>
        )}

        <FormHeader>Sound Effects</FormHeader>
        <FormRow>
          <Checkbox
            checked={enableSoundEffects}
            onChange={e => {
              mixpanel.track(MixpanelEvent.SoundEffects, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              add({
                text: `Sound Effects ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
              setEnableSoundEffects(e.target.checked);
            }}
          >
            Enable Sound Effects
          </Checkbox>
        </FormRow>
        <SoundOptions>
          {SOUND_OPTIONS.map(({ name, icon, sound, mixpanelEvent }) => (
            <div key={name}>
              <Checkbox
                disabled={enableSoundEffects === false}
                checked={enableSoundEffects && enabledSounds[sound]}
                onChange={e => {
                  mixpanel.track(mixpanelEvent, {
                    [MixpanelEventProperty.Enabled]: e.target.checked,
                  });
                  setEnabledSounds({ ...enabledSounds, [sound]: e.target.checked });
                }}
              >
                {name}
              </Checkbox>
              {icon && (
                <IconButton
                  ghost
                  icon={icon}
                  size={FormControlSizes.Tiny}
                  style={{ marginLeft: theme.spacingSmall, paddingBottom: theme.spacingTiny }}
                  onClick={() => playSound(sound, true)}
                />
              )}
            </div>
          ))}
        </SoundOptions>
        {(enableFlexibleUI || enableFlexibleLayout) && (
          <>
            <FormHeader>Layout</FormHeader>
            <p>
              Flexible UI is currenctly available on the trading screen. You can disable it to continue using the
              previous layout.{' '}
              <ButtonWrapper
                as="a"
                target="_blank"
                variant={ButtonVariants.Default}
                dim
                size={FormControlSizes.Small}
                href="https://kb.talostrading.com/principal-trading/flexible-ui"
              >
                Read more <Icon icon={IconName.ExternalLink} />
              </ButtonWrapper>
            </p>
            <FormGroup display="inline-flex">
              <Tooltip tooltip="Enabling or disabling flexible layout will automatically reload the page">
                <Checkbox checked={enableFlexibleLayout} onChange={handleEnableFlexibleLayout}>
                  Enable Flexible UI
                </Checkbox>
              </Tooltip>
            </FormGroup>
            {enableFlexibleLayout && (
              <>
                <Flex alignItems="center" gap="spacing12">
                  <Button onClick={handleReImportTabs} loading={reImportLoading} width={200}>
                    Re-import legacy tabs
                  </Button>
                  <p>
                    Re-import your tabs from the previous layout system, including any market data cards. This will
                    replace your current layouts.
                  </p>
                </Flex>
                <Flex alignItems="center" gap="spacing12">
                  <Button onClick={handleResetLayoutConfig} loading={resetLayoutLoading} width={200}>
                    Reset all layouts to default
                  </Button>
                  <p>Reset all layout changes and tabs, including any market data cards.</p>
                </Flex>
              </>
            )}
          </>
        )}
      </PanelContent>
      <Dialog
        {...blotterResetWarnDialog}
        usePortal={false}
        cancelLabel="Cancel"
        confirmLabel="Confirm"
        onConfirm={handleConfirm}
        title="Confirmation"
      >
        <Text>
          Are you sure you want to <b>Reset Columns</b> on all Blotters?
        </Text>
      </Dialog>
    </Panel>
  );
};
