import type { LayoutSpec, Section } from '../../../types/LayoutConfig';
import { DEFAULT_LAYOUT_SPEC } from './DefaultLayoutSpec';

const DEFAULT_TRADING_LAYOUTS: Record<string, LayoutSpec> = {
  default: DEFAULT_LAYOUT_SPEC,
  secondary: { ...DEFAULT_LAYOUT_SPEC, id: 'secondary', label: 'Secondary' },
};

export const DEFAULT_LAYOUTS: Record<Section, Record<string, LayoutSpec>> = {
  trading: DEFAULT_TRADING_LAYOUTS,
};
