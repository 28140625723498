import {
  formattedDateForSubscription,
  type MinimalSubscriptionResponse,
  SUB_ACCOUNT_RECON_CHECKPOINT,
  type SubAccountReconCheckpoint,
  subAccountReconCheckpointHasBreak,
  useObservableValue,
  useSubscription,
  useWSFilterPipe,
  type WebsocketRequest,
  wsSubscriptionCache,
} from '@talos/kyoko';
import { addSeconds } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { map, type Observable } from 'rxjs';
import { type SubAccountReconOverviewRow, subAccReconCheckpointToBlotterRows } from './reconCheckpointRows';

function getCheckpointID(cp: SubAccountReconCheckpoint) {
  return cp.ID;
}

type SubAccountReconCheckpointRequest = WebsocketRequest & {
  CheckpointIDs?: string[];
  StartDate?: string;
  EndDate?: string;
  ShowZeroBalances?: boolean;
};

export interface UseReconOverviewDataObsParams {
  checkpointIDs?: string[];
  endTime?: Date;
  startTime?: Date;
  showZeroBalances?: boolean;
  onlyShowBreaks?: boolean;
  tag: string;
}

/**
 * Opens a "SubAccountReconCheckpoint" subscription against the backend and returns an observable of SubAccountReconOverviewRow entities.
 * The returned observable is cached, meaning that it can be freely shared among consumers with a guarantee that any late subscribers will
 * receive the complete set of data.
 */
export const useReconOverviewDataObs = ({
  checkpointIDs,
  startTime,
  endTime,
  showZeroBalances,
  onlyShowBreaks,
  tag,
}: UseReconOverviewDataObsParams) => {
  const request: SubAccountReconCheckpointRequest = useMemo(() => {
    // Backend wants some buffer on the start and end times. We do 5 seconds.
    const maybeStartTime = startTime ? formattedDateForSubscription(addSeconds(startTime, -5)) : undefined;
    const maybeEndTime = endTime ? formattedDateForSubscription(addSeconds(endTime, 5)) : undefined;

    return {
      name: SUB_ACCOUNT_RECON_CHECKPOINT,
      tag: 'useSubAccountReconOverviewDataObs ' + tag,
      CheckpointIDs: checkpointIDs,
      StartDate: maybeStartTime,
      EndDate: maybeEndTime,
      ShowZeroBalances: showZeroBalances,
    };
  }, [checkpointIDs, startTime, endTime, showZeroBalances, tag]);

  const { data, isLoading } = useSubscription<SubAccountReconCheckpoint>(request);

  const cachedCheckpointsObs = useMemo(() => data.pipe(wsSubscriptionCache(getCheckpointID)), [data]);

  const latestCheckpointsMetadata: { StartTime?: Date; LastUpdateTime?: Date; EndTime?: Date } = useObservableValue(
    () =>
      cachedCheckpointsObs.pipe(
        map(message => {
          // we just grab some data off of the last checkpoint we receive
          const lastCp = message.data.at(-1);
          if (!lastCp) {
            return {
              StartTime: undefined,
              LastUpdateTime: undefined,
              EndTime: undefined,
            };
          }

          return {
            StartTime: new Date(lastCp.StartTime),
            LastUpdateTime: new Date(lastCp.LastUpdateTime),
            EndTime: new Date(lastCp.EndTime),
          };
        })
      ),
    [cachedCheckpointsObs],
    { StartTime: undefined, LastUpdateTime: undefined, EndTime: undefined }
  );

  const filterFunc = useCallback(
    (checkpoint: SubAccountReconCheckpoint) => {
      if (onlyShowBreaks && !subAccountReconCheckpointHasBreak(checkpoint)) {
        return false;
      }

      return true;
    },
    [onlyShowBreaks]
  );

  const wsFilterPipe = useWSFilterPipe({ getUniqueKey: getCheckpointID, filterFunc });

  const rowsObs: Observable<MinimalSubscriptionResponse<SubAccountReconOverviewRow>> = useMemo(() => {
    return cachedCheckpointsObs.pipe(
      wsFilterPipe,
      map(message => ({ ...message, data: message.data.flatMap(subAccReconCheckpointToBlotterRows) }))
    );
  }, [cachedCheckpointsObs, wsFilterPipe]);

  return {
    /** A cached observable to be used in blotters for example providing SubAccountReconOverviewRows */
    dataObservable: rowsObs,
    /** Loading state of the dataObservable */
    dataIsLoading: isLoading,
    /**
     * An object containing some metadata relating to the last checkpoint of the latest message the dataObservable has received.
     * Usable for showing some metadata information to the user related to what's being rendered in the blotters (eg. StartTime, EndTime)
     */
    latestCheckpointsMetadata,
  };
};
