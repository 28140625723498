import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  Button,
  ButtonGroup,
  ControlPrefix,
  CustomerOrder,
  Dialog,
  Divider,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  Input,
  LookbackOption,
  MixpanelEvent,
  MixpanelEventProperty,
  Order,
  Panel,
  PanelContent,
  SearchSelect,
  Tab,
  TabList,
  TabPanels,
  TabSize,
  Tabs,
  Text,
  Trade,
  VStack,
  getTabLabelForType,
  isDetailsDrawerEntity,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  usePersistedTabs,
  usePortal,
  useRouter,
  useTabs,
  type BlotterTableFilter,
  type Column,
  type SearchSelectProps,
  type TabsProps,
} from '@talos/kyoko';
import type { RowDoubleClickedEvent } from 'ag-grid-community';
import { useDetailsDrawer } from 'components/DetailsDrawer/useDetailsDrawer';
import { getMonitoringOrderDetailsRoute } from 'containers/Routes/routes';
import { BlotterAwareBox } from 'containers/Trading/styles';
import { entries, findIndex, groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v1 as uuid } from 'uuid';
import { useFeatureFlag } from '../../../hooks';
import { customerEntityDisplayNames } from '../../Dealer/Monitoring/types';
import { MonitoringBlotter } from './MonitoringBlotter';
import {
  MONITORING_BLOTTER_PREFIX,
  entityByRequestName,
  entityDisplayNames,
  type BlotterTableEntity,
  type EntityName,
  type MonitoringBlotterTabProps,
  type MonitoringBlotterTableFilter,
} from './types';

const TABS_EXCLUDED_IDS = [];

export function Blotters() {
  const mixpanel = useMixpanel();
  const { openDetailsDrawer } = useDetailsDrawer();
  const [initialFiltersOpen, setInitialFiltersOpen] = useState(false);
  const { history, params, location } = useRouter<{ id: string }, never>();

  const getDetailsHref = (entity: any) => {
    if (entity instanceof Order) {
      return getMonitoringOrderDetailsRoute({ orderID: entity.OrderID, type: 'order' });
    }
    if (entity instanceof CustomerOrder) {
      return getMonitoringOrderDetailsRoute({ orderID: entity.OrderID, type: 'customer-order' });
    }
  };

  const handleDoubleClickRow = useCallback(
    ({ data }: RowDoubleClickedEvent<BlotterTableEntity>) => {
      if (!data) {
        return;
      }
      if (data instanceof Order || data instanceof Trade) {
        const href = getDetailsHref(data);
        if (href) {
          history.push(href);
        }
      } else if (isDetailsDrawerEntity(data)) {
        openDetailsDrawer({ entity: data, getDetailsHref });
      }
    },
    [history, openDetailsDrawer]
  );

  const handleSelect: TabsProps['onSelect'] = useDynamicCallback(index => {
    setInitialFiltersOpen(false);
    const blotterUrl = '/monitoring/blotters/' + tabs.items[index].id;
    if (blotterUrl !== history.location.pathname) {
      history.push(blotterUrl);
    }
  });

  const defaultTabs = useMemo(
    () => [
      {
        label: 'Default',
        id: uuid(),
        defaultColumns: [],
        defaultFilter: {
          _start: LookbackOption.Past24Hours,
          HideAPICalls: true,
        },
        name: 'Order',
        closable: true,
        reorderable: true,
        editable: true,
      },
    ],
    []
  ) satisfies MonitoringBlotterTabProps[];
  const newTabDefaults = useMemo(() => {
    return {
      defaultColumns: [],
      defaultFilter: {
        _start: LookbackOption.Past24Hours,
        HideAPICalls: true,
      },
      closable: true,
      reorderable: true,
      editable: true,
    } satisfies Partial<MonitoringBlotterTabProps>;
  }, []);

  const persistedTabs = usePersistedTabs<MonitoringBlotterTabProps>(MONITORING_BLOTTER_PREFIX, {
    defaultInitialItems: defaultTabs,
    defaultInitialSelectedIndex: 0,
    excludedIDs: TABS_EXCLUDED_IDS,
    onSelect: handleSelect,
  });
  const handleAdd = useDynamicCallback(() => {
    setInitialFiltersOpen(true);
  });

  const tabs = useTabs<MonitoringBlotterTabProps>({
    ...persistedTabs,
    initialItems: persistedTabs.initialItems,
    showAddTab: true,
    tabLabeler: 'New Tab',
    onAdd: handleAdd,
  });

  const handleCloneTab = useDynamicCallback((filter: BlotterTableFilter, columns: Column[]) => {
    tabs.clone(tabs.selectedIndex, {
      defaultColumns: columns,
      defaultFilter: filter as MonitoringBlotterTableFilter,
      closable: true,
      reorderable: true,
      editable: true,
    });
    mixpanel.track(MixpanelEvent.CloneTab);
  });

  const setSelectedTabFromUrl = useDynamicCallback(() => {
    const tabIndex = findIndex(tabs.items, t => t.id === params.id);
    if (tabIndex >= 0 && tabIndex !== tabs.selectedIndex) {
      tabs.setSelectedIndex(tabIndex);
    }
  });

  useEffect(() => setSelectedTabFromUrl(), [location.pathname, setSelectedTabFromUrl]);

  const setSelectedEntity = useDynamicCallback((entityName?: EntityName) => {
    if (entityName) {
      tabs.setItems(currentTabs => {
        const newTabs = [...currentTabs];
        const selectedTab = newTabs[tabs.selectedIndex];
        if (selectedTab) {
          selectedTab.name = entityName;
          mixpanel.track(MixpanelEvent.ChangeMonitoringBlotterEntity, {
            [MixpanelEventProperty.TabLabel]: selectedTab.label,
            [MixpanelEventProperty.TabType]: entityName,
          });
        }
        return newTabs;
      });
    }
  });

  const { enableBuyingPowerMonitoringBlotter } = useFeatureFlag();
  const allowedEntityOptions = useMemo(() => {
    const names = Object.keys(entityByRequestName) as EntityName[];
    const allowedNames = names.filter(name => {
      if (name === 'BuyingPower') {
        return enableBuyingPowerMonitoringBlotter;
      }

      // Can add more clauses here as in the future

      return true;
    });
    return allowedNames;
  }, [enableBuyingPowerMonitoringBlotter]);

  const entitySelectProps = useMemo(
    () =>
      ({
        options: allowedEntityOptions,
        getLabel: name => entityDisplayNames[name].name,
        onChange: setSelectedEntity,
        size: FormControlSizes.Small,
        getGroup: name => entityDisplayNames[name].group,
        dropdownWidth: '240px',
        RenderGroupHeader: AutoCompleteDropdownGroupHeader,
        dropdownPlacement: 'right-end',
      } satisfies Partial<SearchSelectProps<EntityName>>),
    [setSelectedEntity, allowedEntityOptions]
  );

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  const [showNewTabDialog, setShowNewTabDialog] = useState(false);
  // This callback is used to create a new tab, rather than using the handler returned from `useTabs`.
  // We do this so that we can show the entity selector dialog before creating the tab.
  const addNewTab = useCallback(
    (selectedEntity?: EntityName) => {
      if (!selectedEntity) {
        setShowNewTabDialog(false);
        return;
      }
      tabs.setItems(currentTabs => {
        const label = getTabLabelForType({
          tabs: currentTabs,
          typeKey: 'name',
          newTabType: selectedEntity,
          typeToLabel: () => entityDisplayNames[selectedEntity].name,
        });

        const newTabs: MonitoringBlotterTabProps[] = [
          ...currentTabs,
          {
            name: selectedEntity,
            label,
            id: uuid(),
            closable: true,
            reorderable: true,
            editable: true,
            defaultFilter: undefined,
            defaultColumns: undefined,
          },
        ];
        return newTabs;
      });
      tabs.setSelectedIndex(tabs.items.length);
      setShowNewTabDialog(false);
      mixpanel.track(MixpanelEvent.AddMonitoringBlotterTab, {
        [MixpanelEventProperty.TabType]: selectedEntity,
      });
    },
    [tabs, mixpanel]
  );

  return (
    <Panel>
      <PanelContent px="0" pb="0">
        <Tabs {...tabs} onAdd={() => setShowNewTabDialog(true)} h="100%" size={TabSize.Large}>
          <TabList
            flex="0 0 auto"
            isBordered
            rightItems={
              tabs.items[tabs.selectedIndex].name && (
                <HStack justifyContent="flex-end" flex="1 1 auto">
                  <SearchSelect
                    selection={tabs.items[tabs.selectedIndex].name}
                    {...entitySelectProps}
                    portalize={true}
                    dropdownPlacement="bottom"
                    maxHeight={300}
                    prefix={<ControlPrefix>Entity</ControlPrefix>}
                    initialSortByLabel={false}
                  />
                  <Divider orientation="vertical" m="spacingSmall" />
                  <Box ref={filtersContainerRef} />
                </HStack>
              )
            }
          >
            {tabs.items.map((tab, idx) => (
              <Tab key={idx} {...tab} />
            ))}
          </TabList>
          <TabPanels h="100%" w="100%" position="relative">
            {tabs.items.map((tab, i) => (
              <AccordionGroup key={tab.id}>
                {tab.name && (
                  <BlotterAwareBox w="100%" h="100%" key={`component-${i}`}>
                    <MonitoringBlotter
                      onRowDoubleClicked={handleDoubleClickRow}
                      blotterID={`${MONITORING_BLOTTER_PREFIX}/${tab.id}`}
                      tabLabel={tab.label}
                      selectedEntity={tab.name}
                      defaultColumns={tab.defaultColumns ?? newTabDefaults.defaultColumns}
                      defaultFilter={tab.defaultFilter ?? newTabDefaults.defaultFilter}
                      onCloneTab={handleCloneTab}
                      initialIsOpen={initialFiltersOpen}
                      generateOrderDetailsRoute={getMonitoringOrderDetailsRoute}
                      source="monitoring"
                    />
                  </BlotterAwareBox>
                )}
              </AccordionGroup>
            ))}
          </TabPanels>
        </Tabs>
        <NewTabEntitySelector setSelectedEntity={addNewTab} isOpen={showNewTabDialog} source="monitoring" />
      </PanelContent>
    </Panel>
  );
}

export const AutoCompleteDropdownGroupHeader = function AutoCompleteDropdownGroupHeader({ group }) {
  return (
    <HStack
      gap="spacingDefault"
      justifyContent="flex-start"
      alignItems="center"
      background="backgroundDropdownGroupHeader"
      py="spacingSmall"
      px="spacingComfortable"
      fontSize="fontSizeSmall"
    >
      <Icon icon={iconLookup[group.group]} />
      <Text size="fontSizeSm" textTransform="uppercase" weight="fontWeightMedium">
        {group.group}
      </Text>
    </HStack>
  );
} satisfies NonNullable<SearchSelectProps<EntityName>['RenderGroupHeader']>;

const iconLookup = {
  OMS: IconName.Collection,
  Market: IconName.PresentationChartLine,
  Customer: IconName.UserCircle,
} as const;

interface NewTabEntitySelectorProps {
  setSelectedEntity: (entityName?: EntityName) => void;
  isOpen: boolean;
  source?: 'monitoring' | 'dealer-monitoring';
}
export function NewTabEntitySelector({
  setSelectedEntity,
  isOpen,
  source = 'dealer-monitoring',
}: NewTabEntitySelectorProps) {
  const modal = useDisclosure();
  const closeTab = useCallback(() => setSelectedEntity(), [setSelectedEntity]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const displayItems = useMemo(() => {
    const matchingItems = entries(source === 'dealer-monitoring' ? customerEntityDisplayNames : entityDisplayNames)
      .filter(([key, value]) => value.name.toLowerCase().includes(searchPhrase.toLowerCase()))
      .map(([key]) => key);
    const groupsWithItems = entries(groupBy(matchingItems as EntityName[], key => entityDisplayNames[key].group));
    return groupsWithItems;
  }, [searchPhrase, source]);
  return (
    <Dialog
      {...modal}
      usePortal={false}
      title="Entity Selection"
      showCancel={false}
      showConfirm={false}
      width={434}
      showClose={true}
      isOpen={isOpen}
      close={closeTab}
    >
      <VStack w="100%" gap="spacingMedium">
        <Input
          type="text"
          placeholder="Search entity..."
          prefix={<Icon icon={IconName.Search} />}
          onChange={e => setSearchPhrase(e.target.value)}
        />
        {displayItems.map(([group, items]) => (
          <VStack w="100%" gap="spacingSmall" alignItems="flex-start" key={group}>
            <Text color="colors.gray.090" transform="uppercase" size="fontSizeSm">
              <HStack gap="spacingDefault">
                <Icon icon={iconLookup[group]} />
                {group}
              </HStack>
            </Text>
            <ButtonGroup orientation="vertical" w="100%" alignItems="flex-start">
              {items.map(entityName => (
                <EntitySelectButton key={entityName} onClick={() => setSelectedEntity(entityName)}>
                  {entityDisplayNames[entityName].name}
                </EntitySelectButton>
              ))}
            </ButtonGroup>
          </VStack>
        ))}
      </VStack>
    </Dialog>
  );
}

export const EntitySelectButton = styled(Button).attrs(() => ({ endIcon: IconName.ArrowRightCircle, dim: true }))`
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  width: 100%;
`;
