import {
  ACTION,
  IconName,
  LedgerAccountTypeEnum,
  MixpanelEventSource,
  getRowNodesToOperateOn,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
} from '@talos/kyoko';
import type { GetContextMenuItems, GetContextMenuItemsParams, IRowNode, MenuItemDef } from 'ag-grid-enterprise';
import { getOrderDetailsRoute } from 'containers/Routes/routes';
import { useRoleAuth } from 'hooks/useRoleAuth';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlag } from '../../../hooks';
import { useNavigateToOrderDetails } from '../../../hooks/useNavigateToOrderDetails';
import type { IHedgePositionStatusRow } from '../../../providers/HedgePositionStatusProvider';
import { useNavigateToAccountLedgerEvents } from '../../Trading/Markets/AccountLedgerEvents/useNavigateToAccountLedgerEvents';
import { openDetailsMenuItem } from '../Orders/useOrderMenu';

export const useHedgePositionStatusMenu = (): {
  getContextMenuItems: GetContextMenuItems<IHedgePositionStatusRow>;
  modals: React.ReactNode[];
} => {
  const { isAuthorized } = useRoleAuth();

  const { enableAccountLedgerEventsPage } = useFeatureFlag();

  const openDetailsItem = useDynamicCallback((rows: IRowNode<IHedgePositionStatusRow>[]) => {
    const orderIds = rows
      .map(row => row.data?.HedgeOrderID)
      .filter<string>((maybeString): maybeString is string => maybeString !== undefined);

    if (orderIds.length === 0) {
      return null;
    }

    return openDetailsMenuItem({
      orders: orderIds.map(OrderID => ({ OrderID })),
      onOpenDetails,
      text: 'Open Hedge Order Details',
    });
  });

  const navigateToAccountLedgerEvents = useNavigateToAccountLedgerEvents();
  const getMenuItems = useDynamicCallback((rows: IRowNode<IHedgePositionStatusRow>[]) => {
    const items: (MenuItemDef | null)[] = [];

    if (isAuthorized(ACTION.VIEW_ORDER)) {
      items.push(openDetailsItem(rows));
    }

    const hedgeRule = rows?.[0]?.data;
    if (isAuthorized(ACTION.VIEW_LEDGER_EVENTS) && enableAccountLedgerEventsPage && hedgeRule) {
      items.push({
        name: 'Open transaction history',
        action: () => {
          navigateToAccountLedgerEvents({
            asset: hedgeRule.Asset,
            account: hedgeRule.AccountID,
            type: LedgerAccountTypeEnum.SubAccount,
            source: MixpanelEventSource.PositionAutoHedgingBlotter,
          });
        },
        icon: `<i class="ag-icon ${IconName.ViewListDrilldown}"/>`,
      });
    }
    return compact(items);
  });
  const onOpenDetails = useNavigateToOrderDetails(getOrderDetailsRoute);

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  const { handleClickJson, jsonModal } = useJsonModal();

  const getContextMenuItems: GetContextMenuItems<IHedgePositionStatusRow> = useDynamicCallback(
    (params: GetContextMenuItemsParams<IHedgePositionStatusRow>) => {
      const menuItems = getMenuItems(getRowNodesToOperateOn(params));

      return compact([
        ...menuItems,
        ...getDefaultContextMenuItems(params),
        params?.node?.data
          ? {
              icon: `<i class="ag-icon ${IconName.Braces}"/>`,
              name: `Show JSON`,
              action: () => handleClickJson(params?.node?.data),
            }
          : null,
      ]);
    }
  );

  const modals = useMemo(() => [jsonModal], [jsonModal]);

  return {
    getContextMenuItems,
    modals,
  };
};
