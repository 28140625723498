import {
  type FilterableProperty,
  filterExistsAndExcludes,
  type MarketAccount,
  useGenericFilter,
  useMarketAccountGroupsFilter,
  useMarketAccountStatusesFilter,
  useMarketAccountTypesFilter,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useMarketsFilter } from '../../../hooks';
import type { MarketAccountBlotterFilter } from './types';

interface UseMarketAccountBlotterFilterParams {
  persistedBlotterTable: UsePersistedBlotterTable<MarketAccount>;
}

export const useMarketAccountBlotterFilter = ({ persistedBlotterTable }: UseMarketAccountBlotterFilterParams) => {
  const { onFilterChanged, initialFilter } = persistedBlotterTable;

  const marketsFilter = useMarketsFilter();
  const groupsFilter = useMarketAccountGroupsFilter();
  const typesFilter = useMarketAccountTypesFilter();
  const statusesFilter = useMarketAccountStatusesFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [
      marketsFilter,
      { ...groupsFilter, key: 'Groups' },
      { ...typesFilter, key: 'Types' },
      { ...statusesFilter, key: 'Statuses' },
    ],
    [marketsFilter, groupsFilter, typesFilter, statusesFilter]
  );

  const { filter, filterBuilderProps } = useGenericFilter(filterableProperties, { initialFilter, onFilterChanged });

  const filterFunc = useCallback(
    (item: MarketAccount) => {
      if (filterExistsAndExcludes<MarketAccountBlotterFilter, MarketAccount>(filter, 'Markets', item, 'Market')) {
        return false;
      }

      if (filterExistsAndExcludes<MarketAccountBlotterFilter, MarketAccount>(filter, 'Groups', item, 'Group')) {
        return false;
      }

      if (filterExistsAndExcludes<MarketAccountBlotterFilter, MarketAccount>(filter, 'Types', item, 'Type')) {
        return false;
      }

      if (filterExistsAndExcludes<MarketAccountBlotterFilter, MarketAccount>(filter, 'Statuses', item, 'Status')) {
        return false;
      }

      return true;
    },
    [filter]
  );

  return { filterFunc, filterBuilderProps };
};

const colIDToFilterBuilderKeyMap = {
  Market: 'Markets',
  Group: 'Groups',
  Type: 'Types',
  Status: 'Statuses',
} satisfies Partial<Record<keyof MarketAccount, keyof MarketAccountBlotterFilter>>;

export function colIDToFilterBuilderKey(id: string): keyof MarketAccountBlotterFilter | undefined {
  return colIDToFilterBuilderKeyMap[id];
}
