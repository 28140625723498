import { ROLE } from '@talos/kyoko';

type RoleItem = {
  value: ROLE;
  label: string;
};

export const ALL_ROLE_ITEMS: RoleItem[] = [
  { value: ROLE.NONE, label: 'None' },
  { value: ROLE.READ_ONLY, label: 'Viewer' },
  { value: ROLE.TRADER, label: 'Trader' },
  { value: ROLE.ANALYTICS, label: 'Analytics' },
  { value: ROLE.DEALER, label: 'Dealer' },
  { value: ROLE.DEALER_VIEWER, label: 'Dealer Viewer' },
  { value: ROLE.DEALER_ADMIN_OPS, label: 'Dealer Admin Ops' },
  { value: ROLE.TRANSFERS, label: 'Transfers' },
  { value: ROLE.ADMIN, label: 'Admin' },
  { value: ROLE.USER_ADMIN, label: 'User Admin' },
  { value: ROLE.TALOS_SUPPORT, label: 'Talos Support' },
  { value: ROLE.TALOS_ADMIN, label: 'Talos Admin' },
  { value: ROLE.TALOS_ONBOARDING_ADMIN, label: 'Talos Onboarding Admin' },
  { value: ROLE.TALOS_TEST_RUNNER, label: 'Talos Test Runner' },
  { value: ROLE.TALOS_VIEWER, label: 'Talos Viewer' },
  { value: ROLE.TALOS_CANCEL_ORDERS, label: 'Talos Cancel Orders' },
  { value: ROLE.MARKETPLACE_VIEWER, label: 'Marketplace Viewer' },
  { value: ROLE.MARKETPLACE_TRADER, label: 'Marketplace Trader' },
  { value: ROLE.MARKETPLACE_ADMIN, label: 'Marketplace Admin' },
  { value: ROLE.PORTFOLIO, label: 'Portfolio' },
  { value: ROLE.PORTFOLIO_BETA, label: 'Portfolio Beta' },
  { value: ROLE.TREASURY, label: 'Treasury' },
  { value: ROLE.MARKETPLACE_OPS, label: 'Marketplace Ops' },
  { value: ROLE.TRADING_CONFIG, label: 'Trading Configuration' },
  { value: ROLE.TRANSFERS_HIDDEN, label: 'Hidden Transfers' },
  { value: ROLE.EXCHANGE_SETTLEMENT, label: 'Exchange Settlements' },
  { value: ROLE.CUSTODIAN_SETTLEMENT, label: 'Custodian Settlements' },
  { value: ROLE.CREDENTIALS_ADMIN, label: 'Credentials Admin' },
  { value: ROLE.PORTFOLIO_ENGINEERING, label: 'Portfolio Engineering' },
  { value: ROLE.TRADING_CONTROLS_ADMIN, label: 'Trading Controls Admin' },
  { value: ROLE.PORTFOLIO_RECON, label: 'Portfolio Reconciliation' },
  { value: ROLE.AUTOHEDGING_ADMIN, label: 'Autohedging Admin' },
  { value: ROLE.AUTOHEDGING_VIEW, label: 'Autohedging Viewer' },
];

const ROLE_MAP = new Map(ALL_ROLE_ITEMS.map(item => [item.value, item]));

// Roles that should be available as a fallback if admin.UserAdmin.CanAddRoles isn't set
export const DEFAULT_WHITELISTED_ROLES = [
  ROLE.ADMIN,
  ROLE.PORTFOLIO,
  ROLE.TRADER,
  ROLE.TRADING_CONFIG,
  ROLE.TRANSFERS,
  ROLE.USER_ADMIN,
  ROLE.READ_ONLY,
  ROLE.CREDENTIALS_ADMIN,
];

export function prettifyRole(role: ROLE) {
  return ROLE_MAP.get(role)?.label ?? role;
}

const APIKEY_ROLE_BLACKLIST = new Set([ROLE.TALOS_SUPPORT, ROLE.TALOS_ADMIN, ROLE.ADMIN]);

export const APIKEY_ROLE_ITEMS: RoleItem[] = ALL_ROLE_ITEMS.filter(
  roleItem => !APIKEY_ROLE_BLACKLIST.has(roleItem.value)
);
