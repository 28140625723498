import {
  type Transfer,
  TreasuryLinkDirectionEnum,
  TreasuryLinkTypeEnum,
  useMarketAccountsContext,
  useObservableValue,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { usePortfolioAccounts, useTransfers } from '../../../../providers';
import { getEffectiveTreasuryLink } from '../../Addresses/hooks/useEffectiveTreasuryLink';

export interface UseOngoingTransfersParams {
  marketAccount: string;
  currency: string;
}

/**
 * Given a ("trading") market account (which the settlement is on behalf of), returns an array of any transfers not yet terminal
 * which are related to settlement for this account.
 *
 * The purpose of this is to be able to display to a user wishing to initiate settlement with a counterparty that there already
 * are pending transfers on their way to said counterparty.
 *
 * This is implemented for the Dealer Settlement screen specifically - there are certain assumptions made within the hook.
 */
export const useOngoingTransfers = ({ marketAccount, currency }: UseOngoingTransfersParams) => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { treasuryLinksBySpecificnessKey } = usePortfolioAccounts();
  const { transfersByCurrencyObs } = useTransfers();
  const transfersByCurrency = useObservableValue(() => transfersByCurrencyObs, [transfersByCurrencyObs]);

  const sourceMarketAccount = useMemo(() => {
    if (!treasuryLinksBySpecificnessKey) {
      return undefined;
    }

    // Quick FYI on Treasury Links here. Given a trading market account and a currency, we look for a treasury link specifying
    // what the Source and Destination type: transfer market accounts are used for settlement for this trading market account
    // These are then what we'll use for matching any ongoing transfers.
    const linkForSourceMarketAccount = getEffectiveTreasuryLink({
      sourceOrDestinationField: 'SourceMarketAccount', // look for a treasury link representing the effective source market account
      treasuryLinksBySpecificnessKey, // in this data set
      type: TreasuryLinkTypeEnum.OTCSettlement, // for otc settlement
      direction: TreasuryLinkDirectionEnum.Outbound, // in the outbound direction (us sending money to them)
      marketAccount, // for this trading market account
      currency, // and this currency
    });

    return linkForSourceMarketAccount?.SourceMarketAccount;
  }, [treasuryLinksBySpecificnessKey, marketAccount, currency]);

  const destinationMarketAccount = useMemo(() => {
    if (!treasuryLinksBySpecificnessKey) {
      return undefined;
    }

    const linkForDestinationMarketAccount = getEffectiveTreasuryLink({
      sourceOrDestinationField: 'DestinationMarketAccount',
      treasuryLinksBySpecificnessKey,
      type: TreasuryLinkTypeEnum.OTCSettlement,
      direction: TreasuryLinkDirectionEnum.Outbound,
      marketAccount,
      currency,
    });

    return linkForDestinationMarketAccount?.DestinationMarketAccount;
  }, [treasuryLinksBySpecificnessKey, marketAccount, currency]);

  // Simply just convert market account names into market account ids since that's what present in the transfers
  const sourceMarketAccountID = sourceMarketAccount
    ? marketAccountsByName.get(sourceMarketAccount)?.MarketAccountID
    : undefined;
  const destinationMarketAccountID = destinationMarketAccount
    ? marketAccountsByName.get(destinationMarketAccount)?.MarketAccountID
    : undefined;

  // We now have the source, destination and currency to match against and find any ongoing transfers for.
  const relevantTransfers: Transfer[] = useMemo(() => {
    if (!transfersByCurrency) {
      return [];
    }

    const currencyTransfers = transfersByCurrency.get(currency);
    if (!currencyTransfers) {
      return [];
    }

    return currencyTransfers.filter(
      transfer =>
        // matching the from and to sides (currency is already matched above)
        transfer.FromMarketAccountID === sourceMarketAccountID &&
        transfer.ToMarketAccountID === destinationMarketAccountID &&
        // and that the transfer is still ongoing
        !transfer.isTerminal
    );
  }, [transfersByCurrency, sourceMarketAccountID, destinationMarketAccountID, currency]);

  return relevantTransfers;
};
