import { EMPTY_OBJECT } from '@talos/kyoko';
import ReactAutocomplete from 'react-autocomplete';
import { AutocompleteResults, AutocompleteWrapper } from './styles';

export function Autocomplete({ style = {}, isCentered, ...props }) {
  return (
    <AutocompleteWrapper style={style} isCentered={isCentered}>
      <ReactAutocomplete
        /* menuStyle has to be provided for some reason (defaulted to {} above). Otherwise the rendering of the dropdown breaks */
        menuStyle={EMPTY_OBJECT}
        {...props}
        renderItem={({ id, label }, isHighlighted) => (
          <AutocompleteResults key={id} isHighlighted={isHighlighted}>
            {label}
          </AutocompleteResults>
        )}
      />
    </AutocompleteWrapper>
  );
}
