import { BlotterTable, Box, LoaderSizes, logger, useDynamicCallback, type Security } from '@talos/kyoko';
import type { CellValueChangedEvent, GetRowIdParams, GridReadyEvent } from 'ag-grid-community';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useMemo, useRef } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { Loader, LoaderWrapper } from '../Loader';
import {
  selectIsEntryMode,
  selectIsOptionsReferenceDataReady,
  selectOptionStrategy,
  selectOptionStrategyAsRows,
  updateOptionStrategy,
} from './MultilegComboSlice';
import { AgMarketAccountRenderer } from './components/AgMarketAccountRenderer';
import { AgSymbolSelector } from './components/AgSymbolSelector';
import { DEFAULT_COLUMN_DEFINITION, getOptionsColDef } from './optionsColDef';
import type { OptionStrategyRow } from './types';

export const OptionStrategyScreen = ({ panelID }: { panelID: string }) => {
  const theme = useTheme();
  const dispatch = useAppStateDispatch();

  const isReferenceDataReady = useAppStateSelector(selectIsOptionsReferenceDataReady);

  const optionStrategy = useAppStateSelector(state => selectOptionStrategy(state, panelID));
  const rowData = useAppStateSelector(state => selectOptionStrategyAsRows(state, panelID));
  const isEntryMode = useAppStateSelector(state => selectIsEntryMode(state, panelID));

  const context = useRef<{ theme: DefaultTheme }>({ theme });

  const handleCellValueChanged = useDynamicCallback((ev: CellValueChangedEvent) => {
    const field = ev.colDef.field;

    if (!field || !optionStrategy || ev.rowIndex == null) {
      return;
    }

    switch (field) {
      case 'coin':
        dispatch(updateOptionStrategy({ panelID, optionStrategy: optionStrategy.updateCoin(ev.newValue) }));
        return;
      case 'exchange':
        dispatch(updateOptionStrategy({ panelID, optionStrategy: optionStrategy.updateExchange(ev.newValue) }));
        return;
      case 'marketAccount':
        dispatch(
          updateOptionStrategy({
            panelID,
            optionStrategy: optionStrategy.updateMarketAccount(ev.newValue ? [ev.newValue] : [], ev.rowIndex),
            isEditableParam: true,
          })
        );
        return;
      case 'expiryDate':
        dispatch(
          updateOptionStrategy({ panelID, optionStrategy: optionStrategy.updateExpiry(ev.newValue, ev.rowIndex) })
        );
        return;
      case 'strike':
        dispatch(
          updateOptionStrategy({ panelID, optionStrategy: optionStrategy.updateStrike(ev.newValue, ev.rowIndex) })
        );
        return;
      case 'type':
        dispatch(
          updateOptionStrategy({ panelID, optionStrategy: optionStrategy.updateType(ev.newValue, ev.rowIndex) })
        );
        return;
      case 'initiating':
        dispatch(
          updateOptionStrategy({
            panelID,
            optionStrategy: optionStrategy.updateInitiatingLeg(ev.newValue, ev.rowIndex),
            isEditableParam: true,
          })
        );
        return;
      case 'ratio':
        dispatch(
          updateOptionStrategy({
            panelID,
            optionStrategy: optionStrategy.updateRatio(ev.newValue, ev.rowIndex),
            isEditableParam: true,
          })
        );
        return;
      default:
        logger.error(new Error(`Invalid modification on MultilegCombo, field: ${field}`));
    }
  });

  const handleSecurityChanged = useDynamicCallback((security: Security, rowIndex: number) => {
    if (optionStrategy && security) {
      dispatch(
        updateOptionStrategy({
          panelID,
          optionStrategy: optionStrategy.updateSecurity(security, rowIndex),
          isEditableParam: true,
        })
      );
    }
  });

  const columnDefs = useMemo(
    () => getOptionsColDef(handleSecurityChanged, optionStrategy, isEntryMode),
    [optionStrategy, isEntryMode, handleSecurityChanged]
  );

  const ROW_HEIGHT = 34;
  const minHeight = useMemo(
    () => ROW_HEIGHT + rowData.length * ROW_HEIGHT + theme.scrollbarSize,
    [rowData.length, theme.scrollbarSize]
  );

  if (!isReferenceDataReady) {
    return (
      <LoaderWrapper>
        <Loader size={LoaderSizes.SMALL} />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Box
        minHeight={minHeight}
        h={minHeight}
        data-testid="option-strategy-wrapper"
        id="multileg-deepdive-blotter-wrapper"
      >
        <BlotterTable
          gridOptions={{
            // @ts-expect-error  We don't allow context to have just the required data
            context,
            getRowId: (params: GetRowIdParams<OptionStrategyRow>) => params.data.id,
            defaultColDef: DEFAULT_COLUMN_DEFINITION,
            columnDefs,
            singleClickEdit: true,
            stopEditingWhenCellsLoseFocus: true,
            rowData,
            rowHeight: 34,
            onCellValueChanged: handleCellValueChanged,
            onGridReady: (event: GridReadyEvent) => {
              event.api.sizeColumnsToFit();
            },
            suppressContextMenu: true,
          }}
          extraComponents={{
            symbolSelector: AgSymbolSelector,
            marketAccountRenderer: AgMarketAccountRenderer,
          }}
        />
      </Box>
    </>
  );
};
