import { differenceInHours, isSameDay } from 'date-fns';
import { ALL_EXPIRIES_ITEM } from '../types';
import type { OptionFilteringState } from './types';

/**
 * Validate the symbol to make it a valid one, for example,
 * ensure that given the selected market the currency is allowed.
 * Or that the expiry is valid given the market and currency.
 */
export function validateSymbol(
  optionFilter: OptionFilteringState['optionFilter'],
  meta: OptionFilteringState['meta']
): OptionFilteringState['optionFilter'] {
  if (!optionFilter.underlyingCode) {
    optionFilter.underlyingCode = optionFilter.currency;
  }
  const { underlyingCode, expiry, market, marketAccountName } = optionFilter;

  const { expirationByMarketByCurrencyIdentity, marketAccountsByMarket } = meta;

  const validExpirySet = expirationByMarketByCurrencyIdentity.get(underlyingCode)?.get(market) || new Set();
  const validMarketSet = new Set(expirationByMarketByCurrencyIdentity.get(underlyingCode)?.keys() || []);

  const validMarket = (validMarketSet.has(market) ? market : Array.from(validMarketSet).at(0)) || market;
  const validMarketAccountNameSet = new Set(marketAccountsByMarket.get(validMarket)?.map(m => m.Name) || []);

  const validMarketAccountName =
    marketAccountName !== null && validMarketAccountNameSet.has(marketAccountName)
      ? marketAccountName
      : Array.from(validMarketAccountNameSet).at(0) ?? null;

  const allExpiriesAllowed = showAllExpiriesOptionForMarket(validMarket);
  const allExpiriesOrNull = allExpiriesAllowed ? ALL_EXPIRIES_ITEM : null;

  if (validExpirySet.size === 0) {
    // There are no valid expiries
    return {
      ...optionFilter,
      market: validMarket,
      marketAccountName: validMarketAccountName,
      expiry: allExpiriesOrNull,
    };
  }

  // The selected expiry is valid if we allow ALL and the selection is ALL, OR the selected expiry is in the Valid expiries set.
  const isExpiryValid = (allExpiriesAllowed && expiry === ALL_EXPIRIES_ITEM) || (expiry && validExpirySet.has(expiry));
  if (isExpiryValid) {
    return { ...optionFilter, market: validMarket, marketAccountName: validMarketAccountName, expiry };
  }

  /**
   * Else, expiry is invalid. Need to default it in some way. There are three possible cases.
   * 1. expiry is null. We should set it to ALL if thats allowed, otherwise set it to the closest expiry to NOW as possible.
   * 2. expiry is ALL and that is not allowed. We set it to the closest expiry to NOW as possible.
   * 3. expiry is defined but the expiry doesnt exist anymore. We try to set it to any existing expiry on the same day. If none exists, set to ALL if thats allowed, otherwise closest.
   */

  let workingExpiry = expiry;

  if (expiry == null) {
    // 1
    workingExpiry = allExpiriesAllowed ? ALL_EXPIRIES_ITEM : getClosestExpiry(new Date(), [...validExpirySet.values()]);
  } else if (expiry === ALL_EXPIRIES_ITEM && !allExpiriesAllowed) {
    // 2
    workingExpiry = getClosestExpiry(new Date(), [...validExpirySet.values()]);
  } else {
    // 3
    const prevExpiryDate = new Date(expiry);
    const closestExpiryToPrevExpiry = getClosestExpiry(new Date(expiry), [...validExpirySet.values()]);

    const newExpiryIsSameDayAsPrevious =
      closestExpiryToPrevExpiry && isSameDay(new Date(closestExpiryToPrevExpiry), prevExpiryDate);

    if (newExpiryIsSameDayAsPrevious) {
      workingExpiry = closestExpiryToPrevExpiry;
    } else {
      // The closest expiry we found is not the same day as the previously selected one.
      // If we are allowed to select ALL, select it. If we cannot select ALL, select the closest one we found.
      workingExpiry = allExpiriesAllowed ? ALL_EXPIRIES_ITEM : closestExpiryToPrevExpiry;
    }
  }

  return { ...optionFilter, market: validMarket, marketAccountName: validMarketAccountName, expiry: workingExpiry };
}

/**
 * Returns whether or not the user is allowed to view all expiries in the Options Chain. Depends on the Market selected.
 */
export function showAllExpiriesOptionForMarket(market: string): boolean {
  if (isMarketCME(market)) {
    return false;
  }

  return true;
}

export function isMarketCME(market: string) {
  return market.toLowerCase() === 'cme';
}

/**
 * Given some reference date and an array of expiries (as date strings), returns the closest expiry date string to the reference date.
 *
 * The difference computation on the dates is done at an hourly increment.
 */
function getClosestExpiry(referenceDate: Date, expiries: string[]): string | null {
  const expiriesSortedByTimeDiff = expiries.sort((a, b) => {
    const diffA = Math.abs(differenceInHours(referenceDate, new Date(a)));
    const diffB = Math.abs(differenceInHours(referenceDate, new Date(b)));
    return diffA - diffB;
  });

  return expiriesSortedByTimeDiff.at(0) ?? null;
}
