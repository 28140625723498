import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Column } from '@talos/kyoko';
import { castDraft } from 'immer';

type PortfolioManagementPositionRequestState = {
  tagColumns: Column[] | undefined;
};

export const portfolioManagementPositionRequestSlice = createSlice({
  name: 'portfolioManagementPositionRequest',
  initialState: {
    tagColumns: undefined,
  } as PortfolioManagementPositionRequestState,
  reducers: {
    setColumnsForTags: (state, action: PayloadAction<Column[]>) => {
      state.tagColumns = castDraft(action.payload);
    },
  },
});
