import type { ColDef, GridApi } from 'ag-grid-community';
import { compact } from 'lodash';
import type { BlotterTableSort } from '..';

/** Apply the sorts of the current column state to the columns and autoGroupColumnDef */

export type ApplySortsToColumnsArg<TRowType> = {
  api: GridApi<TRowType>;
  columnDefs: ColDef<TRowType>[];
  autoGroupColumnDef: ColDef<TRowType> | undefined;
  initialSorts: BlotterTableSort<TRowType> | undefined;
};
/** Given input columnDefs and autoGroupColumnDef, apply the existing or persisted state */

export function applySortsToColumns<TRowType>(args: ApplySortsToColumnsArg<TRowType>): {
  workingColumnDefs: ColDef<TRowType>[];
  workingAutoGroupColumnDef: ColDef<TRowType> | undefined;
} {
  const { api, columnDefs, autoGroupColumnDef, initialSorts } = args;
  // We create shallow copies of these objects here so we dont mutate anything passed in
  // The steps below mutate these shallow copies.
  const workingColumnDefs = columnDefs.map(c => ({ ...c }));
  const workingAutoGroupColumnDef = autoGroupColumnDef ? { ...autoGroupColumnDef } : undefined;

  // The returned contents here are the sorts which are currently applied to our blotter.
  const sortedColumnStates = api.getColumnState()?.filter(column => column.sort != null);

  // If there are no sorts applied to the blotter currently, but we're configured to have some amount of default sorting, we apply that default sorting.
  const shouldInitialise = initialSorts != null && sortedColumnStates.length === 0;
  const columns = compact([workingAutoGroupColumnDef, ...workingColumnDefs]);
  if (shouldInitialise) {
    const initialSortArr = Array.isArray(initialSorts) ? initialSorts : [initialSorts];
    initialSortArr.forEach((sort, sortIndex) => {
      const [dir, colId] = [sort.substring(0, 1), sort.substring(1, sort.length)];

      // We allow the user to define an initialSort on the group coldef as well, meaning that we have to include that in this search operation.
      const columnDef = columns.find(columnDef => columnDef.colId === colId);
      if (columnDef != null) {
        columnDef.sort = dir === '+' ? 'asc' : 'desc';
        columnDef.sortIndex = sortIndex;
      }
    });
  } else {
    // Else, we don't need to initialise. Just apply all the sorting the blotter is currently doing to this new set of columns our implementer is passing in.
    sortedColumnStates.forEach(sortedColumnState => {
      const columnDef = columns.find(columnDef => columnDef.field === sortedColumnState.colId);
      if (columnDef != null) {
        columnDef.sort = sortedColumnState.sort;
        columnDef.sortIndex = sortedColumnState.sortIndex;
      }
    });
  }
  return { workingColumnDefs, workingAutoGroupColumnDef };
}
