import { NumberInput, type Currency } from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';

export interface SettlementAmountInputProps {
  value: string;
  onChange: (value?: string | null) => void;
  currency: Currency;
}

export const SettlementAmountInput = ({ value, onChange, currency }: SettlementAmountInputProps) => {
  // We want to keep an internal state of the input value which we only emit to the state machine if its a valid, parseable number.
  // Otherwise keep it.
  // If we have an invalid unparseable input value upon blurring the input field, we reset it to the latest correct value from the state machine (value prop)
  const [internalInputValue, setInternalInputValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      // if we are not focused on the input field, then apply all incoming value changes
      setInternalInputValue(value);
    }
  }, [value, isEditing]);

  const handleInputValueChange = useCallback(
    (newValue: string) => {
      setInternalInputValue(newValue);
      if (newValue === '') {
        onChange(newValue);
      } else {
        // we have some input which should be a correct number in order to be eligible for emission
        if (!isNaN(+newValue)) {
          onChange(newValue);
        }
      }
    },
    [onChange]
  );

  return (
    <NumberInput
      value={internalInputValue}
      onChange={handleInputValueChange}
      clearable={true}
      suffix={currency.Symbol}
      defaultIncrement={currency.DefaultIncrement}
      minIncrement={currency.MinIncrement}
      placeholder="Enter settle amount"
      onBlur={() => setIsEditing(false)}
      onFocus={() => setIsEditing(true)}
      autoFocus={false}
      min="0"
      data-testid="settlement-amount-input"
    />
  );
};
