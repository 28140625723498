import { AccordionGroup, HStack, MixpanelEvent, useDrawer, useMixpanel } from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { BlotterContainer } from '../../BlotterContainer';
import { usePortfolioViewStateSelector } from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import { RISK_EXPOSURES_BLOTTER_ID } from '../blotterIdTokens';
import type { BaseBlotterProps } from '../types';
import { PortfolioRiskBlotter, type PortfolioRiskBlotterProps } from './index';
import { InspectorDrawer } from './InspectorDrawer';

export function PortfolioRiskBlotterWrapper() {
  const mixpanel = useMixpanel();
  const wrapperContext: BaseBlotterProps = {
    label: 'Portfolio Risk Blotter',
    id: `${RISK_EXPOSURES_BLOTTER_ID}/v1`,
    tag: `${RISK_EXPOSURES_BLOTTER_ID}`,
  };
  const { selectedPortfolioId } = usePortfolioViewStateSelector();

  const inspectorDrawerDisclosure = useDrawer({
    closeOnClickOutside: false,
    closeOnEscape: true,
    position: 'relative',
    placement: 'right',
    width: 420,
  });
  const [selectedDataItem, setSelectedDataItem] = useState<PortfolioRiskDataItem | undefined>(undefined);

  const onInspectorOpen = useCallback<PortfolioRiskBlotterProps['onInspectorOpen']>(
    (dataItem, onlyIfOpen) => {
      if (onlyIfOpen && !inspectorDrawerDisclosure.isOpen) {
        return;
      }
      setSelectedDataItem(dataItem);
      // only record the event if the inspector is not already open
      mixpanel.track(
        !inspectorDrawerDisclosure.isOpen
          ? MixpanelEvent.PMSPortfolioRiskInspectorOpen
          : MixpanelEvent.PMSPortfolioRiskInspectorUpdated
      );
      inspectorDrawerDisclosure.open();
    },
    [inspectorDrawerDisclosure, mixpanel]
  );

  return (
    <HStack w="100%" h="100%" flex="1" overflow="hidden" gap="spacing2" data-testid="portfolio-risk-blotter-wrapper">
      <BlotterContainer>
        <AccordionGroup key={wrapperContext.id}>
          <PortfolioRiskBlotter
            blotterID={wrapperContext.id}
            wrapperContext={wrapperContext}
            subAccountId={selectedPortfolioId}
            onInspectorOpen={onInspectorOpen}
          />
        </AccordionGroup>
      </BlotterContainer>
      <InspectorDrawer {...inspectorDrawerDisclosure} dataItem={selectedDataItem} />
    </HStack>
  );
}
