import type { ResolutionString } from '@trading-view/charting_library';
import { initialOptionFilterState } from '../../containers/Trading/Markets/Options/Filter/filterSlice';
import type { TypedGroupviewPanelConfigState } from '../../types/LayoutConfig';
import type { GeneralMarketTab } from '../MarketTabs.types';
import { type MarketTab, MarketTabType } from '../MarketTabs.types';
import { fillToNumColumns } from './fillToNumColumns';

export const mapTabToPanel = (tab: MarketTab): TypedGroupviewPanelConfigState => {
  const shared = {
    id: tab.id,
    title: tab.label,
  };
  const defaultParams = { editable: true, closable: true };
  switch (tab.type) {
    case MarketTabType.Option:
      return {
        ...shared,
        contentComponent: 'options',
        params: {
          ...defaultParams,
          type: 'options',
          optionFilter: initialOptionFilterState.optionFilter,
          strikeFilter: initialOptionFilterState.strikeFilter,
          ...tab.data,
        },
      };
    case MarketTabType.OrderDetails:
      return {
        ...shared,
        contentComponent: 'orderDetails',
        params: {
          ...defaultParams,
          type: 'orderDetails',
          orderID: tab.orderID,
          openTab: tab.openTab,
          orderType: 'order',
        },
      };
    case MarketTabType.PriceBlotter:
      return {
        ...shared,
        contentComponent: 'priceGrid',
        params: {
          ...defaultParams,
          type: 'priceGrid',
          ...tab.data,
        },
      };
    case MarketTabType.DeepDive:
      return {
        ...shared,
        contentComponent: 'deepDive',
        params: {
          ...defaultParams,
          type: 'deepDive',
          symbol: tab.symbol,
          selectedMarkets: tab.data.selectedMarkets,
          selectedMarketAccounts: tab.data.selectedMarketAccounts,
          chartResolution: tab.data.chartResolution ?? ('60' as ResolutionString),
        },
      };
    case MarketTabType.ReconDetails:
      return {
        ...shared,
        contentComponent: 'reconMismatchesDetails',
        params: {
          ...defaultParams,
          type: 'reconMismatchesDetails',
          mismatchIDs: tab.mismatchIDs,
        },
      };
    case MarketTabType.Multileg:
      return {
        ...shared,
        contentComponent: 'multilegDeepDive',
        params: {
          ...defaultParams,
          type: 'multilegDeepDive',
          resolvedSymbol: tab.data.resolvedSymbol,
          instrumentType: tab.data.instrumentType,
        },
      };
    case MarketTabType.AccountLedgerEventsDetails:
      return {
        ...shared,
        contentComponent: 'accountLedgerEvents',
        params: {
          ...defaultParams,
          type: 'accountLedgerEvents',
          account: tab.account,
          accountType: tab.accountType,
          asset: tab.asset,
        },
      };
    case MarketTabType.PositionAutoHedging:
      return {
        ...shared,
        contentComponent: 'positionAutoHedgingRules',
        params: {
          ...defaultParams,
          type: 'positionAutoHedgingRules',
        },
      };
    case MarketTabType.CareOrders:
      return {
        ...shared,
        contentComponent: 'careOrders',
        params: {
          ...defaultParams,
          type: 'careOrders',
        },
      };
    case MarketTabType.Market:
      return {
        ...shared,
        contentComponent: 'marketCards',
        params: {
          ...defaultParams,
          type: 'marketCards',
          sortCards: false,
          columns: fillToNumColumns(tab.columns ?? []),
          defaults: tab.defaults,
        },
      };
    // Treat tab without a type as a Market tab
    default: {
      const legacyTab: never = tab;

      return {
        ...shared,
        contentComponent: 'marketCards',
        params: {
          ...defaultParams,
          type: 'marketCards',
          sortCards: false,
          columns: fillToNumColumns((legacyTab as GeneralMarketTab).columns ?? []),
          defaults: (legacyTab as GeneralMarketTab).defaults,
        },
      };
    }
  }
};
