import { createSlice, freeze, type PayloadAction } from '@reduxjs/toolkit';
import {
  EMPTY_OBJECT,
  type AmountObject,
  type MarketAccount,
  type RangeSelectObject,
  type WebsocketError,
} from '@talos/kyoko';
import type { OptionMarketTabData } from 'providers/MarketTabs.types';
import { DEFAULT_OPTION_COLUMNS_IS_VISIBLE } from '../types';
import type { OptionFilteringMeta, OptionFilteringState } from './types';
import { validateSymbol } from './utils';

export const initialOptionFilterState: OptionFilteringState = {
  optionFilter: {
    currency: 'BTC',
    market: 'deribit',
    expiry: null,
    marketAccountName: null,
  },
  strikeFilter: {},
  meta: {
    expirationByMarketByCurrencyIdentity: new Map(),
    optionSecurityBySymbol: new Map(),
    marketAccountsByMarket: new Map(),
  },
  underlyingAmountByExpiry: {},
  loading: true,
  columnDefsVisibility: DEFAULT_OPTION_COLUMNS_IS_VISIBLE,
  error: {},
  tabId: '',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState: initialOptionFilterState,
  reducers: {
    updateMeta: (state, action: PayloadAction<OptionFilteringMeta>) => {
      state.meta = freeze(action.payload);
    },
    hydratePersistedTabState: (state, action: PayloadAction<Partial<OptionMarketTabData>>) => {
      state.strikeFilter = action.payload.strikeFilter || EMPTY_OBJECT;
      state.optionFilter = validateSymbol(action.payload.optionFilter || state.optionFilter, state.meta);
      state.columnDefsVisibility = action.payload.columnDefsVisibility ?? DEFAULT_OPTION_COLUMNS_IS_VISIBLE;
      state.loading = false;
    },
    updateTabId: (state, action: PayloadAction<string>) => {
      state.tabId = action.payload;
    },
    updateCurrency: (state, action: PayloadAction<string>) => {
      if (state.optionFilter.currency !== action.payload) {
        state.optionFilter.currency = action.payload;
        // update the underlying code to be equal to the currency
        state.optionFilter.underlyingCode = action.payload;

        // If changing currency, remove strike range filtering
        delete state.strikeFilter.strikePrice;
        // And unset underlying price
        state.underlyingAmountByExpiry = {};
      }
      state.optionFilter = validateSymbol(state.optionFilter, state.meta);
    },
    updateUnderlyingCode: (state, action: PayloadAction<string>) => {
      state.optionFilter.underlyingCode = action.payload;
    },
    updateMarket: (state, action: PayloadAction<string>) => {
      state.optionFilter.market = action.payload;
      state.optionFilter.underlyingCode = state.optionFilter.currency;
      state.optionFilter = validateSymbol(state.optionFilter, state.meta);
    },
    updateMarketAccount: (state, action: PayloadAction<MarketAccount | undefined>) => {
      if (action.payload?.MarketAccountID) {
        state.optionFilter.marketAccountName = action.payload?.Name;
      }
    },
    updateExpiry: (state, action: PayloadAction<string>) => {
      state.optionFilter.expiry = action.payload;
    },
    // UnderlyingPrice
    updateUnderlyingAmount: (
      state,
      action: PayloadAction<{ expiry: string; value: AmountObject | null; baseCurrency?: string }>
    ) => {
      const { value, expiry, baseCurrency } = action.payload;
      if (value === null) {
        delete state.underlyingAmountByExpiry[expiry];
        return;
      }
      // Ensure that updates to underlying is done to the same currency it was sent to update.
      if (baseCurrency === state.optionFilter.currency) {
        state.underlyingAmountByExpiry[expiry] = value;
      }
    },
    // strikes
    updateATM: (state, action: PayloadAction<number | null>) => {
      state.strikeFilter = action.payload ? { atm: action.payload } : {};
    },
    updateStrikeRange: (state, action: PayloadAction<RangeSelectObject | undefined>) => {
      if (action.payload === undefined) {
        state.strikeFilter.strikePrice = undefined;
        return;
      }
      state.strikeFilter = {
        strikePrice: {
          to: action.payload.to ?? state.strikeFilter.strikePrice?.to,
          from: action.payload.from ?? state.strikeFilter.strikePrice?.from,
        },
      };

      if (state.strikeFilter.strikePrice) {
        if (action.payload.from === null) {
          delete state.strikeFilter.strikePrice.from;
        }
        if (action.payload.to === null) {
          delete state.strikeFilter.strikePrice.to;
        }
      }
    },
    updateDeltaRange: (state, action: PayloadAction<RangeSelectObject | undefined>) => {
      if (action.payload === undefined) {
        state.strikeFilter.delta = undefined;
        return;
      }
      state.strikeFilter = {
        delta: {
          to: action.payload.to ?? state.strikeFilter.delta?.to,
          from: action.payload.from ?? state.strikeFilter.delta?.from,
        },
      };

      if (state.strikeFilter.delta) {
        if (action.payload.from === null) {
          delete state.strikeFilter.delta.from;
        }
        if (action.payload.to === null) {
          delete state.strikeFilter.delta.to;
        }
      }
    },
    updateColumnDefsVisibility: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.columnDefsVisibility = action.payload;
    },
    updateMarketDataError: (state, action: PayloadAction<WebsocketError['msg'] | undefined>) => {
      state.error.marketData = action.payload;
    },
  },
});

export const {
  updateCurrency,
  updateUnderlyingCode,
  updateMarket,
  updateExpiry,
  updateMeta,
  updateATM,
  updateStrikeRange,
  updateDeltaRange,
  hydratePersistedTabState,
  updateUnderlyingAmount,
  updateMarketAccount,
  updateColumnDefsVisibility,
  updateMarketDataError,
  updateTabId,
} = filterSlice.actions;
