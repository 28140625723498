import type {
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  GridApi,
  GridOptions,
  RowClickedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { isEmpty, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import type { UseBlotterTableProps } from '.';
import type { BlotterTableSort, Column } from '..';
import { useMixpanel } from '../../../contexts';
import { MixpanelEvent, MixpanelEventProperty } from '../../../tokens';
import { getAllNodesInGroup, safeGridApi } from '../utils';
import { getBlotterTableSort } from './getBlotterTableSort';

export function useBlotterTableEventHandlers<R>(
  {
    columns,
    sort,
    onColumnsChanged,
    onSortChanged,
    onRowClicked,
    getColumns,
  }: Pick<UseBlotterTableProps<R>, 'columns' | 'sort' | 'onColumnsChanged' | 'onSortChanged'> & {
    onRowClicked?: GridOptions<R>['onRowClicked'];
    getColumns: () => Column[];
  },
  api?: GridApi
) {
  const mixpanel = useMixpanel();
  // Column ordering, width, and visibility
  const previousColumns = useRef(columns);
  useEffect(() => {
    function handleColumnsChanged(
      params: ColumnVisibleEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnRowGroupChangedEvent
    ) {
      if (
        params.type === 'columnVisible' &&
        params.source !== 'api' /* Fired when we programmatically trigger hide/unhide of columns */ &&
        params.source !== 'toolPanelUi' /* Fired when the user clicks one checkbox in the column picker */ &&
        params.source !== 'columnMenu' /* Fired when the user clicks the "select all" checkbox in the column picker */
      ) {
        // Prevent running columns changed hook for non-user-events or when resize is not finished
        return;
      }

      // Skip unfinished or non-user-initiated column moves and resizes
      if (
        (params.type === 'columnMoved' && (params.finished === false || params.source !== 'uiColumnMoved')) ||
        (params.type === 'columnResized' && (params.finished === false || params.source !== 'uiColumnResized'))
      ) {
        return;
      }

      if (params.type === 'columnRowGroupChanged' && params.source === 'toolPanelUi') {
        // The user has changed the row grouping by dragging and dropping. We want to track this in mixpanel.
        mixpanel.track(MixpanelEvent.DragAndDropGrouping, {
          [MixpanelEventProperty.Columns]: params.columns?.map(column => column.getColId()),
        });
      }

      if (params.column && params.type === 'columnVisible' && params.source === 'toolPanelUi') {
        // The user has changed column visibility in the tool panel. We want to track this in mixpanel.
        mixpanel.track(params.column.isVisible() ? MixpanelEvent.ShowBlotterColumn : MixpanelEvent.HideBlotterColumn, {
          [MixpanelEventProperty.Column]: params.column.getColId(),
        });
      }

      if (onColumnsChanged) {
        const next = getColumns();
        if (!isEqual(previousColumns.current, next)) {
          onColumnsChanged(next, params.api);
          previousColumns.current = next;
        }
      }
    }

    safeGridApi(api)?.addEventListener('columnVisible', handleColumnsChanged);
    safeGridApi(api)?.addEventListener('columnMoved', handleColumnsChanged);
    safeGridApi(api)?.addEventListener('columnResized', handleColumnsChanged);
    safeGridApi(api)?.addEventListener('columnRowGroupChanged', handleColumnsChanged);
    return () => {
      safeGridApi(api)?.removeEventListener('columnVisible', handleColumnsChanged);
      safeGridApi(api)?.removeEventListener('columnMoved', handleColumnsChanged);
      safeGridApi(api)?.removeEventListener('columnResized', handleColumnsChanged);
      safeGridApi(api)?.removeEventListener('columnRowGroupChanged', handleColumnsChanged);
    };
  }, [api, onColumnsChanged, columns, getColumns, mixpanel]);

  // Sorting
  const previousSort = useRef<BlotterTableSort<R> | undefined>(sort);
  useEffect(() => {
    function handleSortChanged(params: SortChangedEvent) {
      // Must check for `source` here so that we only run this when the _user click_ is triggering the event.
      if (onSortChanged && params.source === 'uiColumnSorted') {
        const sort = getBlotterTableSort<R>(params.api);
        if (!isEmpty(sort)) {
          if (!isEqual(previousSort.current, sort)) {
            onSortChanged(sort);
            previousSort.current = sort;
          }
        }
      }
    }

    safeGridApi(api)?.addEventListener('sortChanged', handleSortChanged);
    return () => {
      safeGridApi(api)?.removeEventListener('sortChanged', handleSortChanged);
    };
  }, [api, onSortChanged]);

  useEffect(() => {
    function handleRowClicked(params: RowClickedEvent<R>) {
      if (params.data) {
        onRowClicked?.(params);
      }

      // If we are clicking on a group node, we want to (un)select all the children within the group as well.
      if (params.node.group) {
        // The selection state toggling has already happened at this point, so getting this isSelected gives us the new selected state!
        const newSelectedState = params.node.isSelected();

        // There's a strange case where isSelected returns undefined if the group contents are partially selected, but I can't replicate that....
        // For now just return early. Maybe AgGrid's documentation is incorrect? Can only make it return true or false.
        if (newSelectedState === undefined) {
          return;
        }
        // Apply this new selected state to all children within this group recursively
        const nodes = [params.node, ...getAllNodesInGroup(params.node)];
        params.api.setNodesSelected({ nodes, newValue: newSelectedState, source: 'rowClicked' });
      }
    }

    safeGridApi(api)?.addEventListener('rowClicked', handleRowClicked);
    return () => {
      safeGridApi(api)?.removeEventListener('rowClicked', handleRowClicked);
    };
  }, [api, onRowClicked]);
}
