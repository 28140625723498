import { useTheme } from 'styled-components';

import {
  Checkbox,
  Flex,
  InlineFormattedNumber,
  Text,
  useCurrenciesContext,
  useMarketAccountsContext,
} from '@talos/kyoko';

import type { TransferInstruction } from 'containers/Portfolio/types';
import { HeaderMarketAccountLogo } from '../../components/HeaderMarketAccountLogo';
import { OngoingTransfersWarning } from '../OngoingTransfersWarning/OngoingTransfersWarning';
import { AvailableBalanceCell } from '../SettlementBody/AvailableBalanceCell';
import { PotentialAccountWarning } from './PotentialAccountWarning';
import { TransferInstructionTr } from './styles';

export interface SettlePositionRowProps {
  transferInstruction: TransferInstruction;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export const SettlePositionRow = ({ transferInstruction, checked, onCheckedChange }: SettlePositionRowProps) => {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { currenciesBySymbol } = useCurrenciesContext();
  const { backgroundModal } = useTheme();
  const currency = currenciesBySymbol.get(transferInstruction.Currency);

  const fromAccount = marketAccountsByName.get(transferInstruction.SourceMarketAccount)?.DisplayName;
  const toAccount = marketAccountsByName.get(transferInstruction.DestinationMarketAccount)?.DisplayName;

  return (
    <TransferInstructionTr data-testid="settlement-instruction-row">
      <td>
        <Checkbox checked={checked} onChange={event => onCheckedChange(event.target.checked)} />
      </td>
      <td data-testid="settlement-instruction-row-market">
        <HeaderMarketAccountLogo marketAccountName={transferInstruction.MarketAccount} />
      </td>
      <td data-testid="settlement-instruction-row-balance-available">
        {currency && <AvailableBalanceCell marketAccount={transferInstruction.MarketAccount} currency={currency} />}
      </td>
      <td style={{ textAlign: 'right' }} data-testid="settlement-instruction-row-settle-amount">
        <InlineFormattedNumber
          number={transferInstruction.Amount}
          currency={transferInstruction.Currency}
          increment={currency?.DefaultIncrement}
          background={backgroundModal}
        />
      </td>
      <td data-testid="settlement-instruction-row-from-account">
        <Text>{fromAccount}</Text>
      </td>
      <td data-testid="settlement-instruction-row-to-account">
        <Flex alignItems="center" gap="spacingSmall">
          <Text>{toAccount}</Text>
          <PotentialAccountWarning transferInstruction={transferInstruction} />
          <OngoingTransfersWarning
            marketAccount={transferInstruction.MarketAccount}
            currency={transferInstruction.Currency}
          />
        </Flex>
      </td>
    </TransferInstructionTr>
  );
};
