import { HStack, Icon, IconName, type Column, type ColumnDef } from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import type { ImportedCareOrder } from './types';

export const PREVIEW_COLUMNS: Column[] = [
  {
    type: 'security',
    field: 'Symbol',
    title: 'Symbol',
    width: 80,
  },
  {
    type: 'side',
    field: 'Side',
  },
  {
    type: 'size',
    field: 'OrderQty',
    title: 'Order Qty',
    params: {
      currencyField: 'Currency',
    },
  },
  {
    type: 'text',
    field: 'Group',
    width: 100,
  },
  {
    type: 'text',
    field: 'Annotations.ETP.InitiatingFrom',
    title: 'Initiating From',
  },
  {
    type: 'custom',
    field: 'warning',
    title: 'Warning',
    flex: 1,
    params: {
      cellRenderer: ({ value }: ICellRendererParams<ImportedCareOrder, ImportedCareOrder['warning']>) =>
        value == null ? null : (
          <HStack gap="spacingSmall">
            <Icon icon={IconName.ExclamationSolid} color="colorTextWarning" size={16} /> {value}
          </HStack>
        ),
    },
  },
  {
    type: 'text',
    field: 'Currency',
    title: 'Currency',
    hide: true,
  },
  {
    type: 'text',
    field: 'Comments',
    hide: true,
  },
  {
    type: 'text',
    field: 'Group',
    hide: true,
  },
  {
    type: 'text',
    field: 'Counterparty',
    hide: true,
  },
  {
    type: 'text',
    field: 'ClOrdID',
    title: 'ClOrdID',
    hide: true,
  },
] satisfies ColumnDef<ImportedCareOrder>[];
