export const Z_INDEX = {
  adminBlotterHeader: 100,
  temporaryPanel: 110,
  oms: 1000,
  blotters: 1010,
  drawer: 1020,
  popover: 1030,
  modal: 1040,
  parentDropdown: 1049,
  dropdown: 1050,
  adminHeader: 1060,
  infoBar: 2010,
  globalToast: 2020,
};
