import {
  useObservable,
  useSubscription,
  wsStitchWith,
  type HedgeOrderStatus,
  type Order,
  type RequestStream,
} from '@talos/kyoko';
import { useEffect, useRef, useState } from 'react';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { useHedgeOrderStatuses } from '../providers/HedgeOrderStatusProvider';

export interface UseOrderProps {
  orderID?: string;
  parentOrderID?: string;
  parentRFQID?: string;
  tag: string;
}

/**
 * Performs a WS subscription to `Order` given an `orderID`, and maintains the state of that order
 * in a `useState`. No subscription is made if `orderID` is missing. If `orderID` is provided, but
 * the order does not exist in the system, the returned state will not be updated as the order
 * could exist in the future.
 * @param orderID string
 * @param parentOrderID string
 * @param parentRFQID string
 * @param tag string
 */
export function useOrder({ orderID, parentOrderID, parentRFQID, tag }: UseOrderProps): Order | undefined {
  const [request, setRequest] = useState<RequestStream | null>(null);
  const [order, setOrder] = useState<Order>();
  const { data: subscription } = useSubscription(request, { replay: false });
  const latestHedgeOrderStatus = useRef<HedgeOrderStatus | undefined>();
  const { hedgeOrderStatusObs } = useHedgeOrderStatuses();

  const observable = useObservable(
    () =>
      subscription.pipe(
        filter((json: { data: Order[] }) => json.data.length > 0),
        wsStitchWith({
          secondarySource: hedgeOrderStatusObs,
          getPrimaryTypeKey: order => order.OrderID,
          getSecondaryTypeKey: hos => hos.InitiatingOrderID,
          stitch: (newOrder, hos) => {
            if (hos) {
              newOrder.enrichWithHedgeOrderStatus(hos);
            }
            // The `Order` subscription update `{ initial: false }` does not carry the `HedgeOrderStatus`.
            // We need to reuse the latest one we received from the `Order` or `HedgeOrderStatus` subscription
            // as `wsStitchWith` would overwrite the whole order from the update and we would lose the
            // `order.HedgeOrderStatus` property, causing the DDH section to disappear in the UI.
            if (newOrder.HedgeOrderStatus) {
              latestHedgeOrderStatus.current = newOrder.HedgeOrderStatus;
            } else {
              newOrder.HedgeOrderStatus = latestHedgeOrderStatus.current;
            }
            return newOrder;
          },
        }),
        map((json: { data: Order[] }) => json.data.at(-1))
      ),
    [subscription, hedgeOrderStatusObs]
  );

  useEffect(() => {
    setOrder(undefined);
    if (orderID == null && parentOrderID == null && parentRFQID == null) {
      setRequest(null);
    } else {
      const sub = observable.subscribe(setOrder);
      setRequest({
        tag: `${tag}/useOrder`,
        name: 'Order',
        // IncludeHedgeOrderStatus: true, - For backend compatibility. Remove once on 2.49
        ...(orderID != null && { OrderID: orderID }),
        ...(parentOrderID != null && { ParentOrderID: parentOrderID }),
        ...(parentRFQID != null && { ParentRFQID: parentRFQID }),
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, [observable, orderID, parentOrderID, parentRFQID, tag]);

  return order;
}
