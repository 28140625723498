import styled from 'styled-components';

export const Wrapper = styled.label<{ isDragging: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(var(--spacings-16) * 1px);
  height: 100%;
  width: 100%;
  background: var(--backgroundInput);
  border: 1px dashed var(--borderColorInput);
  border-radius: calc(var(--borderRadiusDefault) * 1px);
  cursor: pointer;
  transition: border-color 200ms;
  &:hover {
    border-color: var(--borderColorInputHover);
  }
  ${({ isDragging }) =>
    isDragging &&
    `
    border-color: var(--borderColorInputHover);
  `}
`;
