import type { MessageDescriptor } from 'react-intl';
import { intlDefaultFormatter, type IntlWithFormatter } from '../contexts/IntlContext';
import { orderSliceMessages } from '../providers/WLOrderForm/state/OrderSlice/messages';

export interface FieldData<T = any> {
  name: string;
  value: T | undefined;
  isTouched: boolean;
  isDisabled: boolean;
  isRequired: boolean;
  errors: FieldValidationResult[];
  isVisible: boolean;
  placeholder: string;
}

export enum FieldValidationLevel {
  Error = 'Error',
  Warning = 'Warning',
}

/**
 * This is an internal property set by Fields used to distinguish errors created by either invariant checks or manually defined rules
 * Users of FieldValidationResult should never manually set this property
 */
export enum FieldValidationType {
  Invariant = 'Invariant',
  Rule = 'Rule',
}
export type FieldValidationResult =
  | {
      message: string;
      level: FieldValidationLevel;
      type?: FieldValidationType;
    }
  | {
      message: MessageDescriptor; // MessageDescriptor is an Intl Recognized message
      values: Record<string, any> | undefined; // Values for the MessageDescriptor
      level: FieldValidationLevel;
      type?: FieldValidationType;
    };

// not the React context - can be anything such as the form state the field belongs, in order to apply cross field validation
export type FieldValidationRule<F extends BaseField<FieldData, V>, C = any, V = any> = (
  field: F,
  context?: C
) => FieldValidationResult | null;

export abstract class BaseField<T extends FieldData<V>, V = string> {
  protected readonly data: T;
  constructor(data: T) {
    this.data = data;
  }

  public get name(): string {
    return this.data.name;
  }

  public get displayValue(): string {
    return this.data.value != null ? `${this.data.value}` : '';
  }

  public abstract get value(): V | undefined;

  public get placeholder(): string {
    return this.data.placeholder;
  }

  public get isTouched(): boolean {
    return this.data.isTouched;
  }

  public get isVisible(): boolean {
    return this.data.isVisible;
  }

  public get isDisabled(): boolean {
    return this.data.isDisabled;
  }

  public get isRequired(): boolean {
    return this.data.isRequired;
  }

  public get hasValue(): boolean {
    return this.data.value != null && this.data.value !== '';
  }

  public get errors(): FieldValidationResult[] {
    return this.data.errors.filter(e => e.level === FieldValidationLevel.Error);
  }

  public get warnings(): FieldValidationResult[] {
    return this.data.errors.filter(e => e.level === FieldValidationLevel.Warning);
  }

  public get hasError(): boolean {
    return this.errors.length > 0;
  }

  public get hasWarning(): boolean {
    return this.warnings.length > 0;
  }

  public get errorString(): string {
    return this.getTranslatedErrorString(intlDefaultFormatter);
  }

  public get warningString(): string {
    return this.getTranslatedWarningString(intlDefaultFormatter);
  }

  private getTranslatedValuesFromResult(
    result: FieldValidationResult,
    intl: IntlWithFormatter
  ): Record<string, any> | undefined {
    if (typeof result.message === 'string') {
      return undefined;
    }

    if ('values' in result && result.values) {
      return Object.entries(result.values).reduce((acc, [key, value]) => {
        acc[key] = key in orderSliceMessages ? intl.formatMessage(orderSliceMessages[key], value) : value;
        return acc;
      }, {} as Record<string, any>);
    }

    return undefined;
  }
  public getTranslatedErrorString(intl: IntlWithFormatter): string {
    if (!this.data.isTouched) {
      return '';
    }
    return this.errors
      .map(result => {
        if (typeof result.message === 'string') {
          return result.message;
        }
        return intl.formatMessage(result.message, this.getTranslatedValuesFromResult(result, intl));
      })
      .join(', ');
  }

  public getTranslatedWarningString(intl: IntlWithFormatter): string {
    if (!this.data.isTouched) {
      return '';
    }
    return this.warnings
      .map(result => {
        if (typeof result.message === 'string') {
          return result.message;
        }
        return intl.formatMessage(result.message, 'values' in result ? result.values : undefined);
      })
      .join(', ');
  }

  /**
   * Determines if the field has an invalid value, not based on the field's validation rules, but on its current state.
   */
  public get hasInvalidValue(): boolean {
    if (this.isDisabled) {
      // if disabled, we don't consider the value as invalid
      return false;
    }
    if (this.hasError) {
      return true;
    }
    if (this.isRequired && !this.hasValue) {
      return true;
    }

    return false;
  }

  public abstract setTouched(value: boolean): BaseField<T, V>;
  public abstract setIsVisible(value: boolean): BaseField<T, V>;
  public abstract setIsRequired(value: boolean): BaseField<T, V>;
  public abstract updateValue(value, isSystemOverride?: boolean): BaseField<T, V>;
  public abstract setDisabled(value: boolean): BaseField<T, V>;
  public abstract validate(rules?: FieldValidationRule<any>[], context?: any): BaseField<T, V>;
}
